/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

// #region : blog
export const getAllBlogRequest = createAction('GET_ALL_BLOG_REQUEST');
export const getAllBlogSuccess = createAction('GET_ALL_BLOG_SUCCESS');
export const getAllBlogFailure = createAction('GET_ALL_BLOG_FAILURE');

export const createBlogRequest = createAction('CREATE_BLOG_REQUEST');
export const createBlogSuccess = createAction('CREATE_BLOG_SUCCESS');
export const createBlogFailure = createAction('CREATE_BLOG_FAILURE');
export const resetCreateBlogState = createAction('RESET_CREATE_BLOG_STATE');

export const updateBlogRequest = createAction('UPDATE_BLOG_REQUEST');
export const updateBlogSuccess = createAction('UPDATE_BLOG_SUCCESS');
export const updateBlogFailure = createAction('UPDATE_BLOG_FAILURE');
export const resetUpdateBlogState = createAction('RESET_UPDATE_BLOG_STATE');

export const deleteBlogRequest = createAction('DELETE_BLOG_REQUEST');
export const deleteBlogSuccess = createAction('DELETE_BLOG_SUCCESS');
export const deleteBlogFailure = createAction('DELETE_BLOG_FAILURE');
export const resetDeleteBlogState = createAction('RESET_DELETE_BLOG_STATE');
// #endregion

// #region : voucher
export const getAllVoucherRequest = createAction('GET_ALL_VOUCHER_REQUEST');
export const getAllVoucherSuccess = createAction('GET_ALL_VOUCHER_SUCCESS');
export const getAllVoucherFailure = createAction('GET_ALL_VOUCHER_FAILURE');

export const createVoucherRequest = createAction('CREATE_VOUCHER_REQUEST');
export const createVoucherSuccess = createAction('CREATE_VOUCHER_SUCCESS');
export const createVoucherFailure = createAction('CREATE_VOUCHER_FAILURE');
export const resetCreateVoucherState = createAction('RESET_CREATE_VOUCHER_STATE');

export const updateVoucherRequest = createAction('UPDATE_VOUCHER_REQUEST');
export const updateVoucherSuccess = createAction('UPDATE_VOUCHER_SUCCESS');
export const updateVoucherFailure = createAction('UPDATE_VOUCHER_FAILURE');
export const resetUpdateVoucherState = createAction('RESET_UPDATE_VOUCHER_STATE');

export const deleteVoucherRequest = createAction('DELETE_VOUCHER_REQUEST');
export const deleteVoucherSuccess = createAction('DELETE_VOUCHER_SUCCESS');
export const deleteVoucherFailure = createAction('DELETE_VOUCHER_FAILURE');
export const resetDeleteVoucherState = createAction('RESET_DELETE_VOUCHER_STATE');
// #endregion

// #region : gift
export const getAllGiftRequest = createAction('GET_ALL_GIFT_REQUEST');
export const getAllGiftSuccess = createAction('GET_ALL_GIFT_SUCCESS');
export const getAllGiftFailure = createAction('GET_ALL_GIFT_FAILURE');

export const createGiftRequest = createAction('CREATE_GIFT_REQUEST');
export const createGiftSuccess = createAction('CREATE_GIFT_SUCCESS');
export const createGiftFailure = createAction('CREATE_GIFT_FAILURE');
export const resetCreateGiftState = createAction('RESET_CREATE_GIFT_STATE');

export const updateGiftRequest = createAction('UPDATE_GIFT_REQUEST');
export const updateGiftSuccess = createAction('UPDATE_GIFT_SUCCESS');
export const updateGiftFailure = createAction('UPDATE_GIFT_FAILURE');
export const resetUpdateGiftState = createAction('RESET_UPDATE_GIFT_STATE');

export const deleteGiftRequest = createAction('DELETE_GIFT_REQUEST');
export const deleteGiftSuccess = createAction('DELETE_GIFT_SUCCESS');
export const deleteGiftFailure = createAction('DELETE_GIFT_FAILURE');
export const resetDeleteGiftState = createAction('RESET_DELETE_GIFT_STATE');
// #endregion

// #region config

export const saveConfigRequest = createAction('SAVE_CONFIG_REQUEST');
export const saveConfigSuccess = createAction('SAVE_CONFIG_SUCCESS');
export const saveConfigFailure = createAction('SAVE_CONFIG_FAILURE');
export const resetSaveConfigState = createAction('RESET_SAVE_CONFIG_STATE');

export const getConfigAppRequest = createAction('GET_CONFIG_APP_REQUEST');
export const getConfigAppSuccess = createAction('GET_CONFIG_APP_SUCCESS');
export const getConfigAppFailure = createAction('GET_CONFIG_APP_FAILURE');
// #endregion

export const resetAccumulationState = createAction('RESET_ACCUMULATION_STATE');
