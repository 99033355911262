/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListPayoffs({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/payoffs?${payload.params}` : '/payoffs'));
    yield put(Actions.getListPayoffsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListPayoffsFailure(messages));
    }
  }
}

function* addPayoff({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/payoffs', payload));
    yield put(Actions.addPayoffSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addPayoffFailure(messages));
    }
  }
}
function* addImagePayoff({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/files/upload', payload));
    yield put(Actions.addImagePayoffSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addImagePayoffFailure(messages));
    }
  }
}

function* editPayoff({ payload }) {
  try {
    const form = new FormData();

    form.append('id', payload.id);
    form.append('user_id', payload.fullname);
    form.append('type', payload.type);
    form.append('money', payload.money);
    form.append('level', payload.level);
    form.append('date', payload.date);
    form.append('content', payload.content);
    form.append('note', payload.note);
    form.append('image', payload.image);

    if (payload.position_id) {
      form.append('position_id', payload.position_id);
    }

    const response = yield call(() => axiosMicro.post(`/payoffs/${payload.id}?_method=PATCH`, form));
    if (response?.status === 200) {
      yield put(Actions.editPayoffSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.editPayoffFailure(messages));
    }
  }
}

function* deletePayoff({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/payoffs/${payload}`));
    yield put(Actions.deletePayoffSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deletePayoffFailure(messages));
    }
  }
}
function* getDetailPayoff({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/users/${payload}`));
    yield put(Actions.getDetailPayoffSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDetailPayoffFailure(messages));
    }
  }
}

function* seenNotification({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/notification-views/${payload}/seen`));
    yield put(Actions.seenNotificationSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.seenNotificationFailure(messages));
    }
  }
}

function* ChangeStatusGold({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`users/golds/${payload.id}/status`, { status: payload.status }));
    yield put(Actions.changeStatusGoldSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.changeStatusGoldFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListPayoffsRequest, getListPayoffs);
  yield takeLatest(Actions.addPayoffRequest, addPayoff);
  yield takeLatest(Actions.addImagePayoffRequest, addImagePayoff);
  yield takeLatest(Actions.getDetailPayoffRequest, getDetailPayoff);
  yield takeLatest(Actions.editPayoffRequest, editPayoff);
  yield takeLatest(Actions.deletePayoffRequest, deletePayoff);
  yield takeLatest(Actions.seenNotificationRequest, seenNotification);
  yield takeLatest(Actions.changeStatusGoldRequest, ChangeStatusGold);
}
