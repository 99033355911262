/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* createIntern({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/interns', payload));
    yield put(Actions.createInternSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const message = error.response.data;
      yield put(Actions.createInternFailure(message));
    }
  }
}

function* updateIntern({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield call(() => axiosMicro.patch(`/interns/${payload.id}`, data));
    yield put(Actions.updateInternSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const message = error.response.data;
      yield put(Actions.updateInternFailure(message));
    }
  }
}

function* getAllIntern({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/interns', {
      params: payload,
    }));
    yield put(Actions.getAllInternsSuccess(response?.data));
  } catch (error) {
    if (error?.response?.data) {
      const message = error.response.data;
      yield put(Actions.getAllInternsFailure(message));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.createInternRequest, createIntern);
  yield takeLatest(Actions.getAllInternsRequest, getAllIntern);
  yield takeLatest(Actions.updateInternRequest, updateIntern);
}
