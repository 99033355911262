import { all } from 'redux-saga/effects';

import auth from './auth/saga';
import employee from './employee/saga';
import permissions from './permissions/saga';
import roles from './roles/saga';
import user from './user/saga';
import productType from './productType/saga';
import product from './product/saga';
import customer from './customer/saga';
import shippings from './shippings/saga';
import order from './order/saga';
import regionGroup from './regionGroup/saga';
import shippingsPrice from './shippingsPrice/saga';
import common from './common/saga';
import productPrice from './productPrice/saga';
import work from './work/saga';
import workTracking from './workTracking/saga';
import calendar from './calendar/saga';
import scheduleCalendar from './scheduleCalendar/saga';
import scheduleBookCalendar from './scheduleBookCalendar/saga';
import wareHouse from './warehouse/saga';
import notification from './notifications/saga';
import teams from './teams/saga';
import report from './report/saga';
import call from './call/saga';
import chart from './chart/saga';
import admin from './admin/saga';
import accumulation from './accumulation/saga';
import config from './config/saga';
import transport from './transport/saga';
import region from './region/saga';
import chat from './chat/saga';
import payoff from './payoff/saga';
import debt from './debt/saga';
import bankAccount from './bankAccount/saga';
import orderMFP from './orderMFP/saga';
import intern from './intern/saga';

export default function* rootSaga() {
  yield all([
    auth(),
    employee(),
    user(),
    roles(),
    permissions(),
    product(),
    productType(),
    customer(),
    shippings(),
    order(),
    regionGroup(),
    shippingsPrice(),
    common(),
    productPrice(),
    work(),
    workTracking(),
    calendar(),
    scheduleCalendar(),
    scheduleBookCalendar(),
    wareHouse(),
    notification(),
    teams(),
    report(),
    call(),
    chart(),
    admin(),
    accumulation(),
    config(),
    transport(),
    region(),
    chat(),
    payoff(),
    debt(),
    bankAccount(),
    orderMFP(),
    intern(),
  ]);
}
