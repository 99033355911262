/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllProductsCommon() {
  try {
    const response = yield call(() => axiosMicro.get('/products?limit=0'));
    yield put(Actions.getAllProductsCommonSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllProductsCommonFailure(messages));
    }
  }
}

function* getAllProductTypesCommon() {
  try {
    const response = yield call(() => axiosMicro.get('/product-types?limit=0'));
    yield put(Actions.getAllProductTypesCommonSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllProductTypesCommonFailure(messages));
    }
  }
}

function* getAllRegionGroupsCommon() {
  try {
    const response = yield call(() => axiosMicro.get('/region-groups?limit=0'));
    yield put(Actions.getAllRegionGroupsCommonSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllRegionGroupsCommonFailure(messages));
    }
  }
}

function* getPriceProductByID({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/products/${payload.id}/price`, payload.params));
    yield put(Actions.getPriceProductByIDSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getPriceProductByIDFailure(messages));
    }
  }
}

function* getOrderPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/orders/price${payload}`, {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getOrderPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrderPriceFailure(messages));
    }
  }
}

function* getOrderPriceInEdit({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/orders/price${payload}`, {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getOrderPriceInEditSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrderPriceInEditFailure(messages));
    }
  }
}

function* getAllShippingCommon() {
  try {
    const response = yield call(() => axiosMicro.get('/shippings?limit=0'));
    yield put(Actions.getAllShippingCommonSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllShippingCommonFailure(messages));
    }
  }
}

function* getPriceShippingCommon({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/shippings/${payload.id}/price${payload.params}`, {
      params: {
        timestamp: new Date().getTime().toString(),
      },
    }));
    yield put(Actions.getPriceShippingCommonSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getPriceShippingCommonFailure(messages));
    }
  }
}

function* getPriceShippingInEditCommon({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/shippings/${payload.id}/price${payload.params}`, {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getPriceShippingInEditCommonSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getPriceShippingInEditCommonFailure(messages));
    }
  }
}

function* getAllEmployeesCommon() {
  try {
    const response = yield call(() => axiosMicro.get('/users?limit=0'));
    yield put(Actions.getAllEmployeesCommonSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllEmployeesCommonFailure(messages));
    }
  }
}

function* getAllCustomerCommon({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload ? `/customers${payload}` : '/customers?limit=0', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getAllCustomerCommonSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllEmployeesCommonFailure(messages));
    }
  }
}

function* getAllWorkPlacesCommon() {
  try {
    const response = yield call(() => axiosMicro.get('/work-places?limit=0'));
    yield put(Actions.getAllWorkPlacesCommonSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllWorkPlacesCommonFailure(messages));
    }
  }
}

function* selectedItemsChange({ payload }) {
  yield put(Actions.selectedItemsChange(payload));
}

function* getAllExportWarehouseCommon({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses/exports', {
      params: {
        ...payload.params,
        include: 'order,import,exportCancel',
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllExportWarehouseCommonSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllExportWarehouseCommonFailure(messages));
    }
  }
}

function* uploadFile({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/files/upload', payload));
    yield put(Actions.uploadFileSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.uploadFileFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllProductsCommonRequest, getAllProductsCommon);
  yield takeLatest(
    Actions.getAllProductTypesCommonRequest,
    getAllProductTypesCommon,
  );
  yield takeLatest(
    Actions.getAllRegionGroupsCommonRequest,
    getAllRegionGroupsCommon,
  );
  yield takeLatest(Actions.getPriceProductByIDRequest, getPriceProductByID);
  yield takeLatest(Actions.getOrderPriceRequest, getOrderPrice);
  yield takeLatest(Actions.getOrderPriceInEditRequest, getOrderPriceInEdit);
  yield takeLatest(Actions.getAllShippingCommonRequest, getAllShippingCommon);
  yield takeLatest(Actions.getPriceShippingCommonRequest, getPriceShippingCommon);
  yield takeLatest(Actions.getPriceShippingInEditCommonRequest, getPriceShippingInEditCommon);
  yield takeLatest(Actions.getAllEmployeesCommonRequest, getAllEmployeesCommon);
  yield takeLatest(Actions.getAllCustomerCommonRequest, getAllCustomerCommon);
  yield takeLatest(Actions.getAllWorkPlacesCommonRequest, getAllWorkPlacesCommon);
  yield takeLatest(Actions.selectedItemsChange, selectedItemsChange);
  yield takeLatest(Actions.getAllExportWarehouseCommonRequest, getAllExportWarehouseCommon);
  yield takeLatest(Actions.uploadFileRequest, uploadFile);
}
