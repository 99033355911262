// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllShippingsPriceRequest = createAction('GET_ALL_SHIPPINGS_PRICE_REQUEST');
export const getAllShippingsPriceSuccess = createAction('GET_ALL_SHIPPINGS_PRICE_SUCCESS');
export const getAllShippingsPriceFailure = createAction('GET_ALL_SHIPPINGS_PRICE_FAILURE');

export const addShippingsPriceRequest = createAction('ADD_SHIPPINGS_PRICE_REQUEST');
export const addShippingsPriceSuccess = createAction('ADD_SHIPPINGS_PRICE_SUCCESS');
export const addShippingsPriceFailure = createAction('ADD_SHIPPINGS_PRICE_FAILURE');

export const editShippingsPriceRequest = createAction('EDIT_SHIPPINGS_PRICE_REQUEST');
export const editShippingsPriceSuccess = createAction('EDIT_SHIPPINGS_PRICE_SUCCESS');
export const editShippingsPriceFailure = createAction('EDIT_SHIPPINGS_PRICE_FAILURE');

export const deleteShippingsPriceRequest = createAction('DELETE_SHIPPINGS_PRICE_REQUEST');
export const deleteShippingsPriceSuccess = createAction('DELETE_SHIPPINGS_PRICE_SUCCESS');
export const deleteShippingsPriceFailure = createAction('DELETE_SHIPPINGS_PRICE_FAILURE');

export const getAllShippingsRequest = createAction('GET_ALL_SHIPPINGS_REQUEST');
export const getAllShippingsSuccess = createAction('GET_ALL_SHIPPINGS_SUCCESS');
export const getAllShippingsFailure = createAction('GET_ALL_SHIPPINGS_FAILURE');

export const getAllRegionGroupShippingsRequest = createAction('GET_ALL_REGION_GROUP_SHIPPINGS_REQUEST');
export const getAllRegionGroupShippingsSuccess = createAction('GET_ALL_REGION_GROUP_SHIPPINGS_SUCCESS');
export const getAllRegionGroupShippingsFailure = createAction('GET_ALL_REGION_GROUP_SHIPPINGS_FAILURE');

export const addMultiShippingPriceRequest = createAction('ADD_MULTI_SHIPPING_PRICE_REQUEST');
export const addMultiShippingPriceSuccess = createAction('ADD_MULTI_SHIPPING_PRICE_SUCCESS');
export const addMultiShippingPriceFailure = createAction('ADD_MULTI_SHIPPING_PRICE_FAILURE');
export const resetAddMultiShippingPriceState = createAction('RESET_ADD_MULTI_SHIPPING_PRICE_STATE');

export const resetDeleteShippingsPriceState = createAction('RESET_DELETE_SHIPPINGS_PRICE_STATE');
export const resetEditShippingsPriceState = createAction('RESET_EDIT_SHIPPINGS_PRICE_STATE');
export const resetAddShippingsPriceState = createAction('RESET_ADD_SHIPPINGS_PRICE_STATE');
export const resetShippingsPriceState = createAction('RESET_SHIPPINGS_PRICE_STATE');
