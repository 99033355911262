// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const createInternRequest = createAction('CREATE_INTERN_REQUEST');
export const createInternSuccess = createAction('CREATE_INTERN_SUCCESS');
export const createInternFailure = createAction('CREATE_INTERN_FAILURE');
export const resetCreateIntern = createAction('RESET_CREATE_INTERN');

export const updateInternRequest = createAction('UPDATE_INTERN_REQUEST');
export const updateInternSuccess = createAction('UPDATE_INTERN_SUCCESS');
export const updateInternFailure = createAction('UPDATE_INTERN_FAILURE');
export const resetUpdateIntern = createAction('RESET_UPDATE_INTERN');

export const getAllInternsRequest = createAction('GET_ALL_INTERNS_REQUEST');
export const getAllInternsSuccess = createAction('GET_ALL_INTERNS_SUCCESS');
export const getAllInternsFailure = createAction('GET_ALL_INTERNS_FAILURE');

export const resetInternState = createAction('RESET_INTERN_STATE');
