/* eslint-disable import/no-extraneous-dependencies */
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  isGetAllRevenueChartRequest: false,
  isGetAllRevenueChartSuccess: false,
  isGetAllRevenueChartFailure: false,
  listAllRevenueChart: {},

  //
  isGetAllRevenueDetailChartRequest: false,
  isGetAllRevenueChartDetailSuccess: false,
  isGetAllRevenueDetailChartFailure: false,
  listAllRevenueDetailChart: {},

  //
  isGetAllExportChartRequest: false,
  isGetAllExportChartSuccess: false,
  isGetAllExportChartFailure: false,
  listAllExportChart: {},
  //
  isGetExportChartRequest: false,
  isGetExportChartSuccess: false,
  isGetExportChartFailure: false,
  listExportChart: {},
  //
  isGetAllShippingsChartRequest: false,
  isGetAllShippingsChartSuccess: false,
  isGetAllShippingsChartFailure: false,
  getAllShippingChartState: {},
  //
  errorMessages: [],
};

const reducer = handleActions({
  [Actions.getAllRevenueChartRequest]: (state) => ({
    ...state,
    isGetAllRevenueChartRequest: true,
    isGetAllRevenueChartSuccess: false,
    isGetAllRevenueChartFailure: false,
  }),
  [Actions.getAllRevenueChartSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllRevenueChartRequest: false,
    isGetAllRevenueChartSuccess: true,
    isGetAllRevenueChartFailure: false,
    listAllRevenueChart: payload,
  }),
  [Actions.getAllRevenueChartFailure]: (state, { payload }) => ({
    ...state,
    isGetAllRevenueChartRequest: false,
    isGetAllRevenueChartSuccess: false,
    isGetAllRevenueChartFailure: true,
    errorMessages: payload,
  }),
  [Actions.getAllRevenueDetailChartRequest]: (state) => ({
    ...state,
    isGetAllRevenueChartDetailRequest: true,
    isGetAllRevenueChartDetailSuccess: false,
    isGetAllRevenueChartDetailFailure: false,
  }),
  [Actions.getAllRevenueDetailChartSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllRevenueChartDetailRequest: false,
    isGetAllRevenueChartDetailSuccess: true,
    isGetAllRevenueChartDetailFailure: false,
    listAllRevenueDetailChart: payload,
  }),
  [Actions.getAllRevenueDetailChartFailure]: (state, { payload }) => ({
    ...state,
    isGetAllRevenueChartDetailRequest: false,
    isGetAllRevenueChartDetailSuccess: false,
    isGetAllRevenueChartDetailFailure: true,
    errorMessages: payload,
  }),

  // #region
  [Actions.getAllExportChartRequest]: (state) => ({
    ...state,
    isGetAllExportChartRequest: true,
    isGetAllExportChartSuccess: false,
    isGetAllExportChartFailure: false,
  }),
  [Actions.getAllExportChartSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllExportChartRequest: false,
    isGetAllExportChartSuccess: true,
    isGetAllExportChartFailure: false,
    listAllExportChart: payload,
  }),
  [Actions.getAllExportChartFailure]: (state, { payload }) => ({
    ...state,
    isGetAllExportChartRequest: false,
    isGetAllExportChartSuccess: false,
    isGetAllExportChartFailure: true,
    errorMessages: payload,
  }),

  // #endregion
  // #region
  [Actions.getExportChartRequest]: (state) => ({
    ...state,
    isGetExportChartRequest: true,
    isGetExportChartSuccess: false,
    isGetExportChartFailure: false,
  }),
  [Actions.getExportChartSuccess]: (state, { payload }) => ({
    ...state,
    isGetExportChartRequest: false,
    isGetExportChartSuccess: true,
    isGetExportChartFailure: false,
    listExportChart: payload,
  }),
  [Actions.getExportChartFailure]: (state, { payload }) => ({
    ...state,
    isGetExportChartRequest: false,
    isGetExportChartSuccess: false,
    isGetExportChartFailure: true,
    errorMessages: payload,
  }),

  // #endregion

  // #region
  [Actions.getAllShippingsChartRequest]: (state) => ({
    ...state,
    isGetAllShippingsChartRequest: true,
    isGetAllShippingsChartSuccess: false,
    isGetAllShippingsChartFailure: false,
  }),
  [Actions.getAllShippingsChartSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllShippingsChartRequest: false,
    isGetAllShippingsChartSuccess: true,
    isGetAllShippingsChartFailure: false,
    getAllShippingChartState: payload,
  }),
  [Actions.getAllShippingsChartFailure]: (state, { payload }) => ({
    ...state,
    isGetAllShippingsChartRequest: false,
    isGetAllShippingsChartSuccess: false,
    isGetAllShippingsChartFailure: true,
    errorMessages: payload,
  }),

  // #endregion

  [Actions.resetChartState]: () => initialState,
}, initialState);

export default reducer;
