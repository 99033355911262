/* eslint-disable import/no-extraneous-dependencies */
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  //
  isGetListWareHouseRequest: false,
  isGetListWareHouseSuccess: false,
  isGetListWareHouseFailure: false,
  listWareHouse: {},

  //
  isGetListProdWareHouseRequest: false,
  isGetListProdWareHouseSuccess: false,
  isGetListProdWareHouseFailure: false,
  listProd: {},
  //
  isAddWareHouseRequest: false,
  isAddWareHouseSuccess: false,
  isAddWareHouseFailure: false,
  //
  isEditWareHouseRequest: false,
  isEditWareHouseSuccess: false,
  isEditWareHouseFailure: false,
  //
  isDeleteWareHouseRequest: false,
  isDeleteWareHouseSuccess: false,
  isDeleteWareHouseFailure: false,
  //
  isImportWarehouseRequest: false,
  isImportWarehouseSuccess: false,
  isImportWarehouseFailure: false,
  //
  isGetAllImportWarehouseRequest: false,
  isGetAllImportWarehouseSuccess: false,
  isGetAllImportWarehouseFailure: false,
  listImportWarehouse: {},
  //
  isGetStatisticWarehouseRequest: false,
  isGetStatisticWarehouseSuccess: false,
  isGetStatisticWarehouseFailure: false,
  listStatisticWarehouse: {},
  //
  isExportWarehouseRequest: false,
  isExportWarehouseSuccess: false,
  isExportWarehouseFailure: false,
  //
  isGetAllHistoryWarehouseRequest: false,
  isGetAllHistoryWarehouseSuccess: false,
  isGetAllHistoryWarehouseFailure: false,
  listHistoryWarehouse: {},
  //
  isGetDetailHistoryWarehouseRequest: false,
  isGetDetailHistoryWarehouseSuccess: false,
  isGetDetailHistoryWarehouseFailure: false,
  detailHistoryWarehouse: {},
  //
  isCreateHistoryWarehouseRequest: false,
  isCreateHistoryWarehouseSuccess: false,
  isCreateHistoryWarehouseFailure: false,
  //
  isExportWarehouseHistoryRequest: false,
  isExportWarehouseHistorySuccess: false,
  isExportWarehouseHistoryFailure: false,
  urlExportWarehouseHistory: {},
  //
  isGetAllWarehouseBorrowRequest: false,
  isGetAllWarehouseBorrowSuccess: false,
  isGetAllWarehouseBorrowFailure: false,
  listWarehouseBorrow: {},
  //

  isGetAllSupplierRequest: false,
  isGetAllSupplierSuccess: false,
  isGetAllSupplierFailure: false,
  listAllSupplier: {},
  //
  isCreateSupplierRequest: false,
  isCreateSupplierSuccess: false,
  isCreateSupplierFailure: false,
  //
  isUpdateSupplierRequest: false,
  isUpdateSupplierSuccess: false,
  isUpdateSupplierFailure: false,
  //
  isDeleteSupplierRequest: false,
  isDeleteSupplierSuccess: false,
  isDeleteSupplierFailure: false,
  //
  isGetWarehouseReportRequest: false,
  isGetWarehouseReportSuccess: false,
  isGetWarehouseReportFailure: false,
  getWarehouseReportResponse: {},
  //
  isGetListWaitingAcceptWarehouseRequest: false,
  isGetListWaitingAcceptWarehouseSuccess: false,
  isGetListWaitingAcceptWarehouseFailure: false,
  getListWaitingAcceptWarehouseResponse: {},
  //
  isChangeWaitingAcceptWarehouseRequest: false,
  isChangeWaitingAcceptWarehouseSuccess: false,
  isChangeWaitingAcceptWarehouseFailure: false,
  //
  isChangeSizeWarehouseRequest: false,
  isChangeSizeWarehouseSuccess: false,
  isChangeSizeWarehouseFailure: false,
  //
  isGetListChangeSizeWarehouseRequest: false,
  isGetListChangeSizeWarehouseSuccess: false,
  isGetListChangeSizeWarehouseFailure: false,
  getListChangeSizeWarehouseResponse: {},
  // Cancel warehouse
  isCancelWarehouseRequest: false,
  isCancelWarehouseSuccess: false,
  isCancelWarehouseFailure: false,
  //
  isCreateImportVoucherRequest: false,
  isCreateImportVoucherSuccess: false,
  isCreateImportVoucherFailure: false,
  //
  isGetAdjustInventoryRequest: false,
  isGetAdjustInventorySuccess: false,
  isGetAdjustInventoryFailure: false,
  getAdjustInventoryState: {},
  //
  isCreateAdjustInventoryRequest: false,
  isCreateAdjustInventorySuccess: false,
  isCreateAdjustInventoryFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region
    [Actions.getListWareHouseRequest]: (state) => ({
      ...state,
      isGetListWareHouseRequest: true,
      isGetListWareHouseSuccess: false,
      isGetListWareHouseFailure: false,
    }),

    [Actions.getListWareHouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetListWareHouseRequest: false,
      isGetListWareHouseSuccess: true,
      isGetListWareHouseFailure: false,
      listWareHouse: payload,
    }),
    [Actions.getListWareHouseFailure]: (state, { payload }) => ({
      ...state,
      isGetListWareHouseRequest: false,
      isGetListWareHouseSuccess: false,
      isGetListWareHouseFailure: true,
      errorMessages: payload,
    }),

    // #endregion

    // #region
    [Actions.getListProdWareHouseRequest]: (state) => ({
      ...state,
      isGetListProdWareHouseRequest: true,
      isGetListProdWareHouseSuccess: false,
      isGetListProdWareHouseFailure: false,
    }),
    [Actions.getListProdWareHouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetListProdWareHouseRequest: false,
      isGetListProdWareHouseSuccess: true,
      isGetListProdWareHouseFailure: false,
      listProd: payload,
    }),
    [Actions.getListProdWareHouseFailure]: (state, { payload }) => ({
      ...state,
      isGetListProdWareHouseRequest: false,
      isGetListProdWareHouseSuccess: false,
      isGetListProdWareHouseFailure: true,
      errorMessages: payload,
    }),

    // #endregion

    // #region
    [Actions.addWareHouseRequest]: (state) => ({
      ...state,
      isAddWareHouseRequest: true,
      isAddWareHouseSuccess: false,
      isAddWareHouseFailure: false,
    }),
    [Actions.addWareHouseSuccess]: (state) => ({
      ...state,
      isAddWareHouseRequest: false,
      isAddWareHouseSuccess: true,
      isAddWareHouseFailure: false,
    }),
    [Actions.addWareHouseFailure]: (state, { payload }) => ({
      ...state,
      isAddWareHouseRequest: false,
      isAddWareHouseSuccess: false,
      isAddWareHouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddWareHouseState]: (state) => ({
      ...state,
      isAddWareHouseRequest: false,
      isAddWareHouseSuccess: false,
      isAddWareHouseFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region

    [Actions.editWareHouseRequest]: (state) => ({
      ...state,
      isEditWareHouseRequest: true,
      isEditWareHouseSuccess: false,
      isEditWareHouseFailure: false,
    }),
    [Actions.editWareHouseSuccess]: (state) => ({
      ...state,
      isEditWareHouseRequest: false,
      isEditWareHouseSuccess: true,
      isEditWareHouseFailure: false,
    }),
    [Actions.editWareHouseFailure]: (state, { payload }) => ({
      ...state,
      isEditWareHouseRequest: false,
      isEditWareHouseSuccess: false,
      isEditWareHouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditWareHouseState]: (state) => ({
      ...state,
      isEditWareHouseRequest: false,
      isEditWareHouseSuccess: false,
      isEditWareHouseFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.deleteWareHouseRequest]: (state) => ({
      ...state,
      isDeleteWareHouseRequest: true,
      isDeleteWareHouseSuccess: false,
      isDeleteWareHouseFailure: false,
    }),
    [Actions.deleteWareHouseSuccess]: (state) => ({
      ...state,
      isDeleteWareHouseRequest: false,
      isDeleteWareHouseSuccess: true,
      isDeleteWareHouseFailure: false,
    }),
    [Actions.deleteWareHouseFailure]: (state, { payload }) => ({
      ...state,
      isDeleteWareHouseRequest: false,
      isDeleteWareHouseSuccess: false,
      isDeleteWareHouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteWareHouseState]: (state) => ({
      ...state,
      isDeleteWareHouseRequest: false,
      isDeleteWareHouseSuccess: false,
      isDeleteWareHouseFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.importWarehouseRequest]: (state) => ({
      ...state,
      isImportWarehouseRequest: true,
      isImportWarehouseSuccess: false,
      isImportWarehouseFailure: false,
    }),
    [Actions.importWarehouseSuccess]: (state) => ({
      ...state,
      isImportWarehouseRequest: false,
      isImportWarehouseSuccess: true,
      isImportWarehouseFailure: false,
    }),
    [Actions.importWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isImportWarehouseRequest: false,
      isImportWarehouseSuccess: false,
      isImportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetImportWarehouseState]: (state) => ({
      ...state,
      isImportWarehouseRequest: false,
      isImportWarehouseSuccess: false,
      isImportWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.getAllImportWarehouseRequest]: (state) => ({
      ...state,
      isGetAllImportWarehouseRequest: true,
      isGetAllImportWarehouseSuccess: false,
      isGetAllImportWarehouseFailure: false,
    }),
    [Actions.getAllImportWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllImportWarehouseRequest: false,
      isGetAllImportWarehouseSuccess: true,
      isGetAllImportWarehouseFailure: false,
      listImportWarehouse: payload,
    }),
    [Actions.getAllImportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetAllImportWarehouseRequest: false,
      isGetAllImportWarehouseSuccess: false,
      isGetAllImportWarehouseFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.getStatisticWarehouseRequest]: (state) => ({
      ...state,
      isGetStatisticWarehouseRequest: true,
      isGetStatisticWarehouseSuccess: false,
      isGetStatisticWarehouseFailure: false,
    }),
    [Actions.getStatisticWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetStatisticWarehouseRequest: false,
      isGetStatisticWarehouseSuccess: true,
      isGetStatisticWarehouseFailure: false,
      listStatisticWarehouse: payload,
    }),
    [Actions.getStatisticWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetStatisticWarehouseRequest: false,
      isGetStatisticWarehouseSuccess: false,
      isGetStatisticWarehouseFailure: true,
      errorMessages: payload,
    }),

    // #endregion

    // #region
    [Actions.exportWarehouseRequest]: (state) => ({
      ...state,
      isExportWarehouseRequest: true,
      isExportWarehouseSuccess: false,
      isExportWarehouseFailure: false,
    }),
    [Actions.exportWarehouseSuccess]: (state) => ({
      ...state,
      isExportWarehouseRequest: false,
      isExportWarehouseSuccess: true,
      isExportWarehouseFailure: false,
    }),
    [Actions.exportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isExportWarehouseRequest: false,
      isExportWarehouseSuccess: false,
      isExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportWarehouseState]: (state) => ({
      ...state,
      isExportWarehouseFailure: false,
      isExportWarehouseRequest: false,
      isExportWarehouseSuccess: false,
      errorMessages: [],
    }),

    // #endregion

    // #region
    [Actions.getAllHistoryWarehouseRequest]: (state) => ({
      ...state,
      isGetAllHistoryWarehouseRequest: true,
      isGetAllHistoryWarehouseSuccess: false,
      isGetAllHistoryWarehouseFailure: false,
    }),
    [Actions.getAllHistoryWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllHistoryWarehouseRequest: false,
      isGetAllHistoryWarehouseSuccess: true,
      isGetAllHistoryWarehouseFailure: false,
      listHistoryWarehouse: payload,
    }),
    [Actions.getAllHistoryWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetAllHistoryWarehouseRequest: false,
      isGetAllHistoryWarehouseSuccess: false,
      isGetAllHistoryWarehouseFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.getDetailHistoryWarehouseRequest]: (state) => ({
      ...state,
      isGetDetailHistoryWarehouseRequest: true,
      isGetDetailHistoryWarehouseSuccess: false,
      isGetDetailHistoryWarehouseFailure: false,
    }),
    [Actions.getDetailHistoryWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetDetailHistoryWarehouseRequest: false,
      isGetDetailHistoryWarehouseSuccess: true,
      isGetDetailHistoryWarehouseFailure: false,
      detailHistoryWarehouse: payload,
    }),
    [Actions.getDetailHistoryWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetDetailHistoryWarehouseRequest: false,
      isGetDetailHistoryWarehouseSuccess: false,
      isGetDetailHistoryWarehouseFailure: true,
      errorMessages: payload,
    }),

    // #endregion

    // #region
    [Actions.createHistoryWarehouseRequest]: (state) => ({
      ...state,
      isCreateHistoryWarehouseRequest: true,
      isCreateHistoryWarehouseSuccess: false,
      isCreateHistoryWarehouseFailure: false,
    }),
    [Actions.createHistoryWarehouseSuccess]: (state) => ({
      ...state,
      isCreateHistoryWarehouseRequest: false,
      isCreateHistoryWarehouseSuccess: true,
      isCreateHistoryWarehouseFailure: false,
    }),
    [Actions.createHistoryWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isCreateHistoryWarehouseRequest: false,
      isCreateHistoryWarehouseSuccess: false,
      isCreateHistoryWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateHistoryWarehouseState]: (state) => ({
      ...state,
      isCreateHistoryWarehouseRequest: false,
      isCreateHistoryWarehouseSuccess: false,
      isCreateHistoryWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.exportWarehouseHistoryRequest]: (state) => ({
      ...state,
      isExportWarehouseHistoryRequest: true,
      isExportWarehouseHistorySuccess: false,
      isExportWarehouseHistoryFailure: false,
    }),
    [Actions.exportWarehouseHistorySuccess]: (state, { payload }) => ({
      ...state,
      isExportWarehouseHistoryRequest: false,
      isExportWarehouseHistorySuccess: true,
      isExportWarehouseHistoryFailure: false,
      urlExportWarehouseHistory: payload,
    }),
    [Actions.exportWarehouseHistoryFailure]: (state, { payload }) => ({
      ...state,
      isExportWarehouseHistoryRequest: false,
      isExportWarehouseHistorySuccess: false,
      isExportWarehouseHistoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportWarehouseHistoryState]: (state) => ({
      ...state,
      isExportWarehouseHistoryRequest: false,
      isExportWarehouseHistorySuccess: false,
      isExportWarehouseHistoryFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.getAllWarehouseBorrowRequest]: (state) => ({
      ...state,
      isGetAllWarehouseBorrowRequest: true,
      isGetAllWarehouseBorrowSuccess: false,
      isGetAllHistoryWarehouseFailure: false,
    }),
    [Actions.getAllWarehouseBorrowSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllWarehouseBorrowRequest: false,
      isGetAllWarehouseBorrowSuccess: true,
      isGetAllWarehouseBorrowFailure: false,
      listWarehouseBorrow: payload,
    }),
    [Actions.getAllWarehouseBorrowFailure]: (state, { payload }) => ({
      ...state,
      isGetAllWarehouseBorrowRequest: false,
      isGetAllWarehouseBorrowSuccess: false,
      isGetAllWarehouseBorrowFailure: true,
      errorMessages: payload,
    }),

    // #endregion

    // #region
    [Actions.getAllSupplierRequest]: (state) => ({
      ...state,
      isGetAllSupplierRequest: true,
      isGetAllSupplierSuccess: false,
      isGetAllSupplierFailure: false,
    }),
    [Actions.getAllSupplierSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllSupplierRequest: false,
      isGetAllSupplierSuccess: true,
      isGetAllSupplierFailure: false,
      listAllSupplier: payload,
    }),
    [Actions.getAllSupplierFailure]: (state, { payload }) => ({
      ...state,
      isGetAllSupplierRequest: false,
      isGetAllSupplierSuccess: false,
      isGetAllSupplierFailure: true,
      errorMessages: payload,
    }),

    // #endregion

    // #region
    [Actions.createSupplierRequest]: (state) => ({
      ...state,
      isCreateSupplierRequest: true,
      isCreateSupplierSuccess: false,
      isCreateSupplierFailure: false,
    }),
    [Actions.createSupplierSuccess]: (state) => ({
      ...state,
      isCreateSupplierRequest: false,
      isCreateSupplierSuccess: true,
      isCreateSupplierFailure: false,
    }),
    [Actions.createSupplierFailure]: (state, { payload }) => ({
      ...state,
      isCreateSupplierRequest: false,
      isCreateSupplierSuccess: false,
      isCreateSupplierFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateSupplierState]: (state) => ({
      ...state,
      isCreateSupplierRequest: false,
      isCreateSupplierSuccess: false,
      isCreateSupplierFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region
    [Actions.updateSupplierRequest]: (state) => ({
      ...state,
      isUpdateSupplierRequest: true,
      isUpdateSupplierSuccess: false,
      isUpdateSupplierFailure: false,
    }),
    [Actions.updateSupplierSuccess]: (state) => ({
      ...state,
      isUpdateSupplierRequest: false,
      isUpdateSupplierSuccess: true,
      isUpdateSupplierFailure: false,
    }),
    [Actions.updateSupplierFailure]: (state, { payload }) => ({
      ...state,
      isUpdateSupplierRequest: false,
      isUpdateSupplierSuccess: false,
      isUpdateSupplierFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateSupplierState]: (state) => ({
      ...state,
      isUpdateSupplierRequest: false,
      isUpdateSupplierSuccess: false,
      isUpdateSupplierFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region
    [Actions.deleteSupplierRequest]: (state) => ({
      ...state,
      isDeleteSupplierRequest: true,
      isDeleteSupplierSuccess: false,
      isDeleteSupplierFailure: false,
    }),
    [Actions.deleteSupplierSuccess]: (state) => ({
      ...state,
      isDeleteSupplierRequest: false,
      isDeleteSupplierSuccess: true,
      isDeleteSupplierFailure: false,
    }),
    [Actions.deleteSupplierFailure]: (state, { payload }) => ({
      ...state,
      isDeleteSupplierRequest: false,
      isDeleteSupplierSuccess: false,
      isDeleteSupplierFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteSupplierState]: (state) => ({
      ...state,
      isDeleteSupplierRequest: false,
      isDeleteSupplierSuccess: false,
      isDeleteSupplierFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get Warehouse Report
    [Actions.getWarehouseReportRequest]: (state) => ({
      ...state,
      isGetWarehouseReportRequest: true,
      isGetWarehouseReportSuccess: false,
      isGetWarehouseReportFailure: false,
    }),
    [Actions.getWarehouseReportSuccess]: (state, { payload }) => ({
      ...state,
      isGetWarehouseReportRequest: false,
      isGetWarehouseReportSuccess: true,
      isGetWarehouseReportFailure: false,
      getWarehouseReportResponse: payload,
    }),
    [Actions.getWarehouseReportFailure]: (state, { payload }) => ({
      ...state,
      isGetWarehouseReportRequest: false,
      isGetWarehouseReportSuccess: false,
      isGetWarehouseReportFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetWarehouseReportState]: (state) => ({
      ...state,
      isGetWarehouseReportRequest: false,
      isGetWarehouseReportSuccess: false,
      isGetWarehouseReportFailure: false,
      getWarehouseReportResponse: {},
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.getListWaitingAcceptWarehouseRequest]: (state) => ({
      ...state,
      isGetListWaitingAcceptWarehouseRequest: true,
      isGetListWaitingAcceptWarehouseSuccess: false,
      isGetListWaitingAcceptWarehouseFailure: false,
    }),
    [Actions.getListWaitingAcceptWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetListWaitingAcceptWarehouseRequest: false,
      isGetListWaitingAcceptWarehouseSuccess: true,
      isGetListWaitingAcceptWarehouseFailure: false,
      getListWaitingAcceptWarehouseResponse: payload,
    }),
    [Actions.getListWaitingAcceptWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetListWaitingAcceptWarehouseRequest: false,
      isGetListWaitingAcceptWarehouseSuccess: false,
      isGetListWaitingAcceptWarehouseFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region change status waiting
    [Actions.changeWaitingAcceptWarehouseRequest]: (state) => ({
      ...state,
      isChangeWaitingAcceptWarehouseRequest: true,
      isChangeWaitingAcceptWarehouseSuccess: false,
      isChangeWaitingAcceptWarehouseFailure: false,
    }),
    [Actions.changeWaitingAcceptWarehouseSuccess]: (state) => ({
      ...state,
      isChangeWaitingAcceptWarehouseRequest: false,
      isChangeWaitingAcceptWarehouseSuccess: true,
      isChangeWaitingAcceptWarehouseFailure: false,
    }),
    [Actions.changeWaitingAcceptWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isChangeWaitingAcceptWarehouseRequest: false,
      isChangeWaitingAcceptWarehouseSuccess: false,
      isChangeWaitingAcceptWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetChangeWaitingAcceptWarehouseState]: (state) => ({
      ...state,
      isChangeWaitingAcceptWarehouseRequest: false,
      isChangeWaitingAcceptWarehouseSuccess: false,
      isChangeWaitingAcceptWarehouseFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region
    [Actions.changeSizeWarehouseRequest]: (state) => ({
      ...state,
      isChangeSizeWarehouseRequest: true,
      isChangeSizeWarehouseSuccess: false,
      isChangeSizeWarehouseFailure: false,
    }),
    [Actions.changeSizeWarehouseSuccess]: (state) => ({
      ...state,
      isChangeSizeWarehouseRequest: false,
      isChangeSizeWarehouseSuccess: true,
      isChangeSizeWarehouseFailure: false,
    }),
    [Actions.changeSizeWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isChangeSizeWarehouseRequest: false,
      isChangeSizeWarehouseSuccess: false,
      isChangeSizeWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetChangeSizeWarehouseState]: (state) => ({
      ...state,
      isChangeSizeWarehouseRequest: false,
      isChangeSizeWarehouseSuccess: false,
      isChangeSizeWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.getListChangeSizeWarehouseRequest]: (state) => ({
      ...state,
      isGetListChangeSizeWarehouseRequest: true,
      isGetListChangeSizeWarehouseSuccess: false,
      isGetListChangeSizeWarehouseFailure: false,
    }),
    [Actions.getListChangeSizeWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetListChangeSizeWarehouseRequest: false,
      isGetListChangeSizeWarehouseSuccess: true,
      isGetListChangeSizeWarehouseFailure: false,
      getListChangeSizeWarehouseResponse: payload,
    }),
    [Actions.getListChangeSizeWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetListChangeSizeWarehouseRequest: false,
      isGetListChangeSizeWarehouseSuccess: false,
      isGetListChangeSizeWarehouseFailure: true,
      errorMessages: payload,
    }),

    // #endregion

    // #region Cancel warehouse
    [Actions.cancelWarehouseRequest]: (state) => ({
      ...state,
      isCancelWarehouseRequest: true,
      isCancelWarehouseSuccess: false,
      isCancelWarehouseFailure: false,
    }),
    [Actions.cancelWarehouseSuccess]: (state) => ({
      ...state,
      isCancelWarehouseRequest: false,
      isCancelWarehouseSuccess: true,
      isCancelWarehouseFailure: false,
    }),
    [Actions.cancelWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isCancelWarehouseRequest: false,
      isCancelWarehouseSuccess: false,
      isCancelWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelWarehouse]: (state) => ({
      ...state,
      isCancelWarehouseRequest: false,
      isCancelWarehouseSuccess: false,
      isCancelWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Create order voucher
    [Actions.createImportVoucherRequest]: (state) => ({
      ...state,
      isCreateImportVoucherRequest: true,
      isCreateImportVoucherSuccess: false,
      isCreateImportVoucherFailure: false,
    }),
    [Actions.createImportVoucherSuccess]: (state) => ({
      ...state,
      isCreateImportVoucherRequest: false,
      isCreateImportVoucherSuccess: true,
      isCreateImportVoucherFailure: false,
    }),
    [Actions.createImportVoucherFailure]: (state, { payload }) => ({
      ...state,
      isCreateImportVoucherRequest: false,
      isCreateImportVoucherSuccess: false,
      isCreateImportVoucherFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateImportVoucherState]: (state) => ({
      ...state,
      isCreateImportVoucherRequest: false,
      isCreateImportVoucherSuccess: false,
      isCreateImportVoucherFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region get adjust inventory
    [Actions.getAdjustInventoryRequest]: (state) => ({
      ...state,
      isGetAdjustInventoryRequest: true,
      isGetAdjustInventorySuccess: false,
      isGetAdjustInventoryFailure: false,
    }),
    [Actions.getAdjustInventorySuccess]: (state, { payload }) => ({
      ...state,
      isGetAdjustInventoryRequest: false,
      isGetAdjustInventorySuccess: true,
      isGetAdjustInventoryFailure: false,
      getAdjustInventoryState: payload,
    }),
    [Actions.getAdjustInventoryFailure]: (state, { payload }) => ({
      ...state,
      isGetAdjustInventoryRequest: false,
      isGetAdjustInventorySuccess: false,
      isGetAdjustInventoryFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region create adjust inventory
    [Actions.createAdjustInventoryRequest]: (state) => ({
      ...state,
      isCreateAdjustInventoryRequest: true,
      isCreateAdjustInventorySuccess: false,
      isCreateAdjustInventoryFailure: false,
    }),
    [Actions.createAdjustInventorySuccess]: (state) => ({
      ...state,
      isCreateAdjustInventoryRequest: false,
      isCreateAdjustInventorySuccess: true,
      isCreateAdjustInventoryFailure: false,
    }),
    [Actions.createAdjustInventoryFailure]: (state, { payload }) => ({
      ...state,
      isCreateAdjustInventoryRequest: false,
      isCreateAdjustInventorySuccess: false,
      isCreateAdjustInventoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateAdjustInventory]: (state) => ({
      ...state,
      isCreateAdjustInventoryRequest: false,
      isCreateAdjustInventorySuccess: false,
      isCreateAdjustInventoryFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.resetWareHouseState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
