/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const getAllRevenueChartRequest = createAction('GET_ALL_REVENUE_CHART_REQUEST');
export const getAllRevenueChartSuccess = createAction('GET_ALL_REVENUE_CHART_SUCCESS');
export const getAllRevenueChartFailure = createAction('GET_ALL_REVENUE_CHART_FAILURE');

export const getAllExportChartRequest = createAction('GET_ALL_EXPORT_CHART_REQUEST');
export const getAllExportChartSuccess = createAction('GET_ALL_EXPORT_CHART_SUCCESS');
export const getAllExportChartFailure = createAction('GET_ALL_EXPORT_CHART_FAILURE');

export const getAllRevenueDetailChartRequest = createAction('GET_ALL_REVENUE_DETAIL_CHART_REQUEST');
export const getAllRevenueDetailChartSuccess = createAction('GET_ALL_REVENUE_DETAIL_CHART_SUCCESS');
export const getAllRevenueDetailChartFailure = createAction('GET_ALL_REVENUE_DETAIL_CHART_FAILURE');

export const getExportChartRequest = createAction('GET_EXPORT_CHART_REQUEST');
export const getExportChartSuccess = createAction('GET_EXPORT_CHART_SUCCESS');
export const getExportChartFailure = createAction('GET_EXPORT_CHART_FAILURE');

export const getAllShippingsChartRequest = createAction('GET_ALL_SHIPPINGS_CHART_REQUEST');
export const getAllShippingsChartSuccess = createAction('GET_ALL_SHIPPINGS_CHART_SUCCESS');
export const getAllShippingsChartFailure = createAction('GET_ALL_SHIPPINGS_CHART_FAILURE');

export const resetChartState = createAction('RESET_CHART_STATE');
