/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const getListScheduleRequest = createAction('GET_LIST_SCHEDULE_REQUEST');
export const getListScheduleSuccess = createAction('GET_LIST_SCHEDULE_SUCCESS');
export const getListScheduleFailure = createAction('GET_LIST_SCHEDULE_FAILURE');

export const getListEmployeeRequest = createAction('GET_LIST_EMPLOYEE_REQUEST');
export const getListEmployeeSuccess = createAction('GET_LIST_EMPLOYEE_SUCCESS');
export const getListEmployeeFailure = createAction('GET_LIST_EMPLOYEE_FAILURE');

export const getListWorkPlaceRequest = createAction('GET_LIST_WORK_PLACE_REQUEST');
export const getListWorkPlaceSuccess = createAction('GET_LIST_WORK_PLACE_SUCCESS');
export const getListWorkPlaceFailure = createAction('GET_LIST_WORK_PLACE_FAILURE');

export const addScheduleRequest = createAction('ADD_SCHEDULE_REQUEST');
export const addScheduleSuccess = createAction('ADD_SCHEDULE_SUCCESS');
export const addScheduleFailure = createAction('ADD_SCHEDULE_FAILURE');
export const resetAddScheduleState = createAction('RESET_ADD_SCHEDULE_STATE');

export const editScheduleRequest = createAction('EDIT_SCHEDULE_REQUEST');
export const editScheduleSuccess = createAction('EDIT_SCHEDULE_SUCCESS');
export const editScheduleFailure = createAction('EDIT_SCHEDULE_FAILURE');
export const resetEditScheduleState = createAction('RESET_EDIT_SCHEDULE_STATE');

export const deleteScheduleRequest = createAction('DELETE_SCHEDULE_REQUEST');
export const deleteScheduleSuccess = createAction('DELETE_SCHEDULE_SUCCESS');
export const deleteScheduleFailure = createAction('DELETE_SCHEDULE_FAILURE');
export const resetDeleteScheduleState = createAction('RESET_DELETE_SCHEDULE_STATE');

export const resetScheduleState = createAction('RESET_SCHEDULE_STATE');
