// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';
import * as Action from './actions';

const initialState = {
  isGetListCalendarRequest: false,
  isGetListCalendarSuccess: false,
  isGetListCalendarFailure: false,
  listCalendars: {},
  //
  isAddCalendarRequest: false,
  isAddCalendarSuccess: false,
  isAddCalendarFailure: false,
  //
  isEditCalendarRequest: false,
  isEditCalendarSuccess: false,
  isEditCalendarFailure: false,
  //
  isDeleteCalendarRequest: false,
  isDeleteCalendarSuccess: false,
  isDeleteCalendarFailure: false,
  //
  isGetListEmployeeRequest: false,
  isGetListEmployeeSuccess: false,
  isGetListEmployeeFailure: false,
  listEmployees: {},
  //
  isGetListWorkPlaceRequest: false,
  isGetListWorkPlaceSuccess: false,
  isGetListWorkPlaceFailure: false,
  listWorkPlace: {},
  //
  errorMessages: [],
};

const reducer = handleActions({
  // #region
  [Action.getListScheduleRequest]: (state) => ({
    ...state,
    isGetListCalendarRequest: true,
    isGetListCalendarSuccess: false,
    isGetListCalendarFailure: false,
  }),
  [Action.getListScheduleSuccess]: (state, { payload }) => ({
    ...state,
    isGetListCalendarRequest: false,
    isGetListCalendarSuccess: true,
    isGetListCalendarFailure: false,
    listCalendars: payload,
  }),
  [Action.getListScheduleFailure]: (state, { payload }) => ({
    ...state,
    isGetListCalendarRequest: false,
    isGetListCalendarSuccess: false,
    isGetListCalendarFailure: true,
    errorMessages: payload,
  }),
  // #endregion
  // #region
  [Action.addScheduleRequest]: (state) => ({
    ...state,
    isAddCalendarRequest: true,
    isAddCalendarSuccess: false,
    isAddCalendarFailure: false,
  }),
  [Action.addScheduleSuccess]: (state) => ({
    ...state,
    isAddCalendarRequest: false,
    isAddCalendarSuccess: true,
    isAddCalendarFailure: false,
  }),
  [Action.addScheduleFailure]: (state, { payload }) => ({
    ...state,
    isAddCalendarRequest: false,
    isAddCalendarSuccess: false,
    isAddCalendarFailure: true,
    errorMessages: payload,
  }),
  [Action.resetAddScheduleState]: (state) => ({
    ...state,
    isAddCalendarRequest: false,
    isAddCalendarSuccess: false,
    isAddCalendarFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region
  [Action.editScheduleRequest]: (state) => ({
    ...state,
    isEditCalendarRequest: true,
    isEditCalendarSuccess: false,
    isEditCalendarFailure: false,
  }),
  [Action.editScheduleSuccess]: (state) => ({
    ...state,
    isEditCalendarRequest: false,
    isEditCalendarSuccess: true,
    isEditCalendarFailure: false,
  }),
  [Action.editScheduleFailure]: (state, { payload }) => ({
    ...state,
    isEditCalendarRequest: false,
    isEditCalendarSuccess: false,
    isEditCalendarFailure: true,
    errorMessages: payload,
  }),
  [Action.resetEditScheduleState]: (state) => ({
    ...state,
    isAddCalendarRequest: false,
    isAddCalendarSuccess: false,
    isAddCalendarFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region
  [Action.deleteScheduleRequest]: (state) => ({
    ...state,
    isDeleteCalendarRequest: true,
    isDeleteCalendarSuccess: false,
    isDeleteCalendarFailure: false,
  }),
  [Action.deleteScheduleSuccess]: (state) => ({
    ...state,
    isDeleteCalendarRequest: false,
    isDeleteCalendarSuccess: true,
    isDeleteCalendarFailure: false,
  }),
  [Action.deleteScheduleFailure]: (state, { payload }) => ({
    ...state,
    isDeleteCalendarRequest: false,
    isDeleteCalendarSuccess: false,
    isDeleteCalendarFailure: true,
    errorMessages: payload,
  }),
  [Action.resetDeleteScheduleState]: (state) => ({
    ...state,
    isDeleteCalendarRequest: false,
    isDeleteCalendarSuccess: false,
    isDeleteCalendarFailure: false,
    errorMessages: [],
  }),
  // #endregion
  // #region

  // #region
  [Action.getListEmployeeRequest]: (state) => ({
    ...state,
    isGetListEmployeeRequest: true,
    isGetListEmployeeSuccess: false,
    isGetListEmployeeFailure: false,
  }),
  [Action.getListEmployeeSuccess]: (state, { payload }) => ({
    ...state,
    isGetListEmployeeRequest: false,
    isGetListEmployeeSuccess: true,
    isGetListEmployeeFailure: false,
    listEmployees: payload,
  }),
  [Action.getListEmployeeRequest]: (state, { payload }) => ({
    ...state,
    isGetListEmployeeRequest: false,
    isGetListEmployeeSuccess: false,
    isGetListEmployeeFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  // #region
  [Action.getListWorkPlaceRequest]: (state) => ({
    ...state,
    isGetListWorkPlaceRequest: true,
    isGetListWorkPlaceSuccess: false,
    isGetListWorkPlaceFailure: false,
  }),
  [Action.getListWorkPlaceSuccess]: (state, { payload }) => ({
    ...state,
    isGetListWorkPlaceRequest: false,
    isGetListWorkPlaceSuccess: true,
    isGetListWorkPlaceFailure: false,
    listWorkPlace: payload,
  }),
  [Action.getListWorkPlaceFailure]: (state, { payload }) => ({
    ...state,
    isGetListWorkPlaceRequest: false,
    isGetListWorkPlaceSuccess: false,
    isGetListWorkPlaceFailure: true,
    errorMessages: payload,
  }),
  // #endregion
  [Action.resetScheduleState]: () => initialState,
  // #endregion
}, initialState);

export default reducer;
