// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';
import * as Action from './actions';

const initialState = {
  //
  isGetAllSchedulesCalendarRequest: false,
  isGetAllSchedulesCalendarSuccess: false,
  isGetAllSchedulesCalendarFailure: false,
  allSchedulesCalendarState: false,
  //
  isCreateScheduleCalendarRequest: false,
  isCreateScheduleCalendarSuccess: false,
  isCreateScheduleCalendarFailure: false,
  //
  isUpdateScheduleCalendarRequest: false,
  isUpdateScheduleCalendarSuccess: false,
  isUpdateScheduleCalendarFailure: false,
  //
  isDeleteScheduleCalendarRequest: false,
  isDeleteScheduleCalendarSuccess: false,
  isDeleteScheduleCalendarFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Schedules
    [Action.getAllSchedulesCalendarRequest]: (state) => ({
      ...state,
      isGetAllSchedulesCalendarRequest: true,
      isGetAllSchedulesCalendarSuccess: false,
      isGetAllSchedulesCalendarFailure: false,
    }),
    [Action.getAllSchedulesCalendarSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllSchedulesCalendarRequest: false,
      isGetAllSchedulesCalendarSuccess: true,
      isGetAllSchedulesCalendarFailure: false,
      allSchedulesCalendarState: payload,
    }),
    [Action.getAllSchedulesCalendarFailure]: (state, { payload }) => ({
      ...state,
      isGetAllSchedulesCalendarRequest: false,
      isGetAllSchedulesCalendarSuccess: false,
      isGetAllSchedulesCalendarFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Schedule
    [Action.createScheduleCalendarRequest]: (state) => ({
      ...state,
      isCreateScheduleCalendarRequest: true,
      isCreateScheduleCalendarSuccess: false,
      isCreateScheduleCalendarFailure: false,
    }),
    [Action.createScheduleCalendarSuccess]: (state) => ({
      ...state,
      isCreateScheduleCalendarRequest: false,
      isCreateScheduleCalendarSuccess: true,
      isCreateScheduleCalendarFailure: false,
    }),
    [Action.createScheduleCalendarFailure]: (state, { payload }) => ({
      ...state,
      isCreateScheduleCalendarRequest: false,
      isCreateScheduleCalendarSuccess: false,
      isCreateScheduleCalendarFailure: true,
      errorMessages: payload,
    }),
    [Action.resetCreateScheduleCalendarState]: (state) => ({
      ...state,
      isCreateScheduleCalendarRequest: false,
      isCreateScheduleCalendarSuccess: false,
      isCreateScheduleCalendarFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update Schedule
    [Action.updateScheduleCalendarRequest]: (state) => ({
      ...state,
      isUpdateScheduleCalendarRequest: true,
      isUpdateScheduleCalendarSuccess: false,
      isUpdateScheduleCalendarFailure: false,
    }),
    [Action.updateScheduleCalendarSuccess]: (state) => ({
      ...state,
      isUpdateScheduleCalendarRequest: false,
      isUpdateScheduleCalendarSuccess: true,
      isUpdateScheduleCalendarFailure: false,
    }),
    [Action.updateScheduleCalendarFailure]: (state, { payload }) => ({
      ...state,
      isUpdateScheduleCalendarRequest: false,
      isUpdateScheduleCalendarSuccess: false,
      isUpdateScheduleCalendarFailure: true,
      errorMessages: payload,
    }),
    [Action.resetUpdateScheduleCalendarState]: (state) => ({
      ...state,
      isUpdateScheduleCalendarRequest: false,
      isUpdateScheduleCalendarSuccess: false,
      isUpdateScheduleCalendarFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete Schedule
    [Action.deleteScheduleCalendarRequest]: (state) => ({
      ...state,
      isDeleteScheduleCalendarRequest: true,
      isDeleteScheduleCalendarSuccess: false,
      isDeleteScheduleCalendarFailure: false,
    }),
    [Action.deleteScheduleCalendarSuccess]: (state) => ({
      ...state,
      isDeleteScheduleCalendarRequest: false,
      isDeleteScheduleCalendarSuccess: true,
      isDeleteScheduleCalendarFailure: false,
    }),
    [Action.deleteScheduleCalendarFailure]: (state, { payload }) => ({
      ...state,
      isDeleteScheduleCalendarRequest: false,
      isDeleteScheduleCalendarSuccess: false,
      isDeleteScheduleCalendarFailure: true,
      errorMessages: payload,
    }),
    [Action.resetDeleteScheduleCalendarState]: (state) => ({
      ...state,
      isDeleteScheduleCalendarRequest: false,
      isDeleteScheduleCalendarSuccess: false,
      isDeleteScheduleCalendarFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Action.resetScheduleCalendarState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
