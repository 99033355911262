/* eslint-disable import/no-extraneous-dependencies */
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  isGetAllShippingsPriceRequest: false,
  isGetAllShippingsPriceSuccess: false,
  isGetAllShippingsPriceFailure: false,
  listShippingsPriceState: {},

  isGetAllShippingsRequest: false,
  isGetAllShippingsSuccess: false,
  isGetAllShippingsFailure: false,
  listShippings: {},

  isGetAllRegionGroupRequest: false,
  isGetAllRegionGroupSuccess: false,
  isGetAllRegionGroupFailure: false,
  listRegionGroup: {},

  isAddShippingsPriceRequest: false,
  isAddShippingsPriceSuccess: false,
  isAddShippingsPriceFailure: false,

  isEditShippingsPriceRequest: false,
  isEditShippingsPriceSuccess: false,
  isEditShippingsPriceFailure: false,

  isDeleteShippingsPriceRequest: false,
  isDeleteShippingsPriceSuccess: false,
  isDeleteShippingsPriceFailure: false,

  isAddMultiShippingPriceRequest: false,
  isAddMultiShippingPriceSuccess: false,
  isAddMultiShippingPriceFailure: false,

  errorMessages: [],
};

const reducer = handleActions(
  {
    [Actions.getAllShippingsPriceRequest]: (state) => ({
      ...state,
      isGetAllShippingsPriceRequest: true,
      isGetAllShippingsPriceSuccess: false,
      isGetAllShippingsPriceFailure: false,
    }),
    [Actions.getAllShippingsPriceSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllShippingsPriceRequest: false,
      isGetAllShippingsPriceSuccess: true,
      isGetAllShippingsPriceFailure: false,
      listShippingsPriceState: payload,
    }),
    [Actions.getAllShippingsPriceFailure]: (state, { payload }) => ({
      ...state,
      isGetAllShippingsPriceRequest: false,
      isGetAllShippingsPriceSuccess: false,
      isGetAllShippingsPriceFailure: true,
      errorMessages: payload,
    }),
    // #region
    [Actions.getAllRegionGroupShippingsRequest]: (state) => ({
      ...state,
      isGetAllRegionGroupRequest: true,
      isGetAllRegionGroupSuccess: false,
      isGetAllRegionGroupFailure: false,
    }),
    [Actions.getAllRegionGroupShippingsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllRegionGroupRequest: false,
      isGetAllRegionGroupSuccess: true,
      isGetAllRegionGroupFailure: false,
      listRegionGroup: payload,
    }),
    [Actions.getAllRegionGroupShippingsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllRegionGroupRequest: false,
      isGetAllRegionGroupSuccess: false,
      isGetAllRegionGroupFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.getAllShippingsRequest]: (state) => ({
      ...state,
      isGetAllShippingsRequest: true,
      isGetAllShippingsSuccess: false,
      isGetAllShippingsFailure: false,
    }),
    [Actions.getAllShippingsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllShippingsRequest: false,
      isGetAllShippingsSuccess: true,
      isGetAllShippingsFailure: false,
      listShippings: payload,
    }),
    [Actions.getAllShippingsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllShippingsRequest: false,
      isGetAllShippingsSuccess: false,
      isGetAllShippingsFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.addShippingsPriceRequest]: (state) => ({
      ...state,
      isAddShippingsPriceRequest: true,
      isAddShippingsPriceSuccess: false,
      isAddShippingsPriceFailure: false,
    }),
    [Actions.addShippingsPriceSuccess]: (state) => ({
      ...state,
      isAddShippingsPriceSuccess: true,
      isAddShippingsPriceFailure: false,
      isAddShippingsPriceRequest: false,
    }),
    [Actions.addShippingsPriceFailure]: (state, { payload }) => ({
      ...state,
      isAddShippingsPriceRequest: false,
      isAddShippingsPriceSuccess: false,
      isAddShippingsPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddShippingsPriceState]: (state) => ({
      ...state,
      isAddShippingsPriceRequest: false,
      isAddShippingsPriceSuccess: false,
      isAddShippingsPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region
    [Actions.editShippingsPriceRequest]: (state) => ({
      ...state,
      isEditShippingsPriceRequest: true,
      isEditShippingsPriceSuccess: false,
      isEditShippingsPriceFailure: false,
    }),
    [Actions.editShippingsPriceSuccess]: (state) => ({
      ...state,
      isEditShippingsPriceRequest: false,
      isEditShippingsPriceSuccess: true,
      isEditShippingsPriceFailure: false,
    }),
    [Actions.editShippingsPriceFailure]: (state, { payload }) => ({
      ...state,
      isEditShippingsPriceRequest: false,
      isEditShippingsPriceSuccess: false,
      isEditShippingsPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditShippingsPriceState]: (state) => ({
      ...state,
      isEditShippingsPriceRequest: false,
      isEditShippingsPriceSuccess: false,
      isEditShippingsPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region
    [Actions.deleteShippingsPriceRequest]: (state) => ({
      ...state,
      isDeleteShippingsPriceRequest: true,
      isDeleteShippingsPriceSuccess: false,
      isDeleteShippingsPriceFailure: false,
    }),
    [Actions.deleteShippingsPriceSuccess]: (state) => ({
      ...state,
      isDeleteShippingsPriceRequest: false,
      isDeleteShippingsPriceSuccess: true,
      isDeleteShippingsPriceFailure: false,
    }),
    [Actions.deleteShippingsPriceFailure]: (state, { payload }) => ({
      ...state,
      isDeleteShippingsPriceRequest: false,
      isDeleteShippingsPriceSuccess: false,
      isDeleteShippingsPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteShippingsPriceState]: (state) => ({
      ...state,
      isDeleteShippingsPriceRequest: false,
      isDeleteShippingsPriceSuccess: false,
      isDeleteShippingsPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.addMultiShippingPriceRequest]: (state) => ({
      ...state,
      isAddMultiShippingPriceRequest: true,
      isAddMultiShippingPriceSuccess: false,
      isAddMultiShippingPriceFailure: false,
    }),
    [Actions.addMultiShippingPriceSuccess]: (state) => ({
      ...state,
      isAddMultiShippingPriceRequest: false,
      isAddMultiShippingPriceSuccess: true,
      isAddMultiShippingPriceFailure: false,
    }),
    [Actions.addMultiShippingPriceFailure]: (state, { payload }) => ({
      ...state,
      isAddMultiShippingPriceRequest: false,
      isAddMultiShippingPriceSuccess: false,
      isAddMultiShippingPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddMultiShippingPriceState]: (state) => ({
      ...state,
      isAddMultiShippingPriceRequest: false,
      isAddMultiShippingPriceSuccess: false,
      isAddMultiShippingPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : reset
    [Actions.resetShippingsPriceState]: () => initialState,
    // #endregion
  }, initialState,
);

export default reducer;
