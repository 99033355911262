// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllTeamsRequest: false,
  isGetAllTeamsSuccess: false,
  isGetAllTeamsFailure: false,
  allTeamsState: {},
  //
  isGetMyTeamsRequest: false,
  isGetMyTeamsSuccess: false,
  isGetMyTeamsFailure: false,
  myTeamsState: {},
  //
  isCreateTeamRequest: false,
  isCreateTeamSuccess: false,
  isCreateTeamFailure: false,
  isAddMemberTeamRequest: false,
  isAddMemberTeamSuccess: false,
  isAddMemberTeamFailure: false,
  //
  isUpdateTeamRequest: false,
  isUpdateTeamSuccess: false,
  isUpdateTeamFailure: false,
  isUpdateCurrentTeamRequest: false,
  isUpdateCurrentTeamSuccess: false,
  isUpdateCurrentTeamFailure: false,
  //
  isDeleteTeamRequest: false,
  isDeleteTeamSuccess: false,
  isDeleteTeamFailure: false,
  isDeleteMemberTeamRequest: false,
  isDeleteMemberTeamSuccess: false,
  isDeleteMemberTeamFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Teams
    [Actions.getAllTeamsRequest]: (state) => ({
      ...state,
      isGetAllTeamsRequest: true,
      isGetAllTeamsSuccess: false,
      isGetAllTeamsFailure: false,
    }),
    [Actions.getAllTeamsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllTeamsRequest: false,
      isGetAllTeamsSuccess: true,
      isGetAllTeamsFailure: false,
      allTeamsState: payload,
    }),
    [Actions.getAllTeamsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllTeamsRequest: false,
      isGetAllTeamsSuccess: false,
      isGetAllTeamsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get My Teams
    [Actions.getMyTeamsRequest]: (state) => ({
      ...state,
      isGetMyTeamsRequest: true,
      isGetMyTeamsSuccess: false,
      isGetMyTeamsFailure: false,
    }),
    [Actions.getMyTeamsSuccess]: (state, { payload }) => ({
      ...state,
      isGetMyTeamsRequest: false,
      isGetMyTeamsSuccess: true,
      isGetMyTeamsFailure: false,
      myTeamsState: payload,
    }),
    [Actions.getMyTeamsFailure]: (state, { payload }) => ({
      ...state,
      isGetMyTeamsRequest: false,
      isGetMyTeamsSuccess: false,
      isGetMyTeamsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetMyTeamsState]: (state) => ({
      ...state,
      isGetMyTeamsRequest: false,
      isGetMyTeamsSuccess: false,
      isGetMyTeamsFailure: false,
      myTeamsState: {},
    }),
    // #endregion
    // #region : Create Team + Add Member Team
    [Actions.createTeamRequest]: (state) => ({
      ...state,
      isCreateTeamRequest: true,
      isCreateTeamSuccess: false,
      isCreateTeamFailure: false,
    }),
    [Actions.createTeamSuccess]: (state, { payload }) => ({
      ...state,
      isCreateTeamRequest: false,
      isCreateTeamSuccess: true,
      isCreateTeamFailure: false,
      allTeamsState:
        state.allTeamsState?.meta?.pagination?.current_page === 1
          ? {
            ...state.allTeamsState,
            data:
                state.allTeamsState.meta.pagination.count
                === state.allTeamsState.meta.pagination.per_page
                  ? [payload.data].concat(
                    state.allTeamsState.data.slice(
                      0,
                      state.allTeamsState.data.length - 1,
                    ),
                  )
                  : [payload.data].concat(state.allTeamsState.data),
            meta: {
              ...state.allTeamsState.meta,
              pagination: {
                ...state.allTeamsState.meta.pagination,
                count:
                    state.allTeamsState.meta.pagination.count
                    === state.allTeamsState.meta.pagination.per_page
                      ? state.allTeamsState.meta.pagination.per_page
                      : state.allTeamsState.meta.pagination.count + 1,
                total: state.allTeamsState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.allTeamsState.meta.pagination.total + 1)
                      / state.allTeamsState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.allTeamsState },
    }),
    [Actions.createTeamFailure]: (state, { payload }) => ({
      ...state,
      isCreateTeamRequest: false,
      isCreateTeamSuccess: false,
      isCreateTeamFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateTeamState]: (state) => ({
      ...state,
      isCreateTeamRequest: false,
      isCreateTeamSuccess: false,
      isCreateTeamFailure: false,
      errorMessages: [],
    }),
    [Actions.addMemberTeamRequest]: (state) => ({
      ...state,
      isAddMemberTeamRequest: true,
      isAddMemberTeamSuccess: false,
      isAddMemberTeamFailure: false,
    }),
    [Actions.addMemberTeamSuccess]: (state) => ({
      ...state,
      isAddMemberTeamRequest: false,
      isAddMemberTeamSuccess: true,
      isAddMemberTeamFailure: false,
    }),
    [Actions.addMemberTeamFailure]: (state, { payload }) => ({
      ...state,
      isAddMemberTeamRequest: false,
      isAddMemberTeamSuccess: false,
      isAddMemberTeamFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddMemberTeamState]: (state) => ({
      ...state,
      isAddMemberTeamRequest: false,
      isAddMemberTeamSuccess: false,
      isAddMemberTeamFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update Team + Update Current Team
    [Actions.updateTeamRequest]: (state) => ({
      ...state,
      isUpdateTeamRequest: true,
      isUpdateTeamSuccess: false,
      isUpdateTeamFailure: false,
    }),
    [Actions.updateTeamSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateTeamRequest: false,
      isUpdateTeamSuccess: true,
      isUpdateTeamFailure: false,
      allTeamsState: {
        ...state.allTeamsState,
        data: state.allTeamsState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateTeamFailure]: (state, { payload }) => ({
      ...state,
      isUpdateTeamRequest: false,
      isUpdateTeamSuccess: false,
      isUpdateTeamFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateTeamState]: (state) => ({
      ...state,
      isUpdateTeamRequest: false,
      isUpdateTeamSuccess: false,
      isUpdateTeamFailure: false,
      errorMessages: [],
    }),
    [Actions.updateCurrentTeamRequest]: (state) => ({
      ...state,
      isUpdateCurrentTeamRequest: true,
      isUpdateCurrentTeamSuccess: false,
      isUpdateCurrentTeamFailure: false,
    }),
    [Actions.updateCurrentTeamSuccess]: (state) => ({
      ...state,
      isUpdateCurrentTeamRequest: false,
      isUpdateCurrentTeamSuccess: true,
      isUpdateCurrentTeamFailure: false,
    }),
    [Actions.updateCurrentTeamFailure]: (state, { payload }) => ({
      ...state,
      isUpdateCurrentTeamRequest: false,
      isUpdateCurrentTeamSuccess: false,
      isUpdateCurrentTeamFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateCurrentTeamState]: (state) => ({
      ...state,
      isUpdateCurrentTeamRequest: false,
      isUpdateCurrentTeamSuccess: false,
      isUpdateCurrentTeamFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete Team + Delete Member Team
    [Actions.deleteTeamRequest]: (state) => ({
      ...state,
      isDeleteTeamRequest: true,
      isDeleteTeamSuccess: false,
      isDeleteTeamFailure: false,
    }),
    [Actions.deleteTeamSuccess]: (state) => ({
      ...state,
      isDeleteTeamRequest: false,
      isDeleteTeamSuccess: true,
      isDeleteTeamFailure: false,
    }),
    [Actions.deleteTeamFailure]: (state, { payload }) => ({
      ...state,
      isDeleteTeamRequest: false,
      isDeleteTeamSuccess: false,
      isDeleteTeamFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteTeamState]: (state) => ({
      ...state,
      isDeleteTeamRequest: false,
      isDeleteTeamSuccess: false,
      isDeleteTeamFailure: false,
      errorMessages: [],
    }),
    [Actions.deleteMemberTeamRequest]: (state) => ({
      ...state,
      isDeleteMemberTeamRequest: true,
      isDeleteMemberTeamSuccess: false,
      isDeleteMemberTeamFailure: false,
    }),
    [Actions.deleteMemberTeamSuccess]: (state) => ({
      ...state,
      isDeleteMemberTeamRequest: false,
      isDeleteMemberTeamSuccess: true,
      isDeleteMemberTeamFailure: false,
    }),
    [Actions.deleteMemberTeamFailure]: (state, { payload }) => ({
      ...state,
      isDeleteMemberTeamRequest: false,
      isDeleteMemberTeamSuccess: false,
      isDeleteMemberTeamFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteMemberTeamState]: (state) => ({
      ...state,
      isDeleteMemberTeamRequest: false,
      isDeleteMemberTeamSuccess: false,
      isDeleteMemberTeamFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetTeamsState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
