// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isSearchReceiverRequest: false,
  isSearchReceiverSuccess: false,
  isSearchReceiverFailure: false,
  searchReceiverState: {},
  //
  isRegionReceiverRequest: false,
  isRegionReceiverSuccess: false,
  isRegionReceiverFailure: false,
  regionReceiverState: {},
  //
  isSearchProductTypeRequest: false,
  isSearchProductTypeSuccess: false,
  isSearchProductTypeFailure: false,
  searchProductTypeState: {},
  //
  isSearchProductRequest: false,
  isSearchProductSuccess: false,
  isSearchProductFailure: false,
  searchProductState: {},
  //
  isGetListOrdersRequest: false,
  isGetListOrdersSuccess: false,
  isGetListOrdersFailure: false,
  listOrdersState: {},
  //
  isGetListDepositOrdersRequest: false,
  isGetListDepositOrdersSuccess: false,
  isGetListDepositOrdersFailure: false,
  listDepositOrdersState: {},
  //
  isGetOrderBookingHistoryRequest: false,
  isGetOrderBookingHistorySuccess: false,
  isGetOrderBookingHistoryFailure: false,
  listOrderBookingHistory: {},
  //
  isCreateOrderRequest: false,
  isCreateOrderSuccess: false,
  isCreateOrderFailure: false,
  createOrderResponse: {},
  //
  isCreateOrderBookingRequest: false,
  isCreateOrderBookingSuccess: false,
  isCreateOrderBookingFailure: false,
  createOrderBookingResponse: {},
  //
  isUpdateOrderRequest: false,
  isUpdateOrderSuccess: false,
  isUpdateOrderFailure: false,
  responseUpdateOrder: {},
  //
  isUpdateOrderBookingRequest: false,
  isUpdateOrderBookingSuccess: false,
  isUpdateOrderBookingFailure: false,
  responseUpdateOrderBooking: {},
  //
  isActiveOrderBookingRequest: false,
  isActiveOrderBookingSuccess: false,
  isActiveOrderBookingFailure: false,
  responseActiveOrderBooking: {},
  //
  isDoneOrderBookingRequest: false,
  isDoneOrderBookingSuccess: false,
  isDoneOrderBookingFailure: false,
  responseDoneOrderBooking: {},
  //
  isCancelOrderBookingRequest: false,
  isCancelOrderBookingSuccess: false,
  isCancelOrderBookingFailure: false,
  responseCancelOrderBooking: {},
  //
  isGetMoneyBookingOrderRequest: false,
  isGetMoneyBookingOrderSuccess: false,
  isGetMoneyBookingOrderFailure: false,
  responseGetMoneyBookingOrder: {},
  //
  isUpdateOrderExpenseRequest: false,
  isUpdateOrderExpenseSuccess: false,
  isUpdateOrderExpenseFailure: false,
  responseUpdateExpenseOrder: {},
  //
  isDeleteOrderRequest: false,
  isDeleteOrderSuccess: false,
  isDeleteOrderFailure: false,
  //
  isGetAllShippingOrderRequest: false,
  isGetAllShippingOrderSuccess: false,
  isGetAllShippingOrderFailure: false,
  listShippingOrderState: {},
  //
  isUpdateShippingOrderRequest: false,
  isUpdateShippingOrderSuccess: false,
  isUpdateShippingOrderFailure: false,
  //
  isPrintedOrderRequest: false,
  isPrintedOrderSuccess: false,
  isPrintedOrderFailure: false,
  //
  isUpdateStatusOrderRequest: false,
  isUpdateStatusOrderSuccess: false,
  isUpdateStatusOrderFailure: false,
  //
  isUpdateWarehouseOrderRequest: false,
  isUpdateWarehouseOrderSuccess: false,
  isUpdateWarehouseOrderFailure: false,
  //
  isUpdateShippingIdOrderRequest: false,
  isUpdateShippingIdOrderSuccess: false,
  isUpdateShippingIdOrderFailure: false,
  //
  isGetAllWarehouseOrderRequest: false,
  isGetAllWarehouseOrderSuccess: false,
  isGetAllWarehouseOrderFailure: false,
  listWarehouse: {},
  //
  isGetAllOrderRequest: false,
  isGetAllOrderSuccess: false,
  isGetAllOrderFailure: false,
  listAllOrder: {},
  //
  isGetAllRegionGroupOrderRequest: false,
  isGetAllRegionGroupOrderSuccess: false,
  isGetAllRegionGroupOrderFailure: false,
  listRegionGroup: {},
  //
  isGetAllProductPriceOrderRequest: false,
  isGetAllProductPriceOrderSuccess: false,
  isGetAllProductPriceOrderFailure: false,
  listProductPrice: {},
  //
  isGetOrderLateRequest: false,
  isGetOrderLateSuccess: false,
  isGetOrderLateFailure: false,
  listOrderLate: {},
  //

  isUpdateStatusMultiRequest: false,
  isUpdateStatusMultiSuccess: false,
  isUpdateStatusMultiFailure: false,
  //
  isGetAllOrderInChartRevenueRequest: false,
  isGetAllOrderInChartRevenueSuccess: false,
  isGetAllOrderInChartRevenueFailure: false,
  listAllOrderInChartRevenue: {},
  //
  isGetAllOrderInChartGrowRequest: false,
  isGetAllOrderInChartGrowSuccess: false,
  isGetAllOrderInChartGrowFailure: false,
  listAllOrderInChartGrow: {},
  //
  isUpdateContemporaryExpenseRequest: false,
  isUpdateContemporaryExpenseSuccess: false,
  isUpdateContemporaryExpenseFailure: false,
  responseUpdateContemporary: {},
  //
  isGetAllOrderFromAppRequest: false,
  isGetAllOrderFromAppSuccess: false,
  isGetAllOrderFromAppFailure: false,
  listOrderFromApp: {},
  //
  isApproveOrderFromAppRequest: false,
  isApproveOrderFromAppSuccess: false,
  isApproveOrderFromAppFailure: false,
  responseApproveOrder: {},
  //
  isRefuseOrderFromAppRequest: false,
  isRefuseOrderFromAppSuccess: false,
  isRefuseOrderFromAppFailure: false,
  //
  isRollBackOrderRequest: false,
  isRollBackOrderSuccess: false,
  isRollBackOrderFailure: false,
  //
  isPrintOrderAndRedirectRequest: false,
  isPrintOrderAndRedirectSuccess: false,
  isPrintOrderAndRedirectFailure: false,
  printOrderState: {},
  //
  isUpdatePaymentOrderAppRequest: false,
  isUpdatePaymentOrderAppSuccess: false,
  isUpdatePaymentOrderAppFailure: false,
  //
  isGetLogOrderRequest: false,
  isGetLogOrderSuccess: false,
  isGetLogOrderFailure: false,
  listLogOrder: {},
  //
  isExchangeVoucherOrderAppRequest: false,
  isExchangeVoucherOrderAppSuccess: false,
  isExchangeVoucherOrderAppFailure: false,
  //
  isGetDetailOrderRequest: false,
  isGetDetailOrderSuccess: false,
  isGetDetailOrderFailure: false,
  detailOrder: {},
  //
  isGetOrderByQrCodeRequest: false,
  isGetOrderByQrCodeSuccess: false,
  isGetOrderByQrCodeFailure: false,
  orderByQrCodeState: {},
  //
  isCancelOrderRequest: false,
  isCancelOrderSuccess: false,
  isCancelOrderFailure: false,
  isRefundOrderRequest: false,
  isRefundOrderSuccess: false,
  isRefundOrderFailure: false,
  //
  isCreateSellingVoucherRequest: false,
  isCreateSellingVoucherSuccess: false,
  isCreateSellingVoucherFailure: false,
  //
  isCreateeInvoiceRequest: false,
  isCreateeInvoiceSuccess: false,
  isCreateeInvoiceFailure: false,
  //
  isCreatePurcharseVoucherRequest: false,
  isCreatePurcharseVoucherSuccess: false,
  isCreatePurcharseVoucherFailure: false,

  //
  isGetListOrderStaffPackingRequest: false,
  isGetListOrderStaffPackingSuccess: false,
  isGetListOrderStaffPackingFailure: false,
  listOrderStaffPacking: {},

  //
  isGetOrderByQrCodeStaffPackingRequest: false,
  isGetOrderByQrCodeStaffPackingSuccess: false,
  isGetOrderByQrCodeStaffPackingFailure: false,
  orderByQrCodeStateStaffPacking: {},
  //
  isUpdateStatusOrderStaffPackingRequest: false,
  isUpdateStatusOrderStaffPackingSuccess: false,
  isUpdateStatusOrderStaffPackingFailure: false,
  //
  isGetPriceShippingLalaRequest: false,
  isGetPriceShippingLalaSuccess: false,
  isGetPriceShippingLalaFailure: false,
  getPriceShippingLalaState: {},
  //
  isCreateShippingLalaRequest: false,
  isCreateShippingLalaSuccess: false,
  isCreateShippingLalaFailure: false,
  //
  isGetListOrderLalaAcceptedRequest: false,
  isGetListOrderLalaAcceptedSuccess: false,
  isGetListOrderLalaAcceptedFailure: false,
  getListOrderLalaAcceptedState: {},
  //
  isCreateFeeSangthuyRequest: false,
  isCreateFeeSangthuySuccess: false,
  isCreateFeeSangthuyFailure: false,
  createFeeSangthuyState: {},
  //
  isGetLogLalaMoveRequest: false,
  isGetLogLalaMoveSuccess: false,
  isGetLogLalaMoveFailure: false,
  listLogLalaMoveState: {},
  //
  isUpdateFeeLalaMoveRequest: false,
  isUpdateFeeLalaMoveSuccess: false,
  isUpdateFeeLalaMoveFailure: false,
  //
  isCreateFeeLalaManualRequest: false,
  isCreateFeeLalaManualSuccess: false,
  isCreateFeeLalaManualFailure: false,
  //
  isCancelLalaMoveRequest: false,
  isCancelLalaMoveSuccess: false,
  isCancelLalaMoveFailure: false,
  //
  isClaimPermissionRequest: false,
  isClaimPermissionSuccess: false,
  isClaimPermissionFailure: false,
  //
  isAcceptPermissionRequest: false,
  isAcceptPermissionSuccess: false,
  isAcceptPermissionFailure: false,
  //
  isUpdateOrderLalaRequest: false,
  isUpdateOrderLalaSuccess: false,
  isUpdateOrderLalaFailure: false,
  //
  isGetListOrderLalaCanChangeRequest: false,
  isGetListOrderLalaCanChangeSuccess: false,
  isGetListOrderLalaCanChangeFailure: false,
  responseGetListOrderLalaCanChange: {},
  //
  isGetAllOrderWaitingRequest: false,
  isGetAllOrderWaitingSuccess: false,
  isGetAllOrderWaitingFailure: false,
  listAllOrderWaiting: {},
  //
  isUpdateMultipleStatusOrderRequest: false,
  isUpdateMultipleStatusOrderSuccess: false,
  isUpdateMultipleStatusOrderFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Search receiver
    [Actions.searchReceiverRequest]: (state) => ({
      ...state,
      isSearchReceiverRequest: true,
      isSearchReceiverSuccess: false,
      isSearchReceiverFailure: false,
    }),
    [Actions.searchReceiverSuccess]: (state, { payload }) => ({
      ...state,
      isSearchReceiverRequest: false,
      isSearchReceiverSuccess: true,
      isSearchReceiverFailure: false,
      searchReceiverState: payload,
    }),
    [Actions.searchReceiverFailure]: (state, { payload }) => ({
      ...state,
      isSearchReceiverRequest: false,
      isSearchReceiverSuccess: false,
      isSearchReceiverFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetSearchReceiverState]: (state) => ({
      ...state,
      isSearchReceiverRequest: false,
      isSearchReceiverSuccess: false,
      isSearchReceiverFailure: false,
      searchReceiverState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Region receiver
    [Actions.regionReceiverRequest]: (state) => ({
      ...state,
      isRegionReceiverRequest: true,
      isRegionReceiverSuccess: false,
      isRegionReceiverFailure: false,
    }),
    [Actions.regionReceiverSuccess]: (state, { payload }) => ({
      ...state,
      isRegionReceiverRequest: false,
      isRegionReceiverSuccess: true,
      isRegionReceiverFailure: false,
      regionReceiverState: payload,
    }),
    [Actions.regionReceiverFailure]: (state, { payload }) => ({
      ...state,
      isRegionReceiverRequest: false,
      isRegionReceiverSuccess: false,
      isRegionReceiverFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRegionReceiverState]: (state) => ({
      ...state,
      isRegionReceiverRequest: false,
      isRegionReceiverSuccess: false,
      isRegionReceiverFailure: false,
      regionReceiverState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Search product
    [Actions.searchProductRequest]: (state) => ({
      ...state,
      isSearchProductRequest: true,
      isSearchProductSuccess: false,
      isSearchProductFailure: false,
    }),
    [Actions.searchProductSuccess]: (state, { payload }) => ({
      ...state,
      isSearchProductRequest: false,
      isSearchProductSuccess: true,
      isSearchProductFailure: false,
      searchProductState: payload,
    }),
    [Actions.searchProductFailure]: (state, { payload }) => ({
      ...state,
      isSearchProductRequest: false,
      isSearchProductSuccess: false,
      isSearchProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetSearchProductState]: (state) => ({
      ...state,
      isSearchProductRequest: false,
      isSearchProductSuccess: false,
      isSearchProductFailure: false,
      searchProductState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Search product type
    [Actions.searchProductTypeRequest]: (state) => ({
      ...state,
      isSearchProductTypeRequest: true,
      isSearchProductTypeSuccess: false,
      isSearchProductTypeFailure: false,
    }),
    [Actions.searchProductTypeSuccess]: (state, { payload }) => ({
      ...state,
      isSearchProductTypeRequest: false,
      isSearchProductTypeSuccess: true,
      isSearchProductTypeFailure: false,
      searchProductTypeState: payload,
    }),
    [Actions.searchProductTypeFailure]: (state, { payload }) => ({
      ...state,
      isSearchProductTypeRequest: false,
      isSearchProductTypeSuccess: false,
      isSearchProductTypeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetSearchProductTypeState]: (state) => ({
      ...state,
      isSearchProductTypeRequest: false,
      isSearchProductTypeSuccess: false,
      isSearchProductTypeFailure: false,
      searchProductTypeState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Get list orders
    [Actions.getListOrdersRequest]: (state) => ({
      ...state,
      isGetListOrdersRequest: true,
      isGetListOrdersSuccess: false,
      isGetListOrdersFailure: false,
    }),
    [Actions.getListOrdersSuccess]: (state, { payload }) => ({
      ...state,
      isGetListOrdersRequest: false,
      isGetListOrdersSuccess: true,
      isGetListOrdersFailure: false,
      listOrdersState: payload,
    }),
    [Actions.getListOrdersFailure]: (state, { payload }) => ({
      ...state,
      isGetListOrdersRequest: false,
      isGetListOrdersSuccess: false,
      isGetListOrdersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region: get list deposit orders
    [Actions.getListDepositOrdersRequest]: (state) => ({
      ...state,
      isGetListDepositOrdersRequest: true,
      isGetListDepositOrdersSuccess: false,
      isGetListDepositOrdersFailure: false,
    }),
    [Actions.getListDepositOrdersSuccess]: (state, { payload }) => ({
      ...state,
      isGetListDepositOrdersRequest: false,
      isGetListDepositOrdersSuccess: true,
      isGetListDepositOrdersFailure: false,
      listDepositOrdersState: payload,
    }),
    [Actions.getListDepositOrdersFailure]: (state, { payload }) => ({
      ...state,
      isGetListDepositOrdersRequest: false,
      isGetListDepositOrdersSuccess: false,
      isGetListDepositOrdersFailure: true,
      errorMessages: payload,
    }),
    // #region: Get order booking history
    [Actions.getOrderBookingHistoryRequest]: (state) => ({
      ...state,
      isGetOrderBookingHistoryRequest: true,
      isGetOrderBookingHistorySuccess: false,
      isGetOrderBookingHistoryFailure: false,
    }),
    [Actions.getOrderBookingHistorySuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderBookingHistoryRequest: false,
      isGetOrderBookingHistorySuccess: true,
      isGetOrderBookingHistoryFailure: false,
      listOrderBookingHistory: payload,
    }),
    [Actions.getOrderBookingHistoryFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderBookingHistoryRequest: false,
      isGetOrderBookingHistorySuccess: false,
      isGetOrderBookingHistoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetOrderBookingHistoryState]: (state) => ({
      ...state,
      isGetOrderBookingHistoryRequest: false,
      isGetOrderBookingHistorySuccess: false,
      isGetOrderBookingHistoryFailure: false,
      listOrderBookingHistory: {},
    }),
    // #endregion
    // #endregion
    // #region : Create order
    [Actions.createOrderRequest]: (state) => ({
      ...state,
      isCreateOrderRequest: true,
      isCreateOrderSuccess: false,
      isCreateOrderFailure: false,
    }),
    [Actions.createOrderSuccess]: (state, { payload }) => ({
      ...state,
      isCreateOrderRequest: false,
      isCreateOrderSuccess: true,
      isCreateOrderFailure: false,
      createOrderResponse: payload,
    }),
    [Actions.createOrderFailure]: (state, { payload }) => ({
      ...state,
      isCreateOrderRequest: false,
      isCreateOrderSuccess: false,
      isCreateOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateOrderState]: (state) => ({
      ...state,
      isCreateOrderRequest: false,
      isCreateOrderSuccess: false,
      isCreateOrderFailure: false,
      createOrderResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region: Create order booking
    [Actions.createOrderBookingRequest]: (state) => ({
      ...state,
      isCreateOrderBookingRequest: true,
      isCreateOrderBookingSuccess: false,
      isCreateOrderBookingFailure: false,
    }),
    [Actions.createOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isCreateOrderBookingRequest: false,
      isCreateOrderBookingSuccess: true,
      isCreateOrderBookingFailure: false,
      createOrderBookingResponse: payload,
    }),
    [Actions.createOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isCreateOrderBookingRequest: false,
      isCreateOrderBookingSuccess: false,
      isCreateOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateOrderBookingState]: (state) => ({
      ...state,
      isCreateOrderBookingRequest: false,
      isCreateOrderBookingSuccess: false,
      isCreateOrderBookingFailure: false,
      createOrderBookingResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Update order
    [Actions.updateOrderRequest]: (state) => ({
      ...state,
      isUpdateOrderRequest: true,
      isUpdateOrderSuccess: false,
      isUpdateOrderFailure: false,
    }),
    [Actions.updateOrderSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateOrderRequest: false,
      isUpdateOrderSuccess: true,
      isUpdateOrderFailure: false,
      responseUpdateOrder: payload,
    }),
    [Actions.updateOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdateOrderRequest: false,
      isUpdateOrderSuccess: false,
      isUpdateOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateOrderState]: (state) => ({
      ...state,
      isUpdateOrderRequest: false,
      isUpdateOrderSuccess: false,
      isUpdateOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region: Update order booking
    [Actions.updateOrderBookingRequest]: (state) => ({
      ...state,
      isUpdateOrderBookingRequest: true,
      isUpdateOrderBookingSuccess: false,
      isUpdateOrderBookingFailure: false,
    }),
    [Actions.updateOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateOrderBookingRequest: false,
      isUpdateOrderBookingSuccess: true,
      isUpdateOrderBookingFailure: false,
      responseUpdateOrderBooking: payload,
    }),
    [Actions.updateOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isUpdateOrderBookingRequest: false,
      isUpdateOrderBookingSuccess: false,
      isUpdateOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateOrderBookingState]: (state) => ({
      ...state,
      isUpdateOrderBookingRequest: false,
      isUpdateOrderBookingSuccess: false,
      isUpdateOrderBookingFailure: false,
      responseUpdateOrderBooking: {},
    }),
    // #endregion
    // #region: Active order booking
    [Actions.activeOrderBookingRequest]: (state) => ({
      ...state,
      isActiveOrderBookingRequest: true,
      isActiveOrderBookingSuccess: false,
      isActiveOrderBookingFailure: false,
    }),
    [Actions.activeOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isActiveOrderBookingRequest: false,
      isActiveOrderBookingSuccess: true,
      isActiveOrderBookingFailure: false,
      responseActiveOrderBooking: payload,
    }),
    [Actions.activeOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isActiveOrderBookingRequest: false,
      isActiveOrderBookingSuccess: false,
      isActiveOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetActiveOrderBookingState]: (state) => ({
      ...state,
      isActiveOrderBookingRequest: false,
      isActiveOrderBookingSuccess: false,
      isActiveOrderBookingFailure: false,
      responseActiveOrderBooking: {},
    }),
    // #endregion
    // #region: Done order booking
    [Actions.doneOrderBookingRequest]: (state) => ({
      ...state,
      isDoneOrderBookingRequest: true,
      isDoneOrderBookingSuccess: false,
      isDoneOrderBookingFailure: false,
    }),
    [Actions.doneOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isDoneOrderBookingRequest: false,
      isDoneOrderBookingSuccess: true,
      isDoneOrderBookingFailure: false,
      responseDoneOrderBooking: payload,
    }),
    [Actions.doneOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isDoneOrderBookingRequest: false,
      isDoneOrderBookingSuccess: false,
      isDoneOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDoneOrderBookingState]: (state) => ({
      ...state,
      isDoneOrderBookingRequest: false,
      isDoneOrderBookingSuccess: false,
      isDoneOrderBookingFailure: false,
      responseDoneOrderBooking: {},
    }),
    // #endregion
    // #region: Cancel order booking
    [Actions.cancelOrderBookingRequest]: (state) => ({
      ...state,
      isCancelOrderBookingRequest: true,
      isCancelOrderBookingSuccess: false,
      isCancelOrderBookingFailure: false,
    }),
    [Actions.cancelOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isCancelOrderBookingRequest: false,
      isCancelOrderBookingSuccess: true,
      isCancelOrderBookingFailure: false,
      responseCancelOrderBooking: payload,
    }),
    [Actions.cancelOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isCancelOrderBookingRequest: false,
      isCancelOrderBookingSuccess: false,
      isCancelOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelOrderBookingState]: (state) => ({
      ...state,
      isCancelOrderBookingRequest: false,
      isCancelOrderBookingSuccess: false,
      isCancelOrderBookingFailure: false,
      responseCancelOrderBooking: {},
    }),
    // #endregion
    // #region: Get money booking order
    [Actions.getMoneyBookingOrderRequest]: (state) => ({
      ...state,
      isGetMoneyBookingOrderRequest: true,
      isGetMoneyBookingOrderSuccess: false,
      isGetMoneyBookingOrderFailure: false,
    }),
    [Actions.getMoneyBookingOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGetMoneyBookingOrderRequest: false,
      isGetMoneyBookingOrderSuccess: true,
      isGetMoneyBookingOrderFailure: false,
      responseGetMoneyBookingOrder: payload,
    }),
    [Actions.getMoneyBookingOrderFailure]: (state, { payload }) => ({
      ...state,
      isGetMoneyBookingOrderRequest: false,
      isGetMoneyBookingOrderSuccess: false,
      isGetMoneyBookingOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetMoneyBookingOrderState]: (state) => ({
      ...state,
      isGetMoneyBookingOrderRequest: false,
      isGetMoneyBookingOrderSuccess: false,
      isGetMoneyBookingOrderFailure: false,
      responseGetMoneyBookingOrder: {},
    }),
    // #endregion
    // #region : Update order expense
    [Actions.updateOrderExpenseRequest]: (state) => ({
      ...state,
      isUpdateOrderExpenseRequest: true,
      isUpdateOrderExpenseSuccess: false,
      isUpdateOrderExpenseFailure: false,
    }),
    [Actions.updateOrderExpenseSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateOrderExpenseRequest: false,
      isUpdateOrderExpenseSuccess: true,
      isUpdateOrderExpenseFailure: false,
      responseUpdateOrderExpense: payload,
    }),
    [Actions.updateOrderExpenseFailure]: (state, { payload }) => ({
      ...state,
      isUpdateOrderExpenseRequest: false,
      isUpdateOrderExpenseSuccess: false,
      isUpdateOrderExpenseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateOrderExpenseState]: (state) => ({
      ...state,
      isUpdateOrderExpenseRequest: false,
      isUpdateOrderExpenseSuccess: false,
      isUpdateOrderExpenseFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete order
    [Actions.deleteOrderRequest]: (state) => ({
      ...state,
      isDeleteOrderRequest: true,
      isDeleteOrderSuccess: false,
      isDeleteOrderFailure: false,
    }),
    [Actions.deleteOrderSuccess]: (state) => ({
      ...state,
      isDeleteOrderRequest: false,
      isDeleteOrderSuccess: true,
      isDeleteOrderFailure: false,
    }),
    [Actions.deleteOrderFailure]: (state, { payload }) => ({
      ...state,
      isDeleteOrderRequest: false,
      isDeleteOrderSuccess: false,
      isDeleteOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteOrderState]: (state) => ({
      ...state,
      isDeleteOrderRequest: false,
      isDeleteOrderSuccess: false,
      isDeleteOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region
    [Actions.getAllShippingOrderRequest]: (state) => ({
      ...state,
      isGetAllShippingOrderRequest: true,
      isGetAllShippingOrderSuccess: false,
      isGetAllShippingOrderFailure: false,
    }),
    [Actions.getAllShippingOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllShippingOrderRequest: false,
      isGetAllShippingOrderSuccess: true,
      isGetAllShippingOrderFailure: false,
      listShippingOrderState: payload,
    }),
    [Actions.getAllShippingOrderFailure]: (state, { payload }) => ({
      ...state,
      isGetAllShippingOrderRequest: false,
      isGetAllShippingOrderSuccess: false,
      isGetAllShippingOrderFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region
    [Actions.updateShippingOrderRequest]: (state) => ({
      ...state,
      isUpdateShippingOrderRequest: true,
      isUpdateShippingOrderSuccess: false,
      isUpdateShippingOrderFailure: false,
    }),
    [Actions.updateShippingOrderSuccess]: (state) => ({
      ...state,
      isUpdateShippingOrderRequest: false,
      isUpdateShippingOrderSuccess: true,
      isUpdateShippingOrderFailure: false,
    }),
    [Actions.updateShippingOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdateShippingOrderRequest: false,
      isUpdateShippingOrderSuccess: false,
      isUpdateShippingOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateShippingOrderState]: (state) => ({
      ...state,
      isUpdateShippingOrderRequest: false,
      isUpdateShippingOrderSuccess: false,
      isUpdateShippingOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region
    [Actions.updateShippingIdOrderRequest]: (state) => ({
      ...state,
      isUpdateShippingIdOrderRequest: true,
      isUpdateShippingIdOrderSuccess: false,
      isUpdateShippingIdOrderFailure: false,
    }),
    [Actions.updateShippingIdOrderSuccess]: (state) => ({
      ...state,
      isUpdateShippingIdOrderRequest: false,
      isUpdateShippingIdOrderSuccess: true,
      isUpdateShippingIdOrderFailure: false,
    }),
    [Actions.updateShippingIdOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdateShippingIdOrderRequest: false,
      isUpdateShippingIdOrderSuccess: false,
      isUpdateShippingIdOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateShippingIdOrderState]: (state) => ({
      ...state,
      isUpdateShippingIdOrderRequest: false,
      isUpdateShippingIdOrderSuccess: false,
      isUpdateShippingIdOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region
    [Actions.printedOrderRequest]: (state) => ({
      ...state,
      isPrintedOrderRequest: true,
      isPrintedOrderSuccess: false,
      isPrintedOrderFailure: false,
    }),
    [Actions.printedOrderSuccess]: (state) => ({
      ...state,
      isPrintedOrderRequest: false,
      isPrintedOrderSuccess: true,
      isPrintedOrderFailure: false,
    }),
    [Actions.printedOrderFailure]: (state, { payload }) => ({
      ...state,
      isPrintedOrderRequest: false,
      isPrintedOrderSuccess: false,
      isPrintedOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetPrintedOrderState]: (state) => ({
      ...state,
      isPrintedOrderRequest: false,
      isPrintedOrderSuccess: false,
      isPrintedOrderFailure: false,
    }),
    // #endregion

    // #region : Update status order
    [Actions.updateStatusOrderRequest]: (state) => ({
      ...state,
      isUpdateStatusOrderRequest: true,
      isUpdateStatusOrderSuccess: false,
      isUpdateStatusOrderFailure: false,
    }),
    [Actions.updateStatusOrderSuccess]: (state) => ({
      ...state,
      isUpdateStatusOrderRequest: false,
      isUpdateStatusOrderSuccess: true,
      isUpdateStatusOrderFailure: false,
    }),
    [Actions.updateStatusOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdateStatusOrderRequest: false,
      isUpdateStatusOrderSuccess: false,
      isUpdateStatusOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateStatusOrderState]: (state) => ({
      ...state,
      isUpdateStatusOrderRequest: false,
      isUpdateStatusOrderSuccess: false,
      isUpdateStatusOrderFailure: false,
      errorMessages: [],
    }),
    // #region : Update warehouse order
    [Actions.updateWarehouseOrderRequest]: (state) => ({
      ...state,
      isUpdateWarehouseOrderRequest: true,
      isUpdateWarehouseOrderSuccess: false,
      isUpdateWarehouseOrderFailure: false,
    }),
    [Actions.updateWarehouseOrderSuccess]: (state) => ({
      ...state,
      isUpdateWarehouseOrderRequest: false,
      isUpdateWarehouseOrderSuccess: true,
      isUpdateWarehouseOrderFailure: false,
    }),
    [Actions.updateWarehouseOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdateWarehouseOrderRequest: false,
      isUpdateWarehouseOrderSuccess: false,
      isUpdateWarehouseOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateWarehouseOrderState]: (state) => ({
      ...state,
      isUpdateWarehouseOrderRequest: false,
      isUpdateWarehouseOrderSuccess: false,
      isUpdateWarehouseOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.getAllWareHouseOrderRequest]: (state) => ({
      ...state,
      isGetAllWarehouseOrderRequest: true,
      isGetAllWarehouseOrderSuccess: false,
      isGetAllWarehouseOrderFailure: false,
    }),
    [Actions.getAllWareHouseOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllWarehouseOrderRequest: false,
      isGetAllWarehouseOrderSuccess: true,
      isGetAllWarehouseOrderFailure: false,
      listWarehouse: payload,
    }),
    [Actions.getAllWareHouseOrderFailure]: (state, { payload }) => ({
      ...state,
      isGetAllWarehouseOrderRequest: false,
      isGetAllWarehouseOrderSuccess: false,
      isGetAllWarehouseOrderFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.getAllOrderRequest]: (state) => ({
      ...state,
      isGetAllOrderRequest: true,
      isGetAllOrderSuccess: false,
      isGetAllOrderFailure: false,
    }),
    [Actions.getAllOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllOrderRequest: false,
      isGetAllOrderSuccess: true,
      isGetAllOrderFailure: false,
      listAllOrder: payload,
    }),
    [Actions.getAllOrderFailure]: (state, { payload }) => ({
      ...state,
      isGetAllOrderRequest: false,
      isGetAllOrderSuccess: false,
      isGetAllOrderFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.getAllRegionGroupOrderRequest]: (state) => ({
      ...state,
      isGetAllRegionGroupOrderRequest: true,
      isGetAllRegionGroupOrderSuccess: false,
      isGetAllRegionGroupOrderFailure: false,
    }),
    [Actions.getAllRegionGroupOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllRegionGroupOrderRequest: false,
      isGetAllRegionGroupOrderSuccess: true,
      isGetAllRegionGroupOrderFailure: false,
      listRegionGroup: payload,
    }),
    [Actions.getAllRegionGroupOrderFailure]: (state, { payload }) => ({
      ...state,
      isGetAllRegionGroupOrderRequest: false,
      isGetAllRegionGroupOrderSuccess: false,
      isGetAllRegionGroupOrderFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region
    [Actions.getAllProductPriceOrderRequest]: (state) => ({
      ...state,
      isGetAllProductPriceOrderRequest: true,
      isGetAllProductPriceOrderSuccess: false,
      isGetAllProductPriceOrderFailure: false,
    }),
    [Actions.getAllProductPriceOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllProductPriceOrderRequest: false,
      isGetAllProductPriceOrderSuccess: true,
      isGetAllProductPriceOrderFailure: false,
      listProductPrice: payload,
    }),
    [Actions.getAllProductPriceOrderFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductPriceOrderRequest: false,
      isGetAllProductPriceOrderSuccess: false,
      isGetAllProductPriceOrderFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.getOrderLateRequest]: (state) => ({
      ...state,
      isGetOrderLateRequest: true,
      isGetOrderLateSuccess: false,
      isGetOrderLateFailure: false,
    }),
    [Actions.getOrderLateSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderLateRequest: false,
      isGetOrderLateSuccess: true,
      isGetOrderLateFailure: false,
      listOrderLate: payload,
    }),
    [Actions.getOrderLateFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderLateRequest: false,
      isGetOrderLateSuccess: false,
      isGetOrderLateFailure: false,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.updateStatusMultiRequest]: (state) => ({
      ...state,
      isUpdateStatusMultiRequest: true,
      isUpdateStatusMultiSuccess: false,
      isUpdateStatusMultiFailure: false,
    }),
    [Actions.updateStatusMultiSuccess]: (state) => ({
      ...state,

      isUpdateStatusMultiRequest: false,
      isUpdateStatusMultiSuccess: true,
      isUpdateStatusMultiFailure: false,
    }),
    [Actions.updateStatusMultiFailure]: (state, { payload }) => ({
      ...state,
      isUpdateStatusMultiRequest: false,
      isUpdateStatusMultiSuccess: false,
      isUpdateStatusMultiFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateStatusMultiState]: (state) => ({
      ...state,
      isUpdateStatusMultiRequest: false,
      isUpdateStatusMultiSuccess: false,
      isUpdateStatusMultiFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region
    [Actions.getAllOrderInChartRevenueRequest]: (state) => ({
      ...state,
      isGetAllOrderInChartRevenueRequest: true,
      isGetAllOrderInChartRevenueSuccess: false,
      isGetAllOrderInChartRevenueFailure: false,
    }),
    [Actions.getAllOrderInChartRevenueSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllOrderInChartRevenueRequest: false,
      isGetAllOrderInChartRevenueSuccess: true,
      isGetAllOrderInChartRevenueFailure: false,
      listAllOrderInChartRevenue: payload,
    }),
    [Actions.getAllOrderInChartRevenueFailure]: (state, { payload }) => ({
      ...state,
      isGetAllOrderInChartRevenueRequest: false,
      isGetAllOrderInChartRevenueSuccess: false,
      isGetAllOrderInChartRevenueFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region
    [Actions.getAllOrderInChartGrowRequest]: (state) => ({
      ...state,
      isGetAllOrderInChartGrowRequest: true,
      isGetAllOrderInChartGrowSuccess: false,
      isGetAllOrderInChartGrowFailure: false,
    }),
    [Actions.getAllOrderInChartGrowSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllOrderInChartGrowRequest: false,
      isGetAllOrderInChartGrowSuccess: true,
      isGetAllOrderInChartGrowFailure: false,
      listAllOrderInChartGrow: payload,
    }),
    [Actions.getAllOrderInChartGrowFailure]: (state, { payload }) => ({
      ...state,
      isGetAllOrderInChartGrowRequest: false,
      isGetAllOrderInChartGrowSuccess: false,
      isGetAllOrderInChartGrowFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.updateContemporaryExpenseRequest]: (state) => ({
      ...state,
      isUpdateContemporaryExpenseRequest: true,
      isUpdateContemporaryExpenseSuccess: false,
      isUpdateContemporaryExpenseFailure: false,
    }),
    [Actions.updateContemporaryExpenseSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateContemporaryExpenseRequest: false,
      isUpdateContemporaryExpenseSuccess: true,
      isUpdateContemporaryExpenseFailure: false,
      responseUpdateContemporary: payload,
    }),
    [Actions.updateContemporaryExpenseFailure]: (state, { payload }) => ({
      ...state,
      isUpdateContemporaryExpenseRequest: false,
      isUpdateContemporaryExpenseSuccess: false,
      isUpdateContemporaryExpenseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateContemporaryExpenseState]: (state) => ({
      ...state,
      isUpdateContemporaryExpenseFailure: false,
      isUpdateContemporaryExpenseRequest: false,
      isUpdateContemporaryExpenseSuccess: false,
      errorMessages: [],
    }),

    // #endregion

    // #region
    [Actions.getAllOrderFromAppRequest]: (state) => ({
      ...state,
      isGetAllOrderFromAppRequest: true,
      isGetAllOrderFromAppSuccess: false,
      isGetAllOrderFromAppFailure: false,
    }),
    [Actions.getAllOrderFromAppSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllOrderFromAppRequest: false,
      isGetAllOrderFromAppSuccess: true,
      isGetAllOrderFromAppFailure: false,
      listOrderFromApp: payload,
    }),
    [Actions.getAllOrderFromAppFailure]: (state, { payload }) => ({
      ...state,
      isGetAllOrderFromAppRequest: false,
      isGetAllOrderFromAppSuccess: false,
      isGetAllOrderFromAppFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region approve order from app
    [Actions.approveOrderFromAppRequest]: (state) => ({
      ...state,
      isApproveOrderFromAppRequest: true,
      isApproveOrderFromAppSuccess: false,
      isApproveOrderFromAppFailure: false,
    }),

    [Actions.approveOrderFromAppSuccess]: (state, { payload }) => ({
      ...state,
      isApproveOrderFromAppRequest: false,
      isApproveOrderFromAppSuccess: true,
      isApproveOrderFromAppFailure: false,
      responseApproveOrder: payload,
    }),
    [Actions.approveOrderFromAppFailure]: (state, { payload }) => ({
      ...state,
      isApproveOrderFromAppRequest: false,
      isApproveOrderFromAppSuccess: false,
      isApproveOrderFromAppFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetApproveOrderFromAppState]: (state) => ({
      ...state,
      isApproveOrderFromAppRequest: false,
      isApproveOrderFromAppSuccess: false,
      isApproveOrderFromAppFailure: false,
      errorMessages: [],
    }),

    // #endregion
    // #region refuse order from app
    [Actions.refuseOrderFromAppRequest]: (state) => ({
      ...state,
      isRefuseOrderFromAppRequest: true,
      isRefuseOrderFromAppSuccess: false,
      isRefuseOrderFromAppFailure: false,
    }),

    [Actions.refuseOrderFromAppSuccess]: (state) => ({
      ...state,
      isRefuseOrderFromAppRequest: false,
      isRefuseOrderFromAppSuccess: true,
      isRefuseOrderFromAppFailure: false,
    }),
    [Actions.refuseOrderFromAppFailure]: (state, { payload }) => ({
      ...state,
      isRefuseOrderFromAppRequest: false,
      isRefuseOrderFromAppSuccess: false,
      isRefuseOrderFromAppFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRefuseOrderFromAppState]: (state) => ({
      ...state,
      isRefuseOrderFromAppRequest: false,
      isRefuseOrderFromAppSuccess: false,
      isRefuseOrderFromAppFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region roll back order
    [Actions.rollBackOrderRequest]: (state) => ({
      ...state,
      isRollBackOrderRequest: true,
      isRollBackOrderSuccess: false,
      isRollBackOrderFailure: false,
    }),
    [Actions.rollBackOrderSuccess]: (state) => ({
      ...state,
      isRollBackOrderRequest: false,
      isRollBackOrderSuccess: true,
      isRollBackOrderFailure: false,
    }),
    [Actions.rollBackOrderFailure]: (state, { payload }) => ({
      ...state,
      isRollBackOrderRequest: false,
      isRollBackOrderSuccess: false,
      isRollBackOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRollBackOrderState]: (state) => ({
      ...state,
      isRollBackOrderRequest: false,
      isRollBackOrderSuccess: false,
      isRollBackOrderFailure: false,
      errorMessages: [],
    }),

    // #endregion
    // #region print order
    [Actions.printOrderAndRedirectRequest]: (state) => ({
      ...state,
      isPrintOrderAndRedirectRequest: true,
      isPrintOrderAndRedirectSuccess: false,
      isPrintOrderAndRedirectFailure: false,
    }),
    [Actions.printOrderAndRedirectSuccess]: (state, { payload }) => ({
      ...state,
      isPrintOrderAndRedirectRequest: false,
      isPrintOrderAndRedirectSuccess: true,
      isPrintOrderAndRedirectFailure: false,
      printOrderState: payload,
    }),
    [Actions.printOrderAndRedirectFailure]: (state, { payload }) => ({
      ...state,
      isPrintOrderAndRedirectRequest: false,
      isPrintOrderAndRedirectSuccess: false,
      isPrintOrderAndRedirectFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetPrintOrderAndRedirectState]: (state) => ({
      ...state,
      isPrintOrderAndRedirectRequest: false,
      isPrintOrderAndRedirectSuccess: false,
      isPrintOrderAndRedirectFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region
    [Actions.updatePaymentOrderAppRequest]: (state) => ({
      ...state,
      isUpdatePaymentOrderAppRequest: true,
      isUpdatePaymentOrderAppSuccess: false,
      isUpdatePaymentOrderAppFailure: false,
    }),
    [Actions.updatePaymentOrderAppSuccess]: (state) => ({
      ...state,
      isUpdatePaymentOrderAppRequest: false,
      isUpdatePaymentOrderAppSuccess: true,
      isUpdatePaymentOrderAppFailure: false,
    }),
    [Actions.updatePaymentOrderAppFailure]: (state, { payload }) => ({
      ...state,
      isUpdatePaymentOrderAppRequest: false,
      isUpdatePaymentOrderAppSuccess: false,
      isUpdatePaymentOrderAppFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdatePaymentOrderAppState]: (state) => ({
      ...state,
      isUpdatePaymentOrderAppRequest: false,
      isUpdatePaymentOrderAppSuccess: false,
      isUpdatePaymentOrderAppFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region exchange voucher
    [Actions.exchangeVoucherOrderAppRequest]: (state) => ({
      ...state,
      isExchangeVoucherOrderAppRequest: true,
      isExchangeVoucherOrderAppSuccess: false,
      isExchangeVoucherOrderAppFailure: false,
    }),
    [Actions.exchangeVoucherOrderAppSuccess]: (state) => ({
      ...state,
      isExchangeVoucherOrderAppRequest: false,
      isExchangeVoucherOrderAppSuccess: true,
      isExchangeVoucherOrderAppFailure: false,
    }),
    [Actions.exchangeVoucherOrderAppFailure]: (state, { payload }) => ({
      ...state,
      isExchangeVoucherOrderAppRequest: false,
      isExchangeVoucherOrderAppSuccess: false,
      isExchangeVoucherOrderAppFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExchangeVoucherState]: (state) => ({
      ...state,
      isExchangeVoucherOrderAppRequest: false,
      isExchangeVoucherOrderAppSuccess: false,
      isExchangeVoucherOrderAppFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.getLogOrderRequest]: (state) => ({
      ...state,
      isGetLogOrderRequest: true,
      isGetLogOrderSuccess: false,
      isGetLogOrderFailure: false,
    }),
    [Actions.getLogOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGetLogOrderRequest: false,
      isGetLogOrderSuccess: true,
      isGetLogOrderFailure: false,
      listLogOrder: payload,
    }),
    [Actions.getLogOrderFailure]: (state, { payload }) => ({
      ...state,
      isGetLogOrderRequest: false,
      isGetLogOrderSuccess: false,
      isGetLogOrderFailure: true,
      errorMessages: payload,
    }),

    // #endregion

    // #region  get detail order
    [Actions.getOrderDetailRequest]: (state) => ({
      ...state,
      isGetDetailOrderRequest: true,
      isGetDetailOrderSuccess: false,
      isGetDetailOrderFailure: false,
    }),
    [Actions.getOrderDetailSuccess]: (state, { payload }) => ({
      ...state,
      isGetDetailOrderRequest: false,
      isGetDetailOrderSuccess: true,
      isGetDetailOrderFailure: false,
      detailOrder: payload,
    }),
    [Actions.getOrderDetailFailure]: (state, { payload }) => ({
      ...state,
      isGetDetailOrderRequest: false,
      isGetDetailOrderSuccess: false,
      isGetDetailOrderFailure: true,
      errorMessages: payload,
    }),

    // #endregion

    // #region  get order by qr code
    [Actions.getOrderByQrCodeRequest]: (state) => ({
      ...state,
      isGetOrderByQrCodeRequest: true,
      isGetOrderByQrCodeSuccess: false,
      isGetOrderByQrCodeFailure: false,
    }),
    [Actions.getOrderByQrCodeSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderByQrCodeRequest: false,
      isGetOrderByQrCodeSuccess: true,
      isGetOrderByQrCodeFailure: false,
      orderByQrCodeState: payload,
    }),
    [Actions.getOrderByQrCodeFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderByQrCodeRequest: false,
      isGetOrderByQrCodeSuccess: false,
      isGetOrderByQrCodeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetOrderByQrCode]: (state) => ({
      ...state,
      isGetOrderByQrCodeRequest: false,
      isGetOrderByQrCodeSuccess: false,
      isGetOrderByQrCodeFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region cancel order
    [Actions.cancelOrderRequest]: (state) => ({
      ...state,
      isCancelOrderRequest: true,
      isCancelOrderSuccess: false,
      isCancelOrderFailure: false,
    }),
    [Actions.cancelOrderSuccess]: (state) => ({
      ...state,
      isCancelOrderRequest: false,
      isCancelOrderSuccess: true,
      isCancelOrderFailure: false,
    }),
    [Actions.cancelOrderFailure]: (state, { payload }) => ({
      ...state,
      isCancelOrderRequest: false,
      isCancelOrderSuccess: false,
      isCancelOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelOrderState]: (state) => ({
      ...state,
      isCancelOrderRequest: false,
      isCancelOrderSuccess: false,
      isCancelOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region refund order
    [Actions.refundOrderRequest]: (state) => ({
      ...state,
      isRefundOrderRequest: true,
      isRefundOrderSuccess: false,
      isRefundOrderFailure: false,
    }),
    [Actions.refundOrderSuccess]: (state) => ({
      ...state,
      isRefundOrderRequest: false,
      isRefundOrderSuccess: true,
      isRefundOrderFailure: false,
    }),
    [Actions.refundOrderFailure]: (state, { payload }) => ({
      ...state,
      isRefundOrderRequest: false,
      isRefundOrderSuccess: false,
      isRefundOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRefundOrderState]: (state) => ({
      ...state,
      isRefundOrderRequest: false,
      isRefundOrderSuccess: false,
      isRefundOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Create order voucher
    [Actions.createSellingVoucherRequest]: (state) => ({
      ...state,
      isCreateSellingVoucherRequest: true,
      isCreateSellingVoucherSuccess: false,
      isCreateSellingVoucherFailure: false,
    }),
    [Actions.createSellingVoucherSuccess]: (state) => ({
      ...state,
      isCreateSellingVoucherRequest: false,
      isCreateSellingVoucherSuccess: true,
      isCreateSellingVoucherFailure: false,
    }),
    [Actions.createSellingVoucherFailure]: (state, { payload }) => ({
      ...state,
      isCreateSellingVoucherRequest: false,
      isCreateSellingVoucherSuccess: false,
      isCreateSellingVoucherFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateSellingVoucherState]: (state) => ({
      ...state,
      isCreateSellingVoucherRequest: false,
      isCreateSellingVoucherSuccess: false,
      isCreateSellingVoucherFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Create order voucher
    [Actions.createInvoiceRequest]: (state) => ({
      ...state,
      isCreateInvoiceRequest: true,
      isCreateInvoiceSuccess: false,
      isCreateInvoiceFailure: false,
    }),
    [Actions.createInvoiceSuccess]: (state) => ({
      ...state,
      isCreateInvoiceRequest: false,
      isCreateInvoiceSuccess: true,
      isCreateInvoiceFailure: false,
    }),
    [Actions.createInvoiceFailure]: (state, { payload }) => ({
      ...state,
      isCreateInvoiceRequest: false,
      isCreateInvoiceSuccess: false,
      isCreateInvoiceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateInvoiceState]: (state) => ({
      ...state,
      isCreateInvoiceRequest: false,
      isCreateInvoiceSuccess: false,
      isCreateInvoiceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Create order invoice
    [Actions.createOrderInvoiceRequest]: (state) => ({
      ...state,
      isCreateOrderInvoiceRequest: true,
      isCreateOrderInvoiceSuccess: false,
      isCreateOrderInvoiceFailure: false,
    }),
    [Actions.createOrderInvoiceSuccess]: (state) => ({
      ...state,
      isCreateOrderInvoiceRequest: false,
      isCreateOrderInvoiceSuccess: true,
      isCreateOrderInvoiceFailure: false,
    }),
    [Actions.createOrderInvoiceFailure]: (state, { payload }) => ({
      ...state,
      isCreateOrderInvoiceRequest: false,
      isCreateOrderInvoiceSuccess: false,
      isCreateOrderInvoiceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateOrderInvoiceState]: (state) => ({
      ...state,
      isCreateOrderInvoiceRequest: false,
      isCreateOrderInvoiceSuccess: false,
      isCreateOrderInvoiceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Edit order invoice
    [Actions.editOrderInvoiceRequest]: (state) => ({
      ...state,
      isEditOrderInvoiceRequest: true,
      isEditOrderInvoiceSuccess: false,
      isEditOrderInvoiceFailure: false,
    }),
    [Actions.editOrderInvoiceSuccess]: (state) => ({
      ...state,
      isEditOrderInvoiceRequest: false,
      isEditOrderInvoiceSuccess: true,
      isEditOrderInvoiceFailure: false,
    }),
    [Actions.editOrderInvoiceFailure]: (state, { payload }) => ({
      ...state,
      isEditOrderInvoiceRequest: false,
      isEditOrderInvoiceSuccess: false,
      isEditOrderInvoiceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditOrderInvoiceState]: (state) => ({
      ...state,
      isEditOrderInvoiceRequest: false,
      isEditOrderInvoiceSuccess: false,
      isEditOrderInvoiceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete order invoice
    [Actions.deleteOrderInvoiceRequest]: (state) => ({
      ...state,
      isDeleteOrderInvoiceRequest: true,
      isDeleteOrderInvoiceSuccess: false,
      isDeleteOrderInvoiceFailure: false,
    }),
    [Actions.deleteOrderInvoiceSuccess]: (state) => ({
      ...state,
      isDeleteOrderInvoiceRequest: false,
      isDeleteOrderInvoiceSuccess: true,
      isDeleteOrderInvoiceFailure: false,
    }),
    [Actions.deleteOrderInvoiceFailure]: (state, { payload }) => ({
      ...state,
      isDeleteOrderInvoiceRequest: false,
      isDeleteOrderInvoiceSuccess: false,
      isDeleteOrderInvoiceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteOrderInvoiceState]: (state) => ({
      ...state,
      isDeleteOrderInvoiceRequest: false,
      isDeleteOrderInvoiceSuccess: false,
      isDeleteOrderInvoiceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Create order purcharse voucher
    [Actions.createPurcharseVoucherRequest]: (state) => ({
      ...state,
      isCreatePurcharseVoucherRequest: true,
      isCreatePurcharseVoucherSuccess: false,
      isCreatePurcharseVoucherFailure: false,
    }),
    [Actions.createPurcharseVoucherSuccess]: (state) => ({
      ...state,
      isCreatePurcharseVoucherRequest: false,
      isCreatePurcharseVoucherSuccess: true,
      isCreatePurcharseVoucherFailure: false,
    }),
    [Actions.createPurcharseVoucherFailure]: (state, { payload }) => ({
      ...state,
      isCreatePurcharseVoucherRequest: false,
      isCreatePurcharseVoucherSuccess: false,
      isCreatePurcharseVoucherFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePurcharseVoucherState]: (state) => ({
      ...state,
      isCreatePurcharseVoucherRequest: false,
      isCreatePurcharseVoucherSuccess: false,
      isCreatePurcharseVoucherFailure: false,
      errorMessages: [],
    }),
    // #endregion

    [Actions.getListOrderStaffPackingRequest]: (state) => ({
      ...state,
      isGetListOrderStaffPackingRequest: true,
      isGetListOrderStaffPackingSuccess: false,
      isGetListOrderStaffPackingFailure: false,
    }),
    [Actions.getListOrderStaffPackingSuccess]: (state, { payload }) => ({
      ...state,
      isGetListOrderStaffPackingRequest: false,
      isGetListOrderStaffPackingSuccess: true,
      isGetListOrderStaffPackingFailure: false,
      listOrderStaffPacking: payload,
    }),
    [Actions.getListOrderStaffPackingFailure]: (state, { payload }) => ({
      ...state,
      isGetListOrderStaffPackingRequest: false,
      isGetListOrderStaffPackingSuccess: false,
      isGetListOrderStaffPackingFailure: true,
      errorMessages: payload,
    }),

    // #region  get order by qr code
    [Actions.getOrderByQrCodeStaffPackingRequest]: (state) => ({
      ...state,
      isGetOrderByQrCodeStaffPackingRequest: true,
      isGetOrderByQrCodeStaffPackingSuccess: false,
      isGetOrderByQrCodeStaffPackingFailure: false,
    }),
    [Actions.getOrderByQrCodeStaffPackingSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderByQrCodeStaffPackingRequest: false,
      isGetOrderByQrCodeStaffPackingSuccess: true,
      isGetOrderByQrCodeStaffPackingFailure: false,
      orderByQrCodeStateStaffPacking: payload,
    }),
    [Actions.getOrderByQrCodeStaffPackingFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderByQrCodeStaffPackingRequest: false,
      isGetOrderByQrCodeStaffPackingSuccess: false,
      isGetOrderByQrCodeStaffPackingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetOrderByQrCodeStaffPacking]: (state) => ({
      ...state,
      isGetOrderByQrCodeStaffPackingRequest: false,
      isGetOrderByQrCodeStaffPackingSuccess: false,
      isGetOrderByQrCodeStaffPackingFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region : Update order
    [Actions.updateStatusOrderStaffPackingRequest]: (state) => ({
      ...state,
      isUpdateStatusOrderStaffPackingRequest: true,
      isUpdateStatusOrderStaffPackingSuccess: false,
      isUpdateStatusOrderStaffPackingFailure: false,
    }),
    [Actions.updateStatusOrderStaffPackingSuccess]: (state) => ({
      ...state,
      isUpdateStatusOrderStaffPackingRequest: false,
      isUpdateStatusOrderStaffPackingSuccess: true,
      isUpdateStatusOrderStaffPackingFailure: false,
    }),
    [Actions.updateStatusOrderStaffPackingFailure]: (state, { payload }) => ({
      ...state,
      isUpdateStatusOrderStaffPackingRequest: false,
      isUpdateStatusOrderStaffPackingSuccess: false,
      isUpdateStatusOrderStaffPackingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateStatusOrderStaffPacking]: (state) => ({
      ...state,
      isUpdateStatusOrderStaffPackingRequest: false,
      isUpdateStatusOrderStaffPackingSuccess: false,
      isUpdateStatusOrderStaffPackingFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region get price lala
    [Actions.getPriceShippingLalaRequest]: (state) => ({
      ...state,
      isGetPriceShippingLalaRequest: true,
      isGetPriceShippingLalaSuccess: false,
      isGetPriceShippingLalaFailure: false,
    }),
    [Actions.getPriceShippingLalaSuccess]: (state, { payload }) => ({
      ...state,
      isGetPriceShippingLalaRequest: false,
      isGetPriceShippingLalaSuccess: true,
      isGetPriceShippingLalaFailure: false,
      getPriceShippingLalaState: payload,
    }),
    [Actions.getPriceShippingLalaFailure]: (state, { payload }) => ({
      ...state,
      isGetPriceShippingLalaRequest: false,
      isGetPriceShippingLalaSuccess: false,
      isGetPriceShippingLalaFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetPriceShippingLalaState]: (state) => ({
      ...state,
      isGetPriceShippingLalaRequest: false,
      isGetPriceShippingLalaSuccess: false,
      isGetPriceShippingLalaFailure: false,
      getPriceShippingLalaState: {},
      errorMessages: [],
    }),

    // #endregion

    // #region create fee ship lala
    [Actions.createShippingLalaRequest]: (state) => ({
      ...state,
      isCreateShippingLalaRequest: true,
      isCreateShippingLalaSuccess: false,
      isCreateShippingLalaFailure: false,
    }),
    [Actions.createShippingLalaSuccess]: (state) => ({
      ...state,
      isCreateShippingLalaRequest: false,
      isCreateShippingLalaSuccess: true,
      isCreateShippingLalaFailure: false,
      isGetPriceShippingLalaRequest: false,
      isGetPriceShippingLalaSuccess: false,
      isGetPriceShippingLalaFailure: false,
      getPriceShippingLalaState: {},
    }),
    [Actions.createShippingLalaFailure]: (state, { payload }) => ({
      ...state,
      isCreateShippingLalaRequest: false,
      isCreateShippingLalaSuccess: false,
      isCreateShippingLalaFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateShippingLalaState]: (state) => ({
      ...state,
      isCreateShippingLalaRequest: false,
      isCreateShippingLalaSuccess: false,
      isCreateShippingLalaFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region getLog
    [Actions.getListOrderLalaAcceptedRequest]: (state) => ({
      ...state,
      isGetListOrderLalaAcceptedRequest: true,
      isGetListOrderLalaAcceptedSuccess: false,
      isGetListOrderLalaAcceptedFailure: false,
    }),
    [Actions.getListOrderLalaAcceptedSuccess]: (state, { payload }) => ({
      ...state,
      isGetListOrderLalaAcceptedRequest: false,
      isGetListOrderLalaAcceptedSuccess: true,
      isGetListOrderLalaAcceptedFailure: false,
      getListOrderLalaAcceptedState: payload,
    }),
    [Actions.getListOrderLalaAcceptedFailure]: (state, { payload }) => ({
      ...state,
      isGetListOrderLalaAcceptedRequest: false,
      isGetListOrderLalaAcceptedSuccess: false,
      isGetListOrderLalaAcceptedFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region create fee lalamove
    [Actions.createFeeSangthuyRequest]: (state) => ({
      ...state,
      isCreateFeeSangthuyRequest: true,
      isCreateFeeSangthuySuccess: false,
      isCreateFeeSangthuyFailure: false,
    }),
    [Actions.createFeeSangthuySuccess]: (state, { payload }) => ({
      ...state,
      isCreateFeeSangthuyRequest: false,
      isCreateFeeSangthuySuccess: true,
      isCreateFeeSangthuyFailure: false,
      createFeeSangthuyState: payload,
    }),
    [Actions.createFeeSangthuyFailure]: (state, { payload }) => ({
      ...state,
      isCreateFeeSangthuyRequest: false,
      isCreateFeeSangthuySuccess: false,
      isCreateFeeSangthuyFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateFeeSangthuyState]: (state) => ({
      ...state,
      isCreateFeeSangthuyRequest: false,
      isCreateFeeSangthuySuccess: false,
      isCreateFeeSangthuyFailure: false,
      createFeeSangthuyState: {},
      errorMessages: [],
    }),

    // #endregion

    // #region log lalamove
    [Actions.getLogLalaMoveRequest]: (state) => ({
      ...state,
      isGetLogLalaMoveRequest: true,
      isGetLogLalaMoveSuccess: false,
      isGetLogLalaMoveFailure: false,
    }),
    [Actions.getLogLalaMoveSuccess]: (state, { payload }) => ({
      ...state,
      isGetLogLalaMoveRequest: false,
      isGetLogLalaMoveSuccess: true,
      isGetLogLalaMoveFailure: false,
      listLogLalaMoveState: payload,
    }),
    [Actions.getLogLalaMoveFailure]: (state, { payload }) => ({
      ...state,
      isGetLogLalaMoveRequest: false,
      isGetLogLalaMoveSuccess: false,
      isGetLogLalaMoveFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region  update fee lala
    [Actions.updateFeeLalaMoveRequest]: (state) => ({
      ...state,
      isUpdateFeeLalaMoveRequest: true,
      isUpdateFeeLalaMoveSuccess: false,
      isUpdateFeeLalaMoveFailure: false,
    }),
    [Actions.updateFeeLalaMoveSuccess]: (state) => ({
      ...state,
      isUpdateFeeLalaMoveRequest: false,
      isUpdateFeeLalaMoveSuccess: true,
      isUpdateFeeLalaMoveFailure: false,
    }),
    [Actions.updateFeeLalaMoveFailure]: (state, { payload }) => ({
      ...state,
      isUpdateFeeLalaMoveRequest: false,
      isUpdateFeeLalaMoveSuccess: false,
      isUpdateFeeLalaMoveFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateFeeLalaMoveState]: (state) => ({
      ...state,
      isUpdateFeeLalaMoveRequest: false,
      isUpdateFeeLalaMoveSuccess: false,
      isUpdateFeeLalaMoveFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region: create fee lala manual
    [Actions.createFeeLalaManualRequest]: (state) => ({
      ...state,
      isCreateFeeLalaManualRequest: true,
      isCreateFeeLalaManualSuccess: false,
      isCreateFeeLalaManualFailure: false,
    }),
    [Actions.createFeeLalaManualSuccess]: (state) => ({
      ...state,
      isCreateFeeLalaManualRequest: false,
      isCreateFeeLalaManualSuccess: true,
      isCreateFeeLalaManualFailure: false,
    }),
    [Actions.createFeeLalaManualFailure]: (state, { payload }) => ({
      ...state,
      isCreateFeeLalaManualRequest: false,
      isCreateFeeLalaManualSuccess: false,
      isCreateFeeLalaManualFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateFeeLalaManualState]: (state) => ({
      ...state,
      isCreateFeeLalaManualRequest: false,
      isCreateFeeLalaManualSuccess: false,
      isCreateFeeLalaManualFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region cancel lalamove

    [Actions.cancelLalaMoveRequest]: (state) => ({
      ...state,
      isCancelLalaMoveRequest: true,
      isCancelLalaMoveSuccess: false,
      isCancelLalaMoveFailure: false,
    }),
    [Actions.cancelLalaMoveSuccess]: (state) => ({
      ...state,
      isCancelLalaMoveRequest: false,
      isCancelLalaMoveSuccess: true,
      isCancelLalaMoveFailure: false,
    }),
    [Actions.cancelLalaMoveFailure]: (state, { payload }) => ({
      ...state,
      isCancelLalaMoveRequest: false,
      isCancelLalaMoveSuccess: false,
      isCancelLalaMoveFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelLalaMoveState]: (state) => ({
      ...state,
      isCancelLalaMoveRequest: false,
      isCancelLalaMoveSuccess: false,
      isCancelLalaMoveFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region claim permission
    [Actions.claimPermissionRequest]: (state) => ({
      ...state,
      isClaimPermissionRequest: true,
      isClaimPermissionSuccess: false,
      isClaimPermissionFailure: false,
    }),
    [Actions.claimPermissionSuccess]: (state) => ({
      ...state,
      isClaimPermissionRequest: false,
      isClaimPermissionSuccess: true,
      isClaimPermissionFailure: false,
    }),
    [Actions.claimPermissionFailure]: (state, { payload }) => ({
      ...state,
      isClaimPermissionRequest: false,
      isClaimPermissionSuccess: false,
      isClaimPermissionFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetClaimPermissionState]: (state) => ({
      ...state,
      isClaimPermissionRequest: false,
      isClaimPermissionSuccess: false,
      isClaimPermissionFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region accept permissions
    [Actions.acceptPermissionRequest]: (state) => ({
      ...state,
      isAcceptPermissionRequest: true,
      isAcceptPermissionSuccess: false,
      isAcceptPermissionFailure: false,
    }),
    [Actions.acceptPermissionSuccess]: (state) => ({
      ...state,
      isAcceptPermissionRequest: false,
      isAcceptPermissionSuccess: true,
      isAcceptPermissionFailure: false,
    }),
    [Actions.acceptPermissionFailure]: (state, { payload }) => ({
      ...state,
      isAcceptPermissionRequest: false,
      isAcceptPermissionSuccess: false,
      isCancelLalaMoveFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAcceptPermissionState]: (state) => ({
      ...state,
      isAcceptPermissionRequest: false,
      isAcceptPermissionSuccess: false,
      isAcceptPermissionFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region update order lala
    [Actions.updateOrderLalaRequest]: (state) => ({
      ...state,
      isUpdateOrderLalaRequest: true,
      isUpdateOrderLalaSuccess: false,
      isUpdateOrderLalaFailure: false,
    }),
    [Actions.updateOrderLalaSuccess]: (state) => ({
      ...state,
      isUpdateOrderLalaRequest: false,
      isUpdateOrderLalaSuccess: true,
      isUpdateOrderLalaFailure: false,
    }),
    [Actions.updateOrderLalaFailure]: (state, { payload }) => ({
      ...state,
      isUpdateOrderLalaRequest: false,
      isUpdateOrderLalaSuccess: false,
      isUpdateOrderLalaFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateOrderLalaState]: (state) => ({
      ...state,
      isUpdateOrderLalaRequest: false,
      isUpdateOrderLalaSuccess: false,
      isUpdateOrderLalaFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region get list order lala can change
    [Actions.getListOrderLalaCanChangeRequest]: (state) => ({
      ...state,
      isGetListOrderLalaCanChangeRequest: true,
      isGetListOrderLalaCanChangeSuccess: false,
      isGetListOrderLalaCanChangeFailure: false,

    }),
    [Actions.getListOrderLalaCanChangeSuccess]: (state, { payload }) => ({
      ...state,
      isGetListOrderLalaCanChangeRequest: false,
      isGetListOrderLalaCanChangeSuccess: true,
      isGetListOrderLalaCanChangeFailure: false,
      responseGetListOrderLalaCanChange: payload,
    }),
    [Actions.getListOrderLalaCanChangeFailure]: (state, { payload }) => ({
      ...state,
      isGetListOrderLalaCanChangeRequest: false,
      isGetListOrderLalaCanChangeSuccess: false,
      isGetListOrderLalaCanChangeFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region : Local
    [Actions.resetOrderState]: () => initialState,
    // #endregion

    // #region
    [Actions.getAllOrderWaitingRequest]: (state) => ({
      ...state,
      isGetAllOrderWaitingRequest: true,
      isGetAllOrderWaitingSuccess: false,
      isGetAllOrderWaitingFailure: false,
    }),
    [Actions.getAllOrderWaitingSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllOrderWaitingRequest: false,
      isGetAllOrderWaitingSuccess: true,
      isGetAllOrderWaitingFailure: false,
      listAllOrderWaiting: payload,
    }),
    [Actions.getAllOrderWaitingFailure]: (state, { payload }) => ({
      ...state,
      isGetAllOrderWaitingRequest: false,
      isGetAllOrderWaitingSuccess: false,
      isGetAllOrderWaitingFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region Update multiple status order
    [Actions.updateMultipleStatusOrderRequest]: (state) => ({
      ...state,
      isUpdateMultipleStatusOrderRequest: true,
      isUpdateContemporaryExpenseSuccess: false,
      isUpdateMultipleStatusOrderFailure: false,
    }),
    [Actions.updateMultipleStatusOrderSuccess]: (state) => ({
      ...state,
      isUpdateMultipleStatusOrderRequest: false,
      isUpdateMultipleStatusOrderSuccess: true,
      isUpdateMultipleStatusOrderFailure: false,
    }),
    [Actions.updateMultipleStatusOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdateMultipleStatusOrderRequest: false,
      isUpdateMultipleStatusOrderSuccess: false,
      isUpdateMultipleStatusOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateMultipleStatusOrder]: (state) => ({
      ...state,
      isUpdateMultipleStatusOrderRequest: false,
      isUpdateMultipleStatusOrderSuccess: false,
      isUpdateMultipleStatusOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
  },
  initialState,
);

export default reducer;
