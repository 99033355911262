// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  isCreateTransportOrderRequest: false,
  isCreateTransportOrderSuccess: false,
  isCreateTransportOrderFailure: false,
  //
  isGetServicesOrderRequest: false,
  isGetServicesOrderSuccess: false,
  isGetServicesOrderFailure: false,
  listServicesOrder: {},
  //
  isGetAllTransportsRequest: false,
  isGetAllTransportsSuccess: false,
  isGetAllTransportsFailure: false,
  getAllTransportsState: {},
  //
  errorMessages: [],
};

const reducer = handleActions({
  [Actions.createTransportOrderRequest]: (state) => ({
    ...state,
    isCreateTransportOrderRequest: true,
    isCreateTransportOrderSuccess: false,
    isCreateTransportOrderFailure: false,
  }),
  [Actions.createTransportOrderSuccess]: (state) => ({
    ...state,
    isCreateTransportOrderRequest: false,
    isCreateTransportOrderSuccess: true,
    isCreateTransportOrderFailure: false,
  }),
  [Actions.createTransportOrderFailure]: (state, { payload }) => ({
    ...state,
    isCreateTransportOrderRequest: false,
    isCreateTransportOrderSuccess: false,
    isCreateTransportOrderFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateTransportOrderState]: (state) => ({
    ...state,
    isCreateTransportOrderRequest: false,
    isCreateTransportOrderSuccess: false,
    isCreateTransportOrderFailure: false,
    errorMessages: [],
  }),
  // #region get services order
  [Actions.getServicesOrderRequest]: (state) => ({
    ...state,
    isGetServicesOrderRequest: true,
    isGetServicesOrderSuccess: false,
    isGetServicesOrderFailure: false,
  }),
  [Actions.getServicesOrderSuccess]: (state, { payload }) => ({
    ...state,
    isGetServicesOrderRequest: false,
    isGetServicesOrderSuccess: true,
    isGetServicesOrderFailure: false,
    listServicesOrder: payload,
  }),
  [Actions.getServicesOrderFailure]: (state, { payload }) => ({
    ...state,
    isGetServicesOrderRequest: false,
    isGetServicesOrderSuccess: false,
    isGetServicesOrderFailure: true,
    errorMessages: payload,
  }),

  // #endregion

  // #region
  [Actions.getAllTransportsRequest]: (state) => ({
    ...state,
    isGetAllTransportsRequest: true,
    isGetAllTransportsSuccess: false,
    isGetAllTransportsFailure: false,
  }),
  [Actions.getAllTransportsSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllTransportsRequest: false,
    isGetAllTransportsSuccess: true,
    isGetAllTransportsFailure: false,
    getAllTransportsState: payload,
  }),
  [Actions.getAllTransportsFailure]: (state, { payload }) => ({
    isGetAllTransportsRequest: false,
    isGetAllTransportsSuccess: false,
    isGetAllTransportsFailure: true,
    errorMessages: payload,
  }),
  // #endregion
  [Actions.resetTransportState]: () => initialState,
}, initialState);

export default reducer;
