import { combineReducers } from 'redux';

import auth from './auth/reducer';
import employee from './employee/reducer';
import menu from './menu/reducer';
import permissions from './permissions/reducer';
import roles from './roles/reducer';
import user from './user/reducer';
import productType from './productType/reducer';
import product from './product/reducer';
import customer from './customer/reducer';
import shippings from './shippings/reducer';
import order from './order/reducer';
import regionGroup from './regionGroup/reducer';
import shippingsPrice from './shippingsPrice/reducer';
import common from './common/reducer';
import productPrice from './productPrice/reducer';
import work from './work/reducer';
import workTracking from './workTracking/reducer';
import calendar from './calendar/reducer';
import scheduleCalendar from './scheduleCalendar/reducer';
import scheduleBookCalendar from './scheduleBookCalendar/reducer';
import wareHouse from './warehouse/reducer';
import notification from './notifications/reducer';
import teams from './teams/reducer';
import report from './report/reducer';
import call from './call/reducer';
import chart from './chart/reducer';
import admin from './admin/reducer';
import accumulation from './accumulation/reducer';
import config from './config/reducer';
import transport from './transport/reducer';
import region from './region/reducer';
import chat from './chat/reducer';
import payoff from './payoff/reducer';
import debt from './debt/reducer';
import bankAccount from './bankAccount/reducer';
import orderMFP from './orderMFP/reducer';
import intern from './intern/reducer';

const rootReducer = combineReducers({
  auth,
  employee,
  menu,
  roles,
  user,
  permissions,
  product,
  productType,
  customer,
  shippings,
  order,
  regionGroup,
  shippingsPrice,
  common,
  productPrice,
  work,
  workTracking,
  calendar,
  scheduleCalendar,
  scheduleBookCalendar,
  wareHouse,
  notification,
  teams,
  report,
  call,
  chart,
  admin,
  accumulation,
  config,
  transport,
  region,
  chat,
  payoff,
  debt,
  bankAccount,
  orderMFP,
  intern,
});

export default rootReducer;
