// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetListCustomerRequest: false,
  isGetListCustomerSuccess: false,
  isGetListCustomerFailure: false,
  listCustomer: {},

  isAddCustomerRequest: false,
  isAddCustomerSuccess: false,
  isAddCustomerFailure: false,
  addCustomerResponse: {},

  isGetDetailCustomerRequest: false,
  isGetDetailCustomerSuccess: false,
  isGetDetailCustomerFailure: false,
  detailCustomer: {},

  isGetAllRegionRequest: false,
  isGetAllRegionSuccess: false,
  isGetAllRegionFailure: false,
  listRegion: {},

  isGetAllWardRequest: false,
  isGetAllWardSuccess: false,
  isGetAllWardFailure: false,
  listWard: {},

  isGetAllDistrictRequest: false,
  isGetAllDistrictSuccess: false,
  isGetAllDistrictFailure: false,
  listDistrict: {},

  isDeleteCustomerRequest: false,
  isDeleteCustomerSuccess: false,
  isDeleteCustomerFailure: false,

  isEditCustomerRequest: false,
  isEditCustomerSuccess: false,
  isEditCustomerFailure: false,
  editCustomerResponse: {},

  isImportCustomerRequest: false,
  isImportCustomerSuccess: false,
  isImportCustomerFailure: false,

  isGetAllOrderCustomerRequest: false,
  isGetAllOrderCustomerSuccess: false,
  isGetAllOrderCustomerFailure: false,
  listOrderCustomer: {},

  isGetAllCustomerAppRequest: false,
  isGetAllCustomerAppSuccess: false,
  isGetAllCustomerAppFailure: false,
  listCustomerApp: {},

  isUpdateCustomerAppRequest: false,
  isUpdateCustomerAppSuccess: false,
  isUpdateCustomerAppFailure: false,

  isApproveCustomerAppRequest: false,
  isApproveCustomerAppSuccess: false,
  isApproveCustomerAppFailure: false,
  responseApproveState: {},

  isBlockCustomerAppRequest: false,
  isBlockCustomerAppSuccess: false,
  isBlockCustomerAppFailure: false,

  isCreateCustomerAppRequest: false,
  isCreateCustomerAppSuccess: false,
  isCreateCustomerAppFailure: false,

  isAddCustomerTaxRequest: false,
  isAddCustomerTaxSuccess: false,
  isAddCustomerTaxFailure: false,
  addCustomerTaxResponse: {},

  isDeleteCustomerTaxRequest: false,
  isDeleteCustomerTaxSuccess: false,
  isDeleteCustomerTaxFailure: false,

  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region: get list customer
    [Actions.getListCustomerRequest]: (state) => ({
      ...state,
      isGetListCustomerRequest: true,
      isGetListCustomerSuccess: false,
      isGetDetailCustomerFailure: false,
    }),
    [Actions.getListCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isGetListCustomerRequest: false,
      isGetListCustomerSuccess: true,
      isGetListCustomerFailure: false,
      listCustomer: payload,
    }),
    [Actions.getListCustomerFailure]: (state, { payload }) => ({
      ...state,
      isGetListCustomerRequest: false,
      isGetDetailCustomerSuccess: false,
      isGetDetailCustomerFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region : add customer
    [Actions.addCustomerRequest]: (state) => ({
      ...state,
      isAddCustomerRequest: true,
      isAddCustomerSuccess: false,
      isAddCustomerFailure: false,
    }),
    [Actions.addCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isAddCustomerRequest: false,
      isAddCustomerSuccess: true,
      isAddCustomerFailure: false,
      addCustomerResponse: payload,
    }),
    [Actions.addCustomerFailure]: (state, { payload }) => ({
      ...state,
      isAddCustomerRequest: false,
      isAddCustomerSuccess: false,
      isAddCustomerFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddCustomerState]: (state) => ({
      ...state,
      isAddCustomerFailure: false,
      isAddCustomerRequest: false,
      isAddCustomerSuccess: false,
      addCustomerResponse: {},
      errorMessages: [],
    }),
    // #endregion

    // #region : get detail
    [Actions.getDetailCustomerRequest]: (state) => ({
      ...state,
      isGetDetailCustomerRequest: true,
      isGetDetailCustomerSuccess: false,
      isGetDetailCustomerFailure: false,
    }),
    [Actions.getDetailCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isGetDetailCustomerRequest: false,
      isGetDetailCustomerSuccess: true,
      isGetDetailCustomerFailure: false,
      detailCustomer: payload,
    }),
    [Actions.getDetailCustomerFailure]: (state, { payload }) => ({
      ...state,
      isGetDetailCustomerRequest: false,
      isGetDetailCustomerSuccess: false,
      isGetDetailCustomerFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region : region
    [Actions.getAllRegionRequest]: (state) => ({
      ...state,
      isGetAllRegionRequest: true,
      isGetAllRegionSuccess: false,
      isGetAllRegionFailure: false,
    }),
    [Actions.getAllRegionSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllRegionRequest: false,
      isGetAllRegionSuccess: true,
      isGetAllRegionFailure: false,
      listRegion: payload,
    }),
    [Actions.getAllRegionFailure]: (state, { payload }) => ({
      ...state,
      isGetAllRegionRequest: false,
      isGetAllRegionSuccess: false,
      isGetAllRegionFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : region
    [Actions.getAllWardRequest]: (state) => ({
      ...state,
      isGetAllWardRequest: true,
      isGetAllWardSuccess: false,
      isGetAllWardFailure: false,
    }),
    [Actions.getAllWardSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllWardRequest: false,
      isGetAllWardSuccess: true,
      isGetAllWardFailure: false,
      listWard: payload,
    }),
    [Actions.getAllWardFailure]: (state, { payload }) => ({
      ...state,
      isGetAllWardRequest: false,
      isGetAllWardSuccess: false,
      isGetAllWardFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region
    [Actions.getAllDistrictRequest]: (state) => ({
      ...state,
      isGetAllDistrictRequest: true,
      isGetAllDistrictSuccess: false,
      isGetAllDistrictFailure: false,
    }),
    [Actions.getAllDistrictSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllDistrictRequest: false,
      isGetAllDistrictSuccess: true,
      isGetAllDistrictFailure: false,
      listDistrict: payload,
    }),
    [Actions.getAllDistrictFailure]: (state, { payload }) => ({
      ...state,
      isGetAllDistrictFailure: true,
      isGetAllDistrictSuccess: false,
      isGetAllDistrictRequest: false,
      errorMessages: payload,
    }),
    // #endregion
    // #region: delete customer
    [Actions.deleteCustomerRequest]: (state) => ({
      ...state,
      isDeleteCustomerRequest: true,
      isDeleteCustomerSuccess: false,
      isDeleteCustomerFailure: false,
    }),
    [Actions.deleteCustomerSuccess]: (state) => ({
      ...state,
      isDeleteCustomerRequest: false,
      isDeleteCustomerSuccess: true,
      isDeleteCustomerFailure: false,

    }),
    [Actions.deleteCustomerFailure]: (state, { payload }) => ({
      ...state,
      isDeleteCustomerRequest: false,
      isDeleteCustomerSuccess: false,
      isDeleteCustomerFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteCustomerState]: (state) => ({
      ...state,
      isDeleteCustomerFailure: false,
      isDeleteCustomerRequest: false,
      isDeleteCustomerSuccess: false,
      errorMessages: [],
    }),
    // #endregion

    // #region: edit customer
    [Actions.editCustomerRequest]: (state) => ({
      ...state,
      isEditCustomerRequest: true,
      isEditCustomerSuccess: false,
      isEditCustomerFailure: false,
    }),
    [Actions.editCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isEditCustomerRequest: false,
      isEditCustomerSuccess: true,
      isEditCustomerFailure: false,
      editCustomerResponse: payload,
    }),
    [Actions.editCustomerFailure]: (state, { payload }) => ({
      ...state,
      isEditCustomerRequest: false,
      isEditCustomerSuccess: false,
      isEditCustomerFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditCustomerState]: (state) => ({
      ...state,
      isEditCustomerFailure: false,
      isEditCustomerRequest: false,
      isEditCustomerSuccess: false,
      editCustomerResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : import customer from excel
    [Actions.importCustomerRequest]: (state) => ({
      ...state,
      isImportCustomerRequest: true,
      isImportCustomerSuccess: false,
      isImportCustomerFailure: false,
    }),
    [Actions.importCustomerSuccess]: (state) => ({
      ...state,
      isImportCustomerRequest: false,
      isImportCustomerSuccess: true,
      isImportCustomerFailure: false,
    }),
    [Actions.importCustomerFailure]: (state, { payload }) => ({
      ...state,
      isImportCustomerRequest: false,
      isImportCustomerSuccess: false,
      isImportCustomerFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetImportCustomerState]: (state) => ({
      ...state,
      isImportCustomerFailure: false,
      isImportCustomerRequest: false,
      isImportCustomerSuccess: false,
      errorMessages: [],
    }),

    // #endregion
    // #region
    [Actions.getAllOrderCustomerRequest]: (state) => ({
      ...state,
      isGetAllOrderCustomerRequest: true,
      isGetAllOrderCustomerSuccess: false,
      isGetAllOrderCustomerFailure: false,
    }),
    [Actions.getAllOrderCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllOrderCustomerRequest: false,
      isGetAllOrderCustomerSuccess: true,
      isGetAllOrderCustomerFailure: false,
      listOrderCustomer: payload,
    }),
    [Actions.getAllOrderCustomerFailure]: (state, { payload }) => ({
      ...state,
      isGetAllOrderCustomerRequest: true,
      isGetAllOrderCustomerSuccess: false,
      isGetAllOrderCustomerFailure: false,
      errorMessages: payload,
    }),
    // #endregion

    // #region get all customer app
    [Actions.getAllCustomerAppRequest]: (state) => ({
      ...state,
      isGetAllCustomerAppRequest: true,
      isGetAllCustomerAppSuccess: false,
      isGetAllCustomerAppFailure: false,
    }),
    [Actions.getAllCustomerAppSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCustomerAppRequest: false,
      isGetAllCustomerAppSuccess: true,
      isGetAllCustomerAppFailure: false,
      listCustomerApp: payload,
    }),
    [Actions.getAllCustomerAppFailure]: (state, { payload }) => ({
      ...state,
      isGetAllCustomerAppRequest: false,
      isGetAllCustomerAppSuccess: false,
      isGetAllCustomerAppFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.updateCustomerAppRequest]: (state) => ({
      ...state,
      isUpdateCustomerAppRequest: true,
      isUpdateCustomerAppSuccess: false,
      isUpdateCustomerAppFailure: false,
    }),
    [Actions.updateCustomerAppSuccess]: (state) => ({
      ...state,
      isUpdateCustomerAppRequest: false,
      isUpdateCustomerAppSuccess: true,
      isUpdateCustomerAppFailure: false,
    }),
    [Actions.updateCustomerAppFailure]: (state, { payload }) => ({
      ...state,
      isUpdateCustomerAppRequest: false,
      isUpdateCustomerAppSuccess: false,
      isUpdateCustomerAppFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateCustomerAppState]: (state) => ({
      ...state,
      isUpdateCustomerAppRequest: false,
      isUpdateCustomerAppSuccess: false,
      isUpdateCustomerAppFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region approve
    [Actions.approveCustomerAppRequest]: (state) => ({
      ...state,
      isApproveCustomerAppRequest: true,
      isApproveCustomerAppSuccess: false,
      isApproveCustomerAppFailure: false,
    }),
    [Actions.approveCustomerAppSuccess]: (state, { payload }) => ({
      ...state,
      isApproveCustomerAppRequest: false,
      isApproveCustomerAppSuccess: true,
      isApproveCustomerAppFailure: false,
      responseApproveState: payload,
    }),
    [Actions.approveCustomerAppFailure]: (state, { payload }) => ({
      ...state,
      isApproveCustomerAppRequest: false,
      isApproveCustomerAppSuccess: false,
      isApproveCustomerAppFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetApproveCustomerAppState]: (state) => ({
      ...state,
      isApproveCustomerAppRequest: false,
      isApproveCustomerAppSuccess: false,
      isApproveCustomerAppFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region block
    [Actions.blockCustomerAppRequest]: (state) => ({
      ...state,
      isBlockCustomerAppRequest: true,
      isBlockCustomerAppSuccess: false,
      isBlockCustomerAppFailure: false,
    }),
    [Actions.blockCustomerAppSuccess]: (state) => ({
      ...state,
      isBlockCustomerAppRequest: false,
      isBlockCustomerAppSuccess: true,
      isBlockCustomerAppFailure: false,
    }),
    [Actions.blockCustomerAppFailure]: (state, { payload }) => ({
      ...state,
      isBlockCustomerAppRequest: false,
      isBlockCustomerAppSuccess: false,
      isBlockCustomerAppFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetBlockCustomerAppState]: (state) => ({
      ...state,
      isBlockCustomerAppRequest: false,
      isBlockCustomerAppSuccess: false,
      isBlockCustomerAppFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region create customer app
    [Actions.createCustomerAppRequest]: (state) => ({
      ...state,
      isCreateCustomerAppRequest: true,
      isCreateCustomerAppSuccess: false,
      isCreateCustomerAppFailure: false,
    }),
    [Actions.createCustomerAppSuccess]: (state) => ({
      ...state,
      isCreateCustomerAppRequest: false,
      isCreateCustomerAppSuccess: true,
      isCreateCustomerAppFailure: false,
    }),
    [Actions.createCustomerAppFailure]: (state, { payload }) => ({
      ...state,
      isCreateCustomerAppRequest: false,
      isCreateCustomerAppSuccess: false,
      isCreateCustomerAppFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateCustomerAppState]: (state) => ({
      ...state,
      isCreateCustomerAppRequest: false,
      isCreateCustomerAppSuccess: false,
      isCreateCustomerAppFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : add customer tax
    [Actions.addCustomerTaxRequest]: (state) => ({
      ...state,
      isAddCustomerTaxRequest: true,
      isAddCustomerTaxSuccess: false,
      isAddCustomerTaxFailure: false,
    }),
    [Actions.addCustomerTaxSuccess]: (state, { payload }) => ({
      ...state,
      isAddCustomerTaxRequest: false,
      isAddCustomerTaxSuccess: true,
      isAddCustomerTaxFailure: false,
      addCustomerTaxResponse: payload,
    }),
    [Actions.addCustomerTaxFailure]: (state, { payload }) => ({
      ...state,
      isAddCustomerTaxRequest: false,
      isAddCustomerTaxSuccess: false,
      isAddCustomerTaxFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddCustomerTaxState]: (state) => ({
      ...state,
      isAddCustomerTaxFailure: false,
      isAddCustomerTaxRequest: false,
      isAddCustomerTaxSuccess: false,
      addCustomerTaxResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region: delete customer tax
    [Actions.deleteCustomerTaxRequest]: (state) => ({
      ...state,
      isDeleteCustomerTaxRequest: true,
      isDeleteCustomerTaxSuccess: false,
      isDeleteCustomerTaxFailure: false,
    }),
    [Actions.deleteCustomerTaxSuccess]: (state) => ({
      ...state,
      isDeleteCustomerTaxRequest: false,
      isDeleteCustomerTaxSuccess: true,
      isDeleteCustomerTaxFailure: false,

    }),
    [Actions.deleteCustomerTaxFailure]: (state, { payload }) => ({
      ...state,
      isDeleteCustomerTaxRequest: false,
      isDeleteCustomerTaxSuccess: false,
      isDeleteCustomerTaxFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteCustomerTaxState]: (state) => ({
      ...state,
      isDeleteCustomerTaxFailure: false,
      isDeleteCustomerTaxRequest: false,
      isDeleteCustomerTaxSuccess: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetCustomerState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
