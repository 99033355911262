/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListShipping({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/shippings?${payload.params}` : '/shippings?limit=0'));
    yield put(Actions.getListShippingsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListShippingsFailure(messages));
    }
  }
}
function* getShipping({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/shippings?id=${payload.id}`));
    yield put(Actions.getShippingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getShippingFailure(messages));
    }
  }
}
function* addShippings({ payload }) {
  try {
    const formData = new FormData();
    formData.append('name', payload.name);
    payload.type.map((type, index) => {
      formData.append(`type[${index}]`, type);
    });

    if (payload.phone) {
      formData.append('phone', payload.phone);
    }
    if (payload.address) {
      formData.append('address', payload.address);
    }
    if (payload.logo) {
      formData.append('logo', payload.logo);
    }

    if (payload.transport_code) {
      formData.append('transport_code', 1);
    } else {
      formData.append('transport_code', 0);
    }
    if (payload.transport) {
      formData.append('transport', 1);
    } else {
      formData.append('transport', 0);
    }

    if (payload.transport_bill) {
      formData.append('transport_bill', payload.transport_bill);
    }
    if (payload.active) {
      formData.append('active', 1);
    } else {
      formData.append('active', 0);
    }
    if (payload.special) {
      formData.append('special', 1);
    } else {
      formData.append('special', 0);
    }
    if (payload.expense) {
      formData.append('expense', 1);
    } else {
      formData.append('expense', 0);
    }
    if (payload.zns) {
      formData.append('zns', 1);
    } else {
      formData.append('zns', 0);
    }
    if (payload.note) {
      formData.append('note', payload.note);
    }

    const response = yield call(() => axiosMicro.post('/shippings', formData));
    yield put(Actions.addShippingsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.addShippingsFailure(message));
    }
  }
}
function* editShippings({ payload }) {
  try {
    const formData = new FormData();
    formData.append('name', payload.name);
    if (payload.logo) {
      formData.append('logo', payload.logo);
    }
    if (payload.phone) {
      formData.append('phone', payload.phone);
    }
    if (payload.address) {
      formData.append('address', payload.address);
    }
    if (payload.special) {
      formData.append('special', 1);
    } else {
      formData.append('special', 0);
    }

    if (payload.transport_code) {
      formData.append('transport_code', 1);
    } else {
      formData.append('transport_code', 0);
    }

    if (payload.transport) {
      formData.append('transport', 1);
    } else {
      formData.append('transport', 0);
    }

    if (payload.transport_bill) {
      formData.append('transport_bill', payload.transport_bill);
    }
    payload.type.map((type, index) => {
      formData.append(`type[${index}]`, type);
    });
    // if (payload.type) {
    //   formData.append('type', payload.type);
    // }
    if (payload.active) {
      formData.append('active', 1);
    } else {
      formData.append('active', 0);
    }
    if (payload.note) {
      formData.append('note', payload.note);
    }
    if (payload.expense) {
      formData.append('expense', 1);
    } else {
      formData.append('expense', 0);
    }
    if (payload.zns) {
      formData.append('zns', 1);
    } else {
      formData.append('zns', 0);
    }
    const response = yield call(() => axiosMicro.post(`/shippings/${payload.id}?_method=PATCH`, formData));
    yield put(Actions.editShippingsSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.editShippingsFailure(message));
    }
  }
}
function* deleteShippings({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/shippings/${payload}`));
    yield put(Actions.deleteShippingsSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.deleteShippingsFailure(message));
    }
  }
}

// function* addShippingsPrice({ payload }) {
//   try {
//     const response = yield call(() => axiosMicro.post('/shipping-prices', payload));
//     yield put(Actions.addShippingsPriceSuccess());
//   } catch (e) {
//     if (e?.response?.data) {
//       const message = e.response.data;
//       yield put(Actions.addShippingsPriceFailure(message));
//     }
//   }
// }

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListShippingsRequest, getListShipping);
  yield takeLatest(Actions.addShippingsRequest, addShippings);
  yield takeLatest(Actions.editShippingsRequest, editShippings);
  yield takeLatest(Actions.deleteShippingsRequest, deleteShippings);
  yield takeLatest(Actions.getShippingRequest, getShipping);

  // yield takeLatest(Actions.addShippingsPriceRequest, addShippingsPrice);
}
