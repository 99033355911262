// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetListProductTypesRequest: false,
  isGetListProductTypesSuccess: false,
  isGetListProductTypesFailure: false,
  listProductTypes: {},
  //
  isCreateProductTypeRequest: false,
  isCreateProductTypeSuccess: false,
  isCreateProductTypeFailure: false,
  //
  isUpdateProductTypeRequest: false,
  isUpdateProductTypeSuccess: false,
  isUpdateProductTypeFailure: false,
  //
  isDeleteProductTypeRequest: false,
  isDeleteProductTypeSuccess: false,
  isDeleteProductTypeFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get list product types
    [Actions.getListProductTypesRequest]: (state) => ({
      ...state,
      isGetListProductTypesRequest: true,
      isGetListProductTypesSuccess: false,
      isGetListProductTypesFailure: false,
    }),
    [Actions.getListProductTypesSuccess]: (state, { payload }) => ({
      ...state,
      isGetListProductTypesRequest: false,
      isGetListProductTypesSuccess: true,
      isGetListProductTypesFailure: false,
      listProductTypes: payload,
    }),
    [Actions.getListProductTypesFailure]: (state, { payload }) => ({
      ...state,
      isGetListProductTypesRequest: false,
      isGetListProductTypesSuccess: false,
      isGetListProductTypesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create product type
    [Actions.createProductTypeRequest]: (state) => ({
      ...state,
      isCreateProductTypeRequest: true,
      isCreateProductTypeSuccess: false,
      isCreateProductTypeFailure: false,
    }),
    [Actions.createProductTypeSuccess]: (state) => ({
      ...state,
      isCreateProductTypeRequest: false,
      isCreateProductTypeSuccess: true,
      isCreateProductTypeFailure: false,
    }),
    [Actions.createProductTypeFailure]: (state, { payload }) => ({
      ...state,
      isCreateProductTypeRequest: false,
      isCreateProductTypeSuccess: false,
      isCreateProductTypeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateProductTypeState]: (state) => ({
      ...state,
      isCreateProductTypeRequest: false,
      isCreateProductTypeSuccess: false,
      isCreateProductTypeFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update product type
    [Actions.updateProductTypeRequest]: (state) => ({
      ...state,
      isUpdateProductTypeRequest: true,
      isUpdateProductTypeSuccess: false,
      isUpdateProductTypeFailure: false,
    }),
    [Actions.updateProductTypeSuccess]: (state) => ({
      ...state,
      isUpdateProductTypeRequest: false,
      isUpdateProductTypeSuccess: true,
      isUpdateProductTypeFailure: false,
    }),
    [Actions.updateProductTypeFailure]: (state, { payload }) => ({
      ...state,
      isUpdateProductTypeRequest: false,
      isUpdateProductTypeSuccess: false,
      isUpdateProductTypeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateProductTypeState]: (state) => ({
      ...state,
      isUpdateProductTypeRequest: false,
      isUpdateProductTypeSuccess: false,
      isUpdateProductTypeFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete product type
    [Actions.deleteProductTypeRequest]: (state) => ({
      ...state,
      isDeleteProductTypeRequest: true,
      isDeleteProductTypeSuccess: false,
      isDeleteProductTypeFailure: false,
    }),
    [Actions.deleteProductTypeSuccess]: (state) => ({
      ...state,
      isDeleteProductTypeRequest: false,
      isDeleteProductTypeSuccess: true,
      isDeleteProductTypeFailure: false,
    }),
    [Actions.deleteProductTypeFailure]: (state, { payload }) => ({
      ...state,
      isDeleteProductTypeRequest: false,
      isDeleteProductTypeSuccess: false,
      isDeleteProductTypeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteProductTypeState]: (state) => ({
      ...state,
      isDeleteProductTypeRequest: false,
      isDeleteProductTypeSuccess: false,
      isDeleteProductTypeFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetProductTypeState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
