// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const updateFirstCallApiNotificationRequest = createAction('updateFirstCallApiNotificationRequest');

export const updateConfigState = createAction('UPDATE_CONFIG_STATE');

export const getConfigRequest = createAction('GET_CONFIG_REQUEST');
export const getConfigSuccess = createAction('GET_CONFIG_SUCCESS');
export const getConfigFailure = createAction('GET_CONFIG_FAILURE');

export const saveConfigLalaRequest = createAction('SAVE_CONFIG_LALA_REQUEST');
export const saveConfigLalaSuccess = createAction('SAVE_CONFIG_LALA_SUCCESS');
export const saveConfigLalaFailure = createAction('SAVE_CONFIG_LALA_FAILURE');
export const resetSaveConfigLalaState = createAction('RESET_SAVE_CONFIG_LALA_STATE');

export const saveConfigTransportRequest = createAction('SAVE_CONFIG_TRANSPORT_REQUEST');
export const saveConfigTransportSuccess = createAction('SAVE_CONFIG_TRANSPORT_SUCCESS');
export const saveConfigTransportFailure = createAction('SAVE_CONFIG_TRANSPORT_FAILURE');
export const resetSaveConfigTransportState = createAction('RESET_SAVE_CONFIG_TRANSPORT_STATE');

export const resetConfigState = createAction('RESET_CONFIG_STATE');
