/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListProductTypes({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/product-types?${payload.params}` : '/product-types',
    ));
    yield put(Actions.getListProductTypesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListProductTypesFailure(messages));
    }
  }
}

function* createProductType({ payload }) {
  try {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('image', payload.image);
    formData.append('note', payload.note);
    if (payload.quantity) {
      formData.append('quantity', 1);
    } else {
      formData.append('quantity', 0);
    }
    const response = yield call(() => axiosMicro.post('/product-types', formData));
    yield put(Actions.createProductTypeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createProductTypeFailure(messages));
    }
  }
}

function* updateProductType({ payload }) {
  try {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('note', payload.note);
    if (payload.image) {
      formData.append('image', payload.image);
    }
    if (payload.quantity) {
      formData.append('quantity', 1);
    } else {
      formData.append('quantity', 0);
    }
    const response = yield call(() => axiosMicro.post(`/product-types/${payload.id}?_method=PATCH`, formData));
    yield put(Actions.updateProductTypeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateProductTypeFailure(messages));
    }
  }
}

function* deleteProductType({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/product-types/${payload}`));
    yield put(Actions.deleteProductTypeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteProductTypeFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListProductTypesRequest, getListProductTypes);
  yield takeLatest(Actions.createProductTypeRequest, createProductType);
  yield takeLatest(Actions.updateProductTypeRequest, updateProductType);
  yield takeLatest(Actions.deleteProductTypeRequest, deleteProductType);
}
