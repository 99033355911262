/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const getListWareHouseRequest = createAction('GET_LIST_WARE_HOUSE_REQUEST');
export const getListWareHouseSuccess = createAction('GET_LIST_WARE_HOUSE_SUCCESS');
export const getListWareHouseFailure = createAction('GET_LIST_WARE_HOUSE_FAILURE');

export const getListProdWareHouseRequest = createAction('GET_LIST_PROD_WARE_HOUSE_REQUEST');
export const getListProdWareHouseSuccess = createAction('GET_LIST_PROD_WARE_HOUSE_SUCCESS');
export const getListProdWareHouseFailure = createAction('GET_LIST_PROD_WARE_HOUSE_FAILURE');

export const addWareHouseRequest = createAction('ADD_WARE_HOUSE_REQUEST');
export const addWareHouseSuccess = createAction('ADD_WARE_HOUSE_SUCCESS');
export const addWareHouseFailure = createAction('ADD_WARE_HOUSE_FAILURE');
export const resetAddWareHouseState = createAction('RESET_ADD_WARE_HOUSE_STATE');

export const editWareHouseRequest = createAction('EDIT_WARE_HOUSE_REQUEST');
export const editWareHouseSuccess = createAction('EDIT_WARE_HOUSE_SUCCESS');
export const editWareHouseFailure = createAction('EDIT_WARE_HOUSE_FAILURE');
export const resetEditWareHouseState = createAction('RESET_EDIT_WARE_HOUSE_STATE');

export const deleteWareHouseRequest = createAction('DELETE_WARE_HOUSE_REQUEST');
export const deleteWareHouseSuccess = createAction('DELETE_WARE_HOUSE_SUCCESS');
export const deleteWareHouseFailure = createAction('DELETE_WARE_HOUSE_FAILURE');
export const resetDeleteWareHouseState = createAction('RESET_DELETE_WARE_HOUSE_STATE');

export const importWarehouseRequest = createAction('IMPORT_WAREHOUSE_REQUEST');
export const importWarehouseSuccess = createAction('IMPORT_WAREHOUSE_SUCCESS');
export const importWarehouseFailure = createAction('IMPORT_WAREHOUSE_FAILURE');
export const resetImportWarehouseState = createAction('RESET_IMPORT_WAREHOUSE_STATE');

export const getAllImportWarehouseRequest = createAction('GET_ALL_IMPORT_WAREHOUSE_REQUEST');
export const getAllImportWarehouseSuccess = createAction('GET_ALL_IMPORT_WAREHOUSE_SUCCESS');
export const getAllImportWarehouseFailure = createAction('GET_ALL_IMPORT_WAREHOUSE_FAILURE');

export const getStatisticWarehouseRequest = createAction('GET_STATISTIC_WAREHOUSE_REQUEST');
export const getStatisticWarehouseSuccess = createAction('GET_STATISTIC_WAREHOUSE_SUCCESS');
export const getStatisticWarehouseFailure = createAction('GET_STATISTIC_WAREHOUSE_FAILURE');

export const exportWarehouseRequest = createAction('EXPORT_WAREHOUSE_REQUEST');
export const exportWarehouseSuccess = createAction('EXPORT_WAREHOUSE_SUCCESS');
export const exportWarehouseFailure = createAction('EXPORT_WAREHOUSE_FAILURE');
export const resetExportWarehouseState = createAction('RESET_EXPORT_WAREHOUSE_STATE');

export const getAllHistoryWarehouseRequest = createAction('GET_ALL_HISTORY_WAREHOUSE_REQUEST');
export const getAllHistoryWarehouseSuccess = createAction('GET_ALL_HISTORY_WAREHOUSE_SUCCESS');
export const getAllHistoryWarehouseFailure = createAction('GET_ALL_HISTORY_WAREHOUSE_FAILURE');

export const getDetailHistoryWarehouseRequest = createAction('GET_DETAIL_HISTORY_WAREHOUSE_REQUEST');
export const getDetailHistoryWarehouseSuccess = createAction('GET_DETAIL_HISTORY_WAREHOUSE_SUCCESS');
export const getDetailHistoryWarehouseFailure = createAction('GET_DETAIL_HISTORY_WAREHOUSE_FAILURE');

export const createHistoryWarehouseRequest = createAction('CREATE_HISTORY_WAREHOUSE_REQUEST');
export const createHistoryWarehouseSuccess = createAction('CREATE_HISTORY_WAREHOUSE_SUCCESS');
export const createHistoryWarehouseFailure = createAction('CREATE_HISTORY_WAREHOUSE_FAILURE');
export const resetCreateHistoryWarehouseState = createAction('RESET_CREATE_HISTORY_WAREHOUSE_STATE');

export const exportWarehouseHistoryRequest = createAction('EXPORT_WAREHOUSE_HISTORY_REQUEST');
export const exportWarehouseHistorySuccess = createAction('EXPORT_WAREHOUSE_HISTORY_SUCCESS');
export const exportWarehouseHistoryFailure = createAction('EXPORT_WAREHOUSE_HISTORY_FAILURE');
export const resetExportWarehouseHistoryState = createAction('RESET_EXPORT_WAREHOUSE_HISTORY_STATE');

export const exportWarehouseReportRequest = createAction('EXPORT_WAREHOUSE_REPORT_REQUEST');
export const exportWarehouseReportSuccess = createAction('EXPORT_WAREHOUSE_REPORT_SUCCESS');
export const exportWarehouseReportFailure = createAction('EXPORT_WAREHOUSE_REPORT_FAILURE');
export const resetExportWarehouseReportState = createAction('RESET_EXPORT_WAREHOUSE_REPORT_STATE');

export const getAllWarehouseBorrowRequest = createAction('GET_ALL_WAREHOUSE_BORROW_REQUEST');
export const getAllWarehouseBorrowSuccess = createAction('GET_ALL_WAREHOUSE_BORROW_SUCCESS');
export const getAllWarehouseBorrowFailure = createAction('GET_ALL_WAREHOUSE_BORROW_FAILURE');

export const getAllSupplierRequest = createAction('GET_ALL_SUPPLIER_REQUEST');
export const getAllSupplierSuccess = createAction('GET_ALL_SUPPLIER_SUCCESS');
export const getAllSupplierFailure = createAction('GET_ALL_SUPPLIER_FAILURE');

export const createSupplierRequest = createAction('CREATE_SUPPLIER_REQUEST');
export const createSupplierSuccess = createAction('CREATE_SUPPLIER_SUCCESS');
export const createSupplierFailure = createAction('CREATE_SUPPLIER_FAILURE');
export const resetCreateSupplierState = createAction('RESET_CREATE_SUPPLIER_STATE');

export const updateSupplierRequest = createAction('UPDATE_SUPPLIER_REQUEST');
export const updateSupplierSuccess = createAction('UPDATE_SUPPLIER_SUCCESS');
export const updateSupplierFailure = createAction('UPDATE_SUPPLIER_FAILURE');
export const resetUpdateSupplierState = createAction('RESET_UPDATE_SUPPLIER_STATE');

export const deleteSupplierRequest = createAction('DELETE_SUPPLIER_REQUEST');
export const deleteSupplierSuccess = createAction('DELETE_SUPPLIER_SUCCESS');
export const deleteSupplierFailure = createAction('DELETE_SUPPLIER_FAILURE');
export const resetDeleteSupplierState = createAction('RESET_DELETE_SUPPLIER_STATE');

export const getWarehouseReportRequest = createAction('GET_WAREHOUSE_REPORT_REQUEST');
export const getWarehouseReportSuccess = createAction('GET_WAREHOUSE_REPORT_SUCCESS');
export const getWarehouseReportFailure = createAction('GET_WAREHOUSE_REPORT_FAILURE');
export const resetGetWarehouseReportState = createAction('RESET_GET_WAREHOUSE_REPORT_STATE');

export const getListWaitingAcceptWarehouseRequest = createAction('GET_LIST_WAITING_ACCEPT_WAREHOUSE_REQUEST');
export const getListWaitingAcceptWarehouseSuccess = createAction('GET_LIST_WAITING_ACCEPT_WAREHOUSE_SUCCESS');
export const getListWaitingAcceptWarehouseFailure = createAction('GET_LIST_WAITING_ACCEPT_WAREHOUSE_FAILURE');

export const changeWaitingAcceptWarehouseRequest = createAction('CHANGE_WAITING_ACCEPT_WAREHOUSE_REQUEST');
export const changeWaitingAcceptWarehouseSuccess = createAction('CHANGE_WAITING_ACCEPT_WAREHOUSE_SUCCESS');
export const changeWaitingAcceptWarehouseFailure = createAction('CHANGE_WAITING_ACCEPT_WAREHOUSE_FAILURE');
export const resetChangeWaitingAcceptWarehouseState = createAction('RESET_CHANGE_WAITING_ACCEPT_WAREHOUSE_STATE');

export const getListChangeSizeWarehouseRequest = createAction('GET_LIST_CHANGE_SIZE_WAREHOUSE_REQUEST');
export const getListChangeSizeWarehouseSuccess = createAction('GET_LIST_CHANGE_SIZE_WAREHOUSE_SUCCESS');
export const getListChangeSizeWarehouseFailure = createAction('GET_LIST_CHANGE_SIZE_WAREHOUSE_FAILURE');

export const changeSizeWarehouseRequest = createAction('CHANGE_SIZE_WAREHOUSE_REQUEST');
export const changeSizeWarehouseSuccess = createAction('CHANGE_SIZE_WAREHOUSE_SUCCESS');
export const changeSizeWarehouseFailure = createAction('CHANGE_SIZE_WAREHOUSE_FAILURE');
export const resetChangeSizeWarehouseState = createAction('RESET_CHANGE_SIZE_WAREHOUSE_STATE');

export const cancelWarehouseRequest = createAction('CANCEL_WAREHOUSE_REQUEST');
export const cancelWarehouseSuccess = createAction('CANCEL_WAREHOUSE_SUCCESS');
export const cancelWarehouseFailure = createAction('CANCEL_WAREHOUSE_FAILURE');
export const resetCancelWarehouse = createAction('RESET_CANCEL_WAREHOUSE');

export const createImportVoucherRequest = createAction('CREATE_IMPORT_VOUCHER_REQUEST');
export const createImportVoucherSuccess = createAction('CREATE_IMPORT_VOUCHER_SUCCESS');
export const createImportVoucherFailure = createAction('CREATE_IMPORT_VOUCHER_FAILURE');
export const resetCreateImportVoucherState = createAction('RESET_CREATE_IMPORT_VOUCHER_STATE');

export const getAdjustInventoryRequest = createAction('GET_ADJUST_INVENTORY_REQUEST');
export const getAdjustInventorySuccess = createAction('GET_ADJUST_INVENTORY_SUCCESS');
export const getAdjustInventoryFailure = createAction('GET_ADJUST_INVENTORY_FAILURE');

export const createAdjustInventoryRequest = createAction('CREATE_ADJUST_INVENTORY_REQUEST');
export const createAdjustInventorySuccess = createAction('CREATE_ADJUST_INVENTORY_SUCCESS');
export const createAdjustInventoryFailure = createAction('CREATE_ADJUST_INVENTORY_FAILURE');
export const resetCreateAdjustInventory = createAction('RESET_CREATE_ADJUST_INVENTORY');

export const resetWareHouseState = createAction('RESET_WARE_HOUSE_STATE');
