/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* createCallSource({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/call-sources', payload));
    if (response?.status === 201) {
      yield put(Actions.createCallSourceSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createCallSourceFailure(messages));
    }
  }
}
function* updateCallSource({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield call(() => axiosMicro.patch(`/call-sources/${id}`, data));
    if (response?.status === 200) {
      yield put(Actions.updateCallSourceSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateCallSourceFailure(messages));
    }
  }
}

function* deleteCallSource({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/call-sources/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteCallSourceSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCallSourceFailure(messages));
    }
  }
}

function* getAllCallSource({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload.params ? `/call-sources?${payload.params}` : '/call-sources'));
    if (response?.status === 200) {
      yield put(Actions.getAllCallSourceSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllCallSourceFailure(messages));
    }
  }
}

// call feedback

function* createCallFeedback({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/call-feedbacks', payload));
    if (response?.status === 201) {
      yield put(Actions.createCallFeedbackSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createCallFeedbackFailure(messages));
    }
  }
}
function* updateCallFeedback({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield call(() => axiosMicro.patch(`/call-feedbacks/${id}`, data));
    if (response?.status === 200) {
      yield put(Actions.updateCallFeedbackSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateCallFeedbackFailure(messages));
    }
  }
}

function* deleteCallFeedback({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/call-feedbacks/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteCallFeedbackSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCallFeedbackFailure(messages));
    }
  }
}

function* getAllCallFeedback({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload ? `/call-feedbacks?${payload.params}` : '/call-feedbacks'));
    if (response?.status === 200) {
      yield put(Actions.getAllCallFeedbackSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllCallFeedbackFailure(messages));
    }
  }
}

// call data

function* createCallData({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/call-datas', payload));
    if (response?.status === 201) {
      yield put(Actions.createCallDataSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createCallDataFailure(messages));
    }
  }
}
function* updateCallData({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield call(() => axiosMicro.patch(`/call-datas/${id}`, data));
    if (response?.status === 200) {
      yield put(Actions.updateCallDataSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateCallDataFailure(messages));
    }
  }
}

function* deleteCallData({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/call-datas/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteCallDataSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCallDataFailure(messages));
    }
  }
}

function* getAllCallData({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload.params ? `/call-datas?${payload.params}` : '/call-datas', {
      params: {
        include: 'callSource,customer,user',
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllCallDataSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllCallDataFailure(messages));
    }
  }
}

function* importCallData({ payload }) {
  try {
    const form = new FormData();
    form.append('file', payload);
    const response = yield call(() => axiosMicro.post('/call-datas/import', form));
    if (response?.status === 200) {
      yield put(Actions.importCallDataSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.importCallDataFailure(messages));
    }
  }
}

function* filterCallData({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/call-datas/filter${payload.params}`));
    yield put(Actions.filterCallDataSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.filterCallDataFailure(messages));
    }
  }
}

// call log

function* createCallLog({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/call-logs', payload));
    if (response?.status === 201) {
      yield put(Actions.createCallLogSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createCallLogFailure(messages));
    }
  }
}
function* updateCallLog({ payload }) {
  try {
    const { id, ...params } = payload;
    const response = yield call(() => axiosMicro.patch(`/call-logs/${id}`, params));
    if (response?.status === 200) {
      yield put(Actions.updateCallLogSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateCallLogFailure(messages));
    }
  }
}

function* deleteCallLog({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/call-logs/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteCallLogSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCallLogFailure(messages));
    }
  }
}

function* getAllCallLog({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload.params ? `/call-logs?${payload.params}` : '/call-logs', {
      params: {
        include: 'user,callFeedback,callData',
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllCallLogSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllCallLogFailure(messages));
    }
  }
}

function* getListCustomerNeedCall({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/call-datas/filter', {
      params: payload,
    }));
    if (response?.status === 200) {
      yield put(Actions.getListCustomerNeedCallSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListCustomerNeedCallFailure(messages));
    }
  }
}

// #region label
function* getAllCallLabel() {
  try {
    const response = yield call(() => axiosMicro.get('/call-labels'));
    if (response?.status === 200) {
      yield put(Actions.getAllCallLabelSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;

      yield put(Actions.getAllCallLabelFailure(messages));
    }
  }
}

function* createCallLabel({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/call-labels', payload));
    if (response?.status === 201) {
      yield put(Actions.createCallLabelSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createCallLabelFailure(messages));
    }
  }
}

function* updateCallLabel({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/call-labels/${payload.id}`, payload));
    if (response?.status === 200) {
      yield put(Actions.updateCallLabelSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateCallLabelFailure(messages));
    }
  }
}

function* deleteCallLabel({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/call-labels/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteCallLabelSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCallLabelFailure(messages));
    }
  }
}
// #endregion

function* addCallingCallData({ payload }) {
  try {
    yield call(() => axiosMicro.post('/call-datas/calling', payload));
    yield put(Actions.addCallingCallDataSuccess());
  } catch (e) {
    if (e.response?.data) {
      const messages = e.response.data;
      yield call(Actions.addCallingCallDataFailure(messages));
    }
  }
}

function* getCallDataImport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/call-datas', {
      params: payload,
    }));
    yield put(Actions.getCallDataImportSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getCallDataImportFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.createCallSourceRequest, createCallSource);
  yield takeLatest(Actions.updateCallSourceRequest, updateCallSource);
  yield takeLatest(Actions.deleteCallSourceRequest, deleteCallSource);
  yield takeLatest(Actions.getAllCallSourceRequest, getAllCallSource);
  yield takeLatest(Actions.createCallFeedbackRequest, createCallFeedback);
  yield takeLatest(Actions.updateCallFeedbackRequest, updateCallFeedback);
  yield takeLatest(Actions.deleteCallFeedbackRequest, deleteCallFeedback);
  yield takeLatest(Actions.getAllCallFeedbackRequest, getAllCallFeedback);
  yield takeLatest(Actions.createCallDataRequest, createCallData);
  yield takeLatest(Actions.updateCallDataRequest, updateCallData);
  yield takeLatest(Actions.deleteCallDataRequest, deleteCallData);
  yield takeLatest(Actions.getAllCallDataRequest, getAllCallData);
  yield takeLatest(Actions.importCallDataRequest, importCallData);
  yield takeLatest(Actions.filterCallDataRequest, filterCallData);
  yield takeLatest(Actions.getListCustomerNeedCallRequest, getListCustomerNeedCall);
  yield takeLatest(Actions.createCallLogRequest, createCallLog);
  yield takeLatest(Actions.updateCallLogRequest, updateCallLog);
  yield takeLatest(Actions.deleteCallLogRequest, deleteCallLog);
  yield takeLatest(Actions.getAllCallLogRequest, getAllCallLog);
  yield takeLatest(Actions.getAllCallLabelRequest, getAllCallLabel);
  yield takeLatest(Actions.createCallLabelRequest, createCallLabel);
  yield takeLatest(Actions.updateCallLabelRequest, updateCallLabel);
  yield takeLatest(Actions.deleteCallLabelRequest, deleteCallLabel);
  yield takeLatest(Actions.addCallingCallDataRequest, addCallingCallData);
  yield takeLatest(Actions.getCallDataImportRequest, getCallDataImport);
}
