/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const getListShippingsRequest = createAction('GET_LIST_SHIPPINGS_REQUEST');
export const getListShippingsSuccess = createAction('GET_LIST_SHIPPINGS_SUCCESS');
export const getListShippingsFailure = createAction('GET_LIST_SHIPPINGS_FAILURE');

export const getShippingRequest = createAction('GET_SHIPPINGS_REQUEST');
export const getShippingSuccess = createAction('GET_SHIPPINGS_SUCCESS');
export const getShippingFailure = createAction('GET_SHIPPINGS_FAILURE');

export const addShippingsRequest = createAction('ADD_SHIPPINGS_REQUEST');
export const addShippingsSuccess = createAction('ADD_SHIPPINGS_SUCCESS');
export const addShippingsFailure = createAction('ADD_SHIPPINGS_FAILURE');

export const editShippingsRequest = createAction('EDIT_SHIPPINGS_REQUEST');
export const editShippingsSuccess = createAction('EDIT_SHIPPINGS_SUCCESS');
export const editShippingsFailure = createAction('EDIT_SHIPPINGS_FAILURE');

export const deleteShippingsRequest = createAction('DELETE_SHIPPINGS_REQUEST');
export const deleteShippingsSuccess = createAction('DELETE_SHIPPINGS_SUCCESS');
export const deleteShippingsFailure = createAction('DELETE_SHIPPINGS_FAILURE');

export const addShippingsPriceRequest = createAction('ADD_SHIPPINGS_PRICE_REQUEST');
export const addShippingsPriceSuccess = createAction('ADD_SHIPPINGS_PRICE_SUCCESS');
export const addShippingsPriceFailure = createAction('ADD_SHIPPINGS_PRICE_FAILURE');

export const resetAddShippingsPriceState = createAction('RESET_SHIPPINGS_PRICE_STATE');
export const resetAddShippingsState = createAction('RESET_ADD_SHIPPINGS_STATE');
export const resetEditShippingsState = createAction('RESET_EDIT_SHIPPINGS_STATE');
export const resetDeleteShippingsState = createAction('RESET_DELETE_SHIPPINGS_STATE');
export const resetShippingsState = createAction('RESET_SHIPPINGS_STATE');
