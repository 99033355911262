/* eslint-disable import/no-extraneous-dependencies */
import { put, call, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListWareHouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload && payload.params ? `/warehouses${payload.params}&include=products` : '/warehouses?include=products'));
    if (response?.status === 200) {
      yield put(Actions.getListWareHouseSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListWareHouseFailure(messages));
    }
  }
}

function* getListProd() {
  try {
    const response = yield call(() => axiosMicro.get('/products'));
    if (response?.status === 200) {
      yield put(Actions.getListProdWareHouseSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListProdWareHouseFailure(messages));
    }
  }
}

function* addWareHouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/warehouses', payload));
    if (response?.status === 201) {
      yield put(Actions.addWareHouseSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addWareHouseFailure(messages));
    }
  }
}
function* editWareHouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/warehouses/${payload.id}`, payload));
    if (response?.status === 200) {
      yield put(Actions.editWareHouseSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.editWareHouseFailure(messages));
    }
  }
}
function* deleteWareHouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/warehouses/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteWareHouseSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteWareHouseFailure(messages));
    }
  }
}

function* importWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/warehouses/${payload.warehouse_id}/import`, payload.params));
    if (response?.status === 201) {
      yield put(Actions.importWarehouseSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.importWarehouseFailure(messages));
    }
  }
}

function* getAllImportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses/imports', {
      params: {
        ...payload.params,
        include: 'order',
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllImportWarehouseSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllImportWarehouseFailure(messages));
    }
  }
}

function* getStatisticWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses/products', {
      params: {
        date: payload,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getStatisticWarehouseSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getStatisticWarehouseFailure(messages));
    }
  }
}

function* exportWarehouse({ payload }) {
  try {
    const { warehouse_id } = payload;

    const response = yield call(() => axiosMicro.post(`/warehouses/${warehouse_id}/export`, payload.params));
    if (response?.status === 201) {
      yield put(Actions.exportWarehouseSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.exportWarehouseFailure(messages));
    }
  }
}

function* getAllHistoryWarehouse({ payload }) {
  try {
    // const response = yield call(() => axiosMicro.get(payload.params ? `/warehouses/histories?${payload.params}` : '/warehouses/histories', {
    //   params: {
    //     include: 'product,user',
    //   },
    // }));
    const response = yield call(() => axiosMicro.get(payload ? `/statistics/warehouse?include=user,history&${payload?.params}` : '/statistics/warehouse?include=user,history'));
    if (response?.status === 200) {
      yield put(Actions.getAllHistoryWarehouseSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllHistoryWarehouseFailure(messages));
    }
  }
}

function* getDetailHistoryWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload.params ? `/warehouses/histories?${payload.params}` : '/warehouses/histories', {
      params: {
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getDetailHistoryWarehouseSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDetailHistoryWarehouseFailure(messages));
    }
  }
}

function* createHistoryWarehouse({ payload }) {
  try {
    const {
      warehouse_id,
      ...data
    } = payload;
    const response = yield call(() => axiosMicro.post(`/warehouses/${warehouse_id}/history`, data));
    if (response?.status === 200) {
      yield put(Actions.createHistoryWarehouseSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createHistoryWarehouseFailure(messages));
    }
  }
}

function* exportWarehouseHistory({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/warehouse', payload));
    if (response?.status === 200) {
      yield put(Actions.exportWarehouseHistorySuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.exportWarehouseHistoryFailure(messages));
    }
  }
}

function* getAllWarehouseBorrow({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses/borrows', {
      params: {
        status: payload?.status,
        orderBy: payload?.orderBy,
        sortedBy: payload?.sortedBy,
        limit: payload?.limit,
        search: payload?.search,
        page: payload.page,
        searchDate: payload?.searchDate,
        include: 'import,export',
        type: 'import-borrow,export-borrow',
        searchFields: 'type:in',
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllWarehouseBorrowSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllWarehouseBorrowFailure(messages));
    }
  }
}

function* getAllSupplier({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/suppliers', {
      params: {
        limit: payload.limit,
        page: payload.page,
        search: payload?.search,
        orderBy: payload?.orderBy,
        sortedBy: payload?.sortedBy,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllSupplierSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllSupplierFailure(messages));
    }
  }
}

function* createSupplier({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/suppliers', payload));
    if (response?.status === 201) {
      yield put(Actions.createSupplierSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createSupplierFailure(messages));
    }
  }
}
function* updateSupplier({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/suppliers/${payload.id}`, payload));
    if (response?.status === 200) {
      yield put(Actions.updateSupplierSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateSupplierFailure(messages));
    }
  }
}
function* deleteSupplier({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/suppliers/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteSupplierSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteSupplierFailure(messages));
    }
  }
}

function* getWarehouseReport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/warehouse', { params: payload }));
    yield put(Actions.getWarehouseReportSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getWarehouseReportFailure(messages));
    }
  }
}

function* getListWaitingAccept({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/warehouses/waitings?${payload.params}` : '/warehouses/waitings'));
    yield put(Actions.getListWaitingAcceptWarehouseSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListWaitingAcceptWarehouseFailure(messages));
    }
  }
}

function* changeWaitingAcceptWarehouse({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(() => axiosMicro.post('/warehouses/waitings', payload));
    yield put(Actions.changeWaitingAcceptWarehouseSuccess());
  } catch (e) {
    if (e.response?.data) {
      const messages = e.response.data;
      yield put(Actions.changeWaitingAcceptWarehouseFailure(messages));
    }
  }
}

function* changeSizeWarehouse({ payload }) {
  try {
    const { warehouse_id, ...data } = payload;
    // eslint-disable-next-line no-unused-vars
    const response = yield call(() => axiosMicro.post(`/warehouses/${warehouse_id}/change`, data));
    yield put(Actions.changeSizeWarehouseSuccess());
  } catch (e) {
    if (e.response?.data) {
      const messages = e.response.data;
      yield put(Actions.changeSizeWarehouseFailure(messages));
    }
  }
}

function* getListChangeSize({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses/changes', {
      params: payload,
    }));
    yield put(Actions.getListChangeSizeWarehouseSuccess(response.data));
  } catch (e) {
    if (e.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListChangeSizeWarehouseFailure(messages));
    }
  }
}

function* cancelWarehouse({ payload }) {
  try {
    yield call(() => axiosMicro.post('/warehouse-cancels', payload));
    yield put(Actions.cancelWarehouseSuccess());
  } catch (error) {
    const messages = error.messages.data;
    yield put(Actions.cancelWarehouseFailure(messages));
  }
}

function* createImportVoucher({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/accounting/voucher/import', payload));
    if (response?.status === 200 && response?.data?.data?.success) {
      yield put(Actions.createImportVoucherSuccess());
    } else {
      yield put(Actions.createImportVoucherFailure(response?.data?.data?.error));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createImportVoucherFailure(messages));
    }
  }
}

function* getAdjustInventory({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouse-adjusts', {
      params: payload,
    }));
    if (response?.status === 200) {
      yield put(Actions.getAdjustInventorySuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAdjustInventoryFailure(messages));
    }
  }
}

function* createAdjustInventory({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/warehouse-adjusts', payload));
    yield put(Actions.createAdjustInventorySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createAdjustInventoryFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListWareHouseRequest, getListWareHouse);
  yield takeLatest(Actions.getListProdWareHouseRequest, getListProd);
  yield takeLatest(Actions.addWareHouseRequest, addWareHouse);
  yield takeLatest(Actions.editWareHouseRequest, editWareHouse);
  yield takeLatest(Actions.deleteWareHouseRequest, deleteWareHouse);
  yield takeLatest(Actions.importWarehouseRequest, importWarehouse);
  yield takeLatest(Actions.getAllImportWarehouseRequest, getAllImportWarehouse);
  yield takeLatest(Actions.getStatisticWarehouseRequest, getStatisticWarehouse);
  yield takeLatest(Actions.exportWarehouseRequest, exportWarehouse);
  yield takeLatest(Actions.getAllHistoryWarehouseRequest, getAllHistoryWarehouse);
  yield takeLatest(Actions.createHistoryWarehouseRequest, createHistoryWarehouse);
  yield takeLatest(Actions.exportWarehouseHistoryRequest, exportWarehouseHistory);
  yield takeLatest(Actions.getDetailHistoryWarehouseRequest, getDetailHistoryWarehouse);
  yield takeLatest(Actions.getAllWarehouseBorrowRequest, getAllWarehouseBorrow);
  yield takeLatest(Actions.getAllSupplierRequest, getAllSupplier);
  yield takeLatest(Actions.createSupplierRequest, createSupplier);
  yield takeLatest(Actions.updateSupplierRequest, updateSupplier);
  yield takeLatest(Actions.deleteSupplierRequest, deleteSupplier);
  yield takeLatest(Actions.getWarehouseReportRequest, getWarehouseReport);
  yield takeLatest(Actions.getListWaitingAcceptWarehouseRequest, getListWaitingAccept);
  yield takeLatest(Actions.changeWaitingAcceptWarehouseRequest, changeWaitingAcceptWarehouse);
  yield takeLatest(Actions.changeSizeWarehouseRequest, changeSizeWarehouse);
  yield takeLatest(Actions.getListChangeSizeWarehouseRequest, getListChangeSize);
  yield takeLatest(Actions.cancelWarehouseRequest, cancelWarehouse);
  yield takeLatest(Actions.createImportVoucherRequest, createImportVoucher);
  yield takeLatest(Actions.getAdjustInventoryRequest, getAdjustInventory);
  yield takeLatest(Actions.createAdjustInventoryRequest, createAdjustInventory);
}
