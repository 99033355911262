// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isCreateInternRequest: false,
  isCreateInternSuccess: false,
  isCreateInternFailure: false,

  isUpdateInternRequest: false,
  isUpdateInternSuccess: false,
  isUpdateInternFailure: false,

  getAllInternRequest: false,
  getAllInternSuccess: false,
  getAllInternFailure: false,
  getAllInternState: {},

  errorMessages: [],
};

const reducer = handleActions({
  // #region Create
  [Actions.createInternRequest]: (state) => ({
    ...state,
    isCreateInternRequest: true,
    isCreateInternSuccess: false,
    isCreateInternFailure: false,
  }),
  [Actions.createInternSuccess]: (state) => ({
    ...state,
    isCreateInternRequest: false,
    isCreateInternSuccess: true,
    isCreateInternFailure: false,
  }),
  [Actions.createInternFailure]: (state, { payload }) => ({
    ...state,
    isCreateInternRequest: false,
    isCreateInternSuccess: false,
    isCreateInternFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateIntern]: (state) => ({
    ...state,
    isCreateInternRequest: false,
    isCreateInternSuccess: false,
    isCreateInternFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region Update
  [Actions.updateInternRequest]: (state) => ({
    ...state,
    isUpdateInternRequest: true,
    isUpdateInternSuccess: false,
    isUpdateInternFailure: false,
  }),
  [Actions.updateInternSuccess]: (state) => ({
    ...state,
    isUpdateInternRequest: false,
    isUpdateInternSuccess: true,
    isUpdateInternFailure: false,
  }),
  [Actions.updateInternFailure]: (state, { payload }) => ({
    ...state,
    isUpdateInternRequest: false,
    isUpdateInternSuccess: false,
    isUpdateInternFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetUpdateIntern]: (state) => ({
    ...state,
    isUpdateInternRequest: false,
    isUpdateInternSuccess: false,
    isUpdateInternFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region Get all
  [Actions.getAllInternsRequest]: (state) => ({
    ...state,
    isGetAllInternsRequest: true,
    isGetAllInternsSuccess: false,
    isGetAllInternsFailure: false,
  }),
  [Actions.getAllInternsSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllInternsRequest: false,
    isGetAllInternsSuccess: true,
    isGetAllInternsFailure: false,
    getAllInternState: payload,
  }),
  [Actions.getAllInternsFailure]: (state, { payload }) => ({
    ...state,
    isGetAllInternsRequest: false,
    isGetAllInternsSuccess: false,
    isGetAllInternsFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  [Actions.resetInternState]: () => initialState,
}, []);

export default reducer;
