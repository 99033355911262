// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getListDebtsRequest = createAction(
  'GET_LIST_DEBTS_REQUEST',
);
export const getListDebtsSuccess = createAction(
  'GET_LIST_DEBTS_SUCCESS',
);
export const getListDebtsFailure = createAction(
  'GET_LIST_DEBTS_FAILURE',
);

export const createDebtRequest = createAction(
  'CREATE_DEBT_REQUEST',
);
export const createDebtSuccess = createAction(
  'CREATE_DEBT_SUCCESS',
);
export const createDebtFailure = createAction(
  'CREATE_DEBT_FAILURE',
);
export const resetCreateDebtState = createAction(
  'RESET_CREATE_DEBT_STATE',
);

export const updateDebtRequest = createAction(
  'UPDATE_DEBT_REQUEST',
);
export const updateDebtSuccess = createAction(
  'UPDATE_DEBT_SUCCESS',
);
export const updateDebtFailure = createAction(
  'UPDATE_DEBT_FAILURE',
);
export const resetUpdateDebtState = createAction(
  'RESET_UPDATE_DEBT_STATE',
);

export const deleteDebtRequest = createAction(
  'DELETE_DEBT_REQUEST',
);
export const deleteDebtSuccess = createAction(
  'DELETE_DEBT_SUCCESS',
);
export const deleteDebtFailure = createAction(
  'DELETE_DEBT_FAILURE',
);
export const resetDeleteDebtState = createAction(
  'RESET_DELETE_DEBT_STATE',
);

export const createPaymentDebtRequest = createAction('CREATE_PAYMENT_DEBT_REQUEST');
export const createPaymentDebtSuccess = createAction('CREATE_PAYMENT_DEBT_SUCCESS');
export const createPaymentDebtFailure = createAction('CREATE_PAYMENT_DEBT_FAILURE');
export const resetCreatePaymentDebt = createAction('RESET_CREATE_PAYMENT_DEBT');

export const getLogDebtRequest = createAction('GET_LOG_DEBT_REQUEST');
export const getLogDebtSuccess = createAction('GET_LOG_DEBT_SUCCESS');
export const getLogDebtFailure = createAction('GET_LOG_DEBT_FAILURE');

export const getListDebtCustomerRequest = createAction('GET_LIST_DEBT_CUSTOMER_REQUEST');
export const getListDebtCustomerSuccess = createAction('GET_LIST_DEBT_CUSTOMER_SUCCESS');
export const getListDebtCustomerFailure = createAction('GET_LIST_DEBT_CUSTOMER_FAILURE');

export const resetGetLogDebtCommon = createAction('RESET_GET_LOG_DEBT_COMMON');

export const resetDebtState = createAction('RESET_DEBT_STATE');
