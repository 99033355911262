/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* createBankAccount({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/bank-accounts', payload));
    if (response?.status === 201) {
      yield put(Actions.createBankAccountSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createBankAccountFailure(messages));
    }
  }
}

function* updateBankAccount({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/bank-accounts/${payload.id}`, payload));
    if (response?.status === 200) {
      yield put(Actions.updateBankAccountSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateBankAccountFailure(messages));
    }
  }
}

function* deleteBankAccount({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/bank-accounts/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteBankAccountSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteBankAccountFailure(messages));
    }
  }
}

function* getAllBankAccount({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/bank-accounts', {
      params: { ...payload },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllBankAccountSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.getAllBankAccountFailure(message));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllBankAccountRequest, getAllBankAccount);
  yield takeLatest(Actions.createBankAccountRequest, createBankAccount);
  yield takeLatest(Actions.updateBankAccountRequest, updateBankAccount);
  yield takeLatest(Actions.deleteBankAccountRequest, deleteBankAccount);
}
