/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllTeams({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/teams?include=users&${payload.params}` : '/teams?include=users&limit=0',
    ));
    yield put(Actions.getAllTeamsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllTeamsFailure(messages));
    }
  }
}

function* getMyTeams({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/teams/my?${payload.params}` : '/teams/my?limit=0',
    ));
    yield put(Actions.getMyTeamsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getMyTeamsFailure(messages));
    }
  }
}

function* createTeam({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/teams?include=users', payload));
    yield put(Actions.createTeamSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createTeamFailure(messages));
    }
  }
}

function* addMemberTeam({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/teams/${payload.id}/member`, payload).body);
    yield put(Actions.addMemberTeamSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addMemberTeamFailure(messages));
    }
  }
}

function* updateTeam({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/teams/${payload.id}?include=users`, payload.body));
    yield put(Actions.updateTeamSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateTeamFailure(messages));
    }
  }
}

function* updateCurrentTeam({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch('/teams/current', payload));
    yield put(Actions.updateCurrentTeamSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateCurrentTeamFailure(messages));
    }
  }
}

function* deleteTeam({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/teams/${payload}`));
    yield put(Actions.deleteTeamSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteTeamFailure(messages));
    }
  }
}

function* deleteMemberTeam({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/teams/${payload.id}/member`, payload.body));
    yield put(Actions.deleteMemberTeamSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteMemberTeamFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllTeamsRequest, getAllTeams);
  yield takeLatest(Actions.getMyTeamsRequest, getMyTeams);
  yield takeLatest(Actions.createTeamRequest, createTeam);
  yield takeLatest(Actions.addMemberTeamRequest, addMemberTeam);
  yield takeLatest(Actions.updateTeamRequest, updateTeam);
  yield takeLatest(Actions.updateCurrentTeamRequest, updateCurrentTeam);
  yield takeLatest(Actions.deleteTeamRequest, deleteTeam);
  yield takeLatest(Actions.deleteMemberTeamRequest, deleteMemberTeam);
}
