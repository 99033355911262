/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListProducts({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/products?${payload.params}` : '/products',
    ));
    yield put(Actions.getListProductsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListProductsFailure(messages));
    }
  }
}

function* createProduct({ payload }) {
  try {
    const formData = new FormData();
    formData.append('code', payload.code);
    formData.append('name', payload.name);
    formData.append('image', payload.image);
    formData.append('unit', payload.unit);
    formData.append('min', payload.min);
    formData.append('size', payload.size);
    formData.append('bag', payload.bag);
    formData.append('type_id', payload.type_id);
    formData.append('desc', payload.desc);
    formData.append('note', payload.note);
    if (payload.sale) {
      formData.append('sale', 1);
    } else {
      formData.append('sale', 0);
    }

    if (payload.active) {
      formData.append('active', 1);
    } else {
      formData.append('active', 0);
    }
    const response = yield call(() => axiosMicro.post('/products', formData));
    yield put(Actions.createProductSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createProductFailure(messages));
    }
  }
}

function* updateProduct({ payload }) {
  try {
    const formData = new FormData();
    formData.append('code', payload.code);
    formData.append('name', payload.name);
    formData.append('unit', payload.unit);
    formData.append('min', payload.min);
    formData.append('size', payload.size);
    formData.append('bag', payload.bag);
    formData.append('type_id', payload.type_id);
    formData.append('desc', payload.desc);
    formData.append('note', payload.note);
    if (payload.sale) {
      formData.append('sale', 1);
    } else {
      formData.append('sale', 0);
    }
    if (payload.active) {
      formData.append('active', 1);
    } else {
      formData.append('active', 0);
    }
    if (payload.image) {
      formData.append('image', payload.image);
    }
    const response = yield call(() => axiosMicro.post(`/products/${payload.id}?_method=PATCH`, formData));
    yield put(Actions.updateProductSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateProductFailure(messages));
    }
  }
}

function* deleteProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/products/${payload}`));
    yield put(Actions.deleteProductSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteProductFailure(messages));
    }
  }
}

function* getAllProductTypes() {
  try {
    const response = yield call(() => axiosMicro.get('/product-types?limit=1000'));
    yield put(Actions.getAllProductTypesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllProductTypesFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListProductsRequest, getListProducts);
  yield takeLatest(Actions.createProductRequest, createProduct);
  yield takeLatest(Actions.updateProductRequest, updateProduct);
  yield takeLatest(Actions.deleteProductRequest, deleteProduct);
  yield takeLatest(Actions.getAllProductTypesRequest, getAllProductTypes);
}
