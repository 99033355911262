// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getListProductsRequest = createAction(
  'GET_LIST_PRODUCTS_REQUEST',
);
export const getListProductsSuccess = createAction(
  'GET_LIST_PRODUCTS_SUCCESS',
);
export const getListProductsFailure = createAction(
  'GET_LIST_PRODUCTS_FAILURE',
);

export const createProductRequest = createAction(
  'CREATE_PRODUCT_REQUEST',
);
export const createProductSuccess = createAction(
  'CREATE_PRODUCT_SUCCESS',
);
export const createProductFailure = createAction(
  'CREATE_PRODUCT_FAILURE',
);
export const resetCreateProductState = createAction(
  'RESET_CREATE_PRODUCT_STATE',
);

export const updateProductRequest = createAction(
  'UPDATE_PRODUCT_REQUEST',
);
export const updateProductSuccess = createAction(
  'UPDATE_PRODUCT_SUCCESS',
);
export const updateProductFailure = createAction(
  'UPDATE_PRODUCT_FAILURE',
);
export const resetUpdateProductState = createAction(
  'RESET_UPDATE_PRODUCT_STATE',
);

export const deleteProductRequest = createAction(
  'DELETE_PRODUCT_REQUEST',
);
export const deleteProductSuccess = createAction(
  'DELETE_PRODUCT_SUCCESS',
);
export const deleteProductFailure = createAction(
  'DELETE_PRODUCT_FAILURE',
);
export const resetDeleteProductState = createAction(
  'RESET_DELETE_PRODUCT_STATE',
);

export const getAllProductTypesRequest = createAction(
  'GET_ALL_PRODUCT_TYPES_REQUEST',
);
export const getAllProductTypesSuccess = createAction(
  'GET_ALL_PRODUCT_TYPES_SUCCESS',
);
export const getAllProductTypesFailure = createAction(
  'GET_ALL_PRODUCT_TYPES_FAILURE',
);

export const resetProductState = createAction('RESET_PRODUCT_STATE');
