// http://api.appcompany.test/v1/transports
/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* createTransportOrder({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(() => axiosMicro.post('/transports', payload));
    yield put(Actions.createTransportOrderSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createTransportOrderFailure(messages));
    }
  }
}

function* getServicesOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/transports/services?${payload.params}`, {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getServicesOrderSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getServicesOrderFailure(messages));
    }
  }
}

function* getAllTransports({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/transports?${payload.params}`));
    if (response?.status === 200) {
      yield put(Actions.getAllTransportsSuccess(response.data));
    }
  } catch (error) {
    if (error?.messages?.data) {
      const messages = error.messages.data;
      yield put(Actions.getAllTransportsFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.createTransportOrderRequest, createTransportOrder);
  yield takeLatest(Actions.getServicesOrderRequest, getServicesOrder);
  yield takeLatest(Actions.getAllTransportsRequest, getAllTransports);
}
