/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListDebts({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/debts/${payload.slug}`, {
      params: payload.params,
    }));
    yield put(Actions.getListDebtsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListDebtsFailure(messages));
    }
  }
}

function* createPaymentDebt({ payload }) {
  try {
    yield call(() => axiosMicro.post('/debts/payment', payload));
    yield put(Actions.createPaymentDebtSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createPaymentDebtFailure(messages));
    }
  }
}

function* getLogDebts({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/debts/logs', {
      params: payload,
    }));
    yield put(Actions.getLogDebtSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getLogDebtFailure(messages));
    }
  }
}

function* getListDebtCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/debts/orders', {
      params: payload,
    }));
    yield put(Actions.getListDebtCustomerSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getListDebtCustomerFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListDebtsRequest, getListDebts);
  yield takeLatest(Actions.createPaymentDebtRequest, createPaymentDebt);
  yield takeLatest(Actions.getLogDebtRequest, getLogDebts);
  yield takeLatest(Actions.getListDebtCustomerRequest, getListDebtCustomer);
}
