// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getProductPricesListRequest = createAction(
  'GET_PRODUCT_PRICES_LIST_REQUEST',
);
export const getProductPricesListSuccess = createAction(
  'GET_PRODUCT_PRICES_LIST_SUCCESS',
);
export const getProductPricesListFailure = createAction(
  'GET_PRODUCT_PRICES_LIST_FAILURE',
);

export const createProductPriceRequest = createAction(
  'CREATE_PRODUCT_PRICE_REQUEST',
);
export const createProductPriceSuccess = createAction(
  'CREATE_PRODUCT_PRICE_SUCCESS',
);
export const createProductPriceFailure = createAction(
  'CREATE_PRODUCT_PRICE_FAILURE',
);
export const resetCreateProductPriceState = createAction(
  'RESET_CREATE_PRODUCT_PRICE_STATE',
);

export const updateProductPriceRequest = createAction(
  'UPDATE_PRODUCT_PRICE_REQUEST',
);
export const updateProductPriceSuccess = createAction(
  'UPDATE_PRODUCT_PRICE_SUCCESS',
);
export const updateProductPriceFailure = createAction(
  'UPDATE_PRODUCT_PRICE_FAILURE',
);
export const resetUpdateProductPriceState = createAction(
  'RESET_UPDATE_PRODUCT_PRICE_STATE',
);

export const deleteProductPriceRequest = createAction(
  'DELETE_PRODUCT_PRICE_REQUEST',
);
export const deleteProductPriceSuccess = createAction(
  'DELETE_PRODUCT_PRICE_SUCCESS',
);
export const deleteProductPriceFailure = createAction(
  'DELETE_PRODUCT_PRICE_FAILURE',
);
export const resetDeleteProductPriceState = createAction(
  'RESET_DELETE_PRODUCT_PRICE_STATE',
);

export const addProductPriceRoadRequest = createAction('ADD_PRODUCT_PRICE_ROAD_REQUEST');
export const addProductPriceRoadSuccess = createAction('ADD_PRODUCT_PRICE__ROAD_SUCCESS');
export const addProductPriceRoadFailure = createAction('ADD_PRODUCT_PRICE_ROAD_FAILURE');
export const resetAddProductPriceRoadState = createAction(
  'RESET_ADD_PRODUCT_PRICE_ROAD_STATE',
);

export const addProductPricePlaneRequest = createAction('ADD_PRODUCT_PRICE_PLANE_REQUEST');
export const addProductPricePlaneSuccess = createAction('ADD_PRODUCT_PRICE__PLANE_SUCCESS');
export const addProductPricePlaneFailure = createAction('ADD_PRODUCT_PRICE_PLANE_FAILURE');
export const resetAddProductPricePlaneState = createAction(
  'RESET_ADD_PRODUCT_PRICE_PLANE_STATE',
);

export const getDateProductPriceRequest = createAction(
  'GET_DATE_PRODUCT_PRICE_REQUEST',
);
export const getDateProductPriceSuccess = createAction(
  'GET_DATE_PRODUCT_PRICE_SUCCESS',
);
export const getDateProductPriceFailure = createAction(
  'GET_DATE_PRODUCT_PRICE_FAILURE',
);
export const resetGetDateProductPriceState = createAction(
  'RESET_GET_DATE_PRODUCT_PRICE_STATE',
);

export const findProductPriceByDateRequest = createAction(
  'FIND_PRODUCT_PRICE_BY_DATE_REQUEST',
);
export const findProductPriceByDateSuccess = createAction(
  'FIND_PRODUCT_PRICE_BY_DATE_SUCCESS',
);
export const findProductPriceByDateFailure = createAction(
  'FIND_PRODUCT_PRICE_BY_DATE_FAILURE',
);
export const resetFindProductPriceByDateState = createAction(
  'RESET_FIND_PRODUCT_PRICE_BY_DATE_STATE',
);

export const deleteProductPriceByDateRequest = createAction(
  'DELETE_PRODUCT_PRICE_BY_DATE_REQUEST',
);
export const deleteProductPriceByDateSuccess = createAction(
  'DELETE_PRODUCT_PRICE_BY_DATE_SUCCESS',
);
export const deleteProductPriceByDateFailure = createAction(
  'DELETE_PRODUCT_PRICE_BY_DATE_FAILURE',
);
export const resetDeleteProductPriceByDateState = createAction(
  'RESET_DELETE_PRODUCT_PRICE_BY_DATE_STATE',
);

export const resetProductPriceState = createAction('RESET_PRODUCT_PRICE_STATE');
