/* eslint-disable import/no-extraneous-dependencies */
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  isGetAllAdminNotificationRequest: false,
  isGetAllAdminNotificationSuccess: false,
  isGetAllAdminNotificationFailure: false,
  listAllAdminNotification: {},
  //
  isGetNotificationBoardRequest: false,
  isGetNotificationBoardSuccess: false,
  isGetNotificationBoardFailure: false,
  listNotificationBoard: {},
  //

  isCreateAdminNotificationRequest: false,
  isCreateAdminNotificationSuccess: false,
  isCreateAdminNotificationFailure: false,
  //
  isSaveAdminNotificationRequest: false,
  isSaveAdminNotificationSuccess: false,
  isSaveAdminNotificationFailure: false,
  //
  isSendAdminNotificationRequest: false,
  isSendAdminNotificationSuccess: false,
  isSendAdminNotificationFailure: false,
  //
  isUpdateAdminNotificationRequest: false,
  isUpdateAdminNotificationSuccess: false,
  isUpdateAdminNotificationFailure: false,
  //
  // #region : blog

  //
  isGetAllAdminBlogRequest: false,
  isGetAllAdminBlogSuccess: false,
  isGetAllAdminBlogFailure: false,
  listAllAdminBlog: {},
  //

  // #endregion
  errorMessages: [],
};

const reducer = handleActions({
  [Actions.getAllAdminNotificationRequest]: (state) => ({
    ...state,
    isGetAllAdminNotificationRequest: true,
    isGetAllAdminNotificationSuccess: false,
    isGetAllAdminNotificationFailure: false,
  }),
  [Actions.getAllAdminNotificationSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllAdminNotificationRequest: false,
    isGetAllAdminNotificationSuccess: true,
    isGetAllAdminNotificationFailure: false,
    listAllAdminNotification: payload,
  }),
  [Actions.getAllAdminNotificationFailure]: (state, { payload }) => ({
    ...state,
    isGetAllAdminNotificationRequest: false,
    isGetAllAdminNotificationSuccess: false,
    isGetAllAdminNotificationFailure: true,
    errorMessages: payload,
  }),
  //
  [Actions.getNotificationBoardRequest]: (state) => ({
    ...state,
    isGetNotificationBoardRequest: true,
    isGetNotificationBoardSuccess: false,
    isGetNotificationBoardFailure: false,
  }),
  [Actions.getNotificationBoardSuccess]: (state, { payload }) => ({
    ...state,
    isGetNotificationBoardRequest: false,
    isGetNotificationBoardSuccess: true,
    isGetNotificationBoardFailure: false,
    listNotificationBoard: payload,
  }),
  [Actions.getNotificationBoardFailure]: (state, { payload }) => ({
    ...state,
    isGetNotificationBoardRequest: false,
    isGetNotificationBoardSuccess: false,
    isGetNotificationBoardFailure: true,
    errorMessages: payload,
  }),
  //
  [Actions.createAdminNotificationRequest]: (state) => ({
    ...state,
    isCreateAdminNotificationRequest: true,
    isCreateAdminNotificationSuccess: false,
    isCreateAdminNotificationFailure: false,
  }),
  [Actions.createAdminNotificationSuccess]: (state) => ({
    ...state,
    isCreateAdminNotificationRequest: false,
    isCreateAdminNotificationSuccess: true,
    isCreateAdminNotificationFailure: false,
  }),
  [Actions.createAdminNotificationFailure]: (state, { payload }) => ({
    ...state,
    isCreateAdminNotificationRequest: false,
    isCreateAdminNotificationSuccess: false,
    isCreateAdminNotificationFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateAdminNotificationState]: (state) => ({
    ...state,
    isCreateAdminNotificationRequest: false,
    isCreateAdminNotificationSuccess: false,
    isCreateAdminNotificationFailure: false,
    errorMessages: [],
  }),
  //
  [Actions.saveAdminNotificationRequest]: (state) => ({
    ...state,
    isSaveAdminNotificationRequest: true,
    isSaveAdminNotificationSuccess: false,
    isSaveAdminNotificationFailure: false,
  }),
  [Actions.saveAdminNotificationSuccess]: (state) => ({
    ...state,
    isSaveAdminNotificationRequest: false,
    isSaveAdminNotificationSuccess: true,
    isSaveAdminNotificationFailure: false,
  }),
  [Actions.saveAdminNotificationFailure]: (state, { payload }) => ({
    ...state,
    isSaveAdminNotificationRequest: false,
    isSaveAdminNotificationSuccess: false,
    isSaveAdminNotificationFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateAdminNotificationState]: (state) => ({
    ...state,
    isSaveAdminNotificationRequest: false,
    isSaveAdminNotificationSuccess: false,
    isSaveAdminNotificationFailure: false,
    errorMessages: [],
  }),
  //
  [Actions.sendAdminNotificationRequest]: (state) => ({
    ...state,
    isSendAdminNotificationRequest: true,
    isSendAdminNotificationSuccess: false,
    isSendAdminNotificationFailure: false,
  }),
  [Actions.sendAdminNotificationSuccess]: (state) => ({
    ...state,
    isSendAdminNotificationRequest: false,
    isSendAdminNotificationSuccess: true,
    isSendAdminNotificationFailure: false,
  }),
  [Actions.sendAdminNotificationFailure]: (state, { payload }) => ({
    ...state,
    isSendAdminNotificationRequest: false,
    isSendAdminNotificationSuccess: false,
    isSendAdminNotificationFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetSendAdminNotificationState]: (state) => ({
    ...state,
    isSendAdminNotificationRequest: false,
    isSendAdminNotificationSuccess: false,
    isSendAdminNotificationFailure: false,
    errorMessages: [],
  }),
  //
  [Actions.updateAdminNotificationRequest]: (state) => ({
    ...state,
    isUpdateAdminNotificationRequest: true,
    isUpdateAdminNotificationSuccess: false,
    isUpdateAdminNotificationFailure: false,
  }),
  [Actions.updateAdminNotificationSuccess]: (state) => ({
    ...state,
    isUpdateAdminNotificationRequest: false,
    isUpdateAdminNotificationSuccess: true,
    isUpdateAdminNotificationFailure: false,
  }),
  [Actions.updateAdminNotificationFailure]: (state, { payload }) => ({
    ...state,
    isUpdateAdminNotificationRequest: false,
    isUpdateAdminNotificationSuccess: false,
    isUpdateAdminNotificationFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetUpdateAdminNotificationState]: (state) => ({
    ...state,
    isUpdateAdminNotificationRequest: false,
    isUpdateAdminNotificationSuccess: false,
    isUpdateAdminNotificationFailure: false,
    errorMessages: [],
  }),
  //

  [Actions.resetAdminState]: () => initialState,
}, initialState);

export default reducer;
