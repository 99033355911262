// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetListShippingsRequest: false,
  isGetListShippingsSuccess: false,
  isGetListShippingsFailure: false,
  listShippingsState: {},

  isGetShippingsRequest: false,
  isGetShippingsSuccess: false,
  isGetShippingsFailure: false,
  shippingsState: {},

  isAddShippingsRequest: false,
  isAddShippingsSuccess: false,
  isAddShippingsFailure: false,
  responseDataAddShipping: {},

  isEditShippingsRequest: false,
  isEditShippingsSuccess: false,
  isEditShippingsFailure: false,

  isDeleteShippingsRequest: false,
  isDeleteShippingsSuccess: false,
  isDeleteShippingsFailure: false,

  isAddShippingsPriceRequest: false,
  isAddShippingsPriceSuccess: false,
  isAddShippingsPriceFailure: false,

  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region: get list shippings
    [Actions.getListShippingsRequest]: (state) => ({
      ...state,
      isGetListShippingsRequest: true,
      isGetListShippingsSuccess: false,
      isGetDetailShippingsFailure: false,
    }),
    [Actions.getListShippingsSuccess]: (state, { payload }) => ({
      ...state,
      isGetListShippingsRequest: false,
      isGetListShippingsSuccess: true,
      isGetListShippingsFailure: false,
      listShippingsState: payload,
    }),
    [Actions.getListShippingsFailure]: (state, { payload }) => ({
      ...state,
      isGetListShippingsRequest: false,
      isGetDetailShippingsSuccess: false,
      isGetDetailShippingsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region: get list shippings
    [Actions.getShippingRequest]: (state) => ({
      ...state,
      isGetShippingsRequest: true,
      isGetShippingsSuccess: false,
      isGetShippingsFailure: false,
    }),
    [Actions.getShippingSuccess]: (state, { payload }) => ({
      ...state,
      isGetShippingsRequest: false,
      isGetShippingsSuccess: true,
      isGetShippingsFailure: false,
      shippingsState: payload,
    }),
    [Actions.getShippingFailure]: (state, { payload }) => ({
      ...state,
      isGetShippingsRequest: false,
      isGetShippingsSuccess: false,
      isGetShippingsFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region : add shippings
    [Actions.addShippingsRequest]: (state) => ({
      ...state,
      isAddShippingsRequest: true,
      isAddShippingsSuccess: false,
      isAddShippingsFailure: false,
    }),
    [Actions.addShippingsSuccess]: (state, { payload }) => ({
      ...state,
      isAddShippingsSuccess: true,
      isAddShippingsRequest: false,
      isAddShippingsFailure: false,
      responseDataAddShipping: payload,

    }),
    [Actions.addShippingsFailure]: (state, { payload }) => ({
      ...state,
      isAddShippingsRequest: false,
      isAddShippingsSuccess: false,
      isAddShippingsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddShippingsState]: (state) => ({
      ...state,
      isAddShippingsFailure: false,
      isAddShippingsRequest: false,
      isAddShippingsSuccess: false,
    }),
    // #endregion
    // #region : edit shippings
    [Actions.editShippingsRequest]: (state) => ({
      ...state,
      isEditShippingsRequest: true,
      isEditShippingsSuccess: false,
      isEditShippingsFailure: false,
    }),
    [Actions.editShippingsSuccess]: (state) => ({
      ...state,
      isEditShippingsSuccess: true,
      isEditShippingsRequest: false,
      isEditShippingsFailure: false,
    }),
    [Actions.editShippingsFailure]: (state, { payload }) => ({
      ...state,
      isEditShippingsRequest: false,
      isEditShippingsSuccess: false,
      isEditShippingsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditShippingsState]: (state) => ({
      ...state,
      isEditShippingsFailure: false,
      isEditShippingsRequest: false,
      isEditShippingsSuccess: false,
    }),
    // #endregion
    // #region : Delete shippings

    [Actions.deleteShippingsRequest]: (state) => ({
      ...state,
      isDeleteShippingsRequest: true,
      isDeleteShippingsSuccess: false,
      isDeleteShippingsFailure: false,
    }),
    [Actions.deleteShippingsSuccess]: (state) => ({
      ...state,
      isDeleteShippingsSuccess: true,
      isDeleteShippingsRequest: false,
      isDeleteShippingsFailure: false,
    }),
    [Actions.deleteShippingsFailure]: (state, { payload }) => ({
      ...state,
      isDeleteShippingsRequest: false,
      isDeleteShippingsSuccess: false,
      isDeleteShippingsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteShippingsState]: (state) => ({
      ...state,
      isDeleteShippingsFailure: false,
      isDeleteShippingsRequest: false,
      isDeleteShippingsSuccess: false,
    }),
    // #endregion

    // #region :
    [Actions.addShippingsPriceRequest]: (state) => ({
      ...state,
      isAddShippingsPriceRequest: true,
      isAddShippingsPriceSuccess: false,
      isAddShippingsPriceFailure: false,
    }),
    [Actions.addShippingsPriceSuccess]: (state) => ({
      ...state,
      isAddShippingsPriceRequest: false,
      isAddShippingsPriceSuccess: true,
      isAddShippingsPriceFailure: false,
    }),
    [Actions.addShippingsPriceFailure]: (state, { payload }) => ({
      ...state,
      isAddShippingsPriceRequest: false,
      isAddShippingsPriceSuccess: false,
      isAddShippingsPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddShippingsPriceState]: (state) => ({
      ...state,
      isAddShippingsPriceRequest: false,
      isAddShippingsPriceSuccess: false,
      isAddShippingsPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetShippingsState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
