/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListOrderMFP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/factory/orders?${payload.params}`));
    yield put(Actions.getListOrdersMFPSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListOrdersMFPFailure(messages));
    }
  }
}

function* getListBuyOrderMFP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`factory/buys?${payload.params}`));
    yield put(Actions.getListBuyOrdersMFPSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListBuyOrdersMFPFailure(messages));
    }
  }
}

function* pushOrderMFP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('factory/orders/send', { ids: payload }));
    yield put(Actions.pushOrderMFPSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.pushOrderMFPFailure(messages));
    }
  }
}

function* buysOrderMFP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('factory/buys', { products: payload.products, note: payload.note }));
    yield put(Actions.buysOrderMFPSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.buysOrderMFPFailure(messages));
    }
  }
}
function* editBuysOrderMFP({ payload }) {
  try {
    let response;
    if (payload.status) {
      response = yield call(() => axiosMicro.patch(`factory/buys/${payload.id}`, { products: payload.products, note: payload.note, status: payload.status }));
    } else {
      response = yield call(() => axiosMicro.patch(`factory/buys/${payload.id}`, { products: payload.products, note: payload.note }));
    }
    yield put(Actions.editBuysOrderMFPSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.editBuysOrderMFPFailure(messages));
    }
  }
}
function* deleteBuyOrderMFP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/factory/buys/${payload.id}`));
    yield put(Actions.deleteBuysOrderMFPSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteBuysOrderMFPFailure(messages));
    }
  }
}
function* warehouseBuyOrderMFP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`factory/buys/${payload.id}/import`));
    yield put(Actions.warehouseBuysOrderMFPSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.warehouseBuysOrderMFPFailure(messages));
    }
  }
}

function* getListOrderRefundsMFP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/factory/refunds?${payload.params}`));
    yield put(Actions.getListOrdersRefundsMFPSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListOrdersRefundsMFPFailure(messages));
    }
  }
}
function* warehouseRefundsOrderMFP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`factory/refunds/${payload.id}/import`));
    yield put(Actions.warehouseRefundsOrderMFPSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.warehouseRefundsOrderMFPFailure(messages));
    }
  }
}
function* createRefundOrderMFP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('factory/refunds', { products: payload.products, note: payload.note }));
    yield put(Actions.createRefundOrderMFPSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createRefundOrderMFPFailure(messages));
    }
  }
}
function* editExchangeOrReturnOrderMFP({ payload }) {
  try {
    let response;
    if (payload.status) {
      response = yield call(() => axiosMicro.patch(`factory/refunds/${payload.id}`, { products: payload.products, note: payload.note, status: payload.status }));
    } else {
      response = yield call(() => axiosMicro.patch(`factory/refunds/${payload.id}`, { products: payload.products, note: payload.note }));
    }
    yield put(Actions.editExchangeOrReturnOrderMFPSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.editExchangeOrReturnOrderMFPFailure(messages));
    }
  }
}
// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListOrdersMFPRequest, getListOrderMFP);
  yield takeLatest(Actions.pushOrderMFPRequest, pushOrderMFP);
  yield takeLatest(Actions.buysOrderMFPRequest, buysOrderMFP);
  yield takeLatest(Actions.getListBuyOrdersMFPRequest, getListBuyOrderMFP);
  yield takeLatest(Actions.editBuysOrderMFPRequest, editBuysOrderMFP);
  yield takeLatest(Actions.deleteBuysOrderMFPRequest, deleteBuyOrderMFP);
  yield takeLatest(Actions.warehouseBuysOrderMFPRequest, warehouseBuyOrderMFP);
  yield takeLatest(Actions.getListOrdersRefundsMFPRequest, getListOrderRefundsMFP);
  yield takeLatest(Actions.warehouseRefundsOrderMFPRequest, warehouseRefundsOrderMFP);
  yield takeLatest(Actions.createRefundOrderMFPRequest, createRefundOrderMFP);
  yield takeLatest(Actions.editExchangeOrReturnOrderMFPRequest, editExchangeOrReturnOrderMFP);
}
