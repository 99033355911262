/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const getAllSchedulesCalendarRequest = createAction('GET_ALL_SCHEDULES_CALENDAR_REQUEST');
export const getAllSchedulesCalendarSuccess = createAction('GET_ALL_SCHEDULES_CALENDAR_SUCCESS');
export const getAllSchedulesCalendarFailure = createAction('GET_ALL_SCHEDULES_CALENDAR_FAILURE');

export const createScheduleCalendarRequest = createAction('CREATE_SCHEDULE_CALENDAR_REQUEST');
export const createScheduleCalendarSuccess = createAction('CREATE_SCHEDULE_CALENDAR_SUCCESS');
export const createScheduleCalendarFailure = createAction('CREATE_SCHEDULE_CALENDAR_FAILURE');
export const resetCreateScheduleCalendarState = createAction('RESET_CREATE_SCHEDULE_CALENDAR_STATE');

export const updateScheduleCalendarRequest = createAction('UPDATE_SCHEDULE_CALENDAR_REQUEST');
export const updateScheduleCalendarSuccess = createAction('UPDATE_SCHEDULE_CALENDAR_SUCCESS');
export const updateScheduleCalendarFailure = createAction('UPDATE_SCHEDULE_CALENDAR_FAILURE');
export const resetUpdateScheduleCalendarState = createAction('RESET_UPDATE_SCHEDULE_CALENDAR_STATE');

export const deleteScheduleCalendarRequest = createAction('DELETE_SCHEDULE_CALENDAR_REQUEST');
export const deleteScheduleCalendarSuccess = createAction('DELETE_SCHEDULE_CALENDAR_SUCCESS');
export const deleteScheduleCalendarFailure = createAction('DELETE_SCHEDULE_CALENDAR_FAILURE');
export const resetDeleteScheduleCalendarState = createAction('RESET_DELETE_SCHEDULE_CALENDAR_STATE');

export const resetScheduleCalendarState = createAction('RESET_SCHEDULE_CALENDAR_STATE');
