// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetListDebtsRequest: false,
  isGetListDebtsSuccess: false,
  isGetListDebtsFailure: false,
  listDebtsState: {},
  //
  isCreateDebtRequest: false,
  isCreateDebtSuccess: false,
  isCreateDebtFailure: false,
  //
  isUpdateDebtRequest: false,
  isUpdateDebtSuccess: false,
  isUpdateDebtFailure: false,
  //
  isDeleteDebtRequest: false,
  isDeleteDebtSuccess: false,
  isDeleteDebtFailure: false,
  //
  isCreatePaymentDebtRequest: false,
  isCreatePaymentDebtSuccess: false,
  isCreatePaymentDebtFailure: false,
  //
  isGetLogDebtRequest: false,
  isGetLogDebtSuccess: false,
  isGetLogDebtFailure: false,
  getLogDebtState: {},
  //
  isGetListDebtCustomerRequest: false,
  isGetListDebtCustomerSuccess: false,
  isGetListDebtCustomerFailure: false,
  getListDebtCustomerState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get list product types
    [Actions.getListDebtsRequest]: (state) => ({
      ...state,
      isGetListDebtsRequest: true,
      isGetListDebtsSuccess: false,
      isGetListDebtsFailure: false,
    }),
    [Actions.getListDebtsSuccess]: (state, { payload }) => ({
      ...state,
      isGetListDebtsRequest: false,
      isGetListDebtsSuccess: true,
      isGetListDebtsFailure: false,
      listDebtsState: payload,
    }),
    [Actions.getListDebtsFailure]: (state, { payload }) => ({
      ...state,
      isGetListDebtsRequest: false,
      isGetListDebtsSuccess: false,
      isGetListDebtsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create product type
    [Actions.createDebtRequest]: (state) => ({
      ...state,
      isCreateDebtRequest: true,
      isCreateDebtSuccess: false,
      isCreateDebtFailure: false,
    }),
    [Actions.createDebtSuccess]: (state) => ({
      ...state,
      isCreateDebtRequest: false,
      isCreateDebtSuccess: true,
      isCreateDebtFailure: false,
    }),
    [Actions.createDebtFailure]: (state, { payload }) => ({
      ...state,
      isCreateDebtRequest: false,
      isCreateDebtSuccess: false,
      isCreateDebtFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateDebtState]: (state) => ({
      ...state,
      isCreateDebtRequest: false,
      isCreateDebtSuccess: false,
      isCreateDebtFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update product type
    [Actions.updateDebtRequest]: (state) => ({
      ...state,
      isUpdateDebtRequest: true,
      isUpdateDebtSuccess: false,
      isUpdateDebtFailure: false,
    }),
    [Actions.updateDebtSuccess]: (state) => ({
      ...state,
      isUpdateDebtRequest: false,
      isUpdateDebtSuccess: true,
      isUpdateDebtFailure: false,
    }),
    [Actions.updateDebtFailure]: (state, { payload }) => ({
      ...state,
      isUpdateDebtRequest: false,
      isUpdateDebtSuccess: false,
      isUpdateDebtFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateDebtState]: (state) => ({
      ...state,
      isUpdateDebtRequest: false,
      isUpdateDebtSuccess: false,
      isUpdateDebtFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete product type
    [Actions.deleteDebtRequest]: (state) => ({
      ...state,
      isDeleteDebtRequest: true,
      isDeleteDebtSuccess: false,
      isDeleteDebtFailure: false,
    }),
    [Actions.deleteDebtSuccess]: (state) => ({
      ...state,
      isDeleteDebtRequest: false,
      isDeleteDebtSuccess: true,
      isDeleteDebtFailure: false,
    }),
    [Actions.deleteDebtFailure]: (state, { payload }) => ({
      ...state,
      isDeleteDebtRequest: false,
      isDeleteDebtSuccess: false,
      isDeleteDebtFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteDebtState]: (state) => ({
      ...state,
      isDeleteDebtRequest: false,
      isDeleteDebtSuccess: false,
      isDeleteDebtFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region create payment debt
    [Actions.createPaymentDebtRequest]: (state) => ({
      ...state,
      isCreatePaymentDebtRequest: true,
      isCreatePaymentDebtSuccess: false,
      isCreatePaymentDebtFailure: false,
    }),
    [Actions.createPaymentDebtSuccess]: (state) => ({
      ...state,
      isCreatePaymentDebtRequest: false,
      isCreatePaymentDebtSuccess: true,
      isCreatePaymentDebtFailure: false,
    }),
    [Actions.createPaymentDebtFailure]: (state, { payload }) => ({
      ...state,
      isCreatePaymentDebtRequest: false,
      isCreatePaymentDebtSuccess: false,
      isCreatePaymentDebtFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePaymentDebt]: (state) => ({
      ...state,
      isCreatePaymentDebtRequest: false,
      isCreatePaymentDebtSuccess: false,
      isCreatePaymentDebtFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.getLogDebtRequest]: (state) => ({
      ...state,
      isGetLogDebtRequest: true,
      isGetLogDebtSuccess: false,
      isGetLogDebtFailure: false,
    }),
    [Actions.getLogDebtSuccess]: (state, { payload }) => ({
      ...state,
      isGetLogDebtRequest: false,
      isGetLogDebtSuccess: true,
      isGetLogDebtFailure: false,
      getLogDebtState: payload,
    }),
    [Actions.getLogDebtFailure]: (state, { payload }) => ({
      ...state,
      isGetLogDebtRequest: false,
      isGetLogDebtSuccess: false,
      isGetLogDebtFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.getListDebtCustomerRequest]: (state) => ({
      ...state,
      isGetListDebtCustomerRequest: true,
      isGetListDebtCustomerSuccess: false,
      isGetListDebtCustomerFailure: false,
    }),
    [Actions.getListDebtCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isGetListDebtCustomerRequest: false,
      isGetListDebtCustomerSuccess: true,
      isGetListDebtCustomerFailure: false,
      getListDebtCustomerState: payload,
    }),
    [Actions.getListDebtCustomerFailure]: (state, { payload }) => ({
      ...state,
      isGetListDebtCustomerRequest: false,
      isGetListDebtCustomerSuccess: false,
      isGetListDebtCustomerFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.resetGetLogDebtCommon]: (state) => ({
      ...state,
      isGetListDebtCustomerRequest: false,
      isGetListDebtCustomerSuccess: false,
      isGetListDebtCustomerFailure: false,
      isGetLogDebtRequest: false,
      isGetLogDebtSuccess: false,
      isGetLogDebtFailure: false,
      getListDebtCustomerState: {},
      getLogDebtState: {},
      errorMessages: [],
    }),
    // #endregion

    // #region : Local
    [Actions.resetDebtState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
