/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const getRevenueReportRequest = createAction('GET_REVENUE_REPORT_REQUEST');
export const getRevenueReportSuccess = createAction('GET_REVENUE_REPORT_SUCCESS');
export const getRevenueReportFailure = createAction('GET_REVENUE_REPORT_FAILURE');

export const exportRevenueReportRequest = createAction('EXPORT_REVENUE_REPORT_REQUEST');
export const exportRevenueReportSuccess = createAction('EXPORT_REVENUE_REPORT_SUCCESS');
export const exportRevenueReportFailure = createAction('EXPORT_REVENUE_REPORT_FAILURE');
export const resetExportRevenueReportState = createAction('RESET_EXPORT_REVENUE_REPORT_STATE');

export const getAllWarehouseReportRequest = createAction('GET_ALL_WAREHOUSE_REPORT_REQUEST');
export const getAllWarehouseReportSuccess = createAction('GET_ALL_WAREHOUSE_REPORT_SUCCESS');
export const getAllWarehouseReportFailure = createAction('GET_ALL_WAREHOUSE_REPORT_FAILURE');

export const changeNoteWarehouseRequest = createAction('CHANGE_NOTE_WAREHOUSE_REQUEST');
export const changeNoteWarehouseSuccess = createAction('CHANGE_NOTE_WAREHOUSE_SUCCESS');
export const changeNoteWarehouseFailure = createAction('CHANGE_NOTE_WAREHOUSE_FAILURE');
export const resetChangeNoteWarehouseState = createAction('RESET_CHANGE_NOTE_WAREHOUSE_STATE');

export const getAllExpensesReportRequest = createAction('GET_ALL_EXPENSES_REPORT_REQUEST');
export const getAllExpensesReportRequestNoLimit = createAction('GET_ALL_EXPENSES_REPORT_REQUEST_NO_LIMIT');
export const getAllExpensesReportSuccess = createAction('GET_ALL_EXPENSES_REPORT_SUCCESS');
export const getAllExpensesReportFailure = createAction('GET_ALL_EXPENSES_REPORT_FAILURE');

export const createAccountingRequest = createAction('ADD_ACCOUNTING_REQUEST');
export const createAccountingSuccess = createAction('ADD_ACCOUNTING_SUCCESS');
export const createAccountingFailure = createAction('ADD_ACCOUNTING_FAILURE');
export const resetCreateAccountingState = createAction('RESET_ADD_ACCOUNTING_STATE');

export const getAllAccountingExpensesRequest = createAction('GET_ALL_ACCOUNTING_EXPENSES_REQUEST');
export const getAllAccountingExpensesSuccess = createAction('GET_ALL_ACCOUNTING_EXPENSES_SUCCESS');
export const getAllAccountingExpensesFailure = createAction('GET_ALL_ACCOUNTING_EXPENSES_FAILURE');

export const getAllAccountingReceiptsRequest = createAction('GET_ALL_ACCOUNTING_RECEIPTS_REQUEST');
export const getAllAccountingReceiptsSuccess = createAction('GET_ALL_ACCOUNTING_RECEIPTS_SUCCESS');
export const getAllAccountingReceiptsFailure = createAction('GET_ALL_ACCOUNTING_RECEIPTS_FAILURE');

export const getAllAccountingSubmitRequest = createAction('GET_ALL_ACCOUNTING_SUBMIT_REQUEST');
export const getAllAccountingSubmitSuccess = createAction('GET_ALL_ACCOUNTING_SUBMIT_SUCCESS');
export const getAllAccountingSubmitFailure = createAction('GET_ALL_ACCOUNTING_SUBMIT_FAILURE');

export const getAllUserFundLogsRequest = createAction('GET_ALL_USER_FUND_LOGS_REQUEST');
export const getAllUserFundLogsSuccess = createAction('GET_ALL_USER_FUND_LOGS_SUCCESS');
export const getAllUserFundLogsFailure = createAction('GET_ALL_USER_FUND_LOGS_FAILURE');

export const getReceiveSubmitsRequest = createAction('GET_RECEIVE_SUBMITS_REQUEST');
export const getReceiveSubmitsSuccess = createAction('GET_RECEIVE_SUBMITS_SUCCESS');
export const getReceiveSubmitsFailure = createAction('GET_RECEIVE_SUBMITS_FAILURE');

export const updateAccountingSubmitRequest = createAction('UPDATE_ACCOUNTING_SUBMIT_REQUEST');
export const updateAccountingSubmitSuccess = createAction('UPDATE_ACCOUNTING_SUBMIT_SUCCESS');
export const updateAccountingSubmitFailure = createAction('UPDATE_ACCOUNTING_SUBMIT_FAILURE');
export const resetUpdateAccountingSubmit = createAction('RESET_DELETE_SCHEDULE_CALENDAR_STATE');

export const createAccountingSubmitRequest = createAction('CREATE_ACCOUNTING_SUBMIT_REQUEST');
export const createAccountingSubmitSuccess = createAction('CREATE_ACCOUNTING_SUBMIT_SUCCESS');
export const createAccountingSubmitFailure = createAction('CREATE_ACCOUNTING_SUBMIT_FAILURE');
export const resetCreateAccountingSubmitState = createAction('RESET_CREATE_ACCOUNTING_SUBMIT_STATE');

export const getAllOrdersRequest = createAction('GET_ALL_ORDERS_REQUEST');
export const getAllOrdersSuccess = createAction('GET_ALL_ORDERS_SUCCESS');
export const getAllOrdersFailure = createAction('GET_ALL_ORDERS_FAILURE');
export const resetGetAllOrdersState = createAction('RESET_GET_ALL_ORDERS_STATE');

export const resetStateReport = createAction('RESET_STATE_REPORT');

export const createAccountingExpenseRequest = createAction('CREATE_ACCOUNTING_EXPENSE_REQUEST');
export const createAccountingExpenseSuccess = createAction('CREATE_ACCOUNTING_EXPENSE_SUCCESS');
export const createAccountingExpenseFailure = createAction('CREATE_ACCOUNTING_EXPENSE_FAILURE');
export const resetCreateAccountingExpenseState = createAction('RESET_CREATE_ACCOUNTING_EXPENSE_STATE');

export const createAccountingReceiptRequest = createAction('CREATE_ACCOUNTING_RECEIPT_REQUEST');
export const createAccountingReceiptSuccess = createAction('CREATE_ACCOUNTING_RECEIPT_SUCCESS');
export const createAccountingReceiptFailure = createAction('CREATE_ACCOUNTING_RECEIPT_FAILURE');
export const resetCreateAccountingReceiptState = createAction('RESET_CREATE_ACCOUNTING_RECEIPT_STATE');

export const deleteAccountingExpenditureRequest = createAction('DELETE_ACCOUNTING_EXPENDITURE_REQUEST');
export const deleteAccountingExpenditureSuccess = createAction('DELETE_ACCOUNTING_EXPENDITURE_SUCCESS');
export const deleteAccountingExpenditureFailure = createAction('DELETE_ACCOUNTING_EXPENDITURE_FAILURE');
export const resetDeleteAccountingExpenditureState = createAction('RESET_DELETE_ACCOUNTING_EXPENDITURE_STATE');

export const getReportFactoryBuyRequest = createAction('GET_REPORT_FACTORY_BUY_REQUEST');
export const getReportFactoryBuySuccess = createAction('GET_REPORT_FACTORY_BUY_SUCCESS');
export const getReportFactoryBuyFailure = createAction('GET_REPORT_FACTORY_BUY_FAILURE');

export const getReportFactoryChangeRequest = createAction('GET_REPORT_FACTORY_CHANGE_REQUEST');
export const getReportFactoryChangeSuccess = createAction('GET_REPORT_FACTORY_CHANGE_SUCCESS');
export const getReportFactoryChangeFailure = createAction('GET_REPORT_FACTORY_CHANGE_FAILURE');
