// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {

  // #region call source
  //
  isCreateCallSourceRequest: false,
  isCreateCallSourceSuccess: false,
  isCreateCallSourceFailure: false,

  //
  isUpdateCallSourceRequest: false,
  isUpdateCallSourceSuccess: false,
  isUpdateCallSourceFailure: false,

  //
  isDeleteCallSourceRequest: false,
  isDeleteCallSourceSuccess: false,
  isDeleteCallSourceFailure: false,

  //
  isGetAllCallSourceRequest: false,
  isGetAllCallSourceSuccess: false,
  isGetAllCallSourceFailure: false,
  listAllCallSource: {},

  // #endregion
  // #region call feedback
  //
  isCreateCallFeedbackRequest: false,
  isCreateCallFeedbackSuccess: false,
  isCreateCallFeedbackFailure: false,

  //
  isUpdateCallFeedbackRequest: false,
  isUpdateCallFeedbackSuccess: false,
  isUpdateCallFeedbackFailure: false,

  //
  isDeleteCallFeedbackRequest: false,
  isDeleteCallFeedbackSuccess: false,
  isDeleteCallFeedbackFailure: false,

  //
  isGetAllCallFeedbackRequest: false,
  isGetAllCallFeedbackSuccess: false,
  isGetAllCallFeedbackFailure: false,
  listAllCallFeedback: {},

  // #endregion
  // #region call data
  //
  isCreateCallDataRequest: false,
  isCreateCallDataSuccess: false,
  isCreateCallDataFailure: false,

  //
  isUpdateCallDataRequest: false,
  isUpdateCallDataSuccess: false,
  isUpdateCallDataFailure: false,

  //
  isDeleteCallDataRequest: false,
  isDeleteCallDataSuccess: false,
  isDeleteCallDataFailure: false,

  //
  isGetAllCallDataRequest: false,
  isGetAllCallDataSuccess: false,
  isGetAllCallDataFailure: false,
  listAllCallData: {},

  //

  isImportCallDataRequest: false,
  isImportCallDataSuccess: false,
  isImportCallDataFailure: false,

  //

  isAddCallingCallDataRequest: false,
  isAddCallingCallDataSuccess: false,
  isAddCallingCallDataFailure: false,
  //

  isGetListCustomerNeedCallRequest: false,
  isGetListCustomerNeedCallSuccess: false,
  isGetListCustomerNeedCallFailure: false,
  listCustomerNeedCall: {},

  //

  isFilterCallDataRequest: false,
  isFilterCallDataSuccess: false,
  isFilterCallDataFailure: false,
  responseFilterCallData: {},

  // #endregion
  // #region call log
  //
  isCreateCallLogRequest: false,
  isCreateCallLogSuccess: false,
  isCreateCallLogFailure: false,
  createCallLogState: {},
  //
  isUpdateCallLogRequest: false,
  isUpdateCallLogSuccess: false,
  isUpdateCallLogFailure: false,

  //
  isDeleteCallLogRequest: false,
  isDeleteCallLogSuccess: false,
  isDeleteCallLogFailure: false,

  //
  isGetAllCallLogRequest: false,
  isGetAllCallLogSuccess: false,
  isGetAllCallLogFailure: false,
  listAllCallLog: {},

  // #endregion

  // #region call label
  isGetAllCallLabelRequest: false,
  isGetAllCallLabelSuccess: false,
  isGetAllCallLabelFailure: false,
  listAllCallLabel: {},

  isCreateCallLabelRequest: false,
  isCreateCallLabelSuccess: false,
  isCreateCallLabelFailure: false,

  isUpdateCallLabelRequest: false,
  isUpdateCallLabelSuccess: false,
  isUpdateCallLabelFailure: false,

  isDeleteCallLabelRequest: false,
  isDeleteCallLabelSuccess: false,
  isDeleteCallLabelFailure: false,
  // #endregion
  //

  // Get call data import

  isGetCallDataImportRequest: false,
  isGetCallDataImportSuccess: false,
  isGetCallDataImportFailure: false,
  getCallDataImportState: {},

  // Local
  errorMessages: [],
};

const reducer = handleActions({

  // #region call source

  // #region
  [Actions.createCallSourceRequest]: (state) => ({
    ...state,
    isCreateCallSourceRequest: true,
    isCreateCallSourceSuccess: false,
    isCreateCallSourceFailure: false,
  }),
  [Actions.createCallSourceSuccess]: (state) => ({
    ...state,
    isCreateCallSourceRequest: false,
    isCreateCallSourceSuccess: true,
    isCreateCallSourceFailure: false,
  }),
  [Actions.createCallSourceFailure]: (state, { payload }) => ({
    ...state,
    isCreateCallSourceRequest: false,
    isCreateCallSourceSuccess: false,
    isCreateCallSourceFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateCallSourceState]: (state) => ({
    ...state,
    isCreateCallSourceRequest: false,
    isCreateCallSourceSuccess: false,
    isCreateCallSourceFailure: false,
    errorMessages: [],
  }),

  // #endregion
  // #region
  [Actions.updateCallSourceRequest]: (state) => ({
    ...state,
    isUpdateCallSourceRequest: true,
    isUpdateCallSourceSuccess: false,
    isUpdateCallSourceFailure: false,
  }),
  [Actions.updateCallSourceSuccess]: (state) => ({
    ...state,
    isUpdateCallSourceRequest: false,
    isUpdateCallSourceSuccess: true,
    isUpdateCallSourceFailure: false,
  }),
  [Actions.updateCallSourceFailure]: (state, { payload }) => ({
    ...state,
    isUpdateCallSourceRequest: false,
    isUpdateCallSourceSuccess: false,
    isUpdateCallSourceFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetUpdateCallSourceState]: (state) => ({
    ...state,
    isUpdateCallSourceRequest: false,
    isUpdateCallSourceSuccess: false,
    isUpdateCallSourceFailure: false,
    errorMessages: [],
  }),

  // #endregion
  // #region
  [Actions.deleteCallSourceRequest]: (state) => ({
    ...state,
    isDeleteCallSourceRequest: true,
    isDeleteCallSourceSuccess: false,
    isDeleteCallSourceFailure: false,
  }),
  [Actions.deleteCallSourceSuccess]: (state) => ({
    ...state,
    isDeleteCallSourceRequest: false,
    isDeleteCallSourceSuccess: true,
    isDeleteCallSourceFailure: false,
  }),
  [Actions.deleteCallSourceFailure]: (state, { payload }) => ({
    ...state,
    isDeleteCallSourceRequest: false,
    isDeleteCallSourceSuccess: false,
    isDeleteCallSourceFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetDeleteCallSourceState]: (state) => ({
    ...state,
    isDeleteCallSourceRequest: false,
    isDeleteCallSourceSuccess: false,
    isDeleteCallSourceFailure: false,
    errorMessages: [],
  }),

  // #endregion

  // #region
  [Actions.getAllCallSourceRequest]: (state) => ({
    ...state,
    isGetAllCallSourceRequest: true,
    isGetAllCallSourceSuccess: false,
    isGetAllCallSourceFailure: false,
  }),
  [Actions.getAllCallSourceSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllCallSourceRequest: false,
    isGetAllCallSourceSuccess: true,
    isGetAllCallSourceFailure: false,
    listAllCallSource: payload,
  }),
  [Actions.getAllCallSourceFailure]: (state, { payload }) => ({
    ...state,
    isGetAllCallSourceRequest: false,
    isGetAllCallSourceSuccess: false,
    isGetAllCallSourceFailure: true,
    errorMessages: payload,
  }),

  // #endregion

  // #endregion
  // #region call feedback

  // #region
  [Actions.createCallFeedbackRequest]: (state) => ({
    ...state,
    isCreateCallFeedbackRequest: true,
    isCreateCallFeedbackSuccess: false,
    isCreateCallFeedbackFailure: false,
  }),
  [Actions.createCallFeedbackSuccess]: (state) => ({
    ...state,
    isCreateCallFeedbackRequest: false,
    isCreateCallFeedbackSuccess: true,
    isCreateCallFeedbackFailure: false,
  }),
  [Actions.createCallFeedbackFailure]: (state, { payload }) => ({
    ...state,
    isCreateCallFeedbackRequest: false,
    isCreateCallFeedbackSuccess: false,
    isCreateCallFeedbackFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateCallFeedbackState]: (state) => ({
    ...state,
    isCreateCallFeedbackRequest: false,
    isCreateCallFeedbackSuccess: false,
    isCreateCallFeedbackFailure: false,
    errorMessages: [],
  }),

  // #endregion call source
  // #region
  [Actions.updateCallFeedbackRequest]: (state) => ({
    ...state,
    isUpdateCallFeedbackRequest: true,
    isUpdateCallFeedbackSuccess: false,
    isUpdateCallFeedbackFailure: false,
  }),
  [Actions.updateCallFeedbackSuccess]: (state) => ({
    ...state,
    isUpdateCallFeedbackRequest: false,
    isUpdateCallFeedbackSuccess: true,
    isUpdateCallFeedbackFailure: false,
  }),
  [Actions.updateCallFeedbackFailure]: (state, { payload }) => ({
    ...state,
    isUpdateCallFeedbackRequest: false,
    isUpdateCallFeedbackSuccess: false,
    isUpdateCallFeedbackFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetUpdateCallFeedbackState]: (state) => ({
    ...state,
    isUpdateCallFeedbackRequest: false,
    isUpdateCallFeedbackSuccess: false,
    isUpdateCallFeedbackFailure: false,
    errorMessages: [],
  }),

  // #endregion
  // #region
  [Actions.deleteCallFeedbackRequest]: (state) => ({
    ...state,
    isDeleteCallFeedbackRequest: true,
    isDeleteCallFeedbackSuccess: false,
    isDeleteCallFeedbackFailure: false,
  }),
  [Actions.deleteCallFeedbackSuccess]: (state) => ({
    ...state,
    isDeleteCallFeedbackRequest: false,
    isDeleteCallFeedbackSuccess: true,
    isDeleteCallFeedbackFailure: false,
  }),
  [Actions.deleteCallFeedbackFailure]: (state, { payload }) => ({
    ...state,
    isDeleteCallFeedbackRequest: false,
    isDeleteCallFeedbackSuccess: false,
    isDeleteCallFeedbackFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetDeleteCallFeedbackState]: (state) => ({
    ...state,
    isDeleteCallFeedbackRequest: false,
    isDeleteCallFeedbackSuccess: false,
    isDeleteCallFeedbackFailure: false,
    errorMessages: [],
  }),

  // #endregion

  // #region
  [Actions.getAllCallFeedbackRequest]: (state) => ({
    ...state,
    isGetAllCallFeedbackRequest: true,
    isGetAllCallFeedbackSuccess: false,
    isGetAllCallFeedbackFailure: false,
  }),
  [Actions.getAllCallFeedbackSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllCallFeedbackRequest: false,
    isGetAllCallFeedbackSuccess: true,
    isGetAllCallFeedbackFailure: false,
    listAllCallFeedback: payload,
  }),
  [Actions.getAllCallFeedbackFailure]: (state, { payload }) => ({
    ...state,
    isGetAllCallFeedbackRequest: false,
    isGetAllCallFeedbackSuccess: false,
    isGetAllCallFeedbackFailure: true,
    errorMessages: payload,
  }),

  // #endregion

  // #endregion
  // #region call data

  // #region
  [Actions.createCallDataRequest]: (state) => ({
    ...state,
    isCreateCallDataRequest: true,
    isCreateCallDataSuccess: false,
    isCreateCallDataFailure: false,
  }),
  [Actions.createCallDataSuccess]: (state) => ({
    ...state,
    isCreateCallDataRequest: false,
    isCreateCallDataSuccess: true,
    isCreateCallDataFailure: false,
  }),
  [Actions.createCallDataFailure]: (state, { payload }) => ({
    ...state,
    isCreateCallDataRequest: false,
    isCreateCallDataSuccess: false,
    isCreateCallDataFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateCallDataState]: (state) => ({
    ...state,
    isCreateCallDataRequest: false,
    isCreateCallDataSuccess: false,
    isCreateCallDataFailure: false,
    errorMessages: [],
  }),

  // #endregion
  // #region
  [Actions.updateCallDataRequest]: (state) => ({
    ...state,
    isUpdateCallDataRequest: true,
    isUpdateCallDataSuccess: false,
    isUpdateCallDataFailure: false,
  }),
  [Actions.updateCallDataSuccess]: (state) => ({
    ...state,
    isUpdateCallDataRequest: false,
    isUpdateCallDataSuccess: true,
    isUpdateCallDataFailure: false,
  }),
  [Actions.updateCallDataFailure]: (state, { payload }) => ({
    ...state,
    isUpdateCallDataRequest: false,
    isUpdateCallDataSuccess: false,
    isUpdateCallDataFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetUpdateCallDataState]: (state) => ({
    ...state,
    isUpdateCallDataRequest: false,
    isUpdateCallDataSuccess: false,
    isUpdateCallDataFailure: false,
    errorMessages: [],
  }),

  // #endregion
  // #region
  [Actions.deleteCallDataRequest]: (state) => ({
    ...state,
    isDeleteCallDataRequest: true,
    isDeleteCallDataSuccess: false,
    isDeleteCallDataFailure: false,
  }),
  [Actions.deleteCallDataSuccess]: (state) => ({
    ...state,
    isDeleteCallDataRequest: false,
    isDeleteCallDataSuccess: true,
    isDeleteCallDataFailure: false,
  }),
  [Actions.deleteCallDataFailure]: (state, { payload }) => ({
    ...state,
    isDeleteCallDataRequest: false,
    isDeleteCallDataSuccess: false,
    isDeleteCallDataFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetDeleteCallDataState]: (state) => ({
    ...state,
    isDeleteCallDataRequest: false,
    isDeleteCallDataSuccess: false,
    isDeleteCallDataFailure: false,
    errorMessages: [],
  }),

  // #endregion

  // #region
  [Actions.getAllCallDataRequest]: (state) => ({
    ...state,
    isGetAllCallDataRequest: true,
    isGetAllCallDataSuccess: false,
    isGetAllCallDataFailure: false,
  }),
  [Actions.getAllCallDataSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllCallDataRequest: false,
    isGetAllCallDataSuccess: true,
    isGetAllCallDataFailure: false,
    listAllCallData: payload,
  }),
  [Actions.getAllCallDataFailure]: (state, { payload }) => ({
    ...state,
    isGetAllCallDataRequest: false,
    isGetAllCallDataSuccess: false,
    isGetAllCallDataFailure: true,
    errorMessages: payload,
  }),

  // #endregion

  [Actions.filterCallDataRequest]: (state) => ({
    ...state,
    isFilterCallDataRequest: true,
    isFilterCallDataSuccess: false,
    isFilterCallDataFailure: false,
  }),
  [Actions.filterCallDataSuccess]: (state, { payload }) => ({
    ...state,
    isFilterCallDataRequest: false,
    isFilterCallDataSuccess: true,
    isFilterCallDataFailure: false,
    responseFilterCallData: payload,
  }),
  [Actions.filterCallDataFailure]: (state, { payload }) => ({
    ...state,
    isFilterCallDataRequest: false,
    isFilterCallDataSuccess: false,
    isFilterCallDataFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetFilterCallData]: (state) => ({
    ...state,
    isFilterCallDataRequest: false,
    isFilterCallDataSuccess: false,
    isFilterCallDataFailure: false,
    responseFilterCallData: {},
    errorMessages: [],
  }),
  // #region
  [Actions.importCallDataRequest]: (state) => ({
    ...state,
    isImportCallDataRequest: true,
    isImportCallDataSuccess: false,
    isImportCallDataFailure: false,
  }),
  [Actions.importCallDataSuccess]: (state) => ({
    ...state,
    isImportCallDataRequest: false,
    isImportCallDataSuccess: true,
    isImportCallDataFailure: false,
  }),
  [Actions.importCallDataFailure]: (state, { payload }) => ({
    ...state,
    isImportCallDataRequest: false,
    isImportCallDataSuccess: false,
    isImportCallDataFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetImportCallDataState]: (state) => ({
    ...state,
    isImportCallDataRequest: false,
    isImportCallDataSuccess: false,
    isImportCallDataFailure: false,
    errorMessages: [],
  }),

  // #endregion

  // #region
  [Actions.getListCustomerNeedCallRequest]: (state) => ({
    ...state,
    isGetListCustomerNeedCallRequest: true,
    isGetListCustomerNeedCallSuccess: false,
    isGetListCustomerNeedCallFailure: false,
  }),
  [Actions.getListCustomerNeedCallSuccess]: (state, { payload }) => ({
    ...state,
    isGetListCustomerNeedCallRequest: false,
    isGetListCustomerNeedCallSuccess: true,
    isGetListCustomerNeedCallFailure: false,
    listCustomerNeedCall: payload,
  }),
  [Actions.getListCustomerNeedCallFailure]: (state, { payload }) => ({
    ...state,
    isGetListCustomerNeedCallRequest: false,
    isGetListCustomerNeedCallSuccess: false,
    isGetListCustomerNeedCallFailure: true,
    errorMessages: payload,
  }),
  // #endregion
  // #endregion
  // #region call log

  // #region
  [Actions.createCallLogRequest]: (state) => ({
    ...state,
    isCreateCallLogRequest: true,
    isCreateCallLogSuccess: false,
    isCreateCallLogFailure: false,
  }),
  [Actions.createCallLogSuccess]: (state, { payload }) => ({
    ...state,
    isCreateCallLogRequest: false,
    isCreateCallLogSuccess: true,
    isCreateCallLogFailure: false,
    createCallLogState: payload,
  }),
  [Actions.createCallLogFailure]: (state, { payload }) => ({
    ...state,
    isCreateCallLogRequest: false,
    isCreateCallLogSuccess: false,
    isCreateCallLogFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateCallLogState]: (state) => ({
    ...state,
    isCreateCallLogRequest: false,
    isCreateCallLogSuccess: false,
    isCreateCallLogFailure: false,
    errorMessages: [],
  }),

  // #endregion
  // #region
  [Actions.updateCallLogRequest]: (state) => ({
    ...state,
    isUpdateCallLogRequest: true,
    isUpdateCallLogSuccess: false,
    isUpdateCallLogFailure: false,
  }),
  [Actions.updateCallLogSuccess]: (state) => ({
    ...state,
    isUpdateCallLogRequest: false,
    isUpdateCallLogSuccess: true,
    isUpdateCallLogFailure: false,
  }),
  [Actions.updateCallLogFailure]: (state, { payload }) => ({
    ...state,
    isUpdateCallLogRequest: false,
    isUpdateCallLogSuccess: false,
    isUpdateCallLogFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetUpdateCallLogState]: (state) => ({
    ...state,
    isUpdateCallLogRequest: false,
    isUpdateCallLogSuccess: false,
    isUpdateCallLogFailure: false,
    errorMessages: [],
  }),

  // #endregion
  // #region
  [Actions.deleteCallLogRequest]: (state) => ({
    ...state,
    isDeleteCallLogRequest: true,
    isDeleteCallLogSuccess: false,
    isDeleteCallLogFailure: false,
  }),
  [Actions.deleteCallLogSuccess]: (state) => ({
    ...state,
    isDeleteCallLogRequest: false,
    isDeleteCallLogSuccess: true,
    isDeleteCallLogFailure: false,
  }),
  [Actions.deleteCallLogFailure]: (state, { payload }) => ({
    ...state,
    isDeleteCallLogRequest: false,
    isDeleteCallLogSuccess: false,
    isDeleteCallLogFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetDeleteCallLogState]: (state) => ({
    ...state,
    isDeleteCallLogRequest: false,
    isDeleteCallLogSuccess: false,
    isDeleteCallLogFailure: false,
    errorMessages: [],
  }),

  // #endregion

  // #region
  [Actions.getAllCallLogRequest]: (state) => ({
    ...state,
    isGetAllCallLogRequest: true,
    isGetAllCallLogSuccess: false,
    isGetAllCallLogFailure: false,
  }),
  [Actions.getAllCallLogSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllCallLogRequest: false,
    isGetAllCallLogSuccess: true,
    isGetAllCallLogFailure: false,
    listAllCallLog: payload,
  }),
  [Actions.getAllCallLogFailure]: (state, { payload }) => ({
    ...state,
    isGetAllCallLogRequest: false,
    isGetAllCallLogSuccess: false,
    isGetAllCallLogFailure: true,
    errorMessages: payload,
  }),

  // #endregion

  // #endregion

  // #region
  [Actions.createCallLabelRequest]: (state) => ({
    ...state,
    isCreateCallLabelRequest: true,
    isCreateCallLabelSuccess: false,
    isCreateCallLabelFailure: false,
  }),
  [Actions.createCallLabelSuccess]: (state) => ({
    ...state,
    isCreateCallLabelRequest: false,
    isCreateCallLabelSuccess: true,
    isCreateCallLabelFailure: false,
  }),
  [Actions.createCallLabelFailure]: (state, { payload }) => ({
    ...state,
    isCreateCallLabelRequest: false,
    isCreateCallLabelSuccess: false,
    isCreateCallLabelFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateCallLabelState]: (state) => ({
    ...state,
    isCreateCallLabelRequest: false,
    isCreateCallLabelSuccess: false,
    isCreateCallLabelFailure: false,
    errorMessages: [],
  }),

  // #endregion
  // #region
  [Actions.updateCallLabelRequest]: (state) => ({
    ...state,
    isUpdateCallLabelRequest: true,
    isUpdateCallLabelSuccess: false,
    isUpdateCallLabelFailure: false,
  }),
  [Actions.updateCallLabelSuccess]: (state) => ({
    ...state,
    isUpdateCallLabelRequest: false,
    isUpdateCallLabelSuccess: true,
    isUpdateCallLabelFailure: false,
  }),
  [Actions.updateCallLabelFailure]: (state, { payload }) => ({
    ...state,
    isUpdateCallLabelRequest: false,
    isUpdateCallLabelSuccess: false,
    isUpdateCallLabelFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetUpdateCallLabelState]: (state) => ({
    ...state,
    isUpdateCallLabelRequest: false,
    isUpdateCallLabelSuccess: false,
    isUpdateCallLabelFailure: false,
    errorMessages: [],
  }),

  // #endregion
  // #region
  [Actions.deleteCallLabelRequest]: (state) => ({
    ...state,
    isDeleteCallLabelRequest: true,
    isDeleteCallLabelSuccess: false,
    isDeleteCallLabelFailure: false,
  }),
  [Actions.deleteCallLabelSuccess]: (state) => ({
    ...state,
    isDeleteCallLabelRequest: false,
    isDeleteCallLabelSuccess: true,
    isDeleteCallLabelFailure: false,
  }),
  [Actions.deleteCallLabelFailure]: (state, { payload }) => ({
    ...state,
    isDeleteCallLabelRequest: false,
    isDeleteCallLabelSuccess: false,
    isDeleteCallLabelFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetDeleteCallLabelState]: (state) => ({
    ...state,
    isDeleteCallLabelRequest: false,
    isDeleteCallLabelSuccess: false,
    isDeleteCallLabelFailure: false,
    errorMessages: [],
  }),

  // #endregion

  // #region
  [Actions.getAllCallLabelRequest]: (state) => ({
    ...state,
    isGetAllCallLabelRequest: true,
    isGetAllCallLabelSuccess: false,
    isGetAllCallLabelFailure: false,
  }),
  [Actions.getAllCallLabelSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllCallLabelRequest: false,
    isGetAllCallLabelSuccess: true,
    isGetAllCallLabelFailure: false,
    listAllCallLabel: payload,
  }),
  [Actions.getAllCallLabelFailure]: (state, { payload }) => ({
    ...state,
    isGetAllCallLabelRequest: false,
    isGetAllCallLabelSuccess: false,
    isGetAllCallLabelFailure: true,
    errorMessages: payload,
  }),

  // #endregion

  // #endregion

  // #region  : Add calling call data
  [Actions.addCallingCallDataRequest]: (state) => ({
    ...state,
    isAddCallingCallDataRequest: true,
    isAddCallingCallDataSuccess: false,
    isAddCallingCallDataFailure: false,
  }),
  [Actions.addCallingCallDataSuccess]: (state) => ({
    ...state,
    isAddCallingCallDataRequest: false,
    isAddCallingCallDataSuccess: true,
    isAddCallingCallDataFailure: false,

  }),
  [Actions.addCallingCallDataFailure]: (state, { payload }) => ({
    ...state,
    isAddCallingCallDataRequest: false,
    isAddCallingCallDataSuccess: false,
    isAddCallingCallDataFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetAddCallingCallData]: (state) => ({
    ...state,
    isAddCallingCallDataRequest: false,
    isAddCallingCallDataSuccess: false,
    isAddCallingCallDataFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region Get call data import
  [Actions.getCallDataImportRequest]: (state) => ({
    ...state,
    isGetCallDataImportRequest: true,
    isGetCallDataImportSuccess: false,
    isGetCallDataImportFailure: false,
  }),
  [Actions.getCallDataImportSuccess]: (state, { payload }) => ({
    ...state,
    isGetCallDataImportRequest: false,
    isGetCallDataImportSuccess: true,
    isGetCallDataImportFailure: false,
    getCallDataImportState: payload,
  }),
  [Actions.getCallDataImportFailure]: (state, { payload }) => ({
    ...state,
    isGetCallDataImportRequest: false,
    isGetCallDataImportSuccess: false,
    isGetCallDataImportFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetGetCallDataImport]: (state) => ({
    ...state,
    isGetCallDataImportRequest: false,
    isGetCallDataImportSuccess: false,
    isGetCallDataImportFailure: false,
    getCallDataImportState: {},
    errorMessages: [],
  }),
  // #endregion

  // #region
  [Actions.resetCallState]: () => initialState,
  // #endregion

}, initialState);

export default reducer;
