/* eslint-disable import/no-extraneous-dependencies */
import { takeLatest, put, call } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getRevenue({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/revenue', { params: payload }));
    if (response?.status === 200) {
      yield put(Actions.getRevenueReportSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getRevenueReportFailure(messages));
    }
  }
}

function* exportRevenue({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/revenue', payload));
    if (response?.status === 200) {
      yield put(Actions.exportRevenueReportSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.exportRevenueReportFailure(messages));
    }
  }
}

function* getAllWarehouseReport() {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses'));
    if (response?.status === 200) {
      yield put(Actions.getAllWarehouseReportSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllWarehouseReportFailure(messages));
    }
  }
}

function* changeNoteWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/das', payload));
    if (response?.status === 200) {
      yield put(Actions.changeNoteWarehouseSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.changeNoteWarehouseFailure(messages));
    }
  }
}

function* getAllExpensesReport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/expenses?orderBy=status;date;code&sortedBy=asc;desc;asc${payload.params}`));
    if (response?.status === 200) {
      yield put(Actions.getAllExpensesReportSuccess(response?.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllExpensesReportFailure(messages));
    }
  }
}

function* createAccounting({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/accounting/voucher/payment', payload));
    if (response?.status === 200) {
      yield put(Actions.createAccountingSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createAccountingFailure(messages));
    }
  }
}

function* getAllAccountingExpenses({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/accounting-expenses?include=user,order,shipping${payload}&status=unsubmitted`));
    if (response?.status === 200) {
      yield put(Actions.getAllAccountingExpensesSuccess(response?.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllAccountingExpensesFailure(messages));
    }
  }
}

function* getAllAccountingReceipts({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/accounting-receipts?include=user,order,shipping${payload}&status=unsubmitted`));
    if (response?.status === 200) {
      yield put(Actions.getAllAccountingReceiptsSuccess(response?.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllAccountingReceiptsFailure(messages));
    }
  }
}

function* getAllUserFundLogs({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/user-fund-logs${payload}`));
    if (response?.status === 200) {
      yield put(Actions.getAllUserFundLogsSuccess(response?.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllUserFundLogsFailure(messages));
    }
  }
}

function* getAllAccountingSubmit({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/accounting-submits?${payload}`));
    if (response?.status === 200) {
      yield put(Actions.getAllAccountingSubmitSuccess(response?.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllAccountingSubmitFailure(messages));
    }
  }
}

function* getReceiveSubmit({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/receive-submits?user_id=${payload}&include=submit`));
    if (response?.status === 200) {
      yield put(Actions.getReceiveSubmitsSuccess(response?.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getReceiveSubmitsFailure(messages));
    }
  }
}

function* updateAccountingSubmit({ payload }) {
  try {
    const { id, ...status } = payload;
    const response = yield call(() => axiosMicro.patch(`/accounting-submits/${id}`, status));
    if (response?.status === 200) {
      yield put(Actions.updateAccountingSubmitSuccess(response?.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateAccountingSubmitFailure(messages));
    }
  }
}

function* createAccountingSubmit({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/accounting-submits', payload));
    yield put(Actions.createAccountingSubmitSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.createAccountingSubmitFailure(message));
    }
  }
}

function* getAllOrders({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/orders?${payload.params}`));
    if (response?.status === 200) {
      yield put(Actions.getAllOrdersSuccess(response?.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllOrdersFailure(messages));
    }
  }
}

function* createAccountingExpense({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/accounting-expenses', payload));
    yield put(Actions.createAccountingExpenseSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.createAccountingExpenseFailure(message));
    }
  }
}

function* createAccountingReceipt({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/accounting-receipts', payload));
    yield put(Actions.createAccountingReceiptSuccess(response.data));
    if (response.data.success === false) {
      const errors = response.data.error;
      if (errors && errors.length > 0) {
        const errorMessage = errors;
        yield put(Actions.createAccountingReceiptFailure(errorMessage));
      }
    }
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.createAccountingReceiptFailure(message));
    }
  }
}

function* deleteAccountingExpenditure({ payload }) {
  try {
    const { id, object } = payload;
    const path = (object === 'AccountingReceipt') ? `/accounting-receipts/${id}` : `/accounting-expenses/${id}`;
    yield call(() => axiosMicro.delete(path));
    yield put(Actions.deleteAccountingExpenditureSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.deleteAccountingExpenditureFailure(message));
    }
  }
}

function* getReportFactoryBuy({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/factory/orders', {
      params: payload,
    }));
    yield put(Actions.getReportFactoryBuySuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const message = error.response.data;
      yield put(Actions.getReportFactoryBuyFailure(message));
    }
  }
}

function* getReportFactoryChange({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/factory/refunds', {
      params: payload,
    }));
    yield put(Actions.getReportFactoryChangeSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const message = error.response.data;
      yield put(Actions.getReportFactoryChangeFailure(message));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getRevenueReportRequest, getRevenue);
  yield takeLatest(Actions.exportRevenueReportRequest, exportRevenue);
  yield takeLatest(Actions.getAllWarehouseReportRequest, getAllWarehouseReport);
  yield takeLatest(Actions.changeNoteWarehouseRequest, changeNoteWarehouse);
  yield takeLatest(Actions.getAllExpensesReportRequest, getAllExpensesReport);
  yield takeLatest(Actions.createAccountingRequest, createAccounting);
  yield takeLatest(Actions.getAllAccountingExpensesRequest, getAllAccountingExpenses);
  yield takeLatest(Actions.getAllAccountingReceiptsRequest, getAllAccountingReceipts);
  yield takeLatest(Actions.getAllAccountingSubmitRequest, getAllAccountingSubmit);
  yield takeLatest(Actions.getAllUserFundLogsRequest, getAllUserFundLogs);
  yield takeLatest(Actions.getReceiveSubmitsRequest, getReceiveSubmit);
  yield takeLatest(Actions.updateAccountingSubmitRequest, updateAccountingSubmit);
  yield takeLatest(Actions.createAccountingSubmitRequest, createAccountingSubmit);
  yield takeLatest(Actions.getAllOrdersRequest, getAllOrders);
  yield takeLatest(Actions.createAccountingExpenseRequest, createAccountingExpense);
  yield takeLatest(Actions.createAccountingReceiptRequest, createAccountingReceipt);
  yield takeLatest(Actions.deleteAccountingExpenditureRequest, deleteAccountingExpenditure);
  yield takeLatest(Actions.getReportFactoryBuyRequest, getReportFactoryBuy);
  yield takeLatest(Actions.getReportFactoryChangeRequest, getReportFactoryChange);
}
