// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';
import * as Action from './actions';

const initialState = {
  // #region add conversation
  isAddConversationRequest: false,
  isAddConversationSuccess: false,
  isAddConversationFailure: false,
  // #endregion

  // #region get conversation
  isGetConversationRequest: false,
  isGetConversationSuccess: false,
  isGetConversationFailure: false,
  responseGetConversation: {},
  // #endregion

  // #region add message
  isAddMessageRequest: false,
  isAddMessageSuccess: false,
  isAddMessageFailure: false,
  // #endregion

  // #region get message
  isGetMessageRequest: false,
  isGetMessageSuccess: false,
  isGetMessageFailure: false,
  responseGetMessage: {},

  // #endregion

  // #region action messages
  isActionMessageRequest: false,
  isActionMessageSuccess: false,
  isActionMessageFailure: false,
  // #endregion

  currentUserChat: {},

  errorMessages: [],
};

const reducer = handleActions({
  // #region add conversation
  [Action.addConversationRequest]: (state) => ({
    ...state,
    isAddConversationRequest: true,
    isAddConversationSuccess: false,
    isAddConversationFailure: false,
  }),
  [Action.addConversationSuccess]: (state) => ({
    ...state,
    isAddConversationRequest: false,
    isAddConversationSuccess: true,
    isAddConversationFailure: false,
  }),
  [Action.addConversationFailure]: (state, { payload }) => ({
    ...state,
    isAddConversationRequest: false,
    isAddConversationFailure: true,
    isAddConversationSuccess: false,
    errorMessages: payload,
  }),
  [Action.resetAddConversationState]: (state) => ({
    ...state,
    isAddConversationRequest: false,
    isAddConversationSuccess: false,
    isAddConversationFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region get conversation
  [Action.getConversationRequest]: (state) => ({
    ...state,
    isGetConversationRequest: true,
    isGetConversationSuccess: false,
    isGetConversationFailure: false,
  }),
  [Action.getConversationSuccess]: (state, { payload }) => ({
    ...state,
    isGetConversationRequest: false,
    isGetConversationSuccess: true,
    isGetConversationFailure: false,
    responseGetConversation: payload,
  }),
  [Action.getConversationFailure]: (state, { payload }) => ({
    ...state,
    isGetConversationRequest: false,
    isGetConversationSuccess: false,
    isGetConversationFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  // #region add message
  [Action.addMessageRequest]: (state) => ({
    ...state,
    isAddMessageRequest: true,
    isAddMessageSuccess: false,
    isAddMessageFailure: false,
  }),
  [Action.addMessageSuccess]: (state) => ({
    ...state,
    isAddMessageRequest: false,
    isAddMessageSuccess: true,
    isAddMessageFailure: false,
  }),
  [Action.addMessageFailure]: (state, { payload }) => ({
    ...state,
    isAddMessageRequest: false,
    isAddMessageSuccess: false,
    isAddMessageFailure: true,
    errorMessages: payload,
  }),
  [Action.resetAddMessageState]: (state) => ({
    ...state,
    isAddMessageRequest: false,
    isAddMessageSuccess: false,
    isAddMessageFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region get messages
  [Action.getMessageRequest]: (state) => ({
    ...state,
    isGetMessageRequest: true,
    isGetMessageSuccess: false,
    isGetMessageFailure: false,
  }),
  [Action.getMessageSuccess]: (state, { payload }) => ({
    ...state,
    isGetMessageRequest: false,
    isGetMessageSuccess: true,
    isGetMessageFailure: false,
    responseGetMessage: payload,
  }),
  [Action.getMessageFailure]: (state, { payload }) => ({
    ...state,
    isGetMessageRequest: false,
    isGetMessageSuccess: false,
    isGetMessageFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  // #region action messages
  [Action.actionMessageRequest]: (state) => ({
    ...state,
    isActionMessageRequest: true,
    isActionMessageSuccess: false,
    isActionMessageFailure: false,
  }),
  [Action.actionMessageSuccess]: (state) => ({
    ...state,
    isActionMessageRequest: false,
    isActionMessageSuccess: true,
    isActionMessageFailure: false,
  }),
  [Action.actionMessageFailure]: (state, { payload }) => ({
    ...state,
    isActionMessageRequest: false,
    isActionMessageSuccess: false,
    isActionMessageFailure: true,
    errorMessages: payload,
  }),
  [Action.resetActionMessageState]: (state) => ({
    ...state,
    isActionMessageRequest: false,
    isActionMessageSuccess: false,
    isActionMessageFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region  chang current user chat
  [Action.changeCurrentUserChat]: (state, { payload }) => ({
    ...state,
    currentUserChat: payload,
  }),
  // #endregion

  // #region Update current user chat
  [Action.updateCurrentUserChat]: (state, { payload }) => ({
    ...state,
    currentUserChat: payload,
  }),
  // #endregion

  // #region local
  [Action.resetChatState]: () => initialState,
  // #endregion
}, initialState);

export default reducer;
