// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getListPayoffsRequest = createAction('GET_LIST_PAYOFF_REQUEST');
export const getListPayoffsSuccess = createAction('GET_LIST_PAYOFF_SUCCESS');
export const getListPayoffsFailure = createAction('GET_LIST_PAYOFF_FAILURE');

export const addPayoffRequest = createAction('ADD_PAYOFF_REQUEST');
export const addPayoffSuccess = createAction('ADD_PAYOFF_SUCCESS');
export const addPayoffFailure = createAction('ADD_PAYOFF_FAILURE');

export const addImagePayoffRequest = createAction('ADD_IMAGE_PAYOFF_REQUEST');
export const addImagePayoffSuccess = createAction('ADD_IMAGE_PAYOFF_SUCCESS');
export const addImagePayoffFailure = createAction('ADD_IMAGE_PAYOFF_FAILURE');
export const resetAddImagePayoffState = createAction('RESET_ADD_IMAGE_PAYOFF_STATE');

export const editPayoffRequest = createAction('EDIT_PAYOFF_REQUEST');
export const editPayoffSuccess = createAction('EDIT_PAYOFF_SUCCESS');
export const editPayoffFailure = createAction('EDIT_PAYOFF_FAILURE');

export const deletePayoffRequest = createAction('DELETE_PAYOFF_REQUEST');
export const deletePayoffSuccess = createAction('DELETE_PAYOFF_SUCCESS');
export const deletePayoffFailure = createAction('DELETE_PAYOFF_FAILURE');

export const getDetailPayoffRequest = createAction('GET_PAYOFF_REQUEST');
export const getDetailPayoffSuccess = createAction('GET_PAYOFF_SUCCESS');
export const getDetailPayoffFailure = createAction('GET_PAYOFF_FAILURE');

export const seenNotificationRequest = createAction('SEEN_NOTIFICATION_REQUEST');
export const seenNotificationSuccess = createAction('SEEN_NOTIFICATION_SUCCESS');
export const seenNotificationFailure = createAction('SEEN_NOTIFICATION_FAILURE');

export const resetAddPayoffState = createAction('RESET_ADD_PAYOFF_STATE');
export const resetEditPayoffState = createAction('RESET_EDIT_PAYOFF_STATE');
export const resetDeletePayoffState = createAction('RESET_DELETE_PAYOFF_STATE');
export const resetDetailPayoffState = createAction('RESET_DETAIL_PAYOFF_STATE');
export const resetPayoffState = createAction('RESET_PAYOFF_STATE');

export const changeStatusGoldRequest = createAction('CHANGE_STATUS_GOLD_REQUEST');
export const changeStatusGoldSuccess = createAction('CHANGE_STATUS_GOLD_SUCCESS');
export const changeStatusGoldFailure = createAction('CHANGE_STATUS_GOLD_FAILURE');
export const resetChangeStatusGold = createAction('RESET_CHANGE_STATUS_GOLD');
