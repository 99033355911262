// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';
import * as Action from './actions';

const initialState = {
  //
  isGetAllScheduleBooksCalendarRequest: false,
  isGetAllScheduleBooksCalendarSuccess: false,
  isGetAllScheduleBooksCalendarFailure: false,
  allScheduleBooksCalendarState: false,
  //
  isCreateScheduleBookCalendarRequest: false,
  isCreateScheduleBookCalendarSuccess: false,
  isCreateScheduleBookCalendarFailure: false,
  //
  isUpdateScheduleBookCalendarRequest: false,
  isUpdateScheduleBookCalendarSuccess: false,
  isUpdateScheduleBookCalendarFailure: false,
  //
  isDeleteScheduleBookCalendarRequest: false,
  isDeleteScheduleBookCalendarSuccess: false,
  isDeleteScheduleBookCalendarFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Schedule Books
    [Action.getAllScheduleBooksCalendarRequest]: (state) => ({
      ...state,
      isGetAllScheduleBooksCalendarRequest: true,
      isGetAllScheduleBooksCalendarSuccess: false,
      isGetAllScheduleBooksCalendarFailure: false,
    }),
    [Action.getAllScheduleBooksCalendarSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllScheduleBooksCalendarRequest: false,
      isGetAllScheduleBooksCalendarSuccess: true,
      isGetAllScheduleBooksCalendarFailure: false,
      allScheduleBooksCalendarState: payload,
    }),
    [Action.getAllScheduleBooksCalendarFailure]: (state, { payload }) => ({
      ...state,
      isGetAllScheduleBooksCalendarRequest: false,
      isGetAllScheduleBooksCalendarSuccess: false,
      isGetAllScheduleBooksCalendarFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Schedule Book
    [Action.createScheduleBookCalendarRequest]: (state) => ({
      ...state,
      isCreateScheduleBookCalendarRequest: true,
      isCreateScheduleBookCalendarSuccess: false,
      isCreateScheduleBookCalendarFailure: false,
    }),
    [Action.createScheduleBookCalendarSuccess]: (state) => ({
      ...state,
      isCreateScheduleBookCalendarRequest: false,
      isCreateScheduleBookCalendarSuccess: true,
      isCreateScheduleBookCalendarFailure: false,
    }),
    [Action.createScheduleBookCalendarFailure]: (state, { payload }) => ({
      ...state,
      isCreateScheduleBookCalendarRequest: false,
      isCreateScheduleBookCalendarSuccess: false,
      isCreateScheduleBookCalendarFailure: true,
      errorMessages: payload,
    }),
    [Action.resetCreateScheduleBookCalendarState]: (state) => ({
      ...state,
      isCreateScheduleBookCalendarRequest: false,
      isCreateScheduleBookCalendarSuccess: false,
      isCreateScheduleBookCalendarFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update Schedule Book
    [Action.updateScheduleBookCalendarRequest]: (state) => ({
      ...state,
      isUpdateScheduleBookCalendarRequest: true,
      isUpdateScheduleBookCalendarSuccess: false,
      isUpdateScheduleBookCalendarFailure: false,
    }),
    [Action.updateScheduleBookCalendarSuccess]: (state) => ({
      ...state,
      isUpdateScheduleBookCalendarRequest: false,
      isUpdateScheduleBookCalendarSuccess: true,
      isUpdateScheduleBookCalendarFailure: false,
    }),
    [Action.updateScheduleBookCalendarFailure]: (state, { payload }) => ({
      ...state,
      isUpdateScheduleBookCalendarRequest: false,
      isUpdateScheduleBookCalendarSuccess: false,
      isUpdateScheduleBookCalendarFailure: true,
      errorMessages: payload,
    }),
    [Action.resetUpdateScheduleBookCalendarState]: (state) => ({
      ...state,
      isUpdateScheduleBookCalendarRequest: false,
      isUpdateScheduleBookCalendarSuccess: false,
      isUpdateScheduleBookCalendarFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete Schedule Book
    [Action.deleteScheduleBookCalendarRequest]: (state) => ({
      ...state,
      isDeleteScheduleBookCalendarRequest: true,
      isDeleteScheduleBookCalendarSuccess: false,
      isDeleteScheduleBookCalendarFailure: false,
    }),
    [Action.deleteScheduleBookCalendarSuccess]: (state) => ({
      ...state,
      isDeleteScheduleBookCalendarRequest: false,
      isDeleteScheduleBookCalendarSuccess: true,
      isDeleteScheduleBookCalendarFailure: false,
    }),
    [Action.deleteScheduleBookCalendarFailure]: (state, { payload }) => ({
      ...state,
      isDeleteScheduleBookCalendarRequest: false,
      isDeleteScheduleBookCalendarSuccess: false,
      isDeleteScheduleBookCalendarFailure: true,
      errorMessages: payload,
    }),
    [Action.resetDeleteScheduleBookCalendarState]: (state) => ({
      ...state,
      isDeleteScheduleBookCalendarRequest: false,
      isDeleteScheduleBookCalendarSuccess: false,
      isDeleteScheduleBookCalendarFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Action.resetScheduleBookCalendarState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
