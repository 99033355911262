// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllTeamsRequest = createAction('GET_ALL_TEAMS_REQUEST');
export const getAllTeamsSuccess = createAction('GET_ALL_TEAMS_SUCCESS');
export const getAllTeamsFailure = createAction('GET_ALL_TEAMS_FAILURE');

export const getMyTeamsRequest = createAction('GET_MY_TEAMS_REQUEST');
export const getMyTeamsSuccess = createAction('GET_MY_TEAMS_SUCCESS');
export const getMyTeamsFailure = createAction('GET_MY_TEAMS_FAILURE');
export const resetGetMyTeamsState = createAction('RESET_GET_MY_TEAM_STATE');

export const createTeamRequest = createAction('CREATE_TEAM_REQUEST');
export const createTeamSuccess = createAction('CREATE_TEAM_SUCCESS');
export const createTeamFailure = createAction('CREATE_TEAM_FAILURE');
export const resetCreateTeamState = createAction('RESET_CREATE_TEAM_STATE');
export const addMemberTeamRequest = createAction('ADD_MEMBER_TEAM_REQUEST');
export const addMemberTeamSuccess = createAction('ADD_MEMBER_TEAM_SUCCESS');
export const addMemberTeamFailure = createAction('ADD_MEMBER_TEAM_FAILURE');
export const resetAddMemberTeamState = createAction(
  'RESET_ADD_MEMBER_TEAM_STATE',
);

export const updateTeamRequest = createAction('UPDATE_TEAM_REQUEST');
export const updateTeamSuccess = createAction('UPDATE_TEAM_SUCCESS');
export const updateTeamFailure = createAction('UPDATE_TEAM_FAILURE');
export const resetUpdateTeamState = createAction('RESET_UPDATE_TEAM_STATE');
export const updateCurrentTeamRequest = createAction(
  'UPDATE_CURRENT_TEAM_REQUEST',
);
export const updateCurrentTeamSuccess = createAction(
  'UPDATE_CURRENT_TEAM_SUCCESS',
);
export const updateCurrentTeamFailure = createAction(
  'UPDATE_CURRENT_TEAM_FAILURE',
);
export const resetUpdateCurrentTeamState = createAction(
  'RESET_UPDATE_CURRENT_TEAM_STATE',
);

export const deleteTeamRequest = createAction('DELETE_TEAM_REQUEST');
export const deleteTeamSuccess = createAction('DELETE_TEAM_SUCCESS');
export const deleteTeamFailure = createAction('DELETE_TEAM_FAILURE');
export const resetDeleteTeamState = createAction('RESET_DELETE_TEAM_STATE');
export const deleteMemberTeamRequest = createAction(
  'DELETE_MEMBER_TEAM_REQUEST',
);
export const deleteMemberTeamSuccess = createAction(
  'DELETE_MEMBER_TEAM_SUCCESS',
);
export const deleteMemberTeamFailure = createAction(
  'DELETE_MEMBER_TEAM_FAILURE',
);
export const resetDeleteMemberTeamState = createAction(
  'RESET_DELETE_MEMBER_TEAM_STATE',
);

export const resetTeamsState = createAction('RESET_TEAMS_STATE');
