/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const createBankAccountRequest = createAction('CREATE_BANK_ACCOUNT_REQUEST');
export const createBankAccountSuccess = createAction('CREATE_BANK_ACCOUNT_SUCCESS');
export const createBankAccountFailure = createAction('CREATE_BANK_ACCOUNT_FAILURE');
export const resetCreateBankAccountState = createAction('RESET_CREATE_BANK_ACCOUNT_STATE');

export const updateBankAccountRequest = createAction('UPDATE_BANK_ACCOUNT_REQUEST');
export const updateBankAccountSuccess = createAction('UPDATE_BANK_ACCOUNT_SUCCESS');
export const updateBankAccountFailure = createAction('UPDATE_BANK_ACCOUNT_FAILURE');
export const resetUpdateBankAccountState = createAction('RESET_UPDATE_BANK_ACCOUNT_STATE');

export const deleteBankAccountRequest = createAction('DELETE_BANK_ACCOUNT_REQUEST');
export const deleteBankAccountSuccess = createAction('DELETE_BANK_ACCOUNT_SUCCESS');
export const deleteBankAccountFailure = createAction('DELETE_BANK_ACCOUNT_FAILURE');
export const resetDeleteBankAccountState = createAction('RESET_DELETE_BANK_ACCOUNT_STATE');

export const getAllBankAccountRequest = createAction('GET_ALL_BANK_ACCOUNT_PAGE_REQUEST');
export const getAllBankAccountSuccess = createAction('GET_ALL_BANK_ACCOUNT_PAGE_SUCCESS');
export const getAllBankAccountFailure = createAction('GET_ALL_BANK_ACCOUNT_PAGE_FAILURE');

export const resetBankAccountState = createAction('RESET_BANK_ACCOUNT_STATE');
