// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getListCustomerRequest = createAction('GET_LIST_CUSTOMER_REQUEST');
export const getListCustomerSuccess = createAction('GET_LIST_CUSTOMER_SUCCESS');
export const getListCustomerFailure = createAction('GET_LIST_CUSTOMER_FAILURE');
export const resetGetListCustomerState = createAction('RESET_GET_LIST_CUSTOMER_STATE');

export const addCustomerRequest = createAction('ADD_CUSTOMER_REQUEST');
export const addCustomerSuccess = createAction('ADD_CUSTOMER_SUCCESS');
export const addCustomerFailure = createAction('ADD_CUSTOMER_FAILURE');
export const resetAddCustomerState = createAction('RESET_ADD_CUSTOMER_STATE');

export const getDetailCustomerRequest = createAction('GET_DETAIL_CUSTOMER_REQUEST');
export const getDetailCustomerSuccess = createAction('GET_DETAIL_CUSTOMER_SUCCESS');
export const getDetailCustomerFailure = createAction('GET_DETAIL_CUSTOMER_FAILURE');
export const resetGetDetailCustomerState = createAction('RESET_GET_DETAIL_CUSTOMER_STATE');

export const getAllRegionRequest = createAction('GET_ALL_REGION_CUSTOMER_REQUEST');
export const getAllRegionSuccess = createAction('GET_ALL_REGION_CUSTOMER_SUCCESS');
export const getAllRegionFailure = createAction('GET_ALL_REGION_CUSTOMER_FAILURE');

export const getAllWardRequest = createAction('GET_ALL_WARD_CUSTOMER_REQUEST');
export const getAllWardSuccess = createAction('GET_ALL_WARD_CUSTOMER_SUCCESS');
export const getAllWardFailure = createAction('GET_ALL_WARD_CUSTOMER_FAILURE');

export const getAllDistrictRequest = createAction('GET_ALL_DISTRICT_REQUEST');
export const getAllDistrictSuccess = createAction('GET_ALL_DISTRICT_SUCCESS');
export const getAllDistrictFailure = createAction('GET_ALL_DISTRICT_FAILURE');

export const deleteCustomerRequest = createAction('DELETE_CUSTOMER_REQUEST');
export const deleteCustomerSuccess = createAction('DELETE_CUSTOMER_SUCCESS');
export const deleteCustomerFailure = createAction('DELETE_CUSTOMER_FAILURE');
export const resetDeleteCustomerState = createAction('RESET_DELETE_CUSTOMER_STATE');

export const editCustomerRequest = createAction('EDIT_CUSTOMER_REQUEST');
export const editCustomerSuccess = createAction('EDIT_CUSTOMER_SUCCESS');
export const editCustomerFailure = createAction('EDIT_CUSTOMER_FAILURE');
export const resetEditCustomerState = createAction('RESET_EDIT_CUSTOMER_STATE');

export const importCustomerRequest = createAction('IMPORT_CUSTOMER_REQUEST');
export const importCustomerSuccess = createAction('IMPORT_CUSTOMER_SUCCESS');
export const importCustomerFailure = createAction('IMPORT_CUSTOMER_FAILURE');
export const resetImportCustomerState = createAction('RESET_IMPORT_CUSTOMER_STATE');

export const getAllOrderCustomerRequest = createAction('GET_ALL_ORDER_CUSTOMER_REQUEST');
export const getAllOrderCustomerSuccess = createAction('GET_ALL_ORDER_CUSTOMER_SUCCESS');
export const getAllOrderCustomerFailure = createAction('GET_ALL_ORDER_CUSTOMER_FAILURE');

export const getAllCustomerAppRequest = createAction('GET_ALL_CUSTOMER_APP_REQUEST');
export const getAllCustomerAppSuccess = createAction('GET_ALL_CUSTOMER_APP_SUCCESS');
export const getAllCustomerAppFailure = createAction('GET_ALL_CUSTOMER_APP_FAILURE');

export const updateCustomerAppRequest = createAction('UPDATE_CUSTOMER_APP_REQUEST');
export const updateCustomerAppSuccess = createAction('UPDATE_CUSTOMER_APP_SUCCESS');
export const updateCustomerAppFailure = createAction('UPDATE_CUSTOMER_APP_FAILURE');
export const resetUpdateCustomerAppState = createAction('RESET_UPDATE_CUSTOMER_APP_STATE');

export const approveCustomerAppRequest = createAction('APPROVE_CUSTOMER_APP_REQUEST');
export const approveCustomerAppSuccess = createAction('APPROVE_CUSTOMER_APP_SUCCESS');
export const approveCustomerAppFailure = createAction('APPROVE_CUSTOMER_APP_FAILURE');
export const resetApproveCustomerAppState = createAction('RESET_APPROVE_CUSTOMER_APP_STATE');

export const blockCustomerAppRequest = createAction('BLOCK_CUSTOMER_APP_REQUEST');
export const blockCustomerAppSuccess = createAction('BLOCK_CUSTOMER_APP_SUCCESS');
export const blockCustomerAppFailure = createAction('BLOCK_CUSTOMER_APP_FAILURE');
export const resetBlockCustomerAppState = createAction('RESET_BLOCK_CUSTOMER_APP_STATE');

export const createCustomerAppRequest = createAction('CREATE_CUSTOMER_APP_REQUEST');
export const createCustomerAppSuccess = createAction('CREATE_CUSTOMER_APP_SUCCESS');
export const createCustomerAppFailure = createAction('CREATE_CUSTOMER_APP_FAILURE');
export const resetCreateCustomerAppState = createAction('RESET_CREATE_CUSTOMER_APP_STATE');

export const resetCustomerState = createAction('RESET_USER_STATE');

export const addCustomerTaxRequest = createAction('ADD_CUSTOMER_TAX_REQUEST');
export const addCustomerTaxSuccess = createAction('ADD_CUSTOMER_TAX_SUCCESS');
export const addCustomerTaxFailure = createAction('ADD_CUSTOMER_TAX_FAILURE');
export const resetAddCustomerTaxState = createAction('RESET_ADD_CUSTOMER_TAX_STATE');

export const deleteCustomerTaxRequest = createAction('DELETE_CUSTOMER_TAX_REQUEST');
export const deleteCustomerTaxSuccess = createAction('DELETE_CUSTOMER_TAX_SUCCESS');
export const deleteCustomerTaxFailure = createAction('DELETE_CUSTOMER_TAX_FAILURE');
export const resetDeleteCustomerTaxState = createAction('RESET_DELETE_CUSTOMER_TAX_STATE');
