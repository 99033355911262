/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllRevenueChart({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/statistics/charts', {
      params: {
        type: 'sell',
        view: payload?.view,
        filter: payload?.filter,
        // date_from: payload && payload?.date_from,
        // date_to: payload && payload?.date_to,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllRevenueChartSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllRevenueChartFailure(messages));
    }
  }
}

function* getAllExportChart({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/statistics/charts', {
      params: {
        type: 'export',
        view: payload?.view,
        filter: payload?.filter,
        export: 'warehouse',
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllExportChartSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllExportChartFailure(messages));
    }
  }
}

function* getAllRevenueDetailChart({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/statistics/charts', {
      params: {
        type: 'revenue',
        view: payload?.view,
        filter: payload?.filter,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllRevenueDetailChartSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllRevenueDetailChartRequest(messages));
    }
  }
}

function* getExportChart({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/statistics/charts', {
      params: {
        type: 'export',
        view: 'day',
        filter: payload?.filter,
        export: 'shipping',
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getExportChartSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getExportChartFailure(messages));
    }
  }
}

function* getAllShippingChart({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/statistics/charts', {
      params: {
        type: 'export',
        view: 'day',
        filter: payload?.filter,
        export: 'shipping-all',
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllShippingsChartSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllShippingsChartFailure(messages));
    }
  }
}
// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllRevenueChartRequest, getAllRevenueChart);
  yield takeLatest(Actions.getAllRevenueDetailChartRequest, getAllRevenueDetailChart);
  yield takeLatest(Actions.getAllExportChartRequest, getAllExportChart);
  yield takeLatest(Actions.getExportChartRequest, getExportChart);
  yield takeLatest(Actions.getAllShippingsChartRequest, getAllShippingChart);
}
