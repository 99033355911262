// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetListPayoffsRequest: false,
  isGetListPayoffsSuccess: false,
  isGetListPayoffsFailure: false,
  listPayoffsState: {},
  isAddPayoffRequest: false,
  isAddPayoffSuccess: false,
  isAddPayoffFailure: false,
  isAddImagePayoffRequest: false,
  isAddImagePayoffSuccess: false,
  isAddImagePayoffFailure: false,
  imagePayoffState: [],
  isEditPayoffRequest: false,
  isEditPayoffSuccess: false,
  isEditPayoffFailure: false,
  isDeletePayoffRequest: false,
  isDeletePayoffSuccess: false,
  isDeletePayoffFailure: false,
  isGetDetailPayoffRequest: false,
  isGetDetailPayoffSuccess: false,
  isGetDetailPayoffFailure: false,
  detailPayoffState: {},
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get list Payoffs
    [Actions.getListPayoffsRequest]: (state) => ({
      ...state,
      isGetListPayoffsRequest: true,
      isGetListPayoffsSuccess: false,
      isGetListPayoffsFailure: false,
    }),
    [Actions.getListPayoffsSuccess]: (state, { payload }) => ({
      ...state,
      isGetListPayoffsRequest: false,
      isGetListPayoffsSuccess: true,
      isGetListPayoffsFailure: false,
      listPayoffsState: payload,
    }),
    [Actions.getListPayoffsFailure]: (state, { payload }) => ({
      ...state,
      isGetListPayoffsRequest: false,
      isGetListPayoffsSuccess: false,
      isGetListPayoffsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Add Payoff
    [Actions.addPayoffRequest]: (state) => ({
      ...state,
      isAddPayoffRequest: true,
      isAddPayoffSuccess: false,
      isAddPayoffFailure: false,
    }),
    [Actions.addPayoffSuccess]: (state) => ({
      ...state,
      isAddPayoffRequest: false,
      isAddPayoffSuccess: true,
      isAddPayoffFailure: false,
    }),
    [Actions.addPayoffFailure]: (state, { payload }) => ({
      ...state,
      isAddPayoffRequest: false,
      isAddPayoffSuccess: false,
      isAddPayoffFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddPayoffState]: (state) => ({
      ...state,
      isAddPayoffRequest: false,
      isAddPayoffSuccess: false,
      isAddPayoffFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region : Add Image Payoff
    [Actions.addImagePayoffRequest]: (state) => ({
      ...state,
      isAddImagePayoffRequest: true,
      isAddImagePayoffSuccess: false,
      isAddImagePayoffFailure: false,
    }),
    [Actions.addImagePayoffSuccess]: (state, { payload }) => ({
      ...state,
      isAddImagePayoffRequest: false,
      isAddImagePayoffSuccess: true,
      isAddImagePayoffFailure: false,
      imagePayoffState: payload,
    }),
    [Actions.addImagePayoffFailure]: (state, { payload }) => ({
      ...state,
      isAddImagePayoffRequest: false,
      isAddImagePayoffSuccess: false,
      isAddImagePayoffFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddImagePayoffState]: (state) => ({
      ...state,
      isAddImagePayoffRequest: false,
      isAddImagePayoffSuccess: false,
      isAddImagePayoffFailure: false,
      imagePayoffState: [],
      errorMessages: [],
    }),

    // #endregion

    // #region: edit Payoff
    [Actions.editPayoffRequest]: (state) => ({
      ...state,
      isEditPayoffRequest: true,
      isEditPayoffSuccess: false,
      isEditPayoffFailure: false,
    }),
    [Actions.editPayoffSuccess]: (state) => ({
      ...state,
      isEditPayoffRequest: false,
      isEditPayoffSuccess: true,
      isEditPayoffFailure: false,
    }),
    [Actions.editPayoffFailure]: (state, { payload }) => ({
      ...state,
      isEditPayoffRequest: false,
      isEditPayoffSuccess: false,
      isEditPayoffFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditPayoffState]: (state) => ({
      ...state,
      isEditPayoffRequest: false,
      isEditPayoffSuccess: false,
      isEditPayoffFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region : delete Payoff

    [Actions.deletePayoffRequest]: (state) => ({
      ...state,
      isDeletePayoffRequest: true,
      isDeletePayoffSuccess: false,
      isDeletePayoffFailure: false,
    }),
    [Actions.deletePayoffSuccess]: (state) => ({
      ...state,
      isDeletePayoffRequest: false,
      isDeletePayoffSuccess: true,
      isDeletePayoffFailure: false,
    }),
    [Actions.deletePayoffFailure]: (state, { payload }) => ({
      ...state,
      isDeletePayoffRequest: false,
      isDeletePayoffSuccess: false,
      isDeletePayoffFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeletePayoffState]: (state) => ({
      ...state,
      isDeletePayoffRequest: false,
      isDeletePayoffSuccess: false,
      isDeletePayoffFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region: Get detail Payoff
    [Actions.getDetailPayoffRequest]: (state) => ({
      ...state,
      isGetDetailPayoffRequest: true,
      isGetDetailPayoffFailure: false,
      isGetDetailPayoffSuccess: false,
    }),
    [Actions.getDetailPayoffSuccess]: (state, { payload }) => ({
      ...state,
      isGetDetailPayoffRequest: false,
      isGetDetailPayoffSuccess: true,
      isGetDetailPayoffFailure: false,
      detailPayoffState: payload,
    }),
    [Actions.getDetailPayoffFailure]: (state, { payload }) => ({
      ...state,
      isGetDetailPayoffFailure: true,
      isGetDetailPayoffRequest: false,
      isGetDetailPayoffSuccess: false,
      errorMessages: payload,
    }),
    [Actions.resetDetailPayoffState]: (state) => ({
      ...state,
      isGetDetailPayoffFailure: false,
      isGetDetailPayoffRequest: false,
      isGetDetailPayoffSuccess: false,
      errorMessages: [],

    }),
    // #endregion

    // #region: seen Notification
    [Actions.seenNotificationRequest]: (state) => ({
      ...state,
      isSeenNotificationRequest: true,
      isSeenNotificationFailure: false,
      isSeenNotificationSuccess: false,
    }),
    [Actions.seenNotificationSuccess]: (state) => ({
      ...state,
      isSeenNotificationRequest: false,
      isSeenNotificationSuccess: true,
      isSeenNotificationFailure: false,
    }),
    [Actions.seenNotificationFailure]: (state, { payload }) => ({
      ...state,
      isSeenNotificationFailure: true,
      isSeenNotificationRequest: false,
      isSeenNotificationSuccess: false,
      errorMessages: payload,
    }),
    // #endregion

    // #region : Change status gold
    [Actions.changeStatusGoldRequest]: (state) => ({
      ...state,
      isChangeStatusGoldRequest: true,
      isChangeStatusGoldSuccess: false,
      isChangeStatusGoldFailure: false,
    }),
    [Actions.changeStatusGoldSuccess]: (state, { payload }) => ({
      ...state,
      isChangeStatusGoldRequest: false,
      isChangeStatusGoldSuccess: true,
      isChangeStatusGoldFailure: false,
      listPayoffsState: payload,
    }),
    [Actions.changeStatusGoldFailure]: (state, { payload }) => ({
      ...state,
      isChangeStatusGoldRequest: false,
      isChangeStatusGoldSuccess: false,
      isChangeStatusGoldFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetChangeStatusGold]: (state) => ({
      ...state,
      isChangeStatusGoldRequest: false,
      isChangeStatusGoldSuccess: false,
      isChangeStatusGoldFailure: false,
    }),

    // #endregion

    // #region : Local
    [Actions.resetPayoffState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
