/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Action from './actions';

function* addConversation({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/chats', payload));
    yield put(Action.addConversationSuccess(response?.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Action.addConversationFailure(messages));
    }
  }
}

function* getConversation({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/chats', {
      params: payload,
    }));
    yield put(Action.getConversationSuccess(response?.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Action.getConversationFailure(messages));
    }
  }
}

function* addMessage({ payload }) {
  try {
    const { room, ...message } = payload;
    const response = yield call(() => axiosMicro.post(`/chats/${room}/messages`, message));
    yield put(Action.addMessageSuccess(response?.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Action.addConversationFailure(messages));
    }
  }
}

function* getMessage({ payload }) {
  try {
    const { room, ...params } = payload;
    const response = yield call(() => axiosMicro.get(`/chats/${room}/messages`, {
      params,
    }));
    yield put(Action.getMessageSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Action.getMessageFailure(messages));
    }
  }
}

function* actionMessage({ payload }) {
  try {
    const { room, ...params } = payload;
    const response = yield call(() => axiosMicro.patch(`/chats/${room}/messages`, { params }));
    yield put(Action.actionMessageSuccess(response?.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Action.actionMessageFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Action.addConversationRequest, addConversation);
  yield takeLatest(Action.getConversationRequest, getConversation);
  yield takeLatest(Action.addMessageRequest, addMessage);
  yield takeLatest(Action.getMessageRequest, getMessage);
  yield takeLatest(Action.actionMessageRequest, actionMessage);
}
