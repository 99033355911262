/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { put, call, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllShippingsPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload.params ? `/shipping-prices?${payload.params}` : '/shipping-prices'));
    yield put(Actions.getAllShippingsPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.getAllShippingsPriceFailure(message));
    }
  }
}

function* getAllShippings() {
  try {
    const response = yield call(() => axiosMicro.get('/shippings?limit=0'));
    yield put(Actions.getAllShippingsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.getAllShippingsFailure(message));
    }
  }
}
function* getAllRegionGroup() {
  try {
    const response = yield call(() => axiosMicro.get('/region-groups'));
    yield put(Actions.getAllRegionGroupShippingsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.getAllRegionGroupShippingsFailure(message));
    }
  }
}

function* addShippingsPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/shipping-prices', payload));
    yield put(Actions.addShippingsPriceSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.addShippingsPriceFailure(message));
    }
  }
}
function* addMultiShippingsPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(payload?.id ? `/shipping-prices/save/${payload?.id}` : '/shipping-prices/save', payload));
    if (response?.status === 200) {
      yield put(Actions.addMultiShippingPriceSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.addMultiShippingPriceFailure(message));
    }
  }
}

function* editShippingsPrice({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield call(() => axiosMicro.patch(`/shipping-prices/${id}`, data));
    yield put(Actions.editShippingsPriceSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.editShippingsPriceFailure(message));
    }
  }
}
function* deleteShippingsPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/shipping-prices/${payload}`));
    yield put(Actions.deleteShippingsPriceSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.deleteShippingsPriceFailure(message));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllShippingsPriceRequest, getAllShippingsPrice);
  yield takeLatest(Actions.getAllShippingsRequest, getAllShippings);
  yield takeLatest(Actions.getAllRegionGroupShippingsRequest, getAllRegionGroup);
  yield takeLatest(Actions.addShippingsPriceRequest, addShippingsPrice);
  yield takeLatest(Actions.editShippingsPriceRequest, editShippingsPrice);
  yield takeLatest(Actions.deleteShippingsPriceRequest, deleteShippingsPrice);
  yield takeLatest(Actions.addMultiShippingPriceRequest, addMultiShippingsPrice);
}
