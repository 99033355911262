/* eslint-disable import/no-extraneous-dependencies */
import { createAction } from 'redux-actions';

export const updateCurrentUserChat = createAction('UPDATE_CURRENT_USER_CHAT');

export const changeCurrentUserChat = createAction('CHANGE_CURRENT_USER_CHAT');

export const addConversationRequest = createAction('ADD_CONVERSATION_REQUEST');
export const addConversationSuccess = createAction('ADD_CONVERSATION_SUCCESS');
export const addConversationFailure = createAction('ADD_CONVERSATION_FAILURE');
export const resetAddConversationState = createAction('RESET_ADD_CONVERSATION_STATE');

export const getConversationRequest = createAction('GET_CONVERSATION_REQUEST');
export const getConversationSuccess = createAction('GET_CONVERSATION_SUCCESS');
export const getConversationFailure = createAction('GET_CONVERSATION_FAILURE');

export const addMessageRequest = createAction('ADD_MESSAGE_REQUEST');
export const addMessageSuccess = createAction('ADD_MESSAGE_SUCCESS');
export const addMessageFailure = createAction('ADD_MESSAGE_FAILURE');
export const resetAddMessageState = createAction('RESET_ADD_MESSAGE_STATE');

export const getMessageRequest = createAction('GET_MESSAGE_REQUEST');
export const getMessageSuccess = createAction('GET_MESSAGE_SUCCESS');
export const getMessageFailure = createAction('GET_MESSAGE_FAILURE');

export const actionMessageRequest = createAction('ACTION_MESSAGE_REQUEST');
export const actionMessageSuccess = createAction('ACTION_MESSAGE_SUCCESS');
export const actionMessageFailure = createAction('ACTION_MESSAGE_FAILURE');
export const resetActionMessageState = createAction('RESET_ACTION_MESSAGE_STATE');

export const resetChatState = createAction('RESET_CHAT_STATE');
