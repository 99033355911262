/* eslint-disable import/no-extraneous-dependencies */
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  isGetRevenueReportRequest: false,
  isGetRevenueReportSuccess: false,
  isGetRevenueReportFailure: false,
  listRevenueOrder: {},
  //
  isExportRevenueReportRequest: false,
  isExportRevenueReportSuccess: false,
  isExportRevenueReportFailure: false,
  linkRevenue: {},
  //
  isGetAllWarehouseReportRequest: false,
  isGetAllWarehouseReportSuccess: false,
  isGetAllWarehouseReportFailure: false,
  listWarehouseReport: {},
  //
  isChangeNoteWarehouseRequest: false,
  isChangeNoteWarehouseSuccess: false,
  isChangeNoteWarehouseFailure: false,
  //
  isGetAllExpensesReportRequest: false,
  isGetAllExpensesReportSuccess: false,
  isGetAllExpensesReportFailure: false,
  listExpensesReport: {},
  //
  isCreateAccountingRequest: false,
  isCreateAccountingSuccess: false,
  isCreateAccountingFailure: false,
  //
  isGetAllAccountingExpensesRequest: false,
  isGetAllAccountingExpensesSuccess: false,
  isGetAllAccountingExpensesFailure: false,
  listAccountingExpenses: {},
  //
  isGetAllAccountingReceiptsRequest: false,
  isGetAllAccountingReceiptsSuccess: false,
  isGetAllAccountingReceiptsFailure: false,
  listAccountingReceipts: {},
  //
  isGetAllAccountingSubmitRequest: false,
  isGetAllAccountingSubmitSuccess: false,
  isGetAllAccountingSubmitFailure: false,
  listAccountingSubmit: {},
  //
  isGetAllUserFundLogsRequest: false,
  isGetAllUserFundLogsSuccess: false,
  isGetAllUserFundLogsFailure: false,
  listUserFundLogs: {},
  //
  isGetReceiveSubmitsRequest: false,
  isGetReceiveSubmitsSuccess: false,
  isGetReceiveSubmitsFailure: false,
  listReceiveSubmits: {},
  //
  isUpdateAccountingSubmitRequest: false,
  isUpdateAccountingSubmitSuccess: false,
  isUpdateAccountingSubmitFailure: false,
  //
  //
  isCreateAccountingSubmitRequest: false,
  isCreateAccountingSubmitSuccess: false,
  isCreateAccountingSubmitFailure: false,
  //
  isCreateAccountingExpenseRequest: false,
  isCreateAccountingExpenseSuccess: false,
  isCreateAccountingExpenseFailure: false,
  //
  isDeleteAccountingExpenditureRequest: false,
  isDeleteAccountingExpenditureSuccess: false,
  isDeleteAccountingExpenditureFailure: false,
  //
  isCreateAccountingReceiptRequest: false,
  isCreateAccountingReceiptSuccess: false,
  isCreateAccountingReceiptFailure: false,
  listAccounting: {},
  //
  isGetAllOrdersRequest: false,
  isGetAllOrdersSuccess: false,
  isGetAllOrdersFailure: false,
  listOrders: {},
  //
  isGetReportFactoryBuyRequest: false,
  isGetReportFactoryBuySuccess: false,
  isGetReportFactoryBuyFailure: false,
  getReportFactoryBuyState: {},
  //
  isGetReportFactoryChangeRequest: false,
  isGetReportFactoryChangeSuccess: false,
  isGetReportFactoryChangeFailure: false,
  getReportFactoryChangeState: {},
  //
  errorMessages: [],
};

const reducer = handleActions({
  [Actions.getRevenueReportRequest]: (state) => ({
    ...state,
    isGetRevenueReportRequest: true,
    isGetRevenueReportSuccess: false,
    isGetRevenueReportFailure: false,
  }),
  [Actions.getRevenueReportSuccess]: (state, { payload }) => ({
    ...state,
    isGetRevenueReportRequest: false,
    isGetRevenueReportSuccess: true,
    isGetRevenueReportFailure: false,
    listRevenueOrder: payload,
  }),
  [Actions.getRevenueReportFailure]: (state, { payload }) => ({
    ...state,
    isGetRevenueReportRequest: false,
    isGetRevenueReportSuccess: false,
    isGetRevenueReportFailure: true,
    errorMessages: payload,
  }),

  // #region
  [Actions.getAllWarehouseReportRequest]: (state) => ({
    ...state,
    isGetAllWarehouseReportRequest: true,
    isGetAllWarehouseReportSuccess: false,
    isGetAllWarehouseReportFailure: false,
  }),
  [Actions.getAllWarehouseReportSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllWarehouseReportRequest: false,
    isGetAllWarehouseReportSuccess: true,
    isGetAllWarehouseReportFailure: false,
    listWarehouseReport: payload,
  }),
  [Actions.getAllWarehouseReportFailure]: (state, { payload }) => ({
    ...state,
    isGetAllWarehouseReportRequest: false,
    isGetAllWarehouseReportSuccess: false,
    isGetAllWarehouseReportFailure: true,
    errorMessages: payload,
  }),

  // #endregion

  // #region

  [Actions.exportRevenueReportRequest]: (state) => ({
    ...state,
    isExportRevenueReportRequest: true,
    isExportRevenueReportSuccess: false,
    isExportRevenueReportFailure: false,
  }),
  [Actions.exportRevenueReportSuccess]: (state, { payload }) => ({
    ...state,
    isExportRevenueReportRequest: false,
    isExportRevenueReportSuccess: true,
    isExportRevenueReportFailure: false,
    linkRevenue: payload,
  }),
  [Actions.exportRevenueReportFailure]: (state, { payload }) => ({
    ...state,
    isExportRevenueReportRequest: false,
    isExportRevenueReportSuccess: false,
    isExportRevenueReportFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetExportRevenueReportState]: (state) => ({
    ...state,
    isExportRevenueReportRequest: false,
    isExportRevenueReportSuccess: false,
    isExportRevenueReportFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region change note
  [Actions.changeNoteWarehouseRequest]: (state) => ({
    ...state,
    isChangeNoteWarehouseRequest: true,
    isChangeNoteWarehouseSuccess: false,
    isChangeNoteWarehouseFailure: false,
  }),
  [Actions.changeNoteWarehouseSuccess]: (state) => ({
    ...state,
    isChangeNoteWarehouseRequest: false,
    isChangeNoteWarehouseSuccess: true,
    isChangeNoteWarehouseFailure: false,
  }),
  [Actions.changeNoteWarehouseFailure]: (state, { payload }) => ({
    ...state,
    isChangeNoteWarehouseRequest: false,
    isChangeNoteWarehouseSuccess: false,
    isChangeNoteWarehouseFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetChangeNoteWarehouseState]: (state) => ({
    ...state,
    isChangeNoteWarehouseRequest: false,
    isChangeNoteWarehouseSuccess: false,
    isChangeNoteWarehouseFailure: false,
    errorMessages: [],
  }),
  // #endregion
  // #region
  [Actions.resetStateReport]: () => initialState,
  // #endregion

  // #region
  [Actions.getAllExpensesReportRequest]: (state) => ({
    ...state,
    isGetAllExpensesReportRequest: true,
    isGetAllExpensesReportSuccess: false,
    isGetAllExpensesReportFailure: false,
  }),
  [Actions.getAllExpensesReportSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllExpensesReportRequest: false,
    isGetAllExpensesReportSuccess: true,
    isGetAllExpensesReportFailure: false,
    listExpensesReport: payload,
  }),
  [Actions.getAllExpensesReportFailure]: (state, { payload }) => ({
    ...state,
    isGetAllExpensesReportRequest: false,
    isGetAllExpensesReportSuccess: false,
    isGetAllExpensesReportFailure: true,
    errorMessages: payload,
  }),

  // #endregion
  // #region
  [Actions.createAccountingRequest]: (state) => ({
    ...state,
    isCreateAccountingRequest: true,
    isCreateAccountingSuccess: false,
    isCreateAccountingFailure: false,
  }),
  [Actions.createAccountingSuccess]: (state) => ({
    ...state,
    isCreateAccountingRequest: false,
    isCreateAccountingSuccess: true,
    isCreateAccountingFailure: false,
  }),
  [Actions.createAccountingFailure]: (state, { payload }) => ({
    ...state,
    isCreateAccountingRequest: false,
    isCreateAccountingSuccess: false,
    isCreateAccountingFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateAccountingState]: (state) => ({
    ...state,
    isCreateAccountingRequest: false,
    isCreateAccountingSuccess: false,
    isCreateAccountingFailure: false,
    errorMessages: [],
  }),

  // #region
  [Actions.getAllAccountingExpensesRequest]: (state) => ({
    ...state,
    isGetAllAccountingExpensesRequest: true,
    isGetAllAccountingExpensesSuccess: false,
    isGetAllAccountingExpensesFailure: false,
  }),
  [Actions.getAllAccountingExpensesSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllAccountingExpensesRequest: false,
    isGetAllAccountingExpensesSuccess: true,
    isGetAllAccountingExpensesFailure: false,
    listAccountingExpenses: payload,
  }),
  [Actions.getAllAccountingExpensesFailure]: (state, { payload }) => ({
    ...state,
    isGetAllAccountingExpensesRequest: false,
    isGetAllAccountingExpensesSuccess: false,
    isGetAllAccountingExpensesFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  // #region
  [Actions.getAllAccountingReceiptsRequest]: (state) => ({
    ...state,
    isGetAllAccountingReceiptsRequest: true,
    isGetAllAccountingReceiptsSuccess: false,
    isGetAllAccountingReceiptsFailure: false,
  }),
  [Actions.getAllAccountingReceiptsSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllAccountingReceiptsRequest: false,
    isGetAllAccountingReceiptsSuccess: true,
    isGetAllAccountingReceiptsFailure: false,
    listAccountingReceipts: payload,
  }),
  [Actions.getAllAccountingReceiptsFailure]: (state, { payload }) => ({
    ...state,
    isGetAllAccountingReceiptsRequest: false,
    isGetAllAccountingReceiptsSuccess: false,
    isGetAllAccountingReceiptsFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  // #region
  [Actions.getAllAccountingSubmitRequest]: (state) => ({
    ...state,
    isGetAllAccountingSubmitRequest: true,
    isGetAllAccountingSubmitSuccess: false,
    isGetAllAccountingSubmitFailure: false,
  }),
  [Actions.getAllAccountingSubmitSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllAccountingSubmitRequest: false,
    isGetAllAccountingSubmitSuccess: true,
    isGetAllAccountingSubmitFailure: false,
    listAccountingSubmit: payload,
  }),
  [Actions.getAllAccountingSubmitFailure]: (state, { payload }) => ({
    ...state,
    isGetAllAccountingSubmitRequest: false,
    isGetAllAccountingSubmitSuccess: false,
    isGetAllAccountingSubmitFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  // #region
  [Actions.getAllUserFundLogsRequest]: (state) => ({
    ...state,
    isGetAllUserFundLogsRequest: true,
    isGetAllUserFundLogsSuccess: false,
    isGetAllUserFundLogsFailure: false,
  }),
  [Actions.getAllUserFundLogsSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllUserFundLogsRequest: false,
    isGetAllUserFundLogsSuccess: true,
    isGetAllUserFundLogsFailure: false,
    listUserFundLogs: payload,
  }),
  [Actions.getAllUserFundLogsFailure]: (state, { payload }) => ({
    ...state,
    isGetAllUserFundLogsRequest: false,
    isGetAllUserFundLogsSuccess: false,
    isGetAllUserFundLogsFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  // #region
  [Actions.getReceiveSubmitsRequest]: (state) => ({
    ...state,
    isGetReceiveSubmitsRequest: true,
    isGetReceiveSubmitsSuccess: false,
    isGetReceiveSubmitsFailure: false,
  }),
  [Actions.getReceiveSubmitsSuccess]: (state, { payload }) => ({
    ...state,
    isGetReceiveSubmitsRequest: false,
    isGetReceiveSubmitsSuccess: true,
    isGetReceiveSubmitsFailure: false,
    listReceiveSubmits: payload,
  }),
  [Actions.getReceiveSubmitsFailure]: (state, { payload }) => ({
    ...state,
    isGetReceiveSubmitsRequest: false,
    isGetReceiveSubmitsSuccess: false,
    isGetReceiveSubmitsFailure: true,
    errorMessages: payload,
  }),
  // #endregion
  [Actions.updateAccountingSubmitRequest]: (state) => ({
    ...state,
    isUpdateAccountingSubmitRequest: true,
    isUpdateAccountingSubmitSuccess: false,
    isUpdateAccountingSubmitFailure: false,
  }),
  [Actions.updateAccountingSubmitSuccess]: (state) => ({
    ...state,
    isUpdateAccountingSubmitRequest: false,
    isUpdateAccountingSubmitSuccess: true,
    isUpdateAccountingSubmitFailure: false,
  }),
  [Actions.updateAccountingSubmitFailure]: (state, { payload }) => ({
    ...state,
    isUpdateAccountingSubmitRequest: false,
    isUpdateAccountingSubmitSuccess: false,
    isUpdateAccountingSubmitFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetUpdateAccountingSubmit]: (state) => ({
    ...state,
    isUpdateAccountingSubmitRequest: false,
    isUpdateAccountingSubmitSuccess: false,
    isUpdateAccountingSubmitFailure: false,
    errorMessages: [],
  }),
  // #endregion
  // #region
  [Actions.createAccountingSubmitRequest]: (state) => ({
    ...state,
    isCreateAccountingSubmitRequest: true,
    isCreateAccountingSubmitSuccess: false,
    isCreateAccountingSubmitFailure: false,
  }),
  [Actions.createAccountingSubmitSuccess]: (state, { payload }) => ({
    ...state,
    isCreateAccountingSubmitRequest: false,
    isCreateAccountingSubmitSuccess: true,
    isCreateAccountingSubmitFailure: false,
    listAccounting: payload,
  }),
  [Actions.createAccountingSubmitFailure]: (state, { payload }) => ({
    ...state,
    isCreateAccountingSubmitRequest: false,
    isCreateAccountingSubmitSuccess: false,
    isCreateAccountingSubmitFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateAccountingSubmitState]: (state) => ({
    ...state,
    isCreateAccountingSubmitRequest: false,
    isCreateAccountingSubmitSuccess: false,
    isCreateAccountingSubmitFailure: false,
    errorMessages: [],
  }),
  // #endregion
  // #region
  [Actions.getAllOrdersRequest]: (state) => ({
    ...state,
    isGetAllOrdersRequest: true,
    isGetAllOrdersSuccess: false,
    isGetAllOrdersFailure: false,
  }),
  [Actions.getAllOrdersSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllOrdersRequest: false,
    isGetAllOrdersSuccess: true,
    isGetAllOrdersFailure: false,
    listOrders: payload,
  }),
  [Actions.getAllOrdersFailure]: (state, { payload }) => ({
    ...state,
    isGetAllOrdersRequest: false,
    isGetAllOrdersSuccess: false,
    isGetAllOrdersFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetGetAllOrdersState]: (state) => ({
    ...state,
    isGetAllOrdersRequest: false,
    isGetAllOrdersSuccess: false,
    isGetAllOrdersFailure: false,
    listOrders: {},
    errorMessages: [],
  }),
  // #endregion
  // #region
  [Actions.createAccountingExpenseRequest]: (state) => ({
    ...state,
    isCreateAccountingExpenseRequest: true,
    isCreateAccountingExpenseSuccess: false,
    isCreateAccountingExpenseFailure: false,
  }),
  [Actions.createAccountingExpenseSuccess]: (state) => ({
    ...state,
    isCreateAccountingExpenseRequest: false,
    isCreateAccountingExpenseSuccess: true,
    isCreateAccountingExpenseFailure: false,
  }),
  [Actions.createAccountingExpenseFailure]: (state, { payload }) => ({
    ...state,
    isCreateAccountingExpenseRequest: false,
    isCreateAccountingExpenseSuccess: false,
    isCreateAccountingExpenseFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateAccountingExpenseState]: (state) => ({
    ...state,
    isCreateAccountingExpenseRequest: false,
    isCreateAccountingExpenseSuccess: false,
    isCreateAccountingExpenseFailure: false,
    errorMessages: [],
  }),
  // #endregion
  // #region
  [Actions.deleteAccountingExpenditureRequest]: (state) => ({
    ...state,
    isDeleteAccountingExpenditureRequest: true,
    isDeleteAccountingExpenditureSuccess: false,
    isDeleteAccountingExpenditureFailure: false,
  }),
  [Actions.deleteAccountingExpenditureSuccess]: (state) => ({
    ...state,
    isDeleteAccountingExpenditureRequest: false,
    isDeleteAccountingExpenditureSuccess: true,
    isDeleteAccountingExpenditureFailure: false,
  }),
  [Actions.deleteAccountingExpenditureFailure]: (state, { payload }) => ({
    ...state,
    isDeleteAccountingExpenditureRequest: false,
    isDeleteAccountingExpenditureSuccess: false,
    isDeleteAccountingExpenditureFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetDeleteAccountingExpenditureState]: (state) => ({
    ...state,
    isDeleteAccountingExpenditureRequest: false,
    isDeleteAccountingExpenditureSuccess: false,
    isDeleteAccountingExpenditureFailure: false,
    errorMessages: [],
  }),
  // #endregion
  // #region
  [Actions.createAccountingReceiptRequest]: (state) => ({
    ...state,
    isCreateAccountingReceiptRequest: true,
    isCreateAccountingReceiptSuccess: false,
    isCreateAccountingReceiptFailure: false,
  }),
  [Actions.createAccountingReceiptSuccess]: (state) => ({
    ...state,
    isCreateAccountingReceiptRequest: false,
    isCreateAccountingReceiptSuccess: true,
    isCreateAccountingReceiptFailure: false,
  }),
  [Actions.createAccountingReceiptFailure]: (state, { payload }) => ({
    ...state,
    isCreateAccountingReceiptRequest: false,
    isCreateAccountingReceiptSuccess: false,
    isCreateAccountingReceiptFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateAccountingReceiptState]: (state) => ({
    ...state,
    isCreateAccountingReceiptRequest: false,
    isCreateAccountingReceiptSuccess: false,
    isCreateAccountingReceiptFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region Get factory buy
  [Actions.getReportFactoryBuyRequest]: (state) => ({
    ...state,
    isGetReportFactoryBuyRequest: true,
    isGetReportFactoryBuySuccess: false,
    isGetReportFactoryBuyFailure: false,
  }),
  [Actions.getReportFactoryBuySuccess]: (state, { payload }) => ({
    ...state,
    isGetReportFactoryBuyRequest: false,
    isGetReportFactoryBuySuccess: true,
    isGetReportFactoryBuyFailure: false,
    getReportFactoryBuyState: payload,
  }),
  [Actions.getReportFactoryBuyFailure]: (state, { payload }) => ({
    ...state,
    isGetReportFactoryBuyRequest: false,
    isGetReportFactoryBuySuccess: false,
    isGetReportFactoryBuyFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  // #region Get factory change
  [Actions.getReportFactoryChangeRequest]: (state) => ({
    ...state,
    isGetReportFactoryChangeRequest: true,
    isGetReportFactoryChangeSuccess: false,
    isGetReportFactoryChangeFailure: false,
  }),
  [Actions.getReportFactoryChangeSuccess]: (state, { payload }) => ({
    ...state,
    isGetReportFactoryChangeRequest: false,
    isGetReportFactoryChangeSuccess: true,
    isGetReportFactoryChangeFailure: false,
    getReportFactoryChangeState: payload,
  }),
  [Actions.getReportFactoryChangeFailure]: (state, { payload }) => ({
    ...state,
    isGetReportFactoryChangeRequest: false,
    isGetReportFactoryChangeSuccess: false,
    isGetReportFactoryChangeFailure: true,
    errorMessages: payload,
  }),
  // #endregion
}, initialState);

export default reducer;
