/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getProductPricesList({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params
        ? `/product-prices?include=product,regionGroup&${payload.params}`
        : '/product-prices?include=product,regionGroup',
    ));
    yield put(Actions.getProductPricesListSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getProductPricesListFailure(messages));
    }
  }
}

function* createProductPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-prices', payload));
    yield put(Actions.createProductPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createProductPriceFailure(messages));
    }
  }
}

function* updateProductPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/product-prices/${payload.id}`, payload.params));
    yield put(Actions.updateProductPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateProductPriceFailure(messages));
    }
  }
}

function* deleteProductPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/product-prices/${payload}`));
    yield put(Actions.deleteProductPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteProductPriceFailure(messages));
    }
  }
}

function* addProductPriceRoad({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-prices/save', { ...payload, type: 'road' }));
    yield put(Actions.addProductPriceRoadSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addProductPriceRoadFailure(messages));
    }
  }
}

function* addProductPricePlane({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-prices/save', { ...payload, type: 'airline' }));
    yield put(Actions.addProductPricePlaneSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addProductPricePlaneFailure(messages));
    }
  }
}

function* getDateProductPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params
        ? `/product-prices/date?${payload.params}`
        : '/product-prices/date',
    ));
    yield put(Actions.getDateProductPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDateProductPriceFailure(messages));
    }
  }
}

function* findProductPriceByDate({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/product-prices/${payload.data}/${payload.time}?is_company=${payload.is_company ? 1 : 0}`));
    yield put(Actions.findProductPriceByDateSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.findProductPriceByDateFailure(messages));
    }
  }
}

function* deleteProductPriceByDate({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/product-prices/${payload}`));
    yield put(Actions.deleteProductPriceByDateSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteProductPriceByDateFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getProductPricesListRequest, getProductPricesList);
  yield takeLatest(Actions.createProductPriceRequest, createProductPrice);
  yield takeLatest(Actions.updateProductPriceRequest, updateProductPrice);
  yield takeLatest(Actions.deleteProductPriceRequest, deleteProductPrice);
  yield takeLatest(Actions.addProductPriceRoadRequest, addProductPriceRoad);
  yield takeLatest(Actions.addProductPricePlaneRequest, addProductPricePlane);
  yield takeLatest(Actions.getDateProductPriceRequest, getDateProductPrice);
  yield takeLatest(
    Actions.findProductPriceByDateRequest,
    findProductPriceByDate,
  );
  yield takeLatest(
    Actions.deleteProductPriceByDateRequest,
    deleteProductPriceByDate,
  );
}
