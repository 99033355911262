/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllAdminNotification({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/notifications', {
      params: {
        limit: payload.limit,
        sortedBy: payload.sortedBy,
        orderBy: payload.orderBy,
        page: payload.page,
        include: 'user',
        search: payload.search,
        searchNull: payload?.searchNull,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllAdminNotificationSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllAdminNotificationFailure(messages));
    }
  }
}

function* getNotificationBoard({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/notifications/board', {
      params: {
        limit: 5,
        sortedBy: payload.sortedBy,
        orderBy: payload.orderBy,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getNotificationBoardSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getNotificationBoardFailure(messages));
    }
  }
}

function* createAdminNotification({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/notifications', payload));
    if (response.status === 201) {
      yield put(Actions.createAdminNotificationSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createAdminNotificationFailure(messages));
    }
  }
}

function* saveAdminNotification({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/notifications', payload));
    if (response?.status === 201) {
      yield put(Actions.saveAdminNotificationSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.saveAdminNotificationFailure(messages));
    }
  }
}

function* sendAdminNotification({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/notifications/${payload.id}`, payload));
    if (response?.status === 200) {
      yield put(Actions.sendAdminNotificationSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.sendAdminNotificationFailure(messages));
    }
  }
}

function* updateAdminNotification({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/notifications/${payload.id}`, payload));
    if (response?.status === 200) {
      yield put(Actions.updateAdminNotificationSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateAdminNotificationFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllAdminNotificationRequest, getAllAdminNotification);
  yield takeLatest(Actions.getNotificationBoardRequest, getNotificationBoard);
  yield takeLatest(Actions.createAdminNotificationRequest, createAdminNotification);
  yield takeLatest(Actions.saveAdminNotificationRequest, saveAdminNotification);
  yield takeLatest(Actions.sendAdminNotificationRequest, sendAdminNotification);
  yield takeLatest(Actions.updateAdminNotificationRequest, updateAdminNotification);
}
