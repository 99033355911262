// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetListOrdersMFPRequest: false,
  isGetListOrdersMFPSuccess: false,
  isGetListOrdersMFPFailure: false,
  listOrdersMFPState: {},
  listBuyOrdersMFPState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {

    // #region get list order lala can change
    [Actions.getListOrdersMFPRequest]: (state) => ({
      ...state,
      isGetListOrdersMFPRequest: true,
      isGetListOrdersMFPSuccess: false,
      isGetListOrdersMFPFailure: false,

    }),
    [Actions.getListOrdersMFPSuccess]: (state, { payload }) => ({
      ...state,
      isGetListOrdersMFPRequest: false,
      isGetListOrdersMFPSuccess: true,
      isGetListOrdersMFPFailure: false,
      listOrdersMFPState: payload,
    }),
    [Actions.getListOrdersMFPFailure]: (state, { payload }) => ({
      ...state,
      isGetListOrdersMFPRequest: false,
      isGetListOrdersMFPSuccess: false,
      isGetListOrdersMFPFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region get list order lala can change
    [Actions.getListBuyOrdersMFPRequest]: (state) => ({
      ...state,
      isGetListBuyOrdersMFPRequest: true,
      isGetListBuyOrdersMFPSuccess: false,
      isGetListBuyOrdersMFPFailure: false,

    }),
    [Actions.getListBuyOrdersMFPSuccess]: (state, { payload }) => ({
      ...state,
      isGetListBuyOrdersMFPRequest: false,
      isGetListBuyOrdersMFPSuccess: true,
      isGetListBuyOrdersMFPFailure: false,
      listBuyOrdersMFPState: payload,
    }),
    [Actions.getListBuyOrdersMFPFailure]: (state, { payload }) => ({
      ...state,
      isGetListBuyOrdersMFPRequest: false,
      isGetListBuyOrdersMFPSuccess: false,
      isGetListBuyOrdersMFPFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region get list order lala can change
    [Actions.pushOrderMFPRequest]: (state) => ({
      ...state,
      isPushOrderMFPRequest: true,
      isPushOrderMFPSuccess: false,
      isPushOrderMFPFailure: false,

    }),
    [Actions.pushOrderMFPSuccess]: (state, { payload }) => ({
      ...state,
      isPushOrderMFPRequest: false,
      isPushOrderMFPSuccess: true,
      isPushOrderMFPFailure: false,
      responsePushOrderMFP: payload,
    }),
    [Actions.pushOrderMFPFailure]: (state, { payload }) => ({
      ...state,
      isPushOrderMFPRequest: false,
      isPushOrderMFPSuccess: false,
      isPushOrderMFPFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region get list order lala can change
    [Actions.buysOrderMFPRequest]: (state) => ({
      ...state,
      isBuysOrderMFPRequest: true,
      isBuysOrderMFPSuccess: false,
      isBuysOrderMFPFailure: false,

    }),
    [Actions.buysOrderMFPSuccess]: (state, { payload }) => ({
      ...state,
      isBuysOrderMFPRequest: false,
      isBuysOrderMFPSuccess: true,
      isBuysOrderMFPFailure: false,
      responseBuysOrderMFP: payload,
    }),
    [Actions.buysOrderMFPFailure]: (state, { payload }) => ({
      ...state,
      isBuysOrderMFPRequest: false,
      isBuysOrderMFPSuccess: false,
      isBuysOrderMFPFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetBuysOrderMFPState]: (state) => ({
      ...state,
      isBuysOrderMFPRequest: false,
      isBuysOrderMFPSuccess: false,
      isBuysOrderMFPFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region get list order lala can change
    [Actions.editBuysOrderMFPRequest]: (state) => ({
      ...state,
      isEditBuysOrderMFPRequest: true,
      isEditBuysOrderMFPSuccess: false,
      isEditBuysOrderMFPFailure: false,
    }),
    [Actions.editBuysOrderMFPSuccess]: (state, { payload }) => ({
      ...state,
      isEditBuysOrderMFPRequest: false,
      isEditBuysOrderMFPSuccess: true,
      isEditBuysOrderMFPFailure: false,
      responseEditBuysOrderMFP: payload,
    }),
    [Actions.editBuysOrderMFPFailure]: (state, { payload }) => ({
      ...state,
      isEditBuysOrderMFPRequest: false,
      isEditBuysOrderMFPSuccess: false,
      isEditBuysOrderMFPFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditBuysOrderMFPState]: (state) => ({
      ...state,
      isEditBuysOrderMFPRequest: false,
      isEditBuysOrderMFPSuccess: false,
      isEditBuysOrderMFPFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region get list order lala can change
    [Actions.deleteBuysOrderMFPRequest]: (state) => ({
      ...state,
      isDeleteBuysOrderMFPRequest: true,
      isDeleteBuysOrderMFPSuccess: false,
      isDeleteBuysOrderMFPFailure: false,

    }),
    [Actions.deleteBuysOrderMFPSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteBuysOrderMFPRequest: false,
      isDeleteBuysOrderMFPSuccess: true,
      isDeleteBuysOrderMFPFailure: false,
      responseDeleteBuysOrderMFP: payload,
    }),
    [Actions.deleteBuysOrderMFPFailure]: (state, { payload }) => ({
      ...state,
      isDeleteBuysOrderMFPRequest: false,
      isDeleteBuysOrderMFPSuccess: false,
      isDeleteBuysOrderMFPFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteBuysOrderMFPState]: (state) => ({
      ...state,
      isDeleteBuysOrderMFPRequest: false,
      isDeleteBuysOrderMFPSuccess: false,
      isDeleteBuysOrderMFPFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region get list order lala can change
    [Actions.warehouseBuysOrderMFPRequest]: (state) => ({
      ...state,
      isWarehouseBuysOrderMFPRequest: true,
      isWarehouseBuysOrderMFPSuccess: false,
      isWarehouseBuysOrderMFPFailure: false,
    }),
    [Actions.warehouseBuysOrderMFPSuccess]: (state) => ({
      ...state,
      isWarehouseBuysOrderMFPRequest: false,
      isWarehouseBuysOrderMFPSuccess: true,
      isWarehouseBuysOrderMFPFailure: false,
    }),
    [Actions.warehouseBuysOrderMFPFailure]: (state, { payload }) => ({
      ...state,
      isWarehouseBuysOrderMFPRequest: false,
      isWarehouseBuysOrderMFPSuccess: false,
      isWarehouseBuysOrderMFPFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetWarehouseBuysOrderMFPState]: (state) => ({
      ...state,
      isWarehouseBuysOrderMFPRequest: false,
      isWarehouseBuysOrderMFPSuccess: false,
      isWarehouseBuysOrderMFPFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region get list order lala can change
    [Actions.getListOrdersRefundsMFPRequest]: (state) => ({
      ...state,
      isGetListOrdersRefundsMFPRequest: true,
      isGetListOrdersRefundsMFPSuccess: false,
      isGetListOrdersRefundsMFPFailure: false,

    }),
    [Actions.getListOrdersRefundsMFPSuccess]: (state, { payload }) => ({
      ...state,
      isGetListOrdersRefundsMFPRequest: false,
      isGetListOrdersRefundsMFPSuccess: true,
      isGetListOrdersRefundsMFPFailure: false,
      listOrdersRefundsMFPState: payload,
    }),
    [Actions.getListOrdersRefundsMFPFailure]: (state, { payload }) => ({
      ...state,
      isGetListOrdersRefundsMFPRequest: false,
      isGetListOrdersRefundsMFPSuccess: false,
      isGetListOrdersRefundsMFPFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region get list order lala can change
    [Actions.warehouseRefundsOrderMFPRequest]: (state) => ({
      ...state,
      isWarehouseRefundsOrderMFPRequest: true,
      isWarehouseRefundsOrderMFPSuccess: false,
      isWarehouseRefundsOrderMFPFailure: false,
    }),
    [Actions.warehouseRefundsOrderMFPSuccess]: (state) => ({
      ...state,
      isWarehouseRefundsOrderMFPRequest: false,
      isWarehouseRefundsOrderMFPSuccess: true,
      isWarehouseRefundsOrderMFPFailure: false,
    }),
    [Actions.warehouseRefundsOrderMFPFailure]: (state, { payload }) => ({
      ...state,
      isWarehouseRefundsOrderMFPRequest: false,
      isWarehouseRefundsOrderMFPSuccess: false,
      isWarehouseRefundsOrderMFPFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetWarehouseRefundsOrderMFPState]: (state) => ({
      ...state,
      isWarehouseRefundsOrderMFPRequest: false,
      isWarehouseRefundsOrderMFPSuccess: false,
      isWarehouseRefundsOrderMFPFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region get list order lala can change
    [Actions.createRefundOrderMFPRequest]: (state) => ({
      ...state,
      isCreateRefundOrderMFPRequest: true,
      isCreateRefundOrderMFPSuccess: false,
      isCreateRefundOrderMFPFailure: false,

    }),
    [Actions.createRefundOrderMFPSuccess]: (state, { payload }) => ({
      ...state,
      isCreateRefundOrderMFPRequest: false,
      isCreateRefundOrderMFPSuccess: true,
      isCreateRefundOrderMFPFailure: false,
      responseCreateRefundOrderMFP: payload,
    }),
    [Actions.createRefundOrderMFPFailure]: (state, { payload }) => ({
      ...state,
      isCreateRefundOrderMFPRequest: false,
      isCreateRefundOrderMFPSuccess: false,
      isCreateRefundOrderMFPFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateRefundOrderMFPState]: (state) => ({
      ...state,
      isCreateRefundOrderMFPRequest: false,
      isCreateRefundOrderMFPSuccess: false,
      isCreateRefundOrderMFPFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region get list order lala can change
    [Actions.editExchangeOrReturnOrderMFPRequest]: (state) => ({
      ...state,
      isEditExchangeOrReturnOrderMFPRequest: true,
      isEditExchangeOrReturnOrderMFPSuccess: false,
      isEditExchangeOrReturnOrderMFPFailure: false,
    }),
    [Actions.editExchangeOrReturnOrderMFPSuccess]: (state, { payload }) => ({
      ...state,
      isEditExchangeOrReturnOrderMFPRequest: false,
      isEditExchangeOrReturnOrderMFPSuccess: true,
      isEditExchangeOrReturnOrderMFPFailure: false,
      responseEditExchangeOrReturnOrderMFP: payload,
    }),
    [Actions.editExchangeOrReturnOrderMFPFailure]: (state, { payload }) => ({
      ...state,
      isEditExchangeOrReturnOrderMFPRequest: false,
      isEditExchangeOrReturnOrderMFPSuccess: false,
      isEditExchangeOrReturnOrderMFPFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditExchangeOrReturnOrderMFPState]: (state) => ({
      ...state,
      isEditExchangeOrReturnOrderMFPRequest: false,
      isEditExchangeOrReturnOrderMFPSuccess: false,
      isEditExchangeOrReturnOrderMFPFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region : Local
    [Actions.resetOrderState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
