// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

// call feedback
export const createCallFeedbackRequest = createAction('CREATE_CALL_FEEDBACK_REQUEST');
export const createCallFeedbackSuccess = createAction('CREATE_CALL_FEEDBACK_SUCCESS');
export const createCallFeedbackFailure = createAction('CREATE_CALL_FEEDBACK_FAILURE');
export const resetCreateCallFeedbackState = createAction('RESET_CREATE_CALL_FEEDBACK_STATE');

export const updateCallFeedbackRequest = createAction('UPDATE_CALL_FEEDBACK_REQUEST');
export const updateCallFeedbackSuccess = createAction('UPDATE_CALL_FEEDBACK_SUCCESS');
export const updateCallFeedbackFailure = createAction('UPDATE_CALL_FEEDBACK_FAILURE');
export const resetUpdateCallFeedbackState = createAction('RESET_UPDATE_CALL_FEEDBACK_STATE');

export const deleteCallFeedbackRequest = createAction('DELETE_CALL_FEEDBACK_REQUEST');
export const deleteCallFeedbackSuccess = createAction('DELETE_CALL_FEEDBACK_SUCCESS');
export const deleteCallFeedbackFailure = createAction('DELETE_CALL_FEEDBACK_FAILURE');
export const resetDeleteCallFeedbackState = createAction('RESET_DELETE_CALL_FEEDBACK_STATE');

export const getAllCallFeedbackRequest = createAction('GET_ALL_CALL_FEEDBACK_REQUEST');
export const getAllCallFeedbackSuccess = createAction('GET_ALL_CALL_FEEDBACK_SUCCESS');
export const getAllCallFeedbackFailure = createAction('GET_ALL_CALL_FEEDBACK_FAILURE');

// call source

export const createCallSourceRequest = createAction('CREATE_CALL_SOURCE_REQUEST');
export const createCallSourceSuccess = createAction('CREATE_CALL_SOURCE_SUCCESS');
export const createCallSourceFailure = createAction('CREATE_CALL_SOURCE_FAILURE');
export const resetCreateCallSourceState = createAction('RESET_CREATE_CALL_SOURCE_STATE');

export const updateCallSourceRequest = createAction('UPDATE_CALL_SOURCE_REQUEST');
export const updateCallSourceSuccess = createAction('UPDATE_CALL_SOURCE_SUCCESS');
export const updateCallSourceFailure = createAction('UPDATE_CALL_SOURCE_FAILURE');
export const resetUpdateCallSourceState = createAction('RESET_UPDATE_CALL_SOURCE_STATE');

export const deleteCallSourceRequest = createAction('DELETE_CALL_SOURCE_REQUEST');
export const deleteCallSourceSuccess = createAction('DELETE_CALL_SOURCE_SUCCESS');
export const deleteCallSourceFailure = createAction('DELETE_CALL_SOURCE_FAILURE');
export const resetDeleteCallSourceState = createAction('RESET_DELETE_CALL_SOURCE_STATE');

export const getAllCallSourceRequest = createAction('GET_ALL_CALL_SOURCE_REQUEST');
export const getAllCallSourceSuccess = createAction('GET_ALL_CALL_SOURCE_SUCCESS');
export const getAllCallSourceFailure = createAction('GET_ALL_CALL_SOURCE_FAILURE');

// call data

export const createCallDataRequest = createAction('CREATE_CALL_DATA_REQUEST');
export const createCallDataSuccess = createAction('CREATE_CALL_DATA_SUCCESS');
export const createCallDataFailure = createAction('CREATE_CALL_DATA_FAILURE');
export const resetCreateCallDataState = createAction('RESET_CREATE_CALL_DATA_STATE');

export const updateCallDataRequest = createAction('UPDATE_CALL_DATA_REQUEST');
export const updateCallDataSuccess = createAction('UPDATE_CALL_DATA_SUCCESS');
export const updateCallDataFailure = createAction('UPDATE_CALL_DATA_FAILURE');
export const resetUpdateCallDataState = createAction('RESET_UPDATE_CALL_DATA_STATE');

export const deleteCallDataRequest = createAction('DELETE_CALL_DATA_REQUEST');
export const deleteCallDataSuccess = createAction('DELETE_CALL_DATA_SUCCESS');
export const deleteCallDataFailure = createAction('DELETE_CALL_DATA_FAILURE');
export const resetDeleteCallDataState = createAction('RESET_DELETE_CALL_DATA_STATE');

export const getAllCallDataRequest = createAction('GET_ALL_CALL_DATA_REQUEST');
export const getAllCallDataSuccess = createAction('GET_ALL_CALL_DATA_SUCCESS');
export const getAllCallDataFailure = createAction('GET_ALL_CALL_DATA_FAILURE');

export const importCallDataRequest = createAction('IMPORT_CALL_DATA_REQUEST');
export const importCallDataSuccess = createAction('IMPORT_CALL_DATA_SUCCESS');
export const importCallDataFailure = createAction('IMPORT_CALL_DATA_FAILURE');
export const resetImportCallDataState = createAction('RESET_IMPORT_CALL_DATA_STATE');

export const filterCallDataRequest = createAction('FILTER_CALL_DATA_REQUEST');
export const filterCallDataSuccess = createAction('FILTER_CALL_DATA_SUCCESS');
export const filterCallDataFailure = createAction('FILTER_CALL_DATA_FAILURE');
export const resetFilterCallData = createAction('RESET_FILTER_CALL_DATA');

export const addCallingCallDataRequest = createAction('ADD_CALLING_CALL_DATA_REQUEST');
export const addCallingCallDataSuccess = createAction('ADD_CALLING_CALL_DATA_SUCCESS');
export const addCallingCallDataFailure = createAction('ADD_CALLING_CALL_DATA_FAILURE');
export const resetAddCallingCallData = createAction('RESET_ADD_CALLING_CALL_DATA');

// call log

export const createCallLogRequest = createAction('CREATE_CALL_LOG_REQUEST');
export const createCallLogSuccess = createAction('CREATE_CALL_LOG_SUCCESS');
export const createCallLogFailure = createAction('CREATE_CALL_LOG_FAILURE');
export const resetCreateCallLogState = createAction('RESET_CREATE_CALL_LOG_STATE');

export const updateCallLogRequest = createAction('UPDATE_CALL_LOG_REQUEST');
export const updateCallLogSuccess = createAction('UPDATE_CALL_LOG_SUCCESS');
export const updateCallLogFailure = createAction('UPDATE_CALL_LOG_FAILURE');
export const resetUpdateCallLogState = createAction('RESET_UPDATE_CALL_LOG_STATE');

export const deleteCallLogRequest = createAction('DELETE_CALL_LOG_REQUEST');
export const deleteCallLogSuccess = createAction('DELETE_CALL_LOG_SUCCESS');
export const deleteCallLogFailure = createAction('DELETE_CALL_LOG_FAILURE');
export const resetDeleteCallLogState = createAction('RESET_DELETE_CALL_LOG_STATE');

export const getAllCallLogRequest = createAction('GET_ALL_CALL_LOG_REQUEST');
export const getAllCallLogSuccess = createAction('GET_ALL_CALL_LOG_SUCCESS');
export const getAllCallLogFailure = createAction('GET_ALL_CALL_LOG_FAILURE');

export const getListCustomerNeedCallRequest = createAction('GET_LIST_CUSTOMER_NEED_CALL_REQUEST');
export const getListCustomerNeedCallSuccess = createAction('GET_LIST_CUSTOMER_NEED_CALL_SUCCESS');
export const getListCustomerNeedCallFailure = createAction('GET_LIST_CUSTOMER_NEED_CALL_FAILURE');

// label

export const getAllCallLabelRequest = createAction('GET_ALL_CALL_LABEL_REQUEST');
export const getAllCallLabelSuccess = createAction('GET_ALL_CALL_LABEL_SUCCESS');
export const getAllCallLabelFailure = createAction('GET_ALL_CALL_LABEL_FAILURE');

export const createCallLabelRequest = createAction('CREATE_CALL_LABEL_REQUEST');
export const createCallLabelSuccess = createAction('CREATE_CALL_LABEL_SUCCESS');
export const createCallLabelFailure = createAction('CREATE_CALL_LABEL_FAILURE');
export const resetCreateCallLabelState = createAction('RESET_CREATE_CALL_LABEL_STATE');

export const updateCallLabelRequest = createAction('UPDATE_CALL_LABEL_REQUEST');
export const updateCallLabelSuccess = createAction('UPDATE_CALL_LABEL_SUCCESS');
export const updateCallLabelFailure = createAction('UPDATE_CALL_LABEL_FAILURE');
export const resetUpdateCallLabelState = createAction('RESET_UPDATE_CALL_LABEL_STATE');

export const deleteCallLabelRequest = createAction('DELETE_CALL_LABEL_REQUEST');
export const deleteCallLabelSuccess = createAction('DELETE_CALL_LABEL_SUCCESS');
export const deleteCallLabelFailure = createAction('DELETE_CALL_LABEL_FAILURE');
export const resetDeleteCallLabelState = createAction('RESET_DELETE_CALL_LABEL_STATE');

export const getCallDataImportRequest = createAction('GET_CALL_DATA_IMPORT_REQUEST');
export const getCallDataImportSuccess = createAction('GET_CALL_DATA_IMPORT_SUCCESS');
export const getCallDataImportFailure = createAction('GET_CALL_DATA_IMPORT_FAILURE');
export const resetGetCallDataImport = createAction('RESET_GET_CALL_DATA_IMPORT');

export const resetCallState = createAction * ('RESET_CALL_STATE');
