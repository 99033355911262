// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const resetOrderState = createAction('RESET_ORDER_STATE');

export const getListOrdersMFPRequest = createAction('GET_LIST_ORDERS_MFP_REQUEST');
export const getListOrdersMFPSuccess = createAction('GET_LIST_ORDERS_MFP_SUCCESS');
export const getListOrdersMFPFailure = createAction('GET_LIST_ORDERS_MFP_FAILURE');

export const pushOrderMFPRequest = createAction('PUSH_ORDER_MFP_REQUEST');
export const pushOrderMFPSuccess = createAction('PUSH_ORDER_MFP_SUCCESS');
export const pushOrderMFPFailure = createAction('PUSH_ORDER_MFP_FAILURE');

export const buysOrderMFPRequest = createAction('BUYS_ORDER_MFP_REQUEST');
export const buysOrderMFPSuccess = createAction('BUYS_ORDER_MFP_SUCCESS');
export const buysOrderMFPFailure = createAction('BUYS_ORDER_MFP_FAILURE');
export const resetBuysOrderMFPState = createAction('RESET_BUYS_ORDER_MFP_STATE');

export const getListBuyOrdersMFPRequest = createAction('GET_LIST_BUY_ORDERS_MFP_REQUEST');
export const getListBuyOrdersMFPSuccess = createAction('GET_LIST_BUY_ORDERS_MFP_SUCCESS');
export const getListBuyOrdersMFPFailure = createAction('GET_LIST_BUY_ORDERS_MFP_FAILURE');

export const editBuysOrderMFPRequest = createAction('EDIT_BUYS_ORDER_MFP_REQUEST');
export const editBuysOrderMFPSuccess = createAction('EDIT_BUYS_ORDER_MFP_SUCCESS');
export const editBuysOrderMFPFailure = createAction('EDIT_BUYS_ORDER_MFP_FAILURE');
export const resetEditBuysOrderMFPState = createAction('RESET_EDIT_BUYS_ORDER_MFP_STATE');

export const deleteBuysOrderMFPRequest = createAction('DELETE_BUYS_ORDER_MFP_REQUEST');
export const deleteBuysOrderMFPSuccess = createAction('DELETE_BUYS_ORDER_MFP_SUCCESS');
export const deleteBuysOrderMFPFailure = createAction('DELETE_BUYS_ORDER_MFP_FAILURE');
export const resetDeleteBuysOrderMFPState = createAction('RESET_DELETE_BUYS_ORDER_MFP_STATE');

export const warehouseBuysOrderMFPRequest = createAction('WAREHOUSE_BUYS_ORDER_MFP_REQUEST');
export const warehouseBuysOrderMFPSuccess = createAction('WAREHOUSE_BUYS_ORDER_MFP_SUCCESS');
export const warehouseBuysOrderMFPFailure = createAction('WAREHOUSE_BUYS_ORDER_MFP_FAILURE');
export const resetWarehouseBuysOrderMFPState = createAction('RESET_WAREHOUSE_BUYS_ORDER_MFP_STATE');

export const getListOrdersRefundsMFPRequest = createAction('GET_LIST_ORDERS_REFUNDS_MFP_REQUEST');
export const getListOrdersRefundsMFPSuccess = createAction('GET_LIST_ORDERS_REFUNDS_MFP_SUCCESS');
export const getListOrdersRefundsMFPFailure = createAction('GET_LIST_ORDERS_REFUNDS_MFP_FAILURE');

export const warehouseRefundsOrderMFPRequest = createAction('WAREHOUSE_REFUNDS_ORDER_MFP_REQUEST');
export const warehouseRefundsOrderMFPSuccess = createAction('WAREHOUSE_REFUNDS_ORDER_MFP_SUCCESS');
export const warehouseRefundsOrderMFPFailure = createAction('WAREHOUSE_REFUNDS_ORDER_MFP_FAILURE');
export const resetWarehouseRefundsOrderMFPState = createAction('RESET_WAREHOUSE_REFUNDS_ORDER_MFP_STATE');

export const createRefundOrderMFPRequest = createAction('CREATE_REFUND_ORDER_MFP_REQUEST');
export const createRefundOrderMFPSuccess = createAction('CREATE_REFUND_ORDER_MFP_SUCCESS');
export const createRefundOrderMFPFailure = createAction('CREATE_REFUND_ORDER_MFP_FAILURE');
export const resetCreateRefundOrderMFPState = createAction('RESET_CREATE_REFUND_ORDER_MFP_STATE');

export const editExchangeOrReturnOrderMFPRequest = createAction('EDIT_EXCHANGE_OR_RETURN_ORDER_MFP_REQUEST');
export const editExchangeOrReturnOrderMFPSuccess = createAction('EDIT_EXCHANGE_OR_RETURN_ORDER_MFP_SUCCESS');
export const editExchangeOrReturnOrderMFPFailure = createAction('EDIT_EXCHANGE_OR_RETURN_ORDER_MFP_FAILURE');
export const resetEditExchangeOrReturnOrderMFPState = createAction('RESET_EDIT_EXCHANGE_OR_RETURN_ORDER_MFP_STATE');
