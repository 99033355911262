/* eslint-disable import/no-extraneous-dependencies */
import { put, call, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Action from './actions';

function* getAllSchedulesCalendar({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/schedules?${payload.params}` : '/schedules',
    ));
    yield put(Action.getAllSchedulesCalendarSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.getAllSchedulesCalendarFailure(message));
    }
  }
}

function* createScheduleCalendar({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/schedules', payload));
    yield put(Action.createScheduleCalendarSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.createScheduleCalendarFailure(message));
    }
  }
}

function* updateScheduleCalendar({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/schedules/${payload.id}`, payload.params));
    yield put(Action.updateScheduleCalendarSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.updateScheduleCalendarFailure(message));
    }
  }
}

function* deleteScheduleCalendar({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/schedules/${payload}`));
    yield put(Action.deleteScheduleCalendarSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Action.deleteScheduleCalendarFailure(message));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    Action.getAllSchedulesCalendarRequest,
    getAllSchedulesCalendar,
  );
  yield takeLatest(
    Action.createScheduleCalendarRequest,
    createScheduleCalendar,
  );
  yield takeLatest(
    Action.updateScheduleCalendarRequest,
    updateScheduleCalendar,
  );
  yield takeLatest(
    Action.deleteScheduleCalendarRequest,
    deleteScheduleCalendar,
  );
}
