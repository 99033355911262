/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';
// #region blog
function* getAllBlog({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/blogs', {
      params: {
        limit: payload.limit,
        sortedBy: payload.sortedBy,
        orderBy: payload.orderBy,
        page: payload.page,
        // include: 'user',
        search: payload.search,
        // searchNull: payload?.searchNull,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllBlogSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllBlogFailure(messages));
    }
  }
}

function* createBlog({ payload }) {
  try {
    const form = new FormData();
    form.append('title', payload.title);
    form.append('content', payload.content);
    form.append('image', payload.image);
    const response = yield call(() => axiosMicro.post('/blogs', form));
    if (response?.status === 201) {
      yield put(Actions.createBlogSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createBlogFailure(messages));
    }
  }
}

function* updateBlog({ payload }) {
  try {
    const form = new FormData();
    if (payload.title) {
      form.append('title', payload.title);
    }
    if (payload.content) {
      form.append('content', payload.content);
    }
    if (payload.image) {
      form.append('image', payload.image);
    }
    if (payload.sort) {
      form.append('sort', payload.sort);
    }
    const response = yield call(() => axiosMicro.post(`/blogs/${payload.id}?_method=patch`, form));
    if (response?.status === 200) {
      yield put(Actions.updateBlogSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateBlogFailure(messages));
    }
  }
}
function* deleteBlog({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/blogs/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteBlogSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteBlogFailure(messages));
    }
  }
}
// #endregion

// #region voucher
function* getAllVoucher({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/vouchers', {
      params: {
        limit: payload.limit,
        sortedBy: payload.sortedBy,
        orderBy: payload.orderBy,
        page: payload.page,
        // include: 'user',
        search: payload.search,
        // searchNull: payload?.searchNull,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllVoucherSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllVoucherFailure(messages));
    }
  }
}

function* createVoucher({ payload }) {
  try {
    const form = new FormData();
    form.append('title', payload.title);
    form.append('content', payload.content);
    form.append('image', payload.image);
    form.append('score', payload.score);
    form.append('type', payload.type);
    form.append('code', payload.code);
    // if (payload.type !== 'fee') {
    form.append('discount', payload.discount);
    // }

    const response = yield call(() => axiosMicro.post('/vouchers', form));
    if (response?.status === 201) {
      yield put(Actions.createVoucherSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createVoucherFailure(messages));
    }
  }
}

function* updateVoucher({ payload }) {
  try {
    const form = new FormData();
    if (
      payload.title
    ) {
      form.append('title', payload.title);
    }
    if (payload.content) {
      form.append('content', payload.content);
    }
    if (payload.image) {
      form.append('image', payload.image);
    }
    if (payload.score) {
      form.append('score', payload.score);
    }
    if (payload.type) {
      form.append('type', payload.type);
    }
    if (payload.code) {
      form.append('code', payload.code);
    }
    // if (payload.type !== 'fee') {
    // if (payload.discount) {
    form.append('discount', payload.discount);

    // }
    if (payload.active) {
      form.append('active', 1);
    } else {
      form.append('active', 0);
    }

    if (payload.sort) {
      form.append('sort', payload.sort);
    }

    const response = yield call(() => axiosMicro.post(`/vouchers/${payload.id}?_method=patch`, form));
    if (response?.status === 200) {
      yield put(Actions.updateVoucherSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateVoucherFailure(messages));
    }
  }
}
function* deleteVoucher({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/vouchers/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteVoucherSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteVoucherFailure(messages));
    }
  }
}

// #endregion
// #region gift
function* getAllGift({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/gifts', {
      params: {
        limit: payload.limit,
        sortedBy: payload.sortedBy,
        orderBy: payload.orderBy,
        page: payload.page,
        // include: 'user',
        search: payload.search,
        // searchNull: payload?.searchNull,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllGiftSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllGiftFailure(messages));
    }
  }
}

function* createGift({ payload }) {
  try {
    const form = new FormData();
    form.append('title', payload.title);
    form.append('content', payload.content);
    form.append('image', payload.image);
    form.append('score', payload.score);
    form.append('code', payload.code);
    const response = yield call(() => axiosMicro.post('/gifts', form));
    if (response?.status === 201) {
      yield put(Actions.createGiftSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createGiftFailure(messages));
    }
  }
}

function* updateGift({ payload }) {
  try {
    const form = new FormData();
    if (payload.title) {
      form.append('title', payload.title);
    }
    if (payload.content) {
      form.append('content', payload.content);
    }
    if (payload.image) {
      form.append('image', payload.image);
    }
    if (payload.score) {
      form.append('score', payload.score);
    }
    if (payload.code) {
      form.append('code', payload.code);
    }
    if (payload.sort) {
      form.append('sort', payload.sort);
    }
    if (payload.active) {
      form.append('active', 1);
    } else {
      form.append('active', 0);
    }
    const response = yield call(() => axiosMicro.post(`/gifts/${payload.id}?_method=patch`, form));
    if (response?.status === 200) {
      yield put(Actions.updateGiftSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateGiftFailure(messages));
    }
  }
}
function* deleteGift({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/gifts/${payload}`));
    if (response?.status === 204) {
      yield put(Actions.deleteGiftSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteGiftFailure(messages));
    }
  }
}

// #endregion

// #region config

function* saveConfig({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/configs/setting', payload));
    if (response?.status === 200) {
      yield put(Actions.saveConfigSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.saveConfigFailure(messages));
    }
  }
}

function* getConfig() {
  try {
    const response = yield call(() => axiosMicro.get('/configs/setting'));
    if (response?.status === 200) {
      yield put(Actions.getConfigAppSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getConfigAppFailure(messages));
    }
  }
}

// #endregion

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllBlogRequest, getAllBlog);
  yield takeLatest(Actions.createBlogRequest, createBlog);
  yield takeLatest(Actions.updateBlogRequest, updateBlog);
  yield takeLatest(Actions.deleteBlogRequest, deleteBlog);
  yield takeLatest(Actions.getAllVoucherRequest, getAllVoucher);
  yield takeLatest(Actions.createVoucherRequest, createVoucher);
  yield takeLatest(Actions.updateVoucherRequest, updateVoucher);
  yield takeLatest(Actions.deleteVoucherRequest, deleteVoucher);
  yield takeLatest(Actions.getAllGiftRequest, getAllGift);
  yield takeLatest(Actions.createGiftRequest, createGift);
  yield takeLatest(Actions.updateGiftRequest, updateGift);
  yield takeLatest(Actions.deleteGiftRequest, deleteGift);
  yield takeLatest(Actions.saveConfigRequest, saveConfig);
  yield takeLatest(Actions.getConfigAppRequest, getConfig);
}
