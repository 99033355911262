// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const createTransportOrderRequest = createAction('CREATE_TRANSPORT_ORDER_REQUEST');
export const createTransportOrderSuccess = createAction('CREATE_TRANSPORT_ORDER_SUCCESS');
export const createTransportOrderFailure = createAction('CREATE_TRANSPORT_ORDER_FAILURE');
export const resetCreateTransportOrderState = createAction('RESET_CREATE_TRANSPORT_ORDER_STATE');

export const getServicesOrderRequest = createAction('GET_SERVICES_ORDER_REQUEST');
export const getServicesOrderSuccess = createAction('GET_SERVICES_ORDER_SUCCESS');
export const getServicesOrderFailure = createAction('GET_SERVICES_ORDER_FAILURE');

export const getAllTransportsRequest = createAction('GET_ALL_TRANSPORT_REQUEST');
export const getAllTransportsSuccess = createAction('GET_ALL_TRANSPORT_SUCCESS');
export const getAllTransportsFailure = createAction('GET_ALL_TRANSPORT_FAILURE');

export const resetTransportState = createAction('RESET_TRANSPORT_STATE');
