/* eslint-disable import/no-extraneous-dependencies */
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  // #region : blog
  //
  isGetAllBlogRequest: false,
  isGetAllBlogSuccess: false,
  isGetAllBlogFailure: false,
  listAllBlog: {},
  //
  isCreateBlogRequest: false,
  isCreateBlogSuccess: false,
  isCreateBlogFailure: false,
  //
  isUpdateBlogRequest: false,
  isUpdateBlogSuccess: false,
  isUpdateBlogFailure: false,
  //
  isDeleteBlogRequest: false,
  isDeleteBlogSuccess: false,
  isDeleteBlogFailure: false,
  // #endregion
  // #region : voucher
  //
  isGetAllVoucherRequest: false,
  isGetAllVoucherSuccess: false,
  isGetAllVoucherFailure: false,
  listAllVoucher: {},
  //
  isCreateVoucherRequest: false,
  isCreateVoucherSuccess: false,
  isCreateVoucherFailure: false,
  //
  isUpdateVoucherRequest: false,
  isUpdateVoucherSuccess: false,
  isUpdateVoucherFailure: false,
  //
  isDeleteVoucherRequest: false,
  isDeleteVoucherSuccess: false,
  isDeleteVoucherFailure: false,
  // #endregion
  // #region : voucher
  //
  isGetAllGiftRequest: false,
  isGetAllGiftSuccess: false,
  isGetAllGiftFailure: false,
  listAllGift: {},
  //
  isCreateGiftRequest: false,
  isCreateGiftSuccess: false,
  isCreateGiftFailure: false,
  //
  isUpdateGiftRequest: false,
  isUpdateGiftSuccess: false,
  isUpdateGiftFailure: false,
  //
  isDeleteGiftRequest: false,
  isDeleteGiftSuccess: false,
  isDeleteGiftFailure: false,
  // #endregion

  // #region config

  isSaveConfigRequest: false,
  isSaveConfigSuccess: false,
  isSaveConfigFailure: false,

  isGetConfigAppRequest: false,
  isGetConfigAppSuccess: false,
  isGetConfigAppFailure: false,
  // #endregion
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : blog
    //
    [Actions.getAllBlogRequest]: (state) => ({
      ...state,
      isGetAllBlogRequest: true,
      isGetAllBlogSuccess: false,
      isGetAllBlogFailure: false,
    }),
    [Actions.getAllBlogSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllBlogRequest: false,
      isGetAllBlogSuccess: true,
      isGetAllBlogFailure: false,
      listAllBlog: payload,
    }),
    [Actions.getAllBlogFailure]: (state, { payload }) => ({
      ...state,
      isGetAllBlogRequest: false,
      isGetAllBlogSuccess: false,
      isGetAllBlogFailure: true,
      errorMessages: payload,
    }),
    //

    [Actions.createBlogRequest]: (state) => ({
      ...state,
      isCreateBlogRequest: true,
      isCreateBlogSuccess: false,
      isCreateBlogFailure: false,
    }),
    [Actions.createBlogSuccess]: (state) => ({
      ...state,
      isCreateBlogRequest: false,
      isCreateBlogSuccess: true,
      isCreateBlogFailure: false,
    }),
    [Actions.createBlogFailure]: (state, { payload }) => ({
      ...state,
      isCreateBlogRequest: false,
      isCreateBlogSuccess: false,
      isCreateBlogFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateBlogState]: (state) => ({
      ...state,
      isCreateBlogRequest: false,
      isCreateBlogSuccess: false,
      isCreateBlogFailure: false,
      errorMessages: [],
    }),
    //

    [Actions.updateBlogRequest]: (state) => ({
      ...state,
      isUpdateBlogRequest: true,
      isUpdateBlogSuccess: false,
      isUpdateBlogFailure: false,
    }),
    [Actions.updateBlogSuccess]: (state) => ({
      ...state,
      isUpdateBlogRequest: false,
      isUpdateBlogSuccess: true,
      isUpdateBlogFailure: false,
    }),
    [Actions.updateBlogFailure]: (state, { payload }) => ({
      ...state,
      isUpdateBlogRequest: false,
      isUpdateBlogSuccess: false,
      isUpdateBlogFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateBlogState]: (state) => ({
      ...state,
      isUpdateBlogRequest: false,
      isUpdateBlogSuccess: false,
      isUpdateBlogFailure: false,
      errorMessages: [],
    }),
    //

    [Actions.deleteBlogRequest]: (state) => ({
      ...state,
      isDeleteBlogRequest: true,
      isDeleteBlogSuccess: false,
      isDeleteBlogFailure: false,
    }),
    [Actions.deleteBlogSuccess]: (state) => ({
      ...state,
      isDeleteBlogRequest: false,
      isDeleteBlogSuccess: true,
      isDeleteBlogFailure: false,
    }),
    [Actions.deleteBlogFailure]: (state, { payload }) => ({
      ...state,
      isDeleteBlogRequest: false,
      isDeleteBlogSuccess: false,
      isDeleteBlogFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteBlogState]: (state) => ({
      ...state,
      isDeleteBlogRequest: false,
      isDeleteBlogSuccess: false,
      isDeleteBlogFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region : voucher
    //
    [Actions.getAllVoucherRequest]: (state) => ({
      ...state,
      isGetAllVoucherRequest: true,
      isGetAllVoucherSuccess: false,
      isGetAllVoucherFailure: false,
    }),
    [Actions.getAllVoucherSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllVoucherRequest: false,
      isGetAllVoucherSuccess: true,
      isGetAllVoucherFailure: false,
      listAllVoucher: payload,
    }),
    [Actions.getAllVoucherFailure]: (state, { payload }) => ({
      ...state,
      isGetAllVoucherRequest: false,
      isGetAllVoucherSuccess: false,
      isGetAllVoucherFailure: true,
      errorMessages: payload,
    }),
    //

    [Actions.createVoucherRequest]: (state) => ({
      ...state,
      isCreateVoucherRequest: true,
      isCreateVoucherSuccess: false,
      isCreateVoucherFailure: false,
    }),
    [Actions.createVoucherSuccess]: (state) => ({
      ...state,
      isCreateVoucherRequest: false,
      isCreateVoucherSuccess: true,
      isCreateVoucherFailure: false,
    }),
    [Actions.createVoucherFailure]: (state, { payload }) => ({
      ...state,
      isCreateVoucherRequest: false,
      isCreateVoucherSuccess: false,
      isCreateVoucherFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateVoucherState]: (state) => ({
      ...state,
      isCreateVoucherRequest: false,
      isCreateVoucherSuccess: false,
      isCreateVoucherFailure: false,
      errorMessages: [],
    }),
    //

    [Actions.updateVoucherRequest]: (state) => ({
      ...state,
      isUpdateVoucherRequest: true,
      isUpdateVoucherSuccess: false,
      isUpdateVoucherFailure: false,
    }),
    [Actions.updateVoucherSuccess]: (state) => ({
      ...state,
      isUpdateVoucherRequest: false,
      isUpdateVoucherSuccess: true,
      isUpdateVoucherFailure: false,
    }),
    [Actions.updateVoucherFailure]: (state, { payload }) => ({
      ...state,
      isUpdateVoucherRequest: false,
      isUpdateVoucherSuccess: false,
      isUpdateVoucherFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateVoucherState]: (state) => ({
      ...state,
      isUpdateVoucherRequest: false,
      isUpdateVoucherSuccess: false,
      isUpdateVoucherFailure: false,
      errorMessages: [],
    }),
    //

    [Actions.deleteVoucherRequest]: (state) => ({
      ...state,
      isDeleteVoucherRequest: true,
      isDeleteVoucherSuccess: false,
      isDeleteVoucherFailure: false,
    }),
    [Actions.deleteVoucherSuccess]: (state) => ({
      ...state,
      isDeleteVoucherRequest: false,
      isDeleteVoucherSuccess: true,
      isDeleteVoucherFailure: false,
    }),
    [Actions.deleteVoucherFailure]: (state, { payload }) => ({
      ...state,
      isDeleteVoucherRequest: false,
      isDeleteVoucherSuccess: false,
      isDeleteVoucherFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteVoucherState]: (state) => ({
      ...state,
      isDeleteVoucherRequest: false,
      isDeleteVoucherSuccess: false,
      isDeleteVoucherFailure: false,
      errorMessages: [],
    }),

    // #endregion
    // #region : gift
    //
    [Actions.getAllGiftRequest]: (state) => ({
      ...state,
      isGetAllGiftRequest: true,
      isGetAllGiftSuccess: false,
      isGetAllGiftFailure: false,
    }),
    [Actions.getAllGiftSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllGiftRequest: false,
      isGetAllGiftSuccess: true,
      isGetAllGiftFailure: false,
      listAllGift: payload,
    }),
    [Actions.getAllGiftFailure]: (state, { payload }) => ({
      ...state,
      isGetAllGiftRequest: false,
      isGetAllGiftSuccess: false,
      isGetAllGiftFailure: true,
      errorMessages: payload,
    }),
    //

    [Actions.createGiftRequest]: (state) => ({
      ...state,
      isCreateGiftRequest: true,
      isCreateGiftSuccess: false,
      isCreateGiftFailure: false,
    }),
    [Actions.createGiftSuccess]: (state) => ({
      ...state,
      isCreateGiftRequest: false,
      isCreateGiftSuccess: true,
      isCreateGiftFailure: false,
    }),
    [Actions.createGiftFailure]: (state, { payload }) => ({
      ...state,
      isCreateGiftRequest: false,
      isCreateGiftSuccess: false,
      isCreateGiftFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateGiftState]: (state) => ({
      ...state,
      isCreateGiftRequest: false,
      isCreateGiftSuccess: false,
      isCreateGiftFailure: false,
      errorMessages: [],
    }),
    //

    [Actions.updateGiftRequest]: (state) => ({
      ...state,
      isUpdateGiftRequest: true,
      isUpdateGiftSuccess: false,
      isUpdateGiftFailure: false,
    }),
    [Actions.updateGiftSuccess]: (state) => ({
      ...state,
      isUpdateGiftRequest: false,
      isUpdateGiftSuccess: true,
      isUpdateGiftFailure: false,
    }),
    [Actions.updateGiftFailure]: (state, { payload }) => ({
      ...state,
      isUpdateGiftRequest: false,
      isUpdateGiftSuccess: false,
      isUpdateGiftFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateGiftState]: (state) => ({
      ...state,
      isUpdateGiftRequest: false,
      isUpdateGiftSuccess: false,
      isUpdateGiftFailure: false,
      errorMessages: [],
    }),
    //

    [Actions.deleteGiftRequest]: (state) => ({
      ...state,
      isDeleteGiftRequest: true,
      isDeleteGiftSuccess: false,
      isDeleteGiftFailure: false,
    }),
    [Actions.deleteGiftSuccess]: (state) => ({
      ...state,
      isDeleteGiftRequest: false,
      isDeleteGiftSuccess: true,
      isDeleteGiftFailure: false,
    }),
    [Actions.deleteGiftFailure]: (state, { payload }) => ({
      ...state,
      isDeleteGiftRequest: false,
      isDeleteGiftSuccess: false,
      isDeleteGiftFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteGiftState]: (state) => ({
      ...state,
      isDeleteGiftRequest: false,
      isDeleteGiftSuccess: false,
      isDeleteGiftFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region config

    [Actions.saveConfigRequest]: (state) => ({
      ...state,
      isSaveConfigRequest: true,
      isSaveConfigSuccess: false,
      isSaveConfigFailure: false,
    }),
    [Actions.saveConfigSuccess]: (state) => ({
      ...state,
      isSaveConfigRequest: false,
      isSaveConfigSuccess: true,
      isSaveConfigFailure: false,
    }),
    [Actions.saveConfigFailure]: (state, { payload }) => ({
      ...state,
      isSaveConfigRequest: false,
      isSaveConfigSuccess: false,
      isSaveConfigFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetSaveConfigState]: (state) => ({
      ...state,
      isSaveConfigRequest: false,
      isSaveConfigSuccess: false,
      isSaveConfigFailure: false,
      errorMessages: [],
    }),

    [Actions.getConfigAppRequest]: (state) => ({
      ...state,
      isGetConfigAppRequest: true,
      isGetConfigAppSuccess: false,
      isGetConfigAppFailure: false,
    }),
    [Actions.getConfigAppSuccess]: (state, { payload }) => ({
      ...state,
      isGetConfigAppRequest: false,
      isGetConfigAppSuccess: true,
      isGetConfigAppFailure: false,
      configAppState: payload,
    }),
    [Actions.getConfigAppFailure]: (state, { payload }) => ({
      ...state,
      isGetConfigAppRequest: false,
      isGetConfigAppSuccess: false,
      isGetConfigAppFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    [Actions.resetAccumulationState]: () => initialState,
  },
  initialState,
);

export default reducer;
