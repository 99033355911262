/* eslint-disable import/no-extraneous-dependencies */
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  //
  isCreateBankAccountRequest: false,
  isCreateBankAccountSuccess: false,
  isCreateBankAccountFailure: false,
  responseCreateBankAccount: {},
  //
  isUpdateBankAccountRequest: false,
  isUpdateBankAccountSuccess: false,
  isUpdateBankAccountFailure: false,
  //
  isDeleteBankAccountRequest: false,
  isDeleteBankAccountSuccess: false,
  isDeleteBankAccountFailure: false,
  //
  isGetAllBankAccountRequest: false,
  isGetAllBankAccountSuccess: false,
  isGetAllBankAccountFailure: false,
  listBankAccountState: {},
  //
  errorMessages: [],
};

const reducer = handleActions({
  // #region create
  [Actions.createBankAccountRequest]: (state) => ({
    ...state,
    isCreateBankAccountRequest: true,
    isCreateBankAccountSuccess: false,
    isCreateBankAccountFailure: false,
  }),
  [Actions.createBankAccountSuccess]: (state, { payload }) => ({
    ...state,
    isCreateBankAccountRequest: false,
    isCreateBankAccountSuccess: true,
    isCreateBankAccountFailure: false,
    responseCreateBankAccount: payload,
  }),
  [Actions.createBankAccountFailure]: (state, { payload }) => ({
    ...state,
    isCreateBankAccountRequest: false,
    isCreateBankAccountSuccess: false,
    isCreateBankAccountFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetCreateBankAccountState]: (state) => ({
    ...state,
    isCreateBankAccountRequest: false,
    isCreateBankAccountSuccess: false,
    isCreateBankAccountFailure: false,
    errorMessages: [],
  }),
  // #endregion
  // #region update
  [Actions.updateBankAccountRequest]: (state) => ({
    ...state,
    isUpdateBankAccountRequest: true,
    isUpdateBankAccountSuccess: false,
    isUpdateBankAccountFailure: false,
  }),
  [Actions.updateBankAccountSuccess]: (state) => ({
    ...state,
    isUpdateBankAccountRequest: false,
    isUpdateBankAccountSuccess: true,
    isUpdateBankAccountFailure: false,
  }),
  [Actions.updateBankAccountFailure]: (state, { payload }) => ({
    ...state,
    isUpdateBankAccountRequest: false,
    isUpdateBankAccountSuccess: false,
    isUpdateBankAccountFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetUpdateBankAccountState]: (state) => ({
    ...state,
    isUpdateBankAccountRequest: false,
    isUpdateBankAccountSuccess: false,
    isUpdateBankAccountFailure: false,
    errorMessages: [],
  }),
  // #endregion
  // #region delete
  [Actions.deleteBankAccountRequest]: (state) => ({
    ...state,
    isDeleteBankAccountRequest: true,
    isDeleteBankAccountSuccess: false,
    isDeleteBankAccountFailure: false,
  }),
  [Actions.deleteBankAccountSuccess]: (state) => ({
    ...state,
    isDeleteBankAccountRequest: false,
    isDeleteBankAccountSuccess: true,
    isDeleteBankAccountFailure: false,
  }),
  [Actions.deleteBankAccountFailure]: (state, { payload }) => ({
    ...state,
    isDeleteBankAccountRequest: false,
    isDeleteBankAccountSuccess: false,
    isDeleteBankAccountFailure: true,
    errorMessages: payload,
  }),
  [Actions.resetDeleteBankAccountState]: (state) => ({
    ...state,
    isDeleteBankAccountRequest: false,
    isDeleteBankAccountSuccess: false,
    isDeleteBankAccountFailure: false,
    errorMessages: [],
  }),
  // #endregion

  // #region list region
  [Actions.getAllBankAccountRequest]: (state) => ({
    ...state,
    isGetAllBankAccountRequest: true,
    isGetAllBankAccountSuccess: false,
    isGetAllBankAccountFailure: false,
  }),
  [Actions.getAllBankAccountSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllBankAccountRequest: false,
    isGetAllBankAccountSuccess: true,
    isGetAllBankAccountFailure: false,
    listBankAccountState: payload,
  }),
  [Actions.getAllBankAccountFailure]: (state, { payload }) => ({
    ...state,
    isGetAllBankAccountRequest: false,
    isGetAllBankAccountSuccess: false,
    isGetAllBankAccountFailure: true,
    errorMessages: payload,
  }),
  // #endregion

  // #region local
  [Actions.resetBankAccountState]: () => initialState,
  // #endregion
}, initialState);

export default reducer;
