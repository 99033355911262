/* eslint-disable import/no-extraneous-dependencies */
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  //
  isGetAllNotificationsRequest: false,
  isGetAllNotificationsSuccess: false,
  isGetAllNotificationsFailure: false,
  listNotifications: {},
  listNotificationsMy: {},
  //
  isChangeStatusNotificationRequest: false,
  isChangeStatusNotificationSuccess: false,
  isChangeStatusNotificationFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region
    [Actions.getAllNotificationPageRequest]: (state) => ({
      ...state,
      isGetAllNotificationsRequest: true,
      isGetAllNotificationsSuccess: false,
      isGetAllNotificationsFailure: false,
    }),
    [Actions.getAllNotificationPageSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllNotificationsRequest: false,
      isGetAllNotificationsSuccess: true,
      isGetAllNotificationsFailure: false,
      listNotifications: payload,
    }),
    [Actions.getAllNotificationPageFailure]: (state, { payload }) => ({
      ...state,
      isGetAllNotificationsRequest: false,
      isGetAllNotificationsSuccess: false,
      isGetAllNotificationsFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.getAllNotificationMyPageRequest]: (state) => ({
      ...state,
      isGetAllNotificationsMyRequest: true,
      isGetAllNotificationsMySuccess: false,
      isGetAllNotificationsMyFailure: false,
    }),
    [Actions.getAllNotificationMyPageSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllNotificationsMyRequest: false,
      isGetAllNotificationsMySuccess: true,
      isGetAllNotificationsMyFailure: false,
      listNotificationsMy: payload,
    }),
    [Actions.getAllNotificationMyPageFailure]: (state, { payload }) => ({
      ...state,
      isGetAllNotificationsMyRequest: false,
      isGetAllNotificationsMySuccess: false,
      isGetAllNotificationsMyFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.changeStatusNotificationPageRequest]: (state) => ({
      ...state,
      isChangeStatusNotificationRequest: true,
      isChangeStatusNotificationSuccess: false,
      isChangeStatusNotificationFailure: false,
    }),
    [Actions.changeStatusNotificationPageSuccess]: (state) => ({
      ...state,
      isChangeStatusNotificationRequest: false,
      isChangeStatusNotificationSuccess: true,
      isChangeStatusNotificationFailure: false,
    }),
    [Actions.changeStatusNotificationPageFailure]: (state, { payload }) => ({
      ...state,
      isChangeStatusNotificationRequest: false,
      isChangeStatusNotificationSuccess: false,
      isChangeStatusNotificationFailure: true,
      errorMessages: payload,
    }),
  }, initialState,
);
export default reducer;
