// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getListProductTypesRequest = createAction(
  'GET_LIST_PRODUCT_TYPES_REQUEST',
);
export const getListProductTypesSuccess = createAction(
  'GET_LIST_PRODUCT_TYPES_SUCCESS',
);
export const getListProductTypesFailure = createAction(
  'GET_LIST_PRODUCT_TYPES_FAILURE',
);

export const createProductTypeRequest = createAction(
  'CREATE_PRODUCT_TYPE_REQUEST',
);
export const createProductTypeSuccess = createAction(
  'CREATE_PRODUCT_TYPE_SUCCESS',
);
export const createProductTypeFailure = createAction(
  'CREATE_PRODUCT_TYPE_FAILURE',
);
export const resetCreateProductTypeState = createAction(
  'RESET_CREATE_PRODUCT_TYPE_STATE',
);

export const updateProductTypeRequest = createAction(
  'UPDATE_PRODUCT_TYPE_REQUEST',
);
export const updateProductTypeSuccess = createAction(
  'UPDATE_PRODUCT_TYPE_SUCCESS',
);
export const updateProductTypeFailure = createAction(
  'UPDATE_PRODUCT_TYPE_FAILURE',
);
export const resetUpdateProductTypeState = createAction(
  'RESET_UPDATE_PRODUCT_TYPE_STATE',
);

export const deleteProductTypeRequest = createAction(
  'DELETE_PRODUCT_TYPE_REQUEST',
);
export const deleteProductTypeSuccess = createAction(
  'DELETE_PRODUCT_TYPE_SUCCESS',
);
export const deleteProductTypeFailure = createAction(
  'DELETE_PRODUCT_TYPE_FAILURE',
);
export const resetDeleteProductTypeState = createAction(
  'RESET_DELETE_PRODUCT_TYPE_STATE',
);

export const resetProductTypeState = createAction('RESET_PRODUCT_TYPE_STATE');
