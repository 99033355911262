/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/customers?include=region,customerTaxes${payload.params}` : '/customers?include=region,customerTaxes', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getListCustomerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListCustomerFailure(messages));
    }
  }
}

function* addCustomer({ payload }) {
  try {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('phone', payload.phone);
    formData.append('region_id', payload.region_id);
    if (payload.email) {
      formData.append('email', payload.email);
    }
    if (payload.address) {
      formData.append('address', payload.address);
    }
    if (payload.type) {
      formData.append('type', payload.type);
    }
    if (payload.note) {
      formData.append('note', payload.note);
    }
    formData.append('zns', payload?.zns ? 1 : 0);
    if (payload.bill) {
      formData.append('bill', payload.bill ? 1 : 0);
    }
    if (payload.bill && payload.bill_info) {
      formData.append('bill_info', payload.bill_info);
    }
    if (payload.isCompany) {
      formData.append('is_company', payload.isCompany ? 1 : 0);
    }
    const response = yield call(() => axiosMicro.post('/customers', formData));
    yield put(Actions.addCustomerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addCustomerFailure(messages));
    }
  }
}

function* getDetailCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/customers/${payload}`, {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getDetailCustomerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDetailCustomerFailure(messages));
    }
  }
}

function* getAllRegion({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload ? `/regions${payload}` : '/regions?search=parent_id:0&limit=0', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getAllRegionSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllRegionFailure(messages));
    }
  }
}

function* getAllWard({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload ? `/regions${payload}` : '/regions?search=parent_id:0&limit=0', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getAllWardSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllWardFailure(messages));
    }
  }
}

function* getAllDistrict({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload && payload < 65 ? `/regions?search=parent_id:${payload}&limit=0` : '/regions?search=parent_id:0&limit=0'));
    yield put(Actions.getAllDistrictSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllDistrictFailure(messages));
    }
  }
}

function* deleteCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/customers/${payload}`));
    yield put(Actions.deleteCustomerSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCustomerFailure(messages));
    }
  }
}

function* editCustomer({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield axiosMicro.patch(`/customers/${id}`, data, {
      params: {
        include: 'region,orderLatest,orders',
      },
    });
    yield put(Actions.editCustomerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.editCustomerFailure(messages));
    }
  }
}

function* importCustomer({ payload }) {
  try {
    const form = new FormData();
    form.append('excel', payload);
    const response = yield axiosMicro.post('/customers/import', form);
    yield put(Actions.importCustomerSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.importCustomerFailure(messages));
    }
  }
}

function* getAllOrderCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/orders?search=customer_id:${payload.id}?${payload.params}` : `/orders?search=customer_id:${payload.id}`));
    yield put(Actions.getAllOrderCustomerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllOrderCustomerFailure(messages));
    }
  }
}

function* getAllCustomerApp({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/sales/customers?${payload.params}` : '/sales/customers', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllCustomerAppSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllCustomerAppFailure(messages));
    }
  }
}

function* updateCustomerApp({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/sales/customers/${payload.id}`, payload.params));
    yield put(Actions.updateCustomerAppSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateCustomerAppFailure(messages));
    }
  }
}

function* approveCustomerApp({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/sales/customers/${payload.id}`, payload.params));
    yield put(Actions.approveCustomerAppSuccess(response?.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.approveCustomerAppFailure(messages));
    }
  }
}

function* blockCustomerApp({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/sales/customers/${payload.id}`, { status: payload.status }));
    yield put(Actions.blockCustomerAppSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.blockCustomerAppFailure(messages));
    }
  }
}
function* createCustomerApp({ payload }) {
  try {
    yield call(() => axiosMicro.post('/sales/customers', payload));
    yield put(Actions.createCustomerAppSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createCustomerAppFailure(messages));
    }
  }
}

function* addCustomerTax({ payload }) {
  try {
    const formData = new FormData();
    formData.append('taxcode', payload.taxcode);
    formData.append('name', payload.name);
    formData.append('phone', payload.phone);
    formData.append('email', payload.email);
    formData.append('address', payload.address);
    formData.append('customer_id', payload.customer_id);
    const response = yield call(() => axiosMicro.post('/customers/tax', formData));
    payload.id = response?.data?.data?.id;
    yield put(Actions.addCustomerTaxSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addCustomerTaxFailure(messages));
    }
  }
}

function* deleteCustomerTax({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/customers/tax/${payload}`));
    yield put(Actions.deleteCustomerTaxSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCustomerTaxFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListCustomerRequest, getListCustomer);
  yield takeLatest(Actions.addCustomerRequest, addCustomer);
  yield takeLatest(Actions.getDetailCustomerRequest, getDetailCustomer);
  yield takeLatest(Actions.getAllRegionRequest, getAllRegion);
  yield takeLatest(Actions.getAllWardRequest, getAllWard);
  yield takeLatest(Actions.getAllDistrictRequest, getAllDistrict);
  yield takeLatest(Actions.deleteCustomerRequest, deleteCustomer);
  yield takeLatest(Actions.editCustomerRequest, editCustomer);
  yield takeLatest(Actions.importCustomerRequest, importCustomer);
  yield takeLatest(Actions.getAllOrderCustomerRequest, getAllOrderCustomer);
  yield takeLatest(Actions.getAllCustomerAppRequest, getAllCustomerApp);
  yield takeLatest(Actions.updateCustomerAppRequest, updateCustomerApp);
  yield takeLatest(Actions.approveCustomerAppRequest, approveCustomerApp);
  yield takeLatest(Actions.blockCustomerAppRequest, blockCustomerApp);
  yield takeLatest(Actions.createCustomerAppRequest, createCustomerApp);
  yield takeLatest(Actions.addCustomerTaxRequest, addCustomerTax);
  yield takeLatest(Actions.deleteCustomerTaxRequest, deleteCustomerTax);
}
