// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllAdminNotificationRequest = createAction('GET_ALL_ADMIN_NOTIFICATION_REQUEST');
export const getAllAdminNotificationSuccess = createAction('GET_ALL_ADMIN_NOTIFICATION_SUCCESS');
export const getAllAdminNotificationFailure = createAction('GET_ALL_ADMIN_NOTIFICATION_FAILURE');

export const createAdminNotificationRequest = createAction('CREATE_ADMIN_NOTIFICATION_REQUEST');
export const createAdminNotificationSuccess = createAction('CREATE_ADMIN_NOTIFICATION_SUCCESS');
export const createAdminNotificationFailure = createAction('CREATE_ADMIN_NOTIFICATION_FAILURE');
export const resetCreateAdminNotificationState = createAction('RESET_CREATE_ADMIN_NOTIFICATION_STATE');

export const saveAdminNotificationRequest = createAction('SAVE_ADMIN_NOTIFICATION_REQUEST');
export const saveAdminNotificationSuccess = createAction('SAVE_ADMIN_NOTIFICATION_SUCCESS');
export const saveAdminNotificationFailure = createAction('SAVE_ADMIN_NOTIFICATION_FAILURE');
export const resetSaveAdminNotificationState = createAction('RESET_SAVE_ADMIN_NOTIFICATION_STATE');

export const sendAdminNotificationRequest = createAction('SEND_ADMIN_NOTIFICATION_REQUEST');
export const sendAdminNotificationSuccess = createAction('SEND_ADMIN_NOTIFICATION_SUCCESS');
export const sendAdminNotificationFailure = createAction('SEND_ADMIN_NOTIFICATION_FAILURE');
export const resetSendAdminNotificationState = createAction('RESET_SEND_ADMIN_NOTIFICATION_STATE');

export const updateAdminNotificationRequest = createAction('UPDATE_ADMIN_NOTIFICATION_REQUEST');
export const updateAdminNotificationSuccess = createAction('UPDATE_ADMIN_NOTIFICATION_SUCCESS');
export const updateAdminNotificationFailure = createAction('UPDATE_ADMIN_NOTIFICATION_FAILURE');
export const resetUpdateAdminNotificationState = createAction('RESET_UPDATE_ADMIN_NOTIFICATION_STATE');

export const getNotificationBoardRequest = createAction('GET_NOTIFICATION_BOARD_REQUEST');
export const getNotificationBoardSuccess = createAction('GET_NOTIFICATION_BOARD_SUCCESS');
export const getNotificationBoardFailure = createAction('GET_NOTIFICATION_BOARD_FAILURE');

export const resetAdminState = createAction('RESET_ADMIN_STATE');
